import React, {
  useContext, useEffect,
} from 'react'
import { Store } from '../../../store/store'
import HomeComponent from './Home'
import RightSideLoading from '../../../components/RightSideLoading'

const Home = () => {
  const { state, dispatch } = useContext(Store)

  useEffect(() => {
    dispatch({ type: 'LOADING_OFF' })
  }, [])

  return (
    // <RightSideLoading>
      <HomeComponent />
    // </RightSideLoading>
    )
}

export default Home
