/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useContext, useEffect } from 'react'
import Profile from './Profile'
import { Store } from '../../../store/store'
import {
  logOut, getUser, regenerateTokens, capitalizeFirstLetter, getUserFromApi, userRoles,
} from '../../helpers'
import { deleteUser, updateUserInfos, updateUserInfoFromAdmin, deactivateAccount as deactivateAccountAPI } from './helpers'
import ModalComponent from '../../../components/Modal'
import { useParams } from 'react-router-dom'
import { dispatchAlert } from '../../menu/helpers'

export default ({
  match,
  history,
}) => {
  const params = useParams()
  const userId = Number(params.userId || getUser().id)
  const isAdmin = !!useParams().userId
  const { state, dispatch } = useContext(Store)

  const { alerts,
    language: {
      profile: {
        mailAddress: mailAddressLang,
        firstname: firstnameLang,
        lastname: lastnameLang,
        gender: genderLang,
        male: maleLang,
        female: femaleLang,
        titleModalError: titleModalErrorLang,
        buttonModalError: buttonModalErrorLang,
        errorModal: errorModalLang,
        cancelTitle: cancelTitleLang,
        updateProfileButton: updateProfileButtonLang,
        desactivateSuccess:desactivateSuccessLang,
        activateSuccess: activateSuccessLang,
        role: {
          label: roleLabelLang,
          roles: rolesLang
        },
        poste: {
          label: posteLabelLang,
          postes: postesLang
        }
      },
    },
  } = state
  const [user, setUser] = useState()
  useEffect(() => {
    if (!userId || userId === getUser().id) {
      setUser(getUser())
    } else {
      getUserFromApi(userId)
        .then(u => setUser(u))
    }
  }, [userId])
  const [inputs, setInputs] = useState([])
  useEffect(() => {
    if (user) {
      const adminInputs = [
        {
          type: 'select',
          name: 'role',
          label: roleLabelLang,
          options: rolesLang.map(({ role, value }) => ({
            key: value + 1, value: value + 1, text: capitalizeFirstLetter(role),
          })),
          value: user.role + 1
        },
        {
          type: 'select',
          name: 'poste',
          label: posteLabelLang,
          options: postesLang.map(({ poste, value }) => ({
            key: value + 1, value: value + 1, text: capitalizeFirstLetter(poste),
          })),
          value: user.poste + 1
        },
      ]
      setInputs([
        {
          type: 'text',
          name: 'email',
          label: mailAddressLang,
          disabled: !isAdmin,
          value: user.email,
        },
        {
          type: 'text',
          name: 'firstname',
          label: firstnameLang,
          placeholder: firstnameLang,
          value: user.firstname,
        },
        {
          type: 'text',
          name: 'lastname',
          label: lastnameLang,
          placeholder: lastnameLang,
          value: user.lastname,
        },
        {
          type: 'radio',
          name: 'gender',
          label: genderLang,
          radios: [{
            label: maleLang, value: 'male',
          },
          {
            label: femaleLang, value: 'female',
          }],
          value: user.gender === 'male' ? maleLang : femaleLang,
        },
        ...(isAdmin ? adminInputs : []),
      ].filter(e => e))
      setIconId(user.icon)
    }
  }, [user, state.languageValue])

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [modal, setModal] = useState(false)
  const translateGender = {
    Woman:'female',
    Femme:'female',
    Man:'male',
    Homme:'male',
  }
  const infosToUpdate = infosUpdated => {
    const infos = {}
    const parseSelect = key => key === 'poste' || key === 'role' ? Number(infosUpdated[key]) - 1 : infosUpdated[key]
    Object.keys(infosUpdated).forEach(key => {
      if (
        parseSelect(key) !== user[key]
        &&
        infosUpdated[key] !== null
        && infosUpdated[key] !== undefined
        && infosUpdated[key] !== '') {
        if (key === 'poste' || key === 'role') {
          infos[key] = Number(infosUpdated[key]) - 1
        } else if (key==='gender'){
          infos[key] = translateGender[infosUpdated[key]]
        }
        else {
          infos[key] = infosUpdated[key]
        }
      }
    })
    return JSON.stringify(infos) === '{}' ? null : infos
  }
  const [iconId, setIconId] = useState()
  const sendForm = async infosUpdated => {
    setLoading(true)
    try {
      const infos = infosToUpdate(infosUpdated)
      if (!infos) {
        throw new Error(errorModalLang)
      }
      await (isAdmin ? updateUserInfoFromAdmin(infos, userId) : updateUserInfos(infos))
      if (!isAdmin || getUser().id === userId) {
        await regenerateTokens(dispatch)
      }
      setLoading(false)
      dispatchAlert(dispatch, alerts, { type: 'success' })
      setUser({ ...user, ...infos })
      return true
    } catch (error) {
      setError(error.message)
      setModal(true)
      setLoading(false)
      dispatchAlert(dispatch, alerts, { type: 'error', message: error.message })
    }
  }

  const deactivateAccount = async () =>{
    try{
      await deactivateAccountAPI(userId)
      if (!!user.is_email_verified && (!isAdmin || getUser().id === userId)) {
        logOut()
      }
      dispatchAlert(dispatch, alerts, { type: 'success', message: user.is_email_verified ? desactivateSuccessLang : activateSuccessLang })
      setUser({...user, is_email_verified: !!!user.is_email_verified})
    }catch(e){
      dispatchAlert(dispatch, alerts, { type: 'error', message: e.message })
    }
    // deleteUser(user.id)
  }

  const updateIcon = icon => sendForm({ icon }).then(() => setIconId(icon))
  const closeModal = () => setModal(false)
  const push = url => history.push(url)
  return (
    <React.Fragment>
      {!!user && <Profile
        updateIcon={updateIcon}
        updateProfileButtonLang={updateProfileButtonLang}
        // cancelTitle={cancelTitleLang}
        inputs={inputs}
        language={state.language.profile}
        user={user}
        push={push}
        // deleteAccount={deleteAccount}
        sendForm={sendForm}
        loading={loading}
        iconId={iconId}
        active={!!user.is_email_verified}
        deactivateAccount={deactivateAccount}
        isAdmin={getUser().role === userRoles.ADMIN || getUser().role ===  userRoles.SUPERADMIN}
      />}
      <ModalComponent
        onClose={closeModal}
        open={modal}
        title={titleModalErrorLang}
        description={error}
        onClick={closeModal}
        button={buttonModalErrorLang}
      />
    </React.Fragment>
  )
}
