import React from 'react';
import { Button, makeStyles, Typography, Tooltip, Box } from '@material-ui/core';
import { Folder as FolderIcon } from '@material-ui/icons';
import moment from 'moment';
import AddBoxIcon from '@mui/icons-material/AddBox';

const useStyles = makeStyles(({ spacing }) => ({
    root: {
        maxWidth: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    folderIcon: {
        height: 50,
        width: 'auto',
        color: 'rgba(74, 74, 74, .55)',
    },
    newFolderIcon: {
        height: '50px!important',
        width: '50px!important',
        color: 'rgba(0, 0, 0, .71)',
    },
    item: {
        maxWidth: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: spacing(1.75),
    },
    tooltipTitle: {
        textAlign: 'center',
        whiteSpace: 'pre-line',
    },
    label: {
        color: 'rgba(0, 0, 0, .87)',
        marginTop: spacing(1),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        maxWidth: 66,
    },
    icon: {
        height: 50,
        width: 'auto',
        color: 'rgba(0, 0, 0, .55)',
    },
}));

const ButtonFolder = ({ children, onClick, create = false, folder = {} }) => {
    const classes = useStyles();
    return (
        create ? <div className={classes.root}>
            <Button onClick={onClick} classes={{ root: classes.button }}>
                <AddBoxIcon classes={{ root: classes.newFolderIcon }} />
            </Button>
            {!create && <Typography classes={{ root: classes.label }}>{children}</Typography>}
        </div> :
            <Box className={classes.item}>
                <div style={{ display: 'flex' }}>
                    <div>
                        <Tooltip
                            title={
                                <div className={classes.tooltipTitle}>
                                    {`${folder.name}\n\n${moment(folder.updated_at || folder.created_at).fromNow()}`}
                                </div>
                            }
                        >
                            <Button onClick={onClick} classes={{ root: classes.button }}>
                                <FolderIcon classes={{ root: classes.folderIcon }} />
                            </Button>
                        </Tooltip>
                        {!create && <Typography classes={{ root: classes.label }}>{children}</Typography>}
                    </div>
                </div>
            </Box>

    );
};

export default ButtonFolder;
