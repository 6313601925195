import React, { useCallback, useMemo, useState, useRef } from 'react';
import LanguageSelection from '../../components/LanguageSelection';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase, InputBase, Grid, Popover, Avatar, IconButton, Typography, Tooltip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Link, useHistory } from 'react-router-dom';
import { getUser, logOut, userRoles } from '../helpers';
import Search from './Search/index';
import Notifications from './Notifications';
import debounce from 'lodash/debounce';
import AppIcons from '../../components/AppIcons';
import UserAvatar from '../../components/UserAvatar';
import PrivateMessageLink from './PrivateMessagesLink';

const useStyles = makeStyles({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'visible',
        padding: 8,
    },
    item: {
        padding: 8,
        borderBottom: '1px solid lightgrey',
    },
    supportIcon: {
        color: '#fff',
    },
    supportTypography: {
        color: '#5458D7',
        fontWeight: 'bold'
    },
});

const HeaderComponent = ({
    setRedirect,
    close,
    isLogin,
    language: {
        logOut: logOutLang,
        profile: profileLang,
        support: supportLang,
        hello: helloLang,
        settings: settingsLang,
        privateMessages: privateMessagesLang,
        tasks: tasksLang,
        notes: notesLang,
        calendar: calendarLang,
    },
}) => {
    const history = useHistory();
    const classes = useStyles();
    const [search, setSearch] = useState('');
    const [openSearch, setOpenSearch] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchInApi, setSearchInApi] = useState('');
    const open = Boolean(anchorEl);
    const inputRef = useRef(null);
    const id = open ? 'simple-popover' : undefined;

    const logOutUser = () => {
        logOut();
        setRedirect('/login');
    };

    const handleSearch = useCallback(
        debounce((value) => {
            value.length > 1 && setSearchInApi(value);
        }, 500),
        []
    );
    const closePopUp = (handleClick = false) => {
        if (inputRef.current.value && !handleClick) {
            inputRef.current.value = '';
        }
        setAnchorEl(null);
        setOpenSearch(false);
    };

    let user = useMemo(() => getUser(), [localStorage.getItem('profile')]);

    return (
        <Grid container item className="header-container" onMouseLeave={close}>
            {isLogin ? (
                <div className="header-logged-in">
                    <Notifications />
                    <PrivateMessageLink title={privateMessagesLang} />
                    <Link className="shadow linear-gradient support-button border-radius-5" to="/support">
                        <AppIcons name="settings" size={15} />
                        <Typography classes={{ root: classes.supportTypography }}>{supportLang}</Typography>
                    </Link>
                    <InputBase
                        startAdornment={<SearchIcon fontSize="large" />}
                        className="bg-white shadow border-radius-5 header-search-bar"
                        fullWidth={true}
                        inputRef={inputRef}
                        onChange={(e) => {
                            setSearch(e.target.value);
                            setOpenSearch(true);
                            handleSearch(e.target.value);
                        }}
                    />
                    {openSearch ? <Search handleClickAway={closePopUp} search={searchInApi} /> : null}
                    <div className="header-icon-container bg-white shadow border-radius-5">
                        <Tooltip placement="top" title={tasksLang}>
                            <IconButton onClick={() => history.push('/task')}>
                                <AppIcons name="task" size={31} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title={calendarLang}>
                            <IconButton onClick={() => history.push('/calendar')}>
                                <AppIcons name="calendar" size={31} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title={notesLang}>
                            <IconButton onClick={() => history.push('/notes')}>
                                <AppIcons name="note" size={31} />
                            </IconButton>
                        </Tooltip>
                        <div className="separator bg-grey" />
                        <ButtonBase aria-describedby={id} onClick={(event) => setAnchorEl(event.currentTarget)}>
                            {helloLang}&nbsp;{user.firstname ? user.firstname : 'Me'}
                            <UserAvatar
                                style={{ marginLeft: 8 }}
                                icon={user.icon || null}
                                firstName={user.firstname}
                                lastName={user.lastname}
                            />
                        </ButtonBase>
                        <Popover
                            open={open}
                            id={id}
                            anchorEl={anchorEl}
                            classes={{ paper: classes.paper }}
                            onClose={closePopUp}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            anchorPosition={{
                                top: 15,
                                left: 15,
                            }}
                        >
                            <LanguageSelection />
                            {(user.role === userRoles.ADMIN || user.role === userRoles.SUPERADMIN) && (
                                <ButtonBase classes={{ root: classes.item }} onClick={() => history.push('/setting')}>
                                    {settingsLang}
                                </ButtonBase>
                            )}
                            {user.role !== userRoles.GUEST && (
                                <ButtonBase classes={{ root: classes.item }} onClick={() => history.push('/profile')}>
                                    {profileLang}
                                </ButtonBase>
                            )}
                            <ButtonBase classes={{ root: classes.item }} className="color-orange" onClick={logOutUser}>
                                {logOutLang}
                            </ButtonBase>
                        </Popover>
                    </div>
                </div>
            ) : (
                    <LanguageSelection />
                )}
        </Grid>
    );
};

export default HeaderComponent;
