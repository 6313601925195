import React, { useState, useContext, useCallback } from 'react';
import { Button, makeStyles } from '@material-ui/core';

import { Store } from '../../store/store';
import { dispatchAlert } from '../../containers/menu/helpers';
import { createPrivateLeenk } from '../../containers/leenks/Leenks/helpers';
import PopperPaper from '../PopperPaper';
import SearchUserField from '../SearchUserField';

import styles from './StartPrivateLeenkButton.styles';

const useStyles = makeStyles(styles);

const StartPrivateLeenkButton = ({ setCurrent, updateTypes, changeLeenk, leenks = [] }) => {
    const classes = useStyles();
    const [anchorElement, setAnchorElement] = useState();

    const openPopper = Boolean(anchorElement);

    const closePopper = useCallback(() => setAnchorElement(null), []);

    const handleButtonClick = useCallback((event) => setAnchorElement(event.currentTarget), []);

    const {
        state: { alerts, language:{leenks: {newMessage:newMessageLang}} },
        dispatch,
    } = useContext(Store);
    const onChange = async (event, newValue) => {
        if (!newValue) return;
        closePopper();
        const leenk = leenks.find(
            (leenk) =>
                (leenk.users || []).length === 2 &&
                (leenk.users || []).findIndex((user) => user.id === newValue.id) !== -1
        );
        if (leenk) return changeLeenk(leenk);
        try {
            const leenk = await createPrivateLeenk([Number(newValue.id)]);
            leenk.name = leenk.id;
            leenk.parentId = leenk.parent;
            setCurrent((curr) => ({
                ...curr,
                leenks: [...curr.leenks, leenk],
                leenk,
                folderId: leenk.parent,
            }));
            updateTypes(leenk);
            changeLeenk(leenk);
            dispatchAlert(dispatch, alerts, { type: 'success' });
        } catch (e) {
            console.log(e.message);
            dispatchAlert(dispatch, alerts, { type: 'error' });
        }
    };

    return (
        <>
            <PopperPaper
                classes={{ paper: classes.popperPaper }}
                anchorEl={anchorElement}
                open={openPopper}
                onClose={closePopper}
            >
                <SearchUserField {...{ onChange }} />
            </PopperPaper>
            <Button classes={{ root: classes.root }} variant="outlined" onClick={handleButtonClick}>
                {newMessageLang}
            </Button>
        </>
    );
};

export default StartPrivateLeenkButton;
