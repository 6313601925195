import React, { useState, useEffect, useContext } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { upperFirst } from 'lodash';

import { getTaskUsers, createTaskAccess, removeTaskAccess } from './helpers';
import { getUsers } from '../spots/Spots/helpers';
import { Store } from '../../store/store';
import { dispatchAlert } from '../menu/helpers';

const UserTaskList = ({
	taskId,
}) => {
	const { state: { alerts, language: { settings:{users:usersLang}, form: {
		add: addLang, 
	} } }, dispatch } = useContext(Store)

	const [users, setUsers] = useState([])
	const [options, setOptions] = useState([])
	const [usersFromApi, setUsersFromApi] = useState([])
	const removeAlreadyAddedUsers = usersFromApi => usersFromApi.filter(user => users.findIndex(({ id }) => id === user.id) === -1)
	const getRemovedUser = newUsers => users.find(user => newUsers.findIndex(({ id }) => id === user.id) === -1)
	const getAddedUser = newUsers => newUsers.find(user => users.findIndex(({ id }) => id === user.id) === -1)
	const removeFromUsers = userId => users.filter(user => user.id !== userId)
	const onChange = async (evt, newValues) => {
		if (newValues.length === users.length)
			return
		try {
			if (newValues.length < users.length) {
				const { id: userId } = getRemovedUser(newValues)
				await removeTaskAccess(userId, taskId)
				setUsers(removeFromUsers(userId))
			} else {
				const newUser = getAddedUser(newValues)
				await createTaskAccess(taskId, newUser.id)
				setUsers(users => [...users, newUser])
			}
			dispatchAlert(dispatch, alerts, { type: 'success' })
		} catch (e) {
			dispatchAlert(dispatch, alerts, { type: 'error' })
			console.log(e.message)
		}
	}
	useEffect(() => {
		setOptions(removeAlreadyAddedUsers(usersFromApi))
	}, [users, usersFromApi])

	useEffect(() => {
		getTaskUsers(taskId)
			.then(setUsers)
		getUsers()
			.then(setUsersFromApi)
	}, [])
	return (
		<Autocomplete

		style={{marginBottom: 8}}
	disableClearable
			onChange={onChange}
			multiple
			id="tags-outlined"
			options={options}
			value={users}
			getOptionLabel={(option) => upperFirst(option.firstname) + ' ' + upperFirst(option.lastname)}
			defaultValue={[]}
			filterSelectedOptions
			renderInput={(params) => (
				<TextField
				label={usersLang} 
					{...params}
					variant="outlined"
				/>
			)}
		/>
	);
};

export default UserTaskList;