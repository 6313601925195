export default ({ spacing, palette, shadows }) => ({
    paper: {
        padding: spacing(2),
    },
    popper: {
        zIndex: 1200,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.71em',
            marginLeft: spacing(0.5),
            marginRight: spacing(0.5),
            '&::before': {
                transformOrigin: '0 100%',
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.71em',
            marginLeft: spacing(0.5),
            marginRight: spacing(0.5),
            '&::before': {
                transformOrigin: '100% 0',
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.71em',
            height: '1em',
            width: '0.71em',
            marginTop: spacing(0.5),
            marginBottom: spacing(0.5),
            '&::before': {
                transformOrigin: '100% 100%',
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.71em',
            height: '1em',
            width: '0.71em',
            marginTop: spacing(0.5),
            marginBottom: spacing(0.5),
            '&::before': {
                transformOrigin: '0 0',
            },
        },
    },
    arrow: {
        overflow: 'hidden',
        position: 'absolute',
        width: '1em',
        height: '0.71em' /* = width / sqrt(2) = (length of the hypotenuse) */,
        boxSizing: 'border-box',
        color: palette.background.paper,
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: '100%',
            height: '100%',
            boxShadow: shadows[1],
            backgroundColor: 'currentColor',
            transform: 'rotate(45deg)',
        },
    },
});
