import React, { useContext, useEffect, useState } from 'react';
import { Grid } from "@material-ui/core";
import { DataGrid } from '@material-ui/data-grid'
import { Store } from "../../../store/store";
import useStyles from '../../setting/Setting/style';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
export const RenderFolderList = ({ elements: elementsActual = [], folderName }) => {
	const { state: { language: {
		folder: {
			name: nameLang,
			creationDate: creationDateLang,
			updatedDate: updatedDateLang,
			type: typeLang,
			size: sizeLang,
		}
	} } } = useContext(Store)
	const iconTypes = {
		FILE: 0,
		FOLDER: 1
	}

	const columns = [
		{
			field: '', headerName: '', renderCell: ({ row: { icon } }) => {
				return icon === iconTypes.FOLDER ? <FolderIcon /> : <InsertDriveFileIcon />
			},
			flex: 0.2
		},
		{ field: 'name', headerName: nameLang, flex: 0.8 },
		{ field: 'created_at', headerName: creationDateLang, flex: 0.3 },
		{ field: 'updated_at', headerName: updatedDateLang, flex: 0.3 },
		{ field: 'extension', headerName: typeLang, flex: 0.2 },
		{ field: 'id', headerName: 'Action', flex: 0.2, renderCell: ({ row: { buttonClick } }) =>  buttonClick
		 },
		// { field: 'size', headerName: sizeLang,}
	]
	const classes = useStyles()
	const elementToRow = element => ({
		...element,
		extension: element.extension || '',
		icon: element.extension ? iconTypes.FILE : iconTypes.FOLDER
	})
	const getRowsFromElements = elements => elements.map(elementToRow)
	const [rows, setRows] = useState(getRowsFromElements(elementsActual))
	useEffect(() => {
		setRows(getRowsFromElements(elementsActual))
	}, [elementsActual])


	const onRowClick = ({row, field}) => {
		if (field !== 'id')
			row.onClick({...row, id: row.elementId})
	}
	return (
		<Grid container item xs direction="column">
			<DataGrid
				onCellClick={onRowClick}
				rows={rows.map((e, id) => ({...e, id, elementId: e.id}))}
				columns={columns}
				pageSize={10}
				autoHeight
				density="compact"
				classes={{ root: classes.dataGrid }}
			/>
		</Grid>
	)
}

export default RenderFolderList