import React from 'react'
import { Header } from 'semantic-ui-react'
import CancelButton from '../../../components/CancelButton'
import RightSide from '../../../components/RightSide'
import FormComponent from '../../../components/Form'
import SelectIcons from './SelectIcons'
import { Switch } from '@material-ui/core'

const Profile = ({
  deleteAccount,
  language: {
    title: titleLang,
    cancel: cancelLang,
    confirm: confirmLang,
    desactivateTitle: desactivateTitleLang,
    accountDeactivate: accountDeactivateLang,
    accountActivate: accountActivateLang,
  },
  inputs,
  sendForm,
  loading,
  cancelTitle,
  updateProfileButtonLang,
  updateIcon,
  iconId,
  active,
  deactivateAccount,
  isAdmin = false
}) => {
  return (
    <RightSide title={titleLang} >

      <div className="width-90 mt-1">
        <div>
          <SelectIcons iconId={iconId} onChange={updateIcon} />
          <FormComponent
            inputs={inputs}
            sendForm={sendForm}
            centered={true}
            flexed={true}
            button={{ align: 'right', color: 'violet' }}
            messages={{ submit: updateProfileButtonLang }}
            loader={loading}
          />
          {!!isAdmin && <div className="profile-action-container">
            <Header>{desactivateTitleLang}</Header>
            <Switch
              checked={active}
              onChange={deactivateAccount}
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <Header as={'h3'}>{active ? accountActivateLang : accountDeactivateLang}</Header>

            {/* <CancelButton
              onConfirm={deleteAccount}
              buttonMsg={cancelLang}
              confirmMsg={confirmLang}
            /> */}
          </div>}
        </div>
      </div>

    </RightSide>
  )
}

export default Profile
