import React, { useEffect, useContext, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { Store } from './store/store';
import { UnAuthRoute, AuthRoute } from './components/GuardRoutes';
import Home from './containers/home/Home';
import Login from './containers/account/Login';
import ForgotPassword from './containers/account/ForgotPassword';
import ResetPassword from './containers/account/ResetPassword';
import Profile from './containers/profile/Profile';
import ProfileUpdate from './containers/profile/ProfileUpdate';
import { getloginPaths, getPathsAccessibility } from './containers/helpers';
import Loader from './components/Loader';
import LogGrid from './components/LogGrid';
import Support from './containers/support';
import ConfirmAccount from './containers/account/confirmAccount';
import { Route, Switch } from 'react-router-dom';
import GoogleConnect from './containers/account/Login/GoogleConnect';
import SignInAdmin from './containers/account/SignInAdmin';
import Mail from './containers/mail/Mail';
import Locker from './containers/locker/Locker';
import Spots from './containers/spots/Spots';
import Leenks from './containers/leenks/Leenks';
import Calendar from './containers/calendar/Calendar';
import Docx from './containers/locker/Docx';
import Task from './containers/task';
import Notes from './containers/notes/Notes';
import JitsiMeetComponent from './containers/meet/Meet';
import Notification from './containers/notification/Notification';
import Setting from './containers/setting/Setting';
import { Toaster } from 'react-hot-toast';
import Viewer from './containers/viewer/Viewer';

const AuthRoutes = ({ history }) => {
    const loginPaths = getloginPaths();
    const exceptAccessibilityPaths = getPathsAccessibility();

    return (
        <LogGrid history={history}>
            <Switch>
                <AuthRoute
                    exact
                    path={loginPaths.leenks}
                    component={Leenks}
                    exceptAccessibility={exceptAccessibilityPaths.leenks}
                />
                <AuthRoute
                    exact
                    path={loginPaths.leenkInvite}
                    component={Leenks}
                    exceptAccessibility={exceptAccessibilityPaths.leenks}
                />
                <AuthRoute
                    exact
                    path={loginPaths.leenk}
                    component={Leenks}
                    exceptAccessibility={exceptAccessibilityPaths.leenk}
                />
                <AuthRoute
                    path={loginPaths.meet}
                    component={JitsiMeetComponent}
                    exceptAccessibility={exceptAccessibilityPaths.meet}
                />
                <AuthRoute
                    exact
                    path={loginPaths.home}
                    component={Home}
                    exceptAccessibility={exceptAccessibilityPaths.home}
                />
                <AuthRoute
                    exact
                    path={loginPaths.profile}
                    component={Profile}
                    exceptAccessibility={exceptAccessibilityPaths.profile}
                />
                <AuthRoute
                    exact
                    path={loginPaths.userProfile}
                    component={Profile}
                    exceptAccessibility={exceptAccessibilityPaths.userProfile}
                />
                <AuthRoute
                    exact
                    path={loginPaths.support}
                    component={Support}
                    exceptAccessibility={exceptAccessibilityPaths.support}
                />
                <AuthRoute
                    exact
                    path={loginPaths.homePrincipal}
                    component={Home}
                    exceptAccessibility={exceptAccessibilityPaths.homePrincipal}
                />
                {/* <AuthRoute exact path={loginPaths.subscription} component={SubscriptionPayment} exceptAccessibility={exceptAccessibilityPaths.subscription} />  */}
                <AuthRoute
                    exact
                    path={loginPaths.mail}
                    component={Mail}
                    exceptAccessibility={exceptAccessibilityPaths.mail}
                />
                <AuthRoute
                    exact
                    path={loginPaths.folder}
                    component={Locker}
                    exceptAccessibility={exceptAccessibilityPaths.folder}
                />
                <AuthRoute
                    path={loginPaths.locker}
                    component={Locker}
                    exceptAccessibility={exceptAccessibilityPaths.locker}
                />
                <AuthRoute
                    exact
                    path={loginPaths.docx}
                    component={Docx}
                    exceptAccessibility={exceptAccessibilityPaths.docx}
                />
                <AuthRoute
                    exact
                    path={loginPaths.tasks}
                    component={Task}
                    exceptAccessibility={exceptAccessibilityPaths.tasks}
                />
                <AuthRoute
                    exact
                    path={loginPaths.task}
                    component={Task}
                    exceptAccessibility={exceptAccessibilityPaths.task}
                />
                <AuthRoute
                    exact
                    path={loginPaths.spots}
                    component={Spots}
                    exceptAccessibility={exceptAccessibilityPaths.spots}
                />
                <AuthRoute
                    exact
                    path={loginPaths.settings}
                    component={Setting}
                    exceptAccessibility={exceptAccessibilityPaths.settings}
                />
                <AuthRoute
                    exact
                    path={loginPaths.settingUser}
                    component={Setting}
                    exceptAccessibility={exceptAccessibilityPaths.settingUser}
                />
                <AuthRoute
                    exact
                    path={loginPaths.calendar}
                    component={Calendar}
                    exceptAccessibility={exceptAccessibilityPaths.calendar}
                />
                <AuthRoute
                    exact
                    path={loginPaths.notes}
                    component={Notes}
                    exceptAccessibility={exceptAccessibilityPaths.notes}
                />
                <AuthRoute
                    exact
                    path={loginPaths.note}
                    component={Notes}
                    exceptAccessibility={exceptAccessibilityPaths.note}
                />
                <AuthRoute
                    exact
                    path={loginPaths.notifications}
                    component={Notification}
                    exceptAccessibility={exceptAccessibilityPaths.notifications}
                />
            </Switch>
        </LogGrid>
    );
};

function App() {
    // const { state } = useContext(Store);
    // eslint-disable-next-line no-unused-vars
    // const [loader, setLoader] = useState(false);
    // useEffect(() => {
    //     if (process.env.NODE_ENV === 'production') {
    //         const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    //         const options = {
    //             autoConfig: true, // set pixel's autoConfig
    //             debug: false, // enable logs
    //         };
    //         ReactPixel.init('184302952767556', advancedMatching, options);
    //         ReactPixel.pageView(); // For tracking page view
    //     }
    // }, []);

    return (
        <>
            <div className="App">
                {/* {loader ? (
                    <Loader />
                ) : ( */}
                    <Switch>
                        <Route exact path="/googleConnect/:token" component={GoogleConnect} />
                        <UnAuthRoute exact path="/init/:token" component={ProfileUpdate} />
                        <UnAuthRoute exact path="/signUp/:token" component={SignInAdmin} />
                        <UnAuthRoute exact path="/login" component={Login} />
                        <UnAuthRoute exact path="/verify/:token" component={ConfirmAccount} />
                        <UnAuthRoute exact path="/confirmAccount" component={ConfirmAccount} />
                        <UnAuthRoute path="/forgot" component={ForgotPassword} />
                        <UnAuthRoute path="/resetPassword/:token" component={ResetPassword} />
                        <AuthRoute path="/" component={AuthRoutes} />
                    </Switch>
                {/* )} */}
            </div>
            <Toaster position="bottom-left" />
            <Viewer />
        </>
    );
}

export default App;
