import { Button, ListItem, ListItemText } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  list: {
    height: '100%',
    padding: theme.spacing(2),
    '&>*:not(:last-child)': {
      marginBottom: theme.spacing(2) 
    }
  },
  user: {
    color: '#007FD3',
  },
  leenk: {
    color: '#F46348',
  },
  task: {
    color: '#A5A5A5',
  },
  subject: {
    color: '#599F7C',
  },
  accept: {
    backgroundColor: '#1773C8',
  },
  refuse: {
    backgroundColor: '#F46348',
  },
  root:{
    position: 'absolute',
    top: '-20px',
    right: '-20px'
  }
}));

export const StyledButton = withStyles(theme => ({
  root: {
    textTransform: 'initial',
    color: 'white',
    '&:hover': {
      color: '#45535C',
    }
  }
}))(Button);

export const StyledListItem = withStyles(theme => ({
  root: {
    textTransform: 'initial',
    backgroundColor: '#F2F2F2',
    color: '#45535C',
    borderRadius: 10,
    '&>*:not(:last-child)': {
      marginRight: theme.spacing(0.5) 
    }
  }
}))(ListItem);

export const StyledListItemText = withStyles(theme => ({
  root: {
    flex: 'initial'
  },
  primary: {
    fontWeight: theme.typography.fontWeightBold,
  }
}))(ListItemText);
