import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { getUser } from '../containers/helpers';
import UserAvatar from './UserAvatar';
import cn from 'classnames';

const useStyles = makeStyles((theme) => ({
    table: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        height: '50%',
        width: '90%',
        borderRadius: 40,
        backgroundColor: '#efefef',
    },
    avatar: {
        height: 60,
        width: 60,
        '& > svg': {
            height: '100%',
            width: '100%',
        },
    },
    right: {
        position: 'absolute',
        right: 'calc(-60px / 2)',
        top: 'calc(50% - 60px / 2)',
    },
    users: {
        display: 'flex',
        flexWrap: 'wrap',
        position: 'absolute',
        left: 75,
        height: 'calc(100% + 60px)',
        width: 'calc(100% - 75px * 2)',
        alignContent: 'space-between',
    },
    user: {
        display: 'flex',
        justifyContent: 'center',
        flex: '1 0 calc(100% / 3)',
    },
    title: {
        fontSize: theme.typography.pxToRem(25),
        textTransform: 'capitalize',
        fontWeight: 500,
    },
}));

const MeetingRoom = ({ meeting, name, users }) => {
    const classes = useStyles();

    const currentUser = getUser();
    const owner = [...users, currentUser].find((e) => e.id === meeting?.owner);

    return (
        <div className={classes.table}>
            {name && (
                <Typography className={classes.title} variant="h3">
                    {name}
                </Typography>
            )}
            <div className={classes.users}>
                {users.map((user, key) => (
                    <div key={key} className={classes.user}>
                        <UserAvatar
                            classes={{ root: classes.avatar }}
                            firstName={user?.firstname}
                            lastName={user?.lastname}
                            icon={user?.icon}
                        />
                    </div>
                ))}
            </div>
            <UserAvatar
                classes={{ root: cn(classes.right, classes.avatar) }}
                firstName={owner?.firstname}
                lastName={owner?.lastname}
                icon={owner?.icon}
            />
        </div>
    );
};

export default MeetingRoom;
