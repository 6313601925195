import { Button } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  task: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    color: '#45535C',
    height: 'auto',
    width: 200,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 10,
    padding: theme.spacing(1)*2,
    backgroundColor: '#fff',
    '&>*:not(:last-child)': {
      marginBottom: theme.spacing(1),
    }
  },
  deleteTask: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 3,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    alignItems: 'flex-start',
    width: '100%',
    '&>*:not(:last-child)': {
      marginRight: theme.spacing(1),
    }
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  tabContent: {
    position: 'relative',
    padding: theme.spacing(3),
    height: '100%',
    '&>*:not(:first-child)': {
      marginBottom: theme.spacing(1),
    }
  },
  content: {
    position: 'relative',
    backgroundColor: 'white',
    overflow: 'hidden',
    width: 600,
    height: 700,
    borderRadius: 10,
  },
  close: {
    position: 'absolute',
    color: 'grey',
    top: -theme.spacing(1),
    right: -theme.spacing(1),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: theme.spacing(1)
    }
  },
  list: {
    borderRadius: 5,
    border: '1px solid lightgrey',
    gridColumn: 2,
    marginBottom: theme.spacing(1),
  },
  borderedContainer: {
    borderRadius: 5,
    border: '1px solid lightgrey',
    padding: theme.spacing(1),
  },
  lineSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tabs: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  tab: {
    textTransform: 'initial'
  },
  comment: {
    borderBottom: '1px solid lightgrey',
  },
  itemTextPrimary: {
    '&>*:first-child': {
      marginRight: theme.spacing(1),
    },
    '&>*:last-child': {
      color: 'grey',
    }
  },
  itemTextSecondary: {
    color: '#45535C',
  },
  inputComment: {
    color: '#1773C8',
    padding: theme.spacing(1),
    borderTop: '1px solid lightgrey',
  },
  star:{
    color:'#F7E800',
    fill: '#F7E800',
    position: 'absolute',
    top: 0,
    left: 0,
    width: 15,
  }
}));

export const StyledButton = withStyles(theme => ({
  root: {
    textTransform: 'initial',
    backgroundColor: '#1773C8',
    color: 'white',
    '&:hover': {
      color: '#45535C',
    }
  }
}))(Button);
