import React from 'react'
import {
  Header,
} from 'semantic-ui-react'
import CancelButton from '../../../components/CancelButton'
import RightSide from '../../../components/RightSide'
import FormComponent from '../../../components/Form'

const Profile = ({
  deleteAccount,
  language : {
    title: titleLang,
    cancel: cancelLang,
    confirm: confirmLang,
    infos:infosLang

  },
  inputs,
  sendForm,
  loading,
  cancelTitle,
  updateProfileButtonLang,
}) => {
  return (
    <RightSide title={titleLang} >

      <div className="width-90">
        <div>
          <Header>{infosLang}</Header>
          <FormComponent
            inputs={inputs}
            sendForm={sendForm}
            centered={true}
            button={{ align: 'right', color: 'violet' }}
            messages={{ submit: updateProfileButtonLang }}
            loader={loading}
          />
        </div>
      </div>

    </RightSide>
  )
}

export default Profile
