import React, {
  useState, useContext, useEffect, useMemo,
} from 'react'
import { Redirect } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import {
  isLoggedIn, getUser, updateLanguage, regenerateTokens,
} from '../helpers'
import { Store } from '../../store/store'
import { readNotification } from './helpers'
import HeaderComponent from './HeaderComponent'

const Header = () => {
  const isLogin = isLoggedIn()
  const { state, dispatch } = useContext(Store)
  const [redirect, setRedirect] = useState(false)
  const user = useMemo(() => getUser(), [localStorage.getItem('profile')])

  const goHome = () => setRedirect('/home')

  // this to put redirect to false when redirect has been done
  useEffect(() => {
    setRedirect(false)
  }, [redirect])

  useEffect(() => {
    if (user.id)
      setTimeout(() => { regenerateTokens(dispatch) }, 1000)
  }, [state.languageValue])

  useEffect(() => {
    dispatch({ type: 'CHANGE_LANGUAGE', payload: user.language })
  }, [user.language])

  const readAndDeleteNotif = notificationId => readNotification(notificationId, dispatch)
  const [isOpen, setIsOpen] = useState(false)
  const [dialogType, setDialogType] = useState()
  const open = (isBell = false) => {
    setDialogType(isBell ? 'notifs' : 'settings')
    setIsOpen(!isOpen)
  }
  const OpenIcon = props => props.name === 'bell'
    ? <div className="relative bell-header-icon"
      onClick={() => props.name.includes('bell') ? open(true) : open()}
    >
      <Icon
        size="large"
        {...props} />
      <div className="notification-bell-number">
        {state.notifications.length}
      </div>
    </div>
    : <Icon
      onClick={() => props.name.includes('bell') ? open(true) : open()}
      className="header-icon"
      size="large"
      {...props} />

  const changeLanguage = language => updateLanguage(language)

  return (redirect
    ? <Redirect to={redirect} />
    : <HeaderComponent
      readAndDeleteNotif={readAndDeleteNotif}
      readNotification={readNotification}
      notifications={state.notifications || []}
      setRedirect={setRedirect}
      redirect={redirect}
      goHome={goHome}
      close={() => setIsOpen(false)}
      isOpen={isOpen}
      dialogType={dialogType}
      isLogin={isLogin}
      OpenIcon={OpenIcon}
      language={state.language.header}
      pro={user.pro}
      changeLanguage={changeLanguage}
    />
  )
}

export default Header
