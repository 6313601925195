import React, { useContext, useEffect, useState } from 'react';
import { Store } from '../../../store/store';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { getSharedTeams, createCompaniesFromTeams, getTeams, getPrivateLeenks } from '../../leenks/Leenks/helpers';
import { isArray } from 'lodash';
import CompanyList from './CompanyList';
import { getUser, capitalizeFirstLetter, isNull } from '../../helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        flexDirection: 'column',
        flexWrap: 'nowrap',
        margin: 0,
        '&>*': {
            marginBottom: theme.spacing(3),
        },
    },
    h2: {
        marginTop: theme.spacing(2),
        fontSize: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightBold,
        textAlign: 'left',
    },
    public: {
        color: '#1773C8',
    },
}));

const ListFolders = ({ navigateToFolder, folderId, path }) => {
    const classes = useStyles();
    const { state, dispatch } = useContext(Store);
    const history = useHistory();
    const {
        language: {
            locker: { publicSpace: publicSpaceLang, accessMyFiles: accessMyFilesLang },
            header: { privateMessages: privateMessagesLang }
        },
    } = state;
    const me = getUser()
    const [accordion, setAccordion] = useState({});
    const searchAccordionInPath = (path = [], folderId, type) => {
        for (let index = 0; index < companies.length; index++) {
            const company = companies[index];
            for (let teamIndex = 0; teamIndex < company.teams.length; teamIndex++) {
                const team = company.teams[teamIndex];
                if (team.leenks && team.leenks.length > 0) {
                    for (let leenkIndex = 0; leenkIndex < team.leenks.length; leenkIndex++) {
                        const leenk = team.leenks[leenkIndex];
                        if ((leenk.folder === folderId || path.includes(leenk.folder)) && type === 'folder') {
                            return {
                                company: company.company,
                                team: team.folderId,
                                leenk: leenk.id
                            }
                        }
                    }
                } else {
                    if (team.folderId === folderId && company.company === 0) {
                        return {
                            company: company.company,
                            team: team.folderId,
                        }
                    }
                }
                if (team.folderId === folderId && type === 'team') {
                    return {
                        company: company.company,
                        team: team.folderId,
                    }
                }
            }
            if (company.company === folderId && type === 'company') {
                return {
                    company: company.company,
                }
            }
        }
        return false
    }
    const haveToClose = (folderId, type) => {
        if (accordion && !isNull(accordion.company)) {
            if (Number(accordion.company) === Number(folderId) && type === 'company') {
                setAccordion({})
                return true
            } if (!isNull(Number(accordion.team)) && Number(accordion.team) === Number(folderId) && type === 'team') {
                setAccordion(accordion => ({ company: accordion.company }))
                return true
            }
        }
        return false
    }
    const getExpended = (folderId, path, type = 'folder') => {
        if (haveToClose(folderId, type)) {
            return
        }
        if (!path) {
            return setAccordion('private');
        }
        const res = searchAccordionInPath(
            isArray(path) ? path : path.paths.map(({ folderId }) => folderId),
            folderId,
            type
        )
        if (res) {
            setAccordion(res)
        }
    };

    const [companies, setCompanies] = useState([])

    useEffect(() => {
        getExpended(folderId, path);
    }, [companies, path]);

    const chargeDatas = async () => {
        const teams = await getTeams();
        const sharedTeamFromApi = await getSharedTeams()
        const privateLeenksFromApi = await getPrivateLeenks()
        const getName = users => {
            const { firstname, lastname, company_id: companyId, company_name: companyName } = users.find(u => u.id !== me.id) || {}
            return `${capitalizeFirstLetter(firstname)} ${capitalizeFirstLetter(lastname)}${companyId && companyName !== me.company ? ` (${companyName})` : ''}`
        }
        const privateLeenks = {
            company: 0,
            name: privateMessagesLang,
            teams: privateLeenksFromApi.map(({
                users = [],
                parent,
                id
            }) =>
                ({
                    id,
                    folderId: parent,
                    name: getName(users)
                }))
        }
        //add sharedTeams in companies, add label "shared" to front color for example
        const sharedTeams = sharedTeamFromApi.map(sharedTeam => ({ ...sharedTeam, shared: true }));
        setCompanies([...createCompaniesFromTeams([...teams, ...sharedTeams]), privateLeenks])
    };

    useEffect(() => {
        chargeDatas();
    }, []);

    return (
        <Box className={cn(classes.root, 'leenks-section scroll-y scrollbar-disable')}>
            <Button
                style={{
                    background: '#f1f2f7'
                }}
                variant={accordion === 'private' ? 'contained' : 'outlined'}
                onClick={() => {
                    dispatch({
                        type: 'UPDATE_FOLDER',
                        payload: { folderId: 0, folderType: 'private', parentType: 'folder' },
                    });
                    getExpended()
                    history.push('/locker');
                }}
            >
                {accessMyFilesLang}
            </Button>
            {companies.length ? (
                <Typography variant="h2" classes={{ root: classes.public, h2: classes.h2 }}>
                    {publicSpaceLang}
                </Typography>
            ) : null}
            <CompanyList
                companies={companies}
                folderId={folderId}
                accordion={accordion}
                path={path}
                navigateToFolder={navigateToFolder}
                getExpended={getExpended}

            />
        </Box>
    );
};

export default ListFolders;
