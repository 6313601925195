import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import DocumentViewer from '../../../components/DocumentViewer';
import { Icon } from 'semantic-ui-react'
import { IconButton } from '@material-ui/core';

const Viewer = () => {
	const history = useHistory()
	const location = useLocation()
	const search = location.search.substring(1);
	const getParamsFromUrl = () => JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
	const { fileId = null } = search && search !== '' ? getParamsFromUrl() : {}

	const hideFile = () => history.push(location.pathname)

	const echapHandler = ({ key }) => {
		switch (key) {
			case 'Escape':
				return hideFile()
			default:
		}
	}

	useEffect(() => {
		if (fileId) {
			window.addEventListener('keydown', echapHandler)
			return () => {
				window.removeEventListener('keydown', echapHandler)
			}
		}
	}, [fileId])

	return Boolean(fileId) &&
		<div className="full-screen-window-container background-opacity-8">
			<IconButton onClick={hideFile} style={{
				right: 0,
				position: 'absolute',
				zIndex: '1001'
			}}>
				<Icon name="remove" style={{
					fontSize: '1.5em',
					lineHeight: 'normal'
				}} color="black" />
			</IconButton>
			<DocumentViewer fileId={fileId} />
		</div>
};

export default Viewer;