import React, { useState, useEffect } from 'react';
import UserIcons, { userIconsLenght } from '../../../components/UserIcons';

const SelectIcons = ({ onChange, iconId }) => {
	const [id, setId] = useState()
	const onSelect = newId => {
		onChange(newId)
		setId(newId)
	}

	useEffect(() => {
		setId(iconId)
	}, [iconId])
	return new Array(userIconsLenght).fill()
		.map((e, index) =>
		<span key={index} className={'icon-profile'}  onClick={() => onSelect(index)}>
			<span className={'shadow-avatar'
		+ (index === id ? ' icon-selected' : '')} />
			<span style={{zIndex: 1, position: 'relative'}}><UserIcons index={index} /></span>
		</span>
			)
};

export default SelectIcons;