export const EN = {
  logOut:  'Log out',
  profile: 'Profile',
  support: 'Support',
  hello: 'Hello',
  settings: 'Settings',
  notifications: 'Notifications',
  privateMessages: 'Privates conversations',
  tasks: 'Tasks',
  notes : 'Notes',
  calendar: 'Calendar'
}

export const FR = {
  logOut:  'Déconnexion',
  profile: 'Profil',
  support: 'Support',
  hello: 'Bonjour',
  settings: 'Réglages',
  notifications: 'Notifications',
  privateMessages: 'Messages Privés',
  tasks: 'Tâches',
  notes : 'Notes',
  calendar: 'Calendrier'
}

export default {
  fr: FR,
  us: EN,
}
