import React, { useState, useContext } from 'react'
import { Store } from '../../../store/store'
import Login from './Login'
import { login } from './helpers'

export default ({ history }) => {
  const { dispatch, state } = useContext(Store)
  const {
    leftSide: {
      mail: mailLang,
      password: passwordLang,
      forgotPassword: forgotPasswordLang,
      unlock: unlockLang,
      typeCode: typeCodeLang,
      valid:validLang,
      accountNotValidated: accountNotValidatedLang,
      errorTitle: errorTitleLang,
      redirectConfirm: redirectConfirmLang,
      noConfirmationMail: noConfirmationMailLang,
    },
  } = state.language.login

  const inputs = [
    {
      type:           'text',
      name:           'email',
      placeholder:    mailLang,
      required:       true,
      silentRequired: true,
      fluid:          true,
    },
    {
      type:           'password',
      name:           'password',
      placeholder:    passwordLang,
      required:       true,
      silentRequired: true,
      fluid:          true,
    },
  ].filter(e => !!e)
  const [errorLogin, setErrorLogin] = useState({ validated: true, message: accountNotValidatedLang })
  const [modal, setModal] = useState(false)
  const cleanNotifications = () => dispatch({ type: 'REMOVE_ALL_NOTIFICATIONS' })

  const sendForm =  async values => {
    try {
      await login(values, dispatch)
      const path = JSON.parse(localStorage.getItem('path'))
      if (path) localStorage.removeItem('path')
      cleanNotifications()
      path ? history.push(path) :
        history.push('/home')
    } catch (e) {
      if (e.message === 'Please verify your email first') {
        setErrorLogin({ validated: false, message: accountNotValidatedLang })
      } else setErrorLogin({ validated: true, message: e.message })

      return setModal(true)
    }
  }

  const goToForgot = () => history.push(`/forgot`)

  return <React.Fragment>
  <Login
    forgotPassword={goToForgot}
    inputs={inputs}
    sendForm={sendForm}
    modal={modal}
    setModal={setModal}
    forgotPasswordLang={forgotPasswordLang}
    noConfirmationMailLang={noConfirmationMailLang}
    unlockLang={unlockLang}
    typeCodeLang={typeCodeLang}
    validLang={validLang}
    errorLogin={errorLogin}
    errorTitleLang={errorTitleLang}
    redirectConfirmLang={redirectConfirmLang}
    history={history}
  />
  </React.Fragment>
}
