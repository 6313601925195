export const FR = {
  task:                     'Tâche',
  taskTitle:                'Titre',
  taskColor:                'Couleur',
  taskStart:                'Début',
  taskEnd:                  'Fin',
  taskChecklist:            'A faire',
  taskStatus:               'Status',
  taskStatusTodo:           'A faire',
  taskStatusProgress:       'En cours',
  taskStatusCompleted:      'Terminé',
  taskLabel:                'Etiquette',
  taskLabelChoose:          'Choisir une étiquette',
  comments:                 'Commentaires',
  commentInputPlaceholder:  'Un commentaire',
  addTask:                  'Ajouter',
  addTaskName:              'Nom',
  addChecklist:             'Ajouter une liste',
  addChecklistPlaceholder:  'Nom de la liste',
  addCheckListElement:      'Ajouter un élément',
  status: {
    title: 'Statut',
    undefined: 'Non défini',
    toDo: 'À faire',
    doing: 'En cours',
    done: 'Terminé',
  }
}

export const EN = {
  task:                     'Task',
  taskTitle:                'Title',
  taskColor:                'Color',
  taskStart:                'Start',
  taskEnd:                  'End',
  taskChecklist:            'To do',
  taskStatus:               'Status',
  taskStatusTodo:           'To do',
  taskStatusProgress:       'In Progress',
  taskStatusCompleted:      'Completed',
  taskLabel:                'Label',
  taskLabelChoose:          'Choose a label',
  comments:                 'Comments',
  commentInputPlaceholder:  'A comment',
  addTask:                  'Add',
  addTaskName:              'Name',
  addChecklist:             'Add checklist',
  addChecklistPlaceholder:  'Checklist name',
  addCheckListElement:      'Add element',
  status: {
    title: 'Status',
    undefined: 'Undefined',
    toDo: 'To do',
    doing: 'Doing',
    done: 'Done',
  }
}

export default {
  fr: FR,
  us: EN,
}
