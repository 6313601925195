import React, { useState, useEffect, useContext } from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import { getFile, getFileDownload } from '../containers/locker/Locker/helpers';
import download from 'js-file-download';
import { IconButton } from '@material-ui/core';
import { Icon } from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router-dom';
import { Store } from '../store/store';
import { dispatchAlert } from '../containers/menu/helpers';
import XlsxViewer from './XlsxViewer';
import { apiUrl, getToken } from '../containers/helpers';

// il risque d'y avoir un problème que j'ai identifié
// lorsque le serveur reçoit un mail, la connection est coupée j'ai l'impression
// À voir si on peut fixer du coté du serveur 
// Car lorsque reception d'un mail il y a, l'image disparait.

const DocumentViewer = ({ fileId }) => {
	const location = useLocation()
	const history = useHistory()
	const hideFile = () => history.push(location.pathname)
	const { dispatch, state: { alerts } } = useContext(Store)
	const [file, setFile] = useState(null)
	function convertBase64ToBlob(base64Image) {
		const parts = base64Image.split(';base64,');
		const imageType = parts[0].split(':')[1];
		const decodedData = window.atob(parts[1]);
		const uInt8Array = new Uint8Array(decodedData.length);
		for (let i = 0; i < decodedData.length; ++i) {
		  uInt8Array[i] = decodedData.charCodeAt(i);
		}
		return new Blob([uInt8Array], { type: imageType });
	  }
	function blobToBase64(blob) {
		return new Promise((resolve, _) => {
		  const reader = new FileReader();
		  reader.onloadend = () => resolve(reader.result);
		  reader.readAsDataURL(blob);
		});
	  }
	const getUrl = async () => {
		try {

			const fileFromApi = await getFile(fileId)
			const data = await getFileDownload(fileId);
			const blob = await data.blob();
			const uri = `${apiUrl}/file/visualisation/${fileId}/${getToken()}`
			return {
				...fileFromApi,
				uri,//URL.createObjectURL(blob),
				fileType: fileFromApi.extension,
				blob,
			}
		} catch ({ status, message, ...e }) {
			if (status === 403) {
				dispatchAlert(dispatch, alerts, { type: 'error', message });
			}
			return hideFile()
		}

	};

	const getFileInfos = () => getUrl()
		.then(setFile)

	useEffect(() => {
		getFileInfos()
	}, [fileId])

	const downloadDocument = () =>
		download(file.blob, file.name);

	return (
		file && <div>
			{file.fileType === 'xlsx' ? <XlsxViewer file={file} />
				: <DocViewer
					style={{
						width: '90vw',
						height: '90vh',
						margin: 'auto'
					}}
					pluginRenderers={DocViewerRenderers}
					documents={[{
						uri: file.uri,
						fileType: file.fileType
					}]}
					config={{
						header: {
							disableHeader: true,
							disableFileName: true,
							//  retainURLParams: true
						}
					}}
				/>
			}

			<IconButton onClick={downloadDocument} style={{
				// right: 0,
				// position: 'absolute',
				// zIndex: '1001'
			}}>
				<Icon name="download" style={{
					fontSize: '1.5em',
					lineHeight: 'normal'
				}} color="black" />
			</IconButton>
		</div>
	);
};

export default DocumentViewer;