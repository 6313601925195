import React, { useState, useEffect, useContext, useCallback } from 'react';
import { updateDocx, getDocx, saveFile } from './helpers';
import Docx from './Docx';
import { EditorState } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import { Store } from '../../../store/store';
import { Button, Grid } from '@material-ui/core';
import debounce from "lodash/debounce";
import { dispatchAlert } from '../../menu/helpers';
import { removeEvent, socket } from '../../../socket';
import { useHistory } from 'react-router-dom';
export default ({ match }) => {
	const history = useHistory()
	const { id: docxId } = match.params
	const { dispatch, state: { alerts, language: { docx:{
		navigateToFolder: navigateToFolderLang
	},general: {
		return: returnLang
	} } } } = useContext(Store);
	const [actual, setActual] = useState('')
	const [docx, setDocx] = useState('')
	const [folderId, setFolderId] = useState()
	const [content, setContent] = useState(EditorState.createEmpty())
	const createFromHtml = raw => raw ? EditorState.createWithContent(convertFromHTML(raw)) : EditorState.createEmpty()
	useEffect(() => {
		getDocx(docxId)
			.then(docx => {
				setFolderId(docx.folder_id)
				setContent(EditorState.moveSelectionToEnd(createFromHtml(docx.actual)))
			})
			return () => {
				removeEvent('join_docx')
				removeEvent('update_docx')
			  }
	}, [])

	useEffect(() => {
		if (socket.connected === false) {
			socket.emit("join_docx", docxId)
			// socket.on('update_docx', obj => setDocx(obj))
		}
	}, [socket])

	useEffect(() => {
		if (socket.status !== '') {
			socket.on('update_docx', obj => {
				if (obj.docxId === docxId) {
					setContent(EditorState.moveFocusToEnd(createFromHtml(obj.docx)))
				}
			})
		}
	}, [socket])

	const sendFile = async () => {
		try {
			const fileToSave = convertToHTML(content.getCurrentContent())
			await saveFile(docxId, fileToSave)
			dispatchAlert(dispatch, alerts, { type: 'success' })
		} catch (e) {
			dispatchAlert(dispatch, alerts, { type: 'error', message: e.message })
			console.log('error', e)
		}
	}

	const handleUpdate = useCallback(
		debounce(async (docxId, fileToUpdate) => {
			try {
				await updateDocx(docxId, fileToUpdate)
			} catch (e) {
				dispatchAlert(dispatch, alerts, { type: 'error', message: e.message })
				console.log('error', e)
			}
		}, 1000),
		[]
	);

	const onChange = newDocx => setContent(newDocx)

	const onBlur = () => {
		const fileToUpdate = convertToHTML(content.getCurrentContent());
		handleUpdate(docxId, fileToUpdate)
	}
	const navigateToFolder = () => history.push('/locker/folder/'+folderId)
	return (
		<Grid container className="page-container border-radius-5 shadow bg-white">
			<Grid container
				item
				direction="column"
				xs={1}
				className="leenks-section scroll-y scrollbar-disable">
				<Button onClick={navigateToFolder}>
					{navigateToFolderLang}
				</Button>
			</Grid>
			<Docx
				content={content}
				onBlur={onBlur}
				saveFile={sendFile}
				onChange={onChange}
			/>
		</Grid>
	);
};
