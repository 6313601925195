import React, { useEffect } from 'react'
import { Badge, Button, Tooltip } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import PersistantNotificationContainer from './PersistantNotificationContainer';
import AppIcons from '../../components/AppIcons';
import useNotificationHooks from './useNotificationHooks';

const Notifications = () => {
  const history = useHistory()
  const { pathname } = useLocation()

  const {
    initNotification,
    notifications,
    language: {
      header: {
        notifications: notificationsLang,
      }
    }
  } = useNotificationHooks()
  useEffect(() => {
    initNotification()
  }, []);
  const navigate = () => pathname === '/notification' ? history.goBack() : history.push('/notification')
  return (
    <>
      <Tooltip placement="top" title={notificationsLang}>
        <Button className="shadow notification" onClick={navigate}>
          <Badge badgeContent={notifications.filter(e => e.read === false).length} color={'error'}>
            <AppIcons name="notification" />
          </Badge>
        </Button>
      </Tooltip>
      <PersistantNotificationContainer />
    </>
  )
}

export default Notifications
