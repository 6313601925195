import React, { useContext } from 'react'
import RightSide from '../../components/RightSide'
import { Header } from 'semantic-ui-react'
import { Store } from '../../store/store'

const Support = () => {
  const { state } = useContext(Store)
  const {
    title:titleLang,
    subtitles:subtitlesLang,
    leftTitle:leftTitleLang,
    leftBox:leftBoxLang,
    rigthTitle:rigthTitleLang,
    rightBox:rightBoxLang,
    guide: guideLang,
    guideButton: guideButtonLang,
  } = state.language.support
  return (
    <RightSide title={titleLang}
      previous={{ name: '', pathname: '' }}>
      <div className="support-subtitle-container">
        {subtitlesLang.split('.').filter(e => e).map((subtitle, index) => (
          <p key={index} className="my-purple subtitle">{subtitle}.</p>
        ))}
      </div>
      <div className="support cards-container centered-container">
          <div className="card-container">
            <Header as="h3" className="my-purple" >{leftTitleLang}</Header>
            <div className="card-content bg-my-purple">
              {leftBoxLang.map((text, index) => (
                <Header key={index} as={index === 2 ? 'h4' : 'h3'}
                  className={`color-white
              ${index === 0 ? ' title-infos' : ' sub-infos'}
              ${index === 2 ?  ' italic' : ''}`}>
                  {text}</Header>
              ))}
            </div>
          </div>

          <div className="card-container not-first">
            <Header as="h3" className="color-blue">
              {rigthTitleLang}
            </Header>
            <div className="card-content bg-blue">
              {rightBoxLang.map((text, index) => (
                <Header key={index} as={index === 2 ? 'h4' : 'h3'}
                  className={`color-white
              ${index === 0 ? ' title-infos' : ' sub-infos'}
              ${index === 2 ?  ' italic' : ''}`}>
                  {text}</Header>
              ))}
            </div>
          </div>
      </div>
    </RightSide>
  )
}

export default Support
