import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Accordion as BaseAccordion,
    Typography,
    ClickAwayListener,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ButtonBase,
} from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import RemoveIcon from '@material-ui/icons/Remove';
import { Store } from '../../../store/store';
import { getPrivateLeenks, getCustomers, getSuppliers, getTeams } from '../../leenks/Leenks/helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: '#F4F5F5',
        color: '#9DA8B0',
        boxShadow: 'none',
        '&.Mui-expanded': {
            margin: 0,
        },
    },
    expandIcon: {
        color: 'inherit',
    },
    details: {
        flexDirection: 'column',
        padding: 0,
    },
    expanded: {
        backgroundColor: '#E2F0F7',
        color: '#1773C8',
        margin: 0,
    },
    second: {
        backgroundColor: 'inherit',
        color: 'inherit',
        boxShadow: 'none',
        borderBottom: '1px solid #fff3',
        '&.Mui-expanded': {
            margin: 0,
        },
    },
    secondExpand: {
        backgroundColor: '#cbe5fb',
    },
    secondDetails: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
    },
    heading: {
        borderBottom: '1px solid #fff3',
    },
    headingContent: {
        alignItems: 'center',
    },
    headingTypo: {
        fontSize: theme.typography.pxToRem(14),
        whiteSpace: 'nowrap',
    },
    nested: {
        fontSize: theme.typography.pxToRem(12),
        paddingLeft: theme.spacing(4),
        '&.Mui-selected': {
            backgroundColor: '#aed8fd',
        },
    },
}));

const ListFolderLeenks = ({ list, action }) => {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = useState(null);

    return list.map(({ name, parentType, parentId, folderId }, key) => (
        <ListItem
            key={key}
            button
            selected={selectedIndex === key}
            onClick={() => {
                setSelectedIndex(key);
                action(parentType, parentId, folderId);
            }}
            className={classes.nested}
        >
            <ListItemIcon style={{ color: 'inherit' }}>
                <RemoveIcon />
            </ListItemIcon>
            <ListItemText primary={name} />
        </ListItem>
    ));
};

const Accordion = ({ list, title, action }) => {
    const classes = useStyles();
    return (
        <BaseAccordion classes={{ root: classes.root, expanded: classes.expanded }}>
            <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{
                    root: classes.heading,
                    content: classes.headingContent,
                    expandIcon: classes.expandIcon,
                }}
            >
                <Typography className={classes.headingTypo}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.details }}>
                {list.map(({ name, list = [], ...element }, key) =>
                    key === 1 ? (
                        <ButtonBase key={key} onClick={() => action(element.parentType)}>
                            {name}
                        </ButtonBase>
                    ) : (
                        <BaseAccordion
                            key={key}
                            square
                            classes={{ root: classes.second, expanded: classes.secondExpand }}
                        >
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                classes={{ expandIcon: classes.expandIcon }}
                            >
                                <Typography className={classes.headingTypo}>{name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.secondDetails}>
                                <List component="nav" aria-label="main leenks folders">
                                    <ListFolderLeenks action={action} list={list} />
                                </List>
                            </AccordionDetails>
                        </BaseAccordion>
                    )
                )}
            </AccordionDetails>
        </BaseAccordion>
    );
};

const TransferMail = ({ createLeenk, transferCorrespondantToLeenk }) => {
    const {
        state: {
            language: {
                mails: { transferMail: transferMailLang, transferContact: transferContactLang, transformToPrivateLeenk:transformToPrivateLeenkLang },
                leenks: {
                    teams: teamsLang,
                },
            },
        },
    } = useContext(Store);

    const [list, setList] = useState([]);

    const extractListFromElement = (element, elementName, parentType) => ({
        name: elementName,
        list: element.map((e) => ({ ...e, parentType, parentId: e.id })),
    });
    const getPrivate = (element, elementName, parentType) => ({
        name: elementName,
        ...element,
        parentType,
        parentId: element.id,
    });

    const chargeDatas = async () => {
        const teams = await getTeams();
        const privates = (await getPrivateLeenks()).map((leenk) => ({ ...leenk, name: leenk.id }));
        setList([
            extractListFromElement(teams, teamsLang, 'TEAM'),
            getPrivate(privates, transformToPrivateLeenkLang, 'FOLDER'),
        ]);
    };
    useEffect(() => {
        chargeDatas();
    }, []);

    return (
        <>
            <Accordion
                list={list.filter((e, index) => index !== list.length - 1)}
                title={transferMailLang}
                action={createLeenk}
            />
            <Accordion list={list} title={transferContactLang} action={transferCorrespondantToLeenk} />
        </>
    );
};

export default TransferMail;
