export default ({ spacing }) => ({
    root: {
        marginTop: 5
    },
    horizontallyCollapsableColumn: {
        borderRight: '1px solid #efefef',
    },
    messengerContainer: {
        height: '100%',
        flex: 1,
        overflowY: 'auto',
    },
    list: {
        color: 'rgba(0, 0, 0, .87)',
    },
    listItemIcon: {
        minWidth: 34,
    },
    selectPrivatesButton: {
        margin: spacing(4, 2),
    },
    createSubjectPopperPaperActions: {
        padding: 0,
        marginTop: spacing(2),
    },
});
