import React, { useState, useContext } from 'react';
import { ButtonGroup, Button } from '@material-ui/core';
import { getMails } from './helpers';
import { Store } from '../../../store/store';

const NavigateEmailsButtons = ({
	loadData,
	dataLoaded
}) => {
	const { state: { 
		mailsLength,
	}, dispatch } = useContext(Store)
	const limit = 50
	const [offset, setOffset] = useState(0)
	const chargeEmails = async offs => {
		loadData()
		try {
			const { mails, numbers } = await getMails(offs)
			setOffset(offs)
			dispatch({ type: 'CHARGE_MAILS', payload: mails })
			dispatch({ type: 'MAILS_DISTANT_LENGHT', payload: numbers })
		} catch (e) {
			console.log('error during email loading', e)
		} finally {
			dataLoaded()
		}
	}
	const chargePreviousEmails = () => chargeEmails(offset - limit)
	const chargeNextEmails = () => chargeEmails(offset + limit)
	return (
		<ButtonGroup variant="text" aria-label="text button group">
			{offset > 0 && <Button onClick={chargePreviousEmails}>{offset - limit} - {offset}</Button>}
			{(offset + limit) < mailsLength && <Button onClick={chargeNextEmails}>{offset + limit} - {offset + (2 * limit)}</Button>}
		</ButtonGroup>
	);
};

export default NavigateEmailsButtons;