import React, { useContext, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { Store } from '../store/store'
import Menu from '../containers/menu'
import Header from '../containers/header'
import { checkConnect } from '../containers/mail/Connect/helpers'
import { socket, reloadSocket } from '../socket'
import { getMails } from '../containers/mail/Mail/helpers'
import { getEnums, getUsersConnected, updateSocketId, getUser, logOut } from '../containers/helpers'
import useNotificationHooks from '../containers/header/useNotificationHooks'
import { getUserById } from '../containers/notification/Notification/helpers'

window.React = React;
const LogGrid = ({ children }) => {
  const { state: { menu, rightSideLoading }, dispatch } = useContext(Store)
  const menuClassName = menu ? '' : 'hide-menu'
  const handleMenuState = () => dispatch({ type: `${menu ? 'HIDE' : 'SHOW'}_MENU` })
  const {
    receiveNotification
  } = useNotificationHooks()

  const dispatchUsers = users => dispatch({
    type: 'INIT_USERS',
    payload: users
  })
  const initSockets = () => {
    getUsersConnected()
      .then(dispatchUsers)
    socket.on('update user connected list', users => {
      dispatchUsers(users)
    })
    socket.on('connect', () => {
      updateSocketId(socket.id)
      console.log("SOCKET CONNECT")
      console.log('socket.id :>> ', socket.id);
    })
    socket.on('notification', notification => {
      receiveNotification(notification)
    })
    socket.on('delete notification', notificationId => {
      dispatch({ type: 'REMOVE_NOTIFICATION', payload: notificationId })
    })
    // socket.on('subscriptionPayment', data => {
    //   dispatch({ type: 'SOCKET/PAYMENT', payload: { ...data } })
    // })
    // socket.on('singlePayment', data => {
    //   dispatch({ type: 'SOCKET/PAYMENT', payload: { ...data } })
    // })
    socket.on('new mail', mail => {
      dispatch({ type: 'ADD_MAIL', payload: mail.mail })
    })
  }

  const mailInit = async () => {
    getMails()
      .then(({ mails, numbers }) => {
        dispatch({ type: 'CHARGE_MAILS', payload: mails })
        dispatch({ type: 'MAILS_DISTANT_LENGHT', payload: numbers })
      }
      )
  }

  const chargeEnums = async () => {
    try {
      const enums = await getEnums()
      dispatch({ type: 'CHARGE_ENUMS', payload: enums })
    } catch (e) {
      console.log('e :>> ', e);
    }
  }

  const disconnectIfDeactivate = () => {
    getUserById(getUser().id)
    .then(({ is_email_verified , ...userf}) => {
      (is_email_verified === 0 || !is_email_verified) && logOut()
    })
}
  useEffect(() => {
    disconnectIfDeactivate()
    chargeEnums()
    reloadSocket()
    dispatch({ type: 'LOADING_OFF' })
    initSockets()
    mailInit()
    checkConnect() //check if exists, if and not runnig, launch and run
      .then(() => {
        dispatch({ type: 'SMTP_CONNECT' })
      })
      .catch(() => {
        dispatch({ type: 'SMTP_DISCONNECT' })
      })
  }, [])
  return (
    <Grid container wrap="nowrap" className={`full-height text-color log-grid-container ${menuClassName}`}>
      {/* <div className="menu-hide-icon-container">
        <Switch onChange={handleMenuState} name="toggleMenu" checked={menu} />
      </div> */}
      <Grid container item xs={2} className="bg-grey-dark padding-0 log-grid-menu-container max-width-168">
        <Menu />
      </Grid>
      <Grid container
        item
        direction="column"
        wrap="nowrap"
        className="padding-0 bg-blue-light log-grid-right-side-container"
      >
        <Header />
        {/* <RightSideBreadcrumbs /> */}
        <Grid container item style={{ height: 'calc( 100% - 100px )', padding: 15 }}>
          {rightSideLoading ? <div>LOADING</div> : children}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LogGrid
