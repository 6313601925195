import React from 'react';
import { useParams } from 'react-router';
import MeetingFloor from './MeetingFloor';
import LeadersFloor from './LeadersFloor';
import OpenSpace from './OpenSpace';
import BreakFloor from './BreakFloor';
import SettingSpace from './Settings';

const SpotRooms = () => {
    const { floorId } = useParams();
    switch (floorId) {
        case 'meeting':
            return <MeetingFloor />;
        case 'direction':
            return <LeadersFloor />;
        case '0':
            return <BreakFloor />;
        case 'setting':
            return <SettingSpace />;
        default:
            return <OpenSpace />;
    }
};

export default SpotRooms;
