import React, { useContext, useEffect, useState } from 'react';
import { Grid, ButtonBase, IconButton, makeStyles } from '@material-ui/core';
import { getMessages, getTasks, sendMessageApi, sendVocalToApi, getSpeakers } from '../helpers';
import Button from '@material-ui/core/Button';
import { Store } from '../../../../store/store';
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import Picker from 'emoji-picker-react';
import MicIcon from '@material-ui/icons/Mic';
import StopIcon from '@material-ui/icons/StopRounded';
import MessageComponent from '../../../../components/Message';
import File from '../File';
import { capitalizeFirstLetter } from '../../../helpers';
import { MentionsInput, Mention } from 'react-mentions';
import {
    removeAccessSubject,
    removeAccessLeenkPrivate,
    createAccessPrivateLeenk,
    createAccessSubject
} from '../../../setting/Setting/helpers';
import { getSpecificFolders, sendFile } from '../../../locker/Locker/helpers';
import Speakers from '../Speakers';
import { socket, removeEvent } from '../../../../socket';

import styles from './Messenger.styles';
import { dispatchAlert } from '../../../menu/helpers';

const neverMatchingRegex = /($a)/;

const useStyles = makeStyles(styles);

const emojiPickerStyles = {
    position: 'absolute',
    bottom: 'calc(100% + 8px)',
    right: 0,
};

const inputMentionsStyles = {
    suggestions: {
        list: {
            overflow: 'auto',
            position: 'absolute',
            bottom: '1em',
            backgroundColor: '#fff',
            borderRadius: 5,
            boxSizing: 'border-box',
            border: '1px solid orange',
        },
        item: {
            padding: '4px 16px',
            '&focused': {
                backgroundColor: '#cee4e5',
            },
        },
    },
};

const Mic = ({ leenk }) => {
    const classes = useStyles();
    const [isRecord, setIsRecord] = useState(false);
    const [recorder, setRecorder] = useState(null);
    const [vocal, setVocal] = useState([]);
    const {
        state: {
            language: {
                leenks: { enableMicro: enableMicroLang },
            },
        },
    } = useContext(Store);
    const startRecord = () => {
        navigator.mediaDevices
            .getUserMedia({ audio: true, video: false })
            .then((stream) => {
                setIsRecord(true);
                let tmp = new MediaRecorder(stream);
                tmp.ondataavailable = (e) => {
                    setVocal([e.data]);
                    const audio = document.getElementById('test');
                    audio.controls = true;
                    audio.src = window.URL.createObjectURL(new Blob([e.data]));
                };
                setRecorder(tmp);
                tmp.start();
            })
            .catch(() => alert(enableMicroLang));
    };

    const stopRecord = () => {
        recorder.stop();
        setIsRecord(false);
    };

    const sendVocal = () => {
        const fd = new FormData();
        fd.append('vocal', vocal[0]);
        sendVocalToApi(fd, leenk)
            .then(() => setVocal([]))
            .catch((err) => console.log(err));
    };

    return (
        <>
            {!isRecord ? (
                vocal.length ? (
                    <>
                        <div className="audio-container bg-white shadow">
                            <audio id={'test'} />
                            <IconButton classes={{ root: classes.iconButton }} onClick={() => setVocal([])}>
                                <DeleteOutlinedIcon />
                            </IconButton>
                        </div>
                        <IconButton classes={{ root: classes.iconButton }} onClick={() => sendVocal()}>
                            <SendOutlinedIcon />
                        </IconButton>
                    </>
                ) : (
                        <IconButton classes={{ root: classes.iconButton }} onClick={() => startRecord()}>
                            <MicIcon />
                        </IconButton>
                    )
            ) : (
                    <>
                        <IconButton classes={{ root: classes.iconButton }} onClick={() => stopRecord()}>
                            <StopIcon />
                        </IconButton>
                    </>
                )}
        </>
    );
};

const MessengerInput = ({ speakers, leenk, leenks, navigateToFolder, folderId }) => {
    const [tasks, setTasks] = useState([]);
    const [folders, setFolders] = useState([]);
    const [files, setFiles] = useState([]);
    const classes = useStyles();
    const [displayEmojiPicker, setDisplayEmojiPicker] = useState(false);
    const [message, setMessage] = useState('');
    const [messageToSend, setMessageToSend] = useState('');
    const [mentions, setMentions] = useState([]);
    const [file, setFile] = useState()
    const [fd, setFd] = useState()
    const {
        state: {
            language: {
                leenks: { sendMessage: sendMessageLang, folder: folderLang, file: fileLang },
            },
        },
    } = useContext(Store);
    const uploadFile = async () => {
        const { id: fileId, extension } = await sendFile(fd, folderId);
        setFile(null)
        setFd(null)
        return { fileId, extension }
    }
    const sendMessage = async () => {
        //add mentions
        try {
            if (messageToSend.length > 0 || fd) {
                if (fd) {
                    const fileFromApi = await uploadFile()
                    await sendMessageApi(leenk, messageToSend, mentions, fileFromApi)
                } else {
                    await sendMessageApi(leenk, messageToSend, mentions)
                }
                setMessage('');
                setMessageToSend('');
            }
        } catch (e) {
            console.log(e)
        }


    };

    const chargeDatas = async (folderId) => {
        try {
            const tasks = await getTasks();
            const { files, folders } = await getSpecificFolders(folderId);
            setTasks(tasks.map((e) => ({ id: 'task' + e.id, display: e.title })));
            setFolders(folders);
            setFiles(files);
        } catch (e) {
            console.log(e);
        }
    };
    const [emojis, setEmojis] = useState([]);
    useEffect(() => {
        fetch(
            'https://gist.githubusercontent.com/oliveratgithub/0bf11a9aff0d6da7b46f1490f86a71eb/raw/d8e4b78cfe66862cf3809443c1dba017f37b61db/emojis.json'
        )
            .then((response) => response.json())
            .then((jsonData) => setEmojis(jsonData.emojis));
    }, []);

    const queryEmojis = (query, callback) => {
        if (query.length === 0) return;

        const matches = emojis
            .filter((emoji) => {
                return emoji.name.indexOf(query.toLowerCase()) > -1;
            })
            .slice(0, 10);
        return matches.map(({ emoji }) => ({ id: emoji }));
    };

    useEffect(() => {
        if (leenk && folderId) {
            chargeDatas(folderId);
        }
        // return () => setTasks([])
    }, [folderId, leenk]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey && messageToSend.length > 0) {
            sendMessage()
        }
    };

    const [elementsLength, setElementsLength] = useState(0);
    const mentionTypes = ['file', 'folder', 'task', 'leenk', 'user'];
    const extractId = (idGet) => {
        for (let index = 0; index < mentionTypes.length; index++) {
            const type = mentionTypes[index];
            if (idGet.includes(type)) {
                const id = Number(idGet.replace(type, ''));
                return { id, type };
            }
        }
        return null;
    };

    const updateMentions = (elements) =>
        setMentions(
            elements.map((element) => ({
                ...extractId(element.id),
                name: element.display,
                position: element.plainTextIndex,
            }))
        );

    const onChangeMentionsInput = (e, newValue, newPlainTextValue, elements) => {
        if (elements.length !== elementsLength) {
            updateMentions(elements);
            setElementsLength(elements.length);
        }
        setMessageToSend(newPlainTextValue);
        setMessage(newValue); //?? retrouver avant
    };

    const addEmojiToInput = (event, { emoji }) => {
        setMessageToSend((value) => value + emoji);
        setMessage((value) => value + emoji);
    };
    const speakersToMap = speakers.map(({ id, firstname, lastname }) => ({
        id: 'user' + id,
        display: [firstname, lastname].map(capitalizeFirstLetter).join(' '),
    }));
    const folderToCharge = folders.map(({ name, id, ...rest }) => ({
        display: name,
        type: 'folder',
        id: 'folder' + id,
        name,
    }));
    const fileToCharge = files.map(({ name, id, ...rest }) => ({ display: name, type: 'file', id: 'file' + id, name }));
    const locker = [...folderToCharge, ...fileToCharge];
    return (
        <>
            <div className={classes.inputContainer}>
                <MentionsInput
                    className={classes.input}
                    placeholder="Message..."
                    //lié à en bas
                    value={message}
                    //je crois en fait que c'est cette fonction qui fait un traitement du texte..
                    onChange={onChangeMentionsInput}
                    onKeyPress={handleKeyPress}
                    allowSuggestionsAboveCursor
                    style={inputMentionsStyles}
                >
                    <Mention trigger=":" markup="__id__" regex={neverMatchingRegex} data={queryEmojis} />
                    <Mention trigger="@" data={speakersToMap} />
                    <Mention trigger="&" data={tasks} />
                    <Mention
                        trigger="#"
                        renderSuggestion={({ type, name }) => [capitalizeFirstLetter(type), name].join('/ ')}
                        data={locker}
                    />
                    <Mention trigger="+" data={leenks.map(({ id, name }) => ({ id: 'leenk' + id, display: name }))} />
                </MentionsInput>
                <div className={classes.iconContainer}>
                    {displayEmojiPicker ? (
                        <Picker pickerStyle={emojiPickerStyles} onEmojiClick={addEmojiToInput} />
                    ) : null}
                    <IconButton
                        classes={{ root: classes.iconButton }}
                        onClick={() => setDisplayEmojiPicker((picker) => !picker)}
                    >
                        <SentimentSatisfiedOutlinedIcon />
                    </IconButton>
                    <Mic leenk={leenk} />
                    <File navigateToFolder={navigateToFolder} setFile={setFile} setFd={setFd} file={file} />
                </div>
            </div>
            <ButtonBase classes={{ root: classes.buttonSubmit }} onClick={sendMessage}>
                {sendMessageLang}
            </ButtonBase>
        </>
    );
};

const Messenger = ({
    leenk,
    parentId,
    parentType,
    leenks,
    folderId,
    navigateToFolder,
    navigateToTask,
    createLeenk,
    propStyle = {},
    setCurrent,
    updateTypes,
    changeLeenk,
    seeSpeakers = true,
    shared
}) => {
    const classes = useStyles();
    const [messages, setMessages] = useState([]);
    const { state, dispatch } = useContext(Store);
    const [speakers, setSpeakers] = useState([]);
    const [offset, setOffset] = useState(0);
    const {
        alerts,
        language: {
            leenks: { previousMessages: previousMessagesLang },
        },
    } = state;

    const scrollToBottom = () => {
        const container = document.querySelector('.messages-container');
        container.scrollTop = container.scrollHeight;
    };

    useEffect(() => {
        if (messages && offset === 0) scrollToBottom();
    }, [messages]);

    useEffect(() => {
        if (parentId && leenk) {
            socket.emit('join_chat', { leenkId: leenk.id, parentId });
        }

        return () => {
            if (leenk && parentId) {
                socket.emit('leave_chat', { leenkId: leenk.id, parentId });
            }
        };
    }, [parentId, leenk, parentType]);

    useEffect(() => {
        socket.on('update_message', (obj) => {
            setMessages((messages) => [...messages, obj]);
        });
        return () => {
            removeEvent('update_message');
        };
    }, [leenk]);

    useEffect(() => {
        if (leenk && leenk.id) {
            setOffset(0);
            getMessages(leenk.id, 0).then((tab) => (offset ? setMessages((m) => [...tab, ...m]) : setMessages(tab)));
            getSpeakers(leenk.id).then((data) => setSpeakers(data));
            // getUsers(leenk).then((users) => console.log(users))
        }
    }, [leenk]);

    useEffect(() => {
        if (leenk && leenk.id) {
            getMessages(leenk.id, offset).then((tab) =>
                offset ? setMessages((m) => [...tab, ...m]) : setMessages(tab)
            );
        }
    }, [offset]);

    const getRemovedSpeaker = newSpeakers => speakers.find(speaker => newSpeakers.findIndex(({ id }) => id === speaker.id) === -1)
    const getAddedSpeaker = newSpeakers => newSpeakers.find(speaker => speakers.findIndex(({ id }) => id === speaker.id) === -1)

    const removeUserFromLeenk = async (userId) => {
        if (parentType === 'FOLDER') {
            await removeAccessLeenkPrivate(leenk.id, [Number(userId)]);
        } else {
            await removeAccessSubject(leenk.id, 0, parentType, userId, folderId);
        }
        setSpeakers(speakers.filter(({ id }) => Number(userId) !== Number(id)));
    };

    const addUserToLeenk = async (userId) => {
        let speaker
        if (parentType === 'FOLDER') {
            speaker = await createAccessPrivateLeenk(leenk.id, [Number(userId)]);
        } else {
            speaker = await createAccessSubject(leenk.id, 0, parentType, userId, leenk.folder, parentId);
        }
        setSpeakers(speakers => [...speakers, speaker])
    }


    const manageSpeakers = async newSpeakers => {
        if (newSpeakers.length === speakers.length)
            return
        try {
            if (newSpeakers.length < speakers.length) {
                const { id: userId } = getRemovedSpeaker(newSpeakers)
                await removeUserFromLeenk(userId)
                dispatchAlert(dispatch, alerts, { type: 'success' })
            } else {
                const newUser = getAddedSpeaker(newSpeakers)
                await addUserToLeenk(newUser.id)
                dispatchAlert(dispatch, alerts, { type: 'success' })
            }
        } catch (e) {
            dispatchAlert(dispatch, alerts, { type: 'error' })
            console.log(e.message)
        }
    }
    //faire un get au dessus

    return (
        <Grid container item direction="column" wrap="nowrap" style={{ height: '100%', ...propStyle }}>
            {!!seeSpeakers && <Speakers
                {...{
                    speakers,
                    leenks,
                    setSpeakers: manageSpeakers,
                    removeUserFromLeenk,
                    parentType,
                    folderId,
                    parentId,
                    leenk,
                    setCurrent,
                    updateTypes,
                    changeLeenk,
                }}
            />}
            <Grid
                classes={{ root: classes.content }}
                container
                item
                direction="column"
                wrap="nowrap"
                className="messages-container scroll-y scrollbar-disable"
            >
                {messages.length >= offset + 20 ? (
                    <Button
                        classes={{ root: classes.buttonMoreRoot, label: classes.buttonMoreLabel }}
                        onClick={() => setOffset((offset) => offset + 20)}
                    >
                        {previousMessagesLang}
                    </Button>
                ) : null}
                {messages.map(({ from, message, created_at, file_id, type, file }, key) => (
                    <MessageComponent
                        type={type}
                        speaker={speakers.find((speaker) => speaker.id === from)}
                        parentType={parentType}
                        createLeenk={createLeenk}
                        navigateToTask={navigateToTask}
                        from={from}
                        message={message}
                        created_at={created_at}
                        key={key}
                        fileId={file_id}
                        shared={shared}
                        file={file}
                    />
                ))}
            </Grid>
            <Grid
                container
                item
                justify="center"
                alignItems="center"
                wrap="nowrap"
                className="bg-grey-light form-message-container"
            >
                <MessengerInput
                    folderId={leenk.folder}
                    speakers={speakers}
                    leenk={leenk.id}
                    leenks={leenks}
                    navigateToFolder={navigateToFolder}
                />
            </Grid>
        </Grid>
    );
};

export default Messenger;
