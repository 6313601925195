import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, Typography, ClickAwayListener, ButtonBase, Box } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { getFolders, getMessages, searchUsers } from './helpers';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined';
import LinkIcon from '@material-ui/icons/Link';
import { useHistory } from 'react-router-dom';
import { Store } from '../../../store/store';
import UserSearchActions from './UserSearchActions';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        boxSizing: 'border-box',
        position: 'absolute',
        top: '100%',
        backgroundColor: '#fff',
        color: '#45535C',
        borderRadius: 5,
        height: 'auto',
        maxHeight: 500,
        zIndex: 10,
        marginLeft: 'auto',
        marginRight: 'auto !important',
        left: 0,
        right: 0,
        overflow: 'scroll',
        width: `calc(100% - ${theme.spacing(4)}px)`,
        boxShadow: '0px 0px 15px 2px #c0c9f0ca',
        '&>*:not(:last-of-type)': {
            borderRight: '3px solid #F4F5F5',
        },
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
    },
    title: {
        fontSize: theme.typography.pxToRem(20),
        textAlign: 'left',
        padding: theme.spacing(2),
        fontWeight: theme.typography.fontWeightBold,
    },
    listRoot: {
        position: 'relative',
        width: '100%',
        maxHeight: 350,
        overflow: 'auto',
        color: '#45535C',
    },
    listSubheader: {
        backgroundColor: '#E2F0F7',
        display: 'flex',
        alignItems: 'center',
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightBold,
        textAlign: 'left',
        color: '#45535C',
    },
    listSubheaderIcon: {
        color: '#45535C',
    },
}));

const Column = ({ children, title }) => {
    const classes = useStyles();

    return (
        <Box className={classes.column}>
            <Typography variant="h3" classes={{ root: classes.title }}>
                {title}:
            </Typography>
            {children}
        </Box>
    );
};

const SearchList = () => {
    const classes = useStyles();

    return (
        <List className={classes.listRoot}>
            {[0, 1, 2].map((item, key) => (
                <ListItem key={key}>
                    <ListItemText primary={`Item ${item}`} />
                </ListItem>
            ))}
        </List>
    );
};

const PinnedSubheaderList = ({
    icon,
    title,
    list,
    navigate,
    baseUrl,
    titleClick = null,
    navigateIsTitleClick = false,
    messageButton = null,
}) => {
    const classes = useStyles();

    return (
        <>
            <ListSubheader classes={{ root: classes.listSubheader }}>
                <ListItemIcon classes={{ root: classes.listSubheaderIcon }}>{icon}</ListItemIcon>
                {title}
                {titleClick ? <ButtonBase 
                    style={{marginLeft: 5}}
                    onClick={titleClick}>Accéder</ButtonBase> : null}
            </ListSubheader>
            {list.map((item, key) => (
                <ListItem key={key} style={key !== 0 ? { borderTop: '1px solid #F4F5F5' } : null}>
                    <ListItemText primary={item.name} />
                    {item.actions ? item.actions : <ButtonBase
                        onClick={
                            navigateIsTitleClick
                                ? titleClick
                                : () => navigate(baseUrl + (baseUrl[baseUrl.length - 1] === '/' ? item.id : ''))
                        }
                    >
                        {messageButton || 'Accéder'}
                    </ButtonBase>}
                </ListItem>
            ))}
        </>
    );
};

const UserList = ({ search, handleClickAway }) => {
    const classes = useStyles();
    const [users, setUsers] = useState([]);
    const addChildrenToUsers =
        usersFromApi =>
            setUsers(usersFromApi
                .map(user => ({
                    ...user,
                    actions: <UserSearchActions user={user} 
                    handleClickAway={handleClickAway} />
                })))
    useEffect(() => {
        if (search) {
            searchUsers(search)
                .then(addChildrenToUsers)
        }
        return () => {
            setUsers([]);
        };
    }, [search]);

    return (
        <List className="scroll-y scrollbar-disable" disablePadding classes={{ root: classes.listRoot }}>
            <PinnedSubheaderList
                icon={<PeopleOutlineIcon fontSize="large" />}
                title="Users"
                list={users}
            />
            {/* <PinnedSubheaderList
                baseUrl={baseUrl}
                navigate={navigate}
                icon={<PeopleOutlineIcon fontSize="large" />}
                title="Customers"
                list={customers}
            />
            <PinnedSubheaderList
                baseUrl={baseUrl}
                navigate={navigate}
                icon={<WorkOutlineOutlinedIcon fontSize="large" />}
                title="Suppliers"
                list={suppliers}
            /> */}
        </List>
    );
};

const FolderList = ({ search, navigate }) => {
    const classes = useStyles();
    const [folders, setFolders] = useState([]);
    const baseUrl = '/locker/folder/';
    useEffect(() => {
        if (search) getFolders(search).then((data) => setFolders(data));
    }, [search]);
    return (
        <List className="scroll-y scrollbar-disable" disablePadding classes={{ root: classes.listRoot }}>
            {folders.filter((e) => e.type === 0).length > 0 ? (
                <PinnedSubheaderList
                    icon={<LockOutlinedIcon fontSize="large" />}
                    title="Privates"
                    list={folders.filter((e) => e.type === 0)}
                    navigate={navigate}
                    baseUrl={baseUrl}
                />
            ) : null}
            {folders.filter((e) => e.type === 1).length > 0 ? (
                <PinnedSubheaderList
                    icon={<PublicOutlinedIcon fontSize="large" />}
                    title="Public"
                    list={folders.filter((e) => e.type === 1)}
                    navigate={navigate}
                    baseUrl={baseUrl}
                />
            ) : null}
        </List>
    );
};

const LeenkList = ({ search, navigate }) => {
    const classes = useStyles();
    const [leenks, setLeenks] = useState([]);
    const [messages, setMessages] = useState([]);
    const baseUrl = '/leenk/';

    useEffect(() => {
        if (search)
            getMessages(search).then((data) => {
                setLeenks(
                    data
                        .reduce(
                            (previous, current) =>
                                previous.some((prev) => prev.name === current.name)
                                    ? [...previous]
                                    : [...previous, current],
                            []
                        )
                        .map(({ name, leenkId }) => ({ name, id: leenkId }))
                );
                setMessages(data);
            });
    }, [search]);

    return (
        <List className="scroll-y scrollbar-disable" disablePadding classes={{ root: classes.listRoot }}>
            {leenks.map(({ name, id }, key) => (
                <PinnedSubheaderList
                    key={key}
                    navigate={navigate}
                    baseUrl={baseUrl}
                    icon={<LinkIcon fontSize="large" />}
                    title={name}
                    titleClick={() => navigate(baseUrl + id)}
                    navigateIsTitleClick
                    messageButton="Accéder au leenk"
                    list={messages.filter((e) => e.name === name).map(({ message, id }) => ({ name: message, id }))}
                />
            ))}
        </List>
    );
};

const Index = ({ search, handleClickAway }) => {
    const classes = useStyles();
    const history = useHistory();
    const navigate = (url) => {
        history.push(url);
        handleClickAway(false);
    };
    const { state: { language: { searchBar: {
        contacts: contactsLang,
        folders: foldersLang,
    } } } } = useContext(Store)
    return (
        <ClickAwayListener onClickAway={() => handleClickAway(true)}>
            <Box className={classes.root}>
                <Column title={contactsLang}>
                    <UserList search={search} handleClickAway={handleClickAway} />
                </Column>
                <Column title={foldersLang}>
                    <FolderList search={search} navigate={navigate} />
                </Column>
                <Column title="Leenks">
                    <LeenkList search={search} navigate={navigate} />
                </Column>
            </Box>
        </ClickAwayListener>
    );
};

export default Index;
