import React, { useEffect, useRef, useState, useContext } from 'react';
import {
  ButtonBase, Grid, IconButton, TextField,
  InputAdornment, Typography, Chip, makeStyles, Tooltip,
} from "@material-ui/core";
import PopUpTask from "./PopUpTask";
import { createTask, getLabels, getTasks, removeTask } from "./helpers";
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { useStyles, StyledButton } from './style'
import { Store } from '../../store/store'
import { useParams } from 'react-router-dom';
import { dispatchAlert } from '../menu/helpers';
import SearchBar from '../../components/SearchBar';
import StarIcon from '@mui/icons-material/Star';
import { getUser } from '../helpers';
import TaskCardUsersDisplay from './TaskCardUsersDisplay';

const Task = ({
  history
}) => {
  const taskId = Number(useParams().taskId)
  const classes = useStyles()
  const [open, setOpen] = useState(!!taskId);
  const [tasks, setTasks] = useState([]);
  const [tasksFromApi, setTasksFromApi] = useState([]);
  const [id, setId] = useState(taskId)
  const [labels, setLabels] = useState([]);
  const { dispatch, state: {
    alerts,
    language: {
      tasks: {
        addTask: addTaskLang,
        addTaskName: addTaskNameLang,
      }
    }
  } } = useContext(Store)
  const user = getUser()
  const chargeDatas = () => {
    getTasks().then(data => {
      setTasks(data)
      setTasksFromApi(data)
    })
    getLabels().then(data => setLabels(data))
  }
  useEffect(() => {
    chargeDatas()
  }, [])

  useEffect(() => {
    if (!!taskId) {
      setId(taskId)
      setOpen(true)
      chargeDatas()
    }
  }, [taskId])

  const navigateToTask = id => history.push('/task/' + id)

  const remove = id => removeTask(id)
    .then(() => {
      setTasks(tasks => tasks.filter(e => e.id !== id))
      setTasksFromApi(tasks => tasks.filter(e => e.id !== id))
    })
    .catch(e => dispatchAlert(dispatch, alerts, { type: 'error', message: e.message }))

  const closePopUpTask = () => {
    setOpen(false)
    setId(0)
    history.push('/task')
  }

  const addTask = task => {
    setTasks(tasks => [...tasks, { ...task }])
    setTasksFromApi(tasks => [...tasks, { ...task }])
    navigateToTask(task.id)
  }


  const AddTask = ({ addTask/* setTasks, setTasksFromApi*/ }) => {
    const [displayInput, setDisplayInput] = useState(false)
    const subjectRef = useRef(null)

    const add = () => createTask(subjectRef.current.value)
      .then(data => {
        const { value } = subjectRef.current || {}
        setDisplayInput(false)
        addTask({ id: data, title: value })
      })
      .catch(e => dispatchAlert(dispatch, alerts, { type: 'error', message: e.message }))

    const cancel = () => {
      subjectRef.current.value = ''
      setDisplayInput(false)
    }


    return displayInput
      ? <div className={classes.task}>
        <TextField fullWidth
          inputRef={subjectRef}
          label={addTaskNameLang}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={add}>
                  <AddCircleOutlineOutlinedIcon />
                </IconButton>
                <IconButton edge="end" onClick={cancel}>
                  <CancelOutlinedIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>
      : <StyledButton onClick={() => setDisplayInput(true)}>
        {addTaskLang}
      </StyledButton>
  }

  return (
    <Grid container direction="row" wrap="nowrap" justify="center">
      <Grid container item alignItems="flex-start" spacing={2} direction="column">
        {tasksFromApi.length > 0 ? <><SearchBar
          list={tasksFromApi.map(({
            label,
            title,
            description,
            ...task
          }) => ({
            label,
            title,
            description,
            ...task,
            valueToCompare: ((labels.find(({ id }) => label === id) || {}) || { label: '' }).label + title + description,
          }))} setList={setTasks} />

          {tasks.map(({ title, id, color, label, description ,created_by}) => (
            <Grid key={id} item style={{ position: 'relative' }}>
              <ButtonBase classes={{ root: classes.task }}
                style={{ borderColor: color || 'white' }}
                onClick={() => navigateToTask(id)}
              >
{created_by === user.id && <StarIcon className={classes.star} fontSize={'small'} />}
                <Typography>{title}</Typography>
                <Typography>{description}</Typography>
                {
                  labels.length && label
                    ? <Chip label={(labels.find(({ id }) => label === id) || {}).label}
                      color="primary"
                      style={{ backgroundColor: (labels.find(({ id }) => label === id) || {}).color || 'black' }}

                    />
                    : null
                }
                <TaskCardUsersDisplay taskId={id} classes={classes} />
              </ButtonBase>
              <IconButton className={classes.deleteTask} onClick={() => remove(id)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          ))}</>: null}
        <Grid item>
          <AddTask addTask={addTask} />
        </Grid>
      </Grid>
      {!!taskId && id ? <PopUpTask
        labels={labels}
        setLabels={setLabels}
        id={id}
        setTasks={setTasks}
        handleClose={closePopUpTask}
        open={open} />: null}
    </Grid>
  );
};

export default Task;
