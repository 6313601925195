import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { getCollaborators, getSpace } from './helpers';
import Spot from '../../../components/Spot';
import { Typography, IconButton } from '@material-ui/core';
import { Settings as SettingsIcon } from '@material-ui/icons';
import { Store } from '../../../store/store';
import OpenSpaceUsers from './OpenSpaceUsers';
import { getUser, userRoles } from '../../helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        width: '100%',
    },
    title: {
        fontSize: theme.typography.pxToRem(24),
        fontWeight: theme.typography.fontWeightBold,
        textAlign: 'left',
        padding: theme.spacing(2),
    },
    container: {
        boxSizing: 'border-box',
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gridGap: theme.spacing(3),
        padding: theme.spacing(2),
        width: '100%',
        height: '100%',
    },
    settingsLink: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
    },
    settingsIcon: {
        height: 30,
        width: 'auto',
    },
}));

const OpenSpace = () => {
    const classes = useStyles();
    const [space, setSpace] = useState([]);
    const [users, setUsers] = useState([]);
    const [currentSpace, setCurrentSpace] = useState({});
    const width = 20;
    const { floorId } = useParams();
    const history = useHistory()
    const {
        state: {
            users: usersConnected,
            language: {
                spots: { addUser: addUserLang },
            },
        },
    } = useContext(Store);

    useEffect(() => {
        for (let i = 0; i < width; i++) setSpace((s) => [...s, i]);
        getSpace(floorId)
            .then((response) => {
                setCurrentSpace(response);
                if (response)
                    getCollaborators(response.space_id).then((data) => {
                        setUsers(
                            data
                                .map(u => ({
                                    ...u,
                                    connected: usersConnected.includes(u.id)
                                }))
                        );
                    });
            })
            .catch(e => history.push('/spots/meeting'))

        return () => {
            setUsers([]);
            setSpace([]);
        };
    }, [floorId, usersConnected]);
    const { role } = getUser()
    const isAdmin = role === userRoles.ADMIN || role === userRoles.SUPERADMIN
    return (
        <>
            <div className={classes.root}>
                <div style={{ display: 'flex' }}>
                    {currentSpace && (
                        <Typography classes={{ root: classes.title }} variant="h2">
                            {currentSpace.title}
                        </Typography>
                    )}
                    {!!users.length && <OpenSpaceUsers {...{ floorId }} />}
                </div>
                <div className={classes.container}>
                    {currentSpace &&
                        space.map((i, index) => (
                            <Spot key={index} index={i} users={users} spaceId={currentSpace.space_id} setUsers={
                                users => setUsers(
                                    users
                                        .map(u => ({
                                            ...u,
                                            connected: usersConnected.includes(u.id)
                                        }))
                                )
                            } />
                        ))}
                </div>
            </div>
            {isAdmin && <Link className={classes.settingsLink} to="/spots/setting">
                <IconButton>
                    <SettingsIcon classes={{ root: classes.settingsIcon }} />
                </IconButton>
            </Link>}
        </>
    );
};

export default OpenSpace;
