import React, { useEffect, useState, useContext } from 'react';
import { createNote, deleteNote, getNotes, updateNote } from "./helpers";
import {
  IconButton, TextField, Grid, List,
  ListItem, ListItemText, ListItemSecondaryAction,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from '@material-ui/icons/Save'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { Store } from '../../../store/store'
import { useParams, useHistory } from 'react-router-dom';
import { dispatchAlert } from '../../menu/helpers';

const useStyles = makeStyles(theme => ({
  save: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: '#1773C8',
    zIndex: 10,
    color: 'white',
    '&:hover': {
      color: 'black'
    }
  },
  listItem: {
    borderBottom: '1px solid  #E8EBEC',
  },
  inputTitle: {
    padding: theme.spacing(1),
    borderBottom: '1px solid  #E8EBEC',
  },
  inputText: {
    padding: theme.spacing(1),
  }
}))

const ListNotes = ({ currentId, notes, setId, setNotes, navigateToNotes }) => {
  const classes = useStyles();
  const { state: {
    language: {
      notes: {
        newNote: newNoteLang,
      }
    }
  } } = useContext(Store)

  const remove = id => deleteNote(id).then(() => {
    setNotes(notes.length > 1 ? notes.filter(e => e.id !== id) : [])
    setId(-1)
  })

  return <Grid container item xs={2} direction="column" className="leenks-section scroll-y scrollbar-disable">
    <List component="nav">
      <ListItem button
        className={classes.listItem}
        selected={currentId === -1}
        onClick={navigateToNotes}>
        <ListItemText primary={newNoteLang} />
      </ListItem>
      {
        notes.map(({ title, id }) => (
          <ListItem key={id} button
            className={classes.listItem}
            selected={id === currentId}
            onClick={() => setId(id)}>
            <ListItemText primary={title} />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => remove(id)}>
                <DeleteForeverOutlinedIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))
      }
    </List>
  </Grid>
}

const Note = ({ note, setNotes, navigateToNote }) => {
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const { dispatch, state: {
    alerts,
    language: {
      notes: {
        noteTitlePLacholder: noteTitlePLacholderLang,
      }
    }
  } } = useContext(Store)

  const save = async () => {
    if (!title)
      return
    try {
      if (note)
        return updateNote(note.id, title, text).then(() => getNotes().then(data => setNotes(data)))
      else {
        const { id } = await createNote(title, text)
        setNotes(notes => setNotes([...notes, { id, title, text }]))
        navigateToNote(id)
      }
    } catch (e) {
      dispatchAlert(dispatch, alerts, { type: 'error', message: e.message })
    }
  }

  const focus = () => document.getElementById('note-content').focus()

  useEffect(() => {
    if (note) {
      setTitle(note.title)
      setText(note.text || '')
    }
    else {
      setTitle('')
      setText('')
    }
  }, [note])

  return <Grid container item xs={10} direction="column" wrap="nowrap" className="leenks-section scroll-y scrollbar-disable">
    <TextField fullWidth
      className={classes.inputTitle}
      InputProps={{ disableUnderline: true }}
      value={title}
      onChange={e => setTitle(e.target.value)}
      placeholder={`${noteTitlePLacholderLang}...`} />
    <div className="scroll-y scrollbar-disable" style={{ height: '100%', cursor: 'text' }}
      role="button" aria-pressed="false" onClick={() => focus()}>
      <TextField multiline fullWidth
        className={classes.inputText}
        id="note-content"
        InputProps={{ disableUnderline: true }}
        value={text}
        onChange={e => setText(e.target.value)} />
    </div>
    <IconButton onClick={save} classes={{ root: classes.save }} >
      <SaveIcon fontSize="large" />
    </IconButton>
  </Grid>
}


const Notes = () => {
  const [notes, setNotes] = useState([])
  const noteId = Number(useParams().noteId) || 0
  const history = useHistory()
  useEffect(() => {
    getNotes()
      .then(data => setNotes(data))
  }, [])

  useEffect(() => {
    if (noteId === -1) {
      return
    }
    if (notes.length > 0 && notes.findIndex(n => n.id === noteId) === -1) {
      if (notes.length > 0) {
        history.replace('/note/' + notes[0].id)
      } else {
        history.replace('/notes')
      }
    }
  }, [notes, noteId])


  const navigateToNote = (id) => history.push('/note/' + id)
  const navigateToNotes = () => history.push('/note/-1')
  return (
    <Grid container className="page-container border-radius-5 shadow bg-white">
      <ListNotes currentId={noteId} setId={navigateToNote} notes={notes} setNotes={setNotes} navigateToNotes={navigateToNotes} />
      <Note note={notes.find(e => e.id === noteId)} setNotes={setNotes} navigateToNote={navigateToNote} />
    </Grid>
  );
};

export default Notes;
