import React, { useCallback, useEffect, useContext, useRef, useState } from 'react';
import {
  IconButton, Modal, TextField, Typography,
  Grid, Tabs, Tab, InputAdornment,
} from "@material-ui/core";
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import ColorLensOutlinedIcon from '@material-ui/icons/ColorLensOutlined';
import { createCheckList, createTask, getCheckLists, getTask, updateTask } from "./helpers";
import { ColorPicker } from 'material-ui-color';
import DateFnsUtils from '@date-io/moment';
import {
  DateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import debounce from "lodash/debounce";
import CheckList from './CheckList'
import Label from "./Label";
import Status from "./Status";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { useStyles, StyledButton } from './style'
import CommentTab from './Comment';
import { Store } from '../../store/store'
import { dispatchAlert } from '../menu/helpers';
import moment from 'moment'
import UserTaskList from './UsersTaskList';
import { useHistory } from 'react-router-dom';

const Title = ({ title, id, setTasks, updateTask }) => {
  const [titleInput, setTitleInput] = useState("")
  const { state: {
    language: {
      tasks: {
        taskTitle: taskTitleLang,
      }
    }
  } } = useContext(Store)

  useEffect(() => {
    if (title)
      setTitleInput(title)
  }, [title])

  const save = async () => {
    if (!titleInput || titleInput === title)
      return;
    try {
      await updateTask({ title: titleInput })
      setTasks(tasks => tasks
        .map(task => task.id === id ? { ...task, title: titleInput } : task))

    } catch (e) {
      console.log('error', e)
    }
  }

  return <TextField
    fullWidth={true}
    variant="outlined"
    value={titleInput}
    onChange={e => setTitleInput(e.target.value)}
    onBlur={save}
    label={taskTitleLang} />
}

const Description = ({ id, description, setTasks, updateTask }) => {
  const [descriptionInput, setDescriptionInput] = useState("")

  useEffect(() => {
    if (description)
      setDescriptionInput(description)
  }, [description])

  const save = async () => {
    if (descriptionInput === description)
      return;
    try {
      await updateTask({ description: descriptionInput })
      setTasks(tasks => tasks
        .map(task => task.id === id ? { ...task, description: descriptionInput } : task))
    } catch (e) {
      console.log('error', e)
    }
  }

  return <TextField
    disabled={!id}
    fullWidth={true}
    variant="outlined"
    margin="normal"
    multiline={true}
    onBlur={save}
    value={descriptionInput}
    onChange={e => setDescriptionInput(e.target.value)}
    label="Description"
    rows={8}
  />
}

const Color = ({ id, tcolor, updateTask }) => {
  const classes = useStyles();
  const [color, setColor] = useState('black');
  const { state: {
    language: {
      tasks: {
        taskColor: taskColorLang,
      }
    }
  } } = useContext(Store)

  const handleSet = useCallback(
    debounce((value) => {
      updateTask({ color: value })
    }, 500),
    []
  );

  useEffect(() => {
    setColor(tcolor || 'black')
  }, [tcolor])

  return <div className={classes.row}>
    <Typography classes={{ root: classes.title }}>
      <ColorLensOutlinedIcon />
      {taskColorLang}:
    </Typography>
    <ColorPicker onChange={e => {
      setColor(e.css.backgroundColor)
      handleSet(e.css.backgroundColor)
    }} value={color} defaultValue="black" hideTextfield />
  </div>
}

const FromTo = ({ dispatch, alerts, label, id, to, tdate, from = false, updateTask }) => {
  const classes = useStyles();
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (tdate)
      setDate(tdate)
  }, [tdate])

  const save = (e) => {
    const obj = to ? {
      to: e
    }
      : {
        from: e
      }
    updateTask(obj)
  }
  const minDate = from ? moment(from).add(1, 'm').toDate() : Date.now()
  return <div className={classes.row}>
    <Typography classes={{ root: classes.title }}>
      <EventOutlinedIcon />
      {label}:
    </Typography>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
      ampm={false}
        clearable
        inputVariant="outlined"
        minDate={minDate}
        value={date}
        onChange={e => {
          setDate(e);
          save(e)
        }}
      />
    </MuiPickersUtilsProvider>
  </div>
}

const AddCheckList = ({ taskId, setCheckLists }) => {
  const classes = useStyles();
  const [displayAdd, setDisplayAdd] = useState(false);
  const subjectRef = useRef(null);
  const { state: {
    language: {
      tasks: {
        addChecklist: addChecklistLang,
        addChecklistPlaceholder: addChecklistPlaceholderLang,
      }
    }
  } } = useContext(Store)

  const cancel = () => {
    setDisplayAdd(false)
  }

  const add = () => createCheckList(taskId, subjectRef.current.value).then(data => {
    setCheckLists(checklists => [...checklists, {
      title: subjectRef.current.value,
      id: data.checklistId
    }]);
    setDisplayAdd(false)
  })

  return (
    <div className={classes.row}>
      {displayAdd ? (
        <div style={{ gridColumn: 2 }}>
          <TextField variant="outlined"
            fullWidth
            placeholder={addChecklistPlaceholderLang}
            inputRef={subjectRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={add}>
                    <AddCircleOutlineOutlinedIcon />
                  </IconButton>
                  <IconButton edge="end" onClick={cancel}>
                    <CancelOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
      ) : <StyledButton style={{ gridColumn: 2 }} onClick={() => setDisplayAdd(true)}>{addChecklistLang}</StyledButton>}
    </div>
  )
}

const a11yProps = index => ({
  id: `full-width-tab-${index}`,
  'aria-controls': `full-width-tabpanel-${index}`,
});

const PopUpTask = ({ id, open, handleClose, setTasks, labels, setLabels }) => {
  const history = useHistory()
  const [tab, setTab] = useState(0);
  const [task, setTask] = useState({});
  const [checkLists, setCheckLists] = useState([])
  const classes = useStyles();
  const { dispatch, state: {
    alerts,
    language: {
      tasks: {
        task: taskLang,
        taskStart: taskStartLang,
        taskEnd: taskEndLang,
        comments: commentsLang,
      }
    }
  } } = useContext(Store)

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
const navigateToAllTasks = () => history.push('/task')
  useEffect(() => {
    if (id)
      Promise.all([
        getTask(id),
        getCheckLists(id)
      ]).then(([task, checkLists]) => {
        setTask(task)
        setCheckLists(checkLists)
      }).catch(navigateToAllTasks)
    return () => {
      setCheckLists([])
      setTask({})
    }
  }, [id])
  const updateTaskRemote = async obj => {
    try {
      await updateTask(obj, id)
      dispatchAlert(dispatch, alerts, { type: 'success' })
    } catch (e) {
      dispatchAlert(dispatch, alerts, { type: 'error' })
      throw e
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
    >
      <Grid container direction="column" wrap="nowrap" classes={{ root: classes.content }}>
        <IconButton className={classes.close} onClick={handleClose}>
          <CloseOutlinedIcon />
        </IconButton>
        <Grid container item style={{ borderBottom: '1px solid lightgrey' }}>
          <Tabs
            value={tab}
            onChange={handleChange}
            className={classes.tabs}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs"
          >
            <Tab className={classes.tab} label={taskLang} {...a11yProps(0)} />
            <Tab className={classes.tab} label={commentsLang} {...a11yProps(1)} />
          </Tabs>
        </Grid>
        <Grid container item
          direction="column"
          wrap="nowrap"
          className="scroll-y scrollbar-disable"
          classes={{ root: classes.tabContent }}
        >
          {!!task && (
            tab === 1
              ? <CommentTab taskId={id} />
              : <>
              <UserTaskList taskId={id} />
                <Title title={task.title} id={id} setTasks={setTasks} updateTask={updateTaskRemote} />
                <Description id={id} description={task.description} setTasks={setTasks} updateTask={updateTaskRemote} />
                <Color id={id} tcolor={task.color} updateTask={updateTaskRemote} />
                <FromTo dispatch={dispatch} alerts={alerts} label={taskStartLang} id={id} tdate={task.from} updateTask={updateTaskRemote} />
                <FromTo dispatch={dispatch} alerts={alerts} label={taskEndLang} id={id} tdate={task.to} to from={task.from} updateTask={updateTaskRemote} />
                <Status id={id} pstatus={task.status} updateTask={updateTaskRemote} />
                <div>
                  {
                    checkLists.map(checkList => <CheckList key={checkList.id} checkList={checkList} setCheckList={setCheckLists} />)
                  }
                  <AddCheckList taskId={id} setCheckLists={setCheckLists} />
                </div>
                <Label taskId={id} tlabel={task.label} updateTask={updateTaskRemote} setTasks={setTasks}
                  labels={labels}
                  setLabels={setLabels}
                />
              </>)
          }
        </Grid>
      </Grid>
    </Modal>
  );
};

export default PopUpTask;
