export default {
    inputContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 5,
        padding: '8px 16px',
        width: '100%',
    },
    input: {
        border: 'none',
        color: '#000',
        width: '100%',
        height: 'auto',
        '& textarea': {
            border: 'none',
        },
    },
    iconContainer: {
        display: 'flex',
        wrap: 'nowrap',
        '&>*': {
            marginLeft: 8,
        },
    },
    iconButton: {
        padding: 0,
    },
    buttonMoreRoot: {
        marginBottom: 32,
        background: 'none',
    },
    buttonMoreLabel: {
        color: '#aeaeae',
        textTransform: 'initial',
    },
    buttonSubmit: {
        backgroundColor: '#1884cc',
        color: 'white',
        borderRadius: 5,
        padding: '8px 16px',
        height: '100%',
        marginLeft: 8,
        fontWeight: 'bold',
    },
};
