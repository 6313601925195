export const FR = {
  required: 'Ce champ est requis',
  emptyFields: 'Champs manquants',
  submit: 'Valider',
  file: 'Choisissez un fichier',
  fileError: 'Taille du fichier trop large, veuillez en choisir un autre.',
  onPressMessage: 'Pressez la touche Entrer pour valider chaque mot.',
  name: 'Nom',
  title: 'Titre',
  add: 'Ajouter',
  remove: 'Supprimer'
}

export const EN = {
  required: 'Field required',
  emptyFields: 'Empty fields',
  submit: 'Valid',
  file: 'Choose File',
  fileError: 'Size of file too large, please choose another one.',
  onPressMessage: 'Press Enter to store each word',
  name: 'Name',
  title: 'Title',
  add: 'Add',
  remove: 'Remove'
}

export default {
  fr: FR,
  us: EN,
}
