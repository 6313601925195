export default {
    root: {
        height: 40,
        width: 40,
        '& > svg': {
            height: '100%',
            width: '100%',
        },
    },
};
