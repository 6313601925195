import React, { useState, useEffect } from 'react';
import UserAvatar from '../../components/UserAvatar';
import { Tooltip, makeStyles } from '@material-ui/core';
import { capitalizeFirstLetter } from '../helpers';
import { getTaskUsers } from './helpers';
import StarIcon from '@mui/icons-material/Star';
const useStyles = makeStyles((theme) => ({
	star: {
		color: '#F7E800',
		fill: '#F7E800',
		position: 'absolute',
		top: '-5px',
		right: '-2px',
		width: 15,
	}
}));

const TaskCardUsersDisplay = ({ taskId }) => {
	const [users, setUsers] = useState([])
	const classes = useStyles()

	useEffect(() => {
		getTaskUsers(taskId)
			.then(setUsers)
	}, [taskId])
	return (
		users.length > 0 && <div className="flex ml-auto">
			{users.map(({
				firstname,
				lastname,
				icon,
				created_by,
				id
			}, key) =>
				<Tooltip key={key} placement="top" title={
					[firstname, lastname].map(capitalizeFirstLetter).join(' ')
				}>
					<div className="relative">
						{created_by === id && <StarIcon className={classes.star} fontSize={'small'} />}
						<UserAvatar firstName={firstname} lastName={lastname} {...{ icon }} />
					</div>
				</Tooltip>

			)}
		</div>
	);
};

export default TaskCardUsersDisplay;