import { fetchApi } from '../../helpers'
// infos = {firstname, lastname, gender, birth, icon}
export const updateUserInfos = (infos) => fetchApi('/user', {
  method: 'PATCH',
  body:   infos,
})
export const updateUserInfoFromAdmin = (infos, userId) => fetchApi('/user/private/'+userId, {
  method: 'PATCH',
  body:   infos,
})
export const deactivateAccount = (userId) => fetchApi('/user/deactivate/'+userId, {
  method: 'PATCH',
})

export const deleteUser = userId => fetchApi('/user/' + userId, {
  method: 'DELETE',
})

export default {
  updateUserInfos,
  deleteUser,
  updateUserInfoFromAdmin,
  deactivateAccount
}
