import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import {
    makeStyles,
    List,
    ListItem,
    ListItemIcon,
    TextField,
    DialogActions,
} from '@material-ui/core';
import {
    getTeams,
    createPrivateLeenk,
    getPrivateLeenks,
    createTeam,
    getSharedTeams,
    createCompaniesFromTeams,
    sendGuestMessageApi,
    updateLeenk,
    updateTeam,
    updateSharedTeam,
    createLeenk
} from './helpers';
import { Store } from '../../../store/store';
import Button from '@material-ui/core/Button';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import Messenger from './Messenger';
import { useParams, useLocation } from 'react-router-dom';
import StartPrivateLeenkButton from '../../../components/StartPrivateLeenkButton';
import { getUser, userRoles } from '../../helpers';
import styles from './Leenks.styles';
import HorizontallyCollapsableColumn from '../../../components/HorizontallyCollapsableColumn';
import { useCallbackOpen } from '../../../hooks/useCallbackOpen';
import PopperPaper from '../../../components/PopperPaper';
import ExternalInteractionButton from './ExternalInteractionButton';
import SharedTeamInvitation from './SharedTeamInvitation';
import CompanyList from './CompanyList';
import SubjectList from './SubjectList';
import { dispatchAlert } from '../../menu/helpers';

const useStyles = makeStyles(styles);

const Leenks = ({ history }) => {
    const classes = useStyles();
    const params = useParams();
    const { pathname } = useLocation();
    const [inviteState, setInviteState] = useState(pathname.includes('invite'))
    const leenkId = params.leenkId ? Number(params.leenkId) : null;
    const { id: currentId, role } = getUser();
    const [privateConvs, setPrivateConvs] = useState([]);
    const subjectRef = useRef(null);
    const initialCurrent = { leenks: [], leenk: { id: leenkId } };
    const [current, setCurrent] = useState(initialCurrent); //null
    const [openSubjects, setSubjectsOpened, , setSubjectsOpenState] = useCallbackOpen(true);
    const [createSubjectListItemAnchorElement, setCreateSubjectListItemAnchorElement] = useState();

    const openCreateSubjectPopperPaper = Boolean(createSubjectListItemAnchorElement);

    const closeCreateSubjectPopperPaper = useCallback(() => setCreateSubjectListItemAnchorElement(null), []);

    const handleCreateSubjectListItemClick = useCallback((event) => {
        setCreateSubjectListItemAnchorElement(event.currentTarget);
    }, []);

    const { state, dispatch } = useContext(Store);

    const { alerts,
        language: {
            leenks: {
                spaces: spacesLang,
                subjects: subjectLang,
                privates: privatesLang,
                teams: teamsLang,
                leenkNoExists: leenkNoExistsLang,
                createTeam: createTeamLang,
                createSubject: createSubjectLang,
                interactCompany: interactCompanyLang,
            },
            form: { name: nameLang },
        },
    } = state;


    const updateLeenkName = async (leenkId, name) => {
        try {
            await updateLeenk(leenkId, { name })
            setCurrent(current => ({
                ...current,
                leenks: current.leenks.map(leenk => leenk.id === leenkId ? ({ ...leenk, name }) : leenk)
            }))
            dispatchAlert(dispatch, alerts, { type: 'success' })
        } catch (e) {
            dispatchAlert(dispatch, alerts, { type: 'error' })
        }
    }

    const getCurrent = (containers, privates) => {
        for (const parentType in containers) {
            if (containers.hasOwnProperty(parentType)) {
                const container = containers[parentType];
                for (let index = 0; index < container.length; index++) {
                    const { leenks = [], folderId, id: parentId, company, shared = false } = container[index];
                    for (let indexLeenk = 0; indexLeenk < leenks.length; indexLeenk++) {
                        const leenk = leenks[indexLeenk];
                        if (leenk.id == leenkId) {
                            return {
                                parentType,
                                parentId,
                                leenks,
                                leenk,
                                folderId,
                                company,
                                shared
                            };
                        }
                    }
                }
            }
        }
        const privateIndex = privates.findIndex(({ id }) => id === leenkId);
        if (privateIndex !== -1) {
            return {
                ...privates[privateIndex],
                leenk: { id: leenkId, name: leenkId, folder: privates[privateIndex].parent },
                leenks: privates,
                parentType: 'FOLDER',
                parentId: privates[privateIndex].parent,
                folderId: privates[privateIndex].parent
            };
        }
        throw new Error(leenkNoExistsLang);
    };
    const [companies, setCompanies] = useState([])
    const updateTeamName = async (teamId, name, { shared = false } = {}) => {
        try {
            await (shared ? updateSharedTeam : updateTeam)(teamId, { name })
            setCompanies(companies => companies.map(company => ({
                ...company,
                teams: company.teams.map(team => team.id === teamId ? ({ ...team, name }) : team)
            })))
            dispatchAlert(dispatch, alerts, { type: 'success' })
        } catch (e) {
            dispatchAlert(dispatch, alerts, { type: 'error' })
        }
    }
    const addLeenkToCompanies = (leenk, team, companies) =>
        companies
            .map(c =>
                ({
                    ...c,
                    teams: c.teams
                        .map(t => t.id === team ? { ...t, leenks: [t.leenks, leenk] } : t)
                })
            )

    const addTeamToCompanies = (team, company, companies) =>
        companies
            .map(c => c.company === company ? { ...c, teams: [...c.teams, team] } : c)

    const saveTeam = async (company, name) => {
        try {
            const res = await createTeam(name)
            setCompanies(addTeamToCompanies({ ...res, leenks: [] }, company, companies))
            setCurrent({
                company,
                parentType: 'TEAM',
                parentId: res.insert_id,
                leenks: [],
                leenk: {},
                folderId: res.folderId,
            });
        } catch (e) {
            console.log(e);
        }
    }


    const chargeDatas = async () => {
        try {
            const teams = await getTeams();
            const sharedTeamFromApi = await getSharedTeams()
            //add sharedTeams in companies, add label "shared" to front color for example
            const sharedTeams = sharedTeamFromApi.map(sharedTeam => ({ ...sharedTeam, shared: true }));
            setCompanies(createCompaniesFromTeams([...teams, ...sharedTeams]))
            const privates = (await getPrivateLeenks()).map((leenk) => {
                const name =
                    [
                        ...leenk.users.filter((user) => user.id !== currentId),
                        leenk.users.find((user) => user.id === currentId),
                    ].filter((e) => !!e)[0] || {};
                return { ...leenk, name: `${name.firstname} ${name.lastname}`, isPrivate: true };
            });
            if (leenkId) {
                const { parentType, parentId, leenks, leenk, folderId, company, shared } = getCurrent(
                    {
                        TEAM: [...teams, ...sharedTeams],
                    },
                    privates
                );
                setCurrent({
                    company,
                    parentType,
                    parentId,
                    leenks,
                    leenk,
                    folderId,
                    shared
                });
            } else {
                setCurrent(initialCurrent);
            }
            setPrivateConvs(privates);
        } catch (e) {
            console.log('ERROR', e);
            history.replace('/leenk');
        }
    };

    useEffect(() => {
        chargeDatas();
    }, [leenkId]);

    const navigateToFolder = () => {
        const { parentType, parentId } = current;
        const { folder } = current.leenk || {}
        return history.push(parentType === 'FOLDER' ? '/locker/folder/' + parentId : '/locker/folder/' + folder);
    };

    const navigateToTask = (taskId) => {
        return history.push('/task/' + taskId);
    };
    const changeLeenk = (leenk) => {
        return history.push('/leenk/' + leenk.id);
        // setCurrent(current => ({
        //   ...current,
        //   leenk: leenk.id
        // }));
    };
    const changeSpace = (obj = initialCurrent, parentType) => {
        setCurrent({
            company: obj.company,
            parentType,
            parentId: obj.id,
            leenks: obj.leenks,
            leenk: obj.leenks.length > 0 ? obj.leenks[0] : {},
            folderId: obj.folderId,
            shared: obj.shared
        });
        if (obj.leenks.length > 0) {
            changeLeenk(obj.leenks[0]);
        }
    };

    const selectPrivate = () => {
        const obj = privateConvs[0] || initialCurrent;
        if (obj.id) { changeLeenk(obj); }
        setCurrent({
            parentType: 'FOLDER',
            parentId: obj.parent,
            leenks: [...privateConvs],
            leenk: obj,
            folderId: obj.folderId || null, // incertitude ?
        });
        setSubjectsOpened();
    };

    const updateTypes = (leenk) => {
        switch (current.parentType) {
            case 'TEAM':
                setCompanies(addLeenkToCompanies(leenk, current.parentId, companies))
                break;
            case 'FOLDER':
                setPrivateConvs([...privateConvs, leenk]);
                break;
            default:
                setCompanies(addLeenkToCompanies(leenk, current.parentId, companies))
                break;
        }
    };

    const createSubject = async () => {
        try {
            const leenk = await createLeenk(
                subjectRef.current.value,
                current.parentId,
                current.parentType,
                current.folderId
            );
            leenk.name = leenk.id;
            leenk.parentId = leenk.parent;
            subjectRef.current.value = '';
            setCurrent((curr) => ({
                ...curr,
                leenks: [...curr.leenks, leenk],
                leenk,
                folderId: leenk.parent,
            })); //?to verify
            updateTypes(leenk);
            changeLeenk(leenk);
            closeCreateSubjectPopperPaper();
        } catch (e) {
            console.log(e.message);
        }
    };
    const moreLeenk = async (partnerId = null, message) => {
        try {
            let leenk;
            if (current.parentType === 'FOLDER') {
                leenk = await createPrivateLeenk([Number(subjectRef.current.value)]);
            } else {
                leenk = await createPrivateLeenk([partnerId]);
                //add message to new Conv
                await sendGuestMessageApi(leenk.id, message, [], partnerId);
            }
            leenk.name = leenk.id;
            leenk.parentId = leenk.parent;
            if (!partnerId) subjectRef.current.value = '';
            setCurrent((curr) => ({
                ...curr,
                leenks: [...curr.leenks, leenk],
                leenk,
                folderId: leenk.parent,
            })); //?to verify
            updateTypes(leenk);
            changeLeenk(leenk);
            closeCreateSubjectPopperPaper();
        } catch (e) {
            console.log(e.message);
        }
    };

    const isGuest = role === userRoles.GUEST

    return (
        <Grid container className="page-container border-radius-5 shadow bg-white">
            {inviteState && <SharedTeamInvitation
                open={inviteState}
                setOpen={setInviteState}
                leenkId={leenkId}
            />}
            <HorizontallyCollapsableColumn open={true} classes={{ root: classes.horizontallyCollapsableColumn }} title={spacesLang}>
                <CompanyList
                    companies={companies}
                    changeSpace={changeSpace}
                    saveTeam={saveTeam}
                    current={current}
                    createTeamLang={createTeamLang}
                    isGuest={isGuest}
                    updateTeamName={updateTeamName}
                />
                {!isGuest && <ExternalInteractionButton />}
                {/* <Button classes={{ root: classes.selectPrivatesButton }} variant="outlined" onClick={selectPrivate}>
                    {privatesLang}
                </Button> */}
            </HorizontallyCollapsableColumn>
            <HorizontallyCollapsableColumn
                classes={{ root: classes.horizontallyCollapsableColumn }}
                title={subjectLang}
                open={openSubjects}
                onChange={setSubjectsOpenState}
            >
                <SubjectList
                    classes={classes}
                    leenks={current.leenks}
                    leenk={current.leenk}
                    changeLeenk={changeLeenk}
                    updateLeenkName={updateLeenkName}
                />
                {current.parentType === 'FOLDER' ? (
                    <StartPrivateLeenkButton
                        setCurrent={setCurrent}
                        updateTypes={updateTypes}
                        changeLeenk={changeLeenk}
                        leenks={current.leenks || []}
                    />
                ) : (
                        !current.shared && !isGuest && <>
                            <PopperPaper
                                placement="left"
                                anchorEl={createSubjectListItemAnchorElement}
                                open={openCreateSubjectPopperPaper}
                                onClose={closeCreateSubjectPopperPaper}
                            >
                                <TextField
                                    variant={'outlined'}
                                    inputRef={subjectRef}
                                    placeholder={current.parentType === null ? 'userId' : nameLang}
                                />
                                <DialogActions classes={{ root: classes.createSubjectPopperPaperActions }}>
                                    <Button onClick={() => createSubject()}>{createSubjectLang}</Button>
                                </DialogActions>
                            </PopperPaper>
                            <List classes={{ root: classes.list }}>
                                <ListItem button onClick={handleCreateSubjectListItemClick}>
                                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                        <AddCircleIcon />
                                    </ListItemIcon>
                                    {createSubjectLang}
                                </ListItem>
                            </List>
                        </>
                    )}
            </HorizontallyCollapsableColumn>
            {!!(current.leenk || {}).id && (
                <div className={classes.messengerContainer}>
                    <Messenger
                        {...current}
                        changeLeenk={changeLeenk}
                        navigateToFolder={navigateToFolder}
                        navigateToTask={navigateToTask}
                        createLeenk={moreLeenk}
                        {...{ setCurrent, updateTypes, changeLeenk }}
                        parentType={current.shared ? 'SHARED_TEAM' : current.parentType}
                        seeSpeakers={current.parentType !== 'FOLDER'}
                    />
                </div>
            )}
        </Grid>
    );
};

export default Leenks;
