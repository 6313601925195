import React from 'react';
import { Button } from '@material-ui/core';
import useShareFileActions from './useShareFileActions';

const ShareFileButton = ({
	file,
	element: { type, title, ...element }
}) => {

	const { handleClick } = useShareFileActions({
		element: { type, title, ...element },
		file,
	})
	return (
		<Button
			variant="contained"
			color="primary"
			style={{ textTransform: 'none', margin: 5 }}
			onClick={handleClick}>{title}</Button>
	);
};

export default ShareFileButton;