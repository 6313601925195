export default ({ spacing }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: spacing(1.5),
    },
    userItem: {
        margin: spacing(1),
    },
    addOpenSpaceUser: {
        marginLeft: spacing(1),
    },
});
