export default ({ spacing }) => ({
    root: {
        padding: spacing(4),
        display: 'flex',
        flexWrap: 'wrap',
    },
    item: {
        maxWidth: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: spacing(1.75),
    },
    tooltipTitle: {
        textAlign: 'center',
        whiteSpace: 'pre-line',
    },
    label: {
        color: 'rgba(0, 0, 0, .87)',
        marginTop: spacing(1),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
    },
    icon: {
        height: 50,
        width: 'auto',
        color: 'rgba(0, 0, 0, .55)',
    },
    img:{ width: 140, height:140 },
    imgNormal:{ width: 80, height:80 },
    imgLittle:{ width: 50, height:50 },
    
});
