import React, { useContext, useEffect } from 'react';
import Inbox from "./Inbox";
import Connect from '../Connect'
import { Grid } from '@material-ui/core';
import { disconnectSmtp, getMails } from "./helpers";
import { Store } from '../../../store/store';
import { dispatchAlert } from '../../menu/helpers';

const Mail = () => {
  const { state: { smtp, mails, alerts, language: { mails: {
    disconnected: disconnectedLang
  }
  } }, dispatch } = useContext(Store)

  const disconnect = async () => {
    try {
      await disconnectSmtp()
      dispatch({ type: 'SMTP_DISCONNECT' })
      dispatchAlert(dispatch, alerts, { type: 'success', message: disconnectedLang })
    } catch (e) {
      dispatchAlert(dispatch, alerts, { type: 'error' })
    }
  }
  useEffect(() => {
    if (smtp) {
      getMails()
        .then(({mails, numbers}) =>
          {
            dispatch({ type: 'CHARGE_MAILS', payload: mails })
            dispatch({ type: 'MAILS_DISTANT_LENGHT', payload:numbers })
        }
        )
    }
  }, [smtp])

  return smtp
    ? <Grid container className="page-container border-radius-5 shadow bg-white">
      <Inbox disconnect={disconnect} mails={mails} />
    </Grid> : <Connect setIsConnect={() => dispatch({ type: 'SMTP_CONNECT' })} />
};

export default Mail;
