import React, { useContext, useState, useCallback, useRef } from 'react';
import { IconButton, Tooltip, TextField, DialogActions, Button, makeStyles } from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import { Store } from '../../../store/store';
import PopperPaper from '../../../components/PopperPaper';
import styles from './Leenks.styles';

const useStyles = makeStyles(styles);
const ItemListUpdateButton = ({onClick, name}) => {
	const classes = useStyles();
	const { state: { language: { leenks: { updateName: updateNameLang } } } } = useContext(Store)
	const subjectRef = useRef(null);

	const [elem, setElem] = useState();
	const isOpen = Boolean(elem);
	const close = useCallback(() => setElem(null), []);

	const open = useCallback((event) => {
		setElem(event.currentTarget);
	}, []);

	const updateName = () => {
		onClick(subjectRef.current.value)
		close()
	}
	return (
		<div>
			<Tooltip title={updateNameLang}>
				<IconButton onClick={open}>
					<EditIcon />
				</IconButton>
			</Tooltip>
			<PopperPaper
				placement="left"
				anchorEl={elem}
				open={isOpen}
				onClose={close}
			>
				<TextField
					variant={'outlined'}
					inputRef={subjectRef}
					defaultValue={name}
				/>
				<DialogActions classes={{ root: classes.createSubjectPopperPaperActions }}>
					<Button onClick={updateName}>{updateNameLang}</Button>
				</DialogActions>
			</PopperPaper>
		</div>
	);
};

export default ItemListUpdateButton;