import React, { useState, useContext } from 'react'
import { sendNewPassword } from './helpers'
import { saveTokens } from '../../helpers'
import { Store } from '../../../store/store'
import UnLogGrid from '../../../components/UnLogGrid'
import ModalComponent from '../../../components/Modal'

const ResetPassword = ({ match, history }) => {
  const { token } = match.params
  const [email, setEmail] = useState('')
  const { dispatch, state } = useContext(Store)

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)

  const [modal, setModal] = useState(false)
  const [error, setError] = useState('')
  const {
    language:{
      login:{
        leftSide: {
          mail: mailLang,
          newPassword: newPasswordLang,
          newPasswordTitle:newPasswordTitleLang,
          confirmErrorTitle: confirmErrorTitleLang,
          confirmErrorButton: confirmErrorButtonLang,
        },
      },
    },
  } = state
  const inputs = [{
    type:           'text',
    name:           'email',
    label:          mailLang,
    value:          email,
    required:       true,
    silentRequired: true,
    fluid:          true,
  },
  {
    type:           'password',
    name:           'password',
    label:          newPasswordLang,
    required:       true,
    silentRequired: true,
    fluid:          true,
    legend:         'minimum 5 chars',
    signIn:         true,
  },
  ]
  const sendPassword = async values => {
    try {
      const jwt =  await sendNewPassword(token, values.email, values.password)

      saveTokens(jwt, dispatch)
      history.push('/home')
    } catch (e) {
      setEmail(email)
      setError(e.message)
      setModal(true)
    }
  }

  return (
    <React.Fragment>
      <UnLogGrid
        inputs={inputs}
        sendForm={sendPassword}
        page="reset"
        message={newPasswordTitleLang}
        loader={loading}
      />
      <ModalComponent
        onClose={() => history.push('/login')}
        open={modal}
        title={confirmErrorTitleLang}
        description={error}
        onClick={() => history.push('/login')}
        button={confirmErrorButtonLang}
      />
    </React.Fragment>
  )
}

export default ResetPassword
