export const FR = {
    move: 'Déplacer',
    share: 'Partager',
    goBack: 'Retourner en arrière',
    moveHere: 'Déplacer ici',
    add: 'Ajouter',
    rename: 'Renommer',
    remove: 'Supprimer',
    cancel: 'Annuler',
    file: {
        tooBig: 'Fichier trop volumineux.',
        uploadFile: 'Envoyer un fichier',
        uploading: "En cours d'envoi...",
        createDocument: 'Créer un nouveau document',
        newDocumentName: 'Nom du document',
    },
    name: 'Nom',
    creationDate: 'Date de création',
    updatedDate: 'Date de modification',
    type: 'Type',
    size: 'Taille'

};

export const EN = {
    move: 'Move',
    share: 'Share',
    goBack: 'Go back',
    moveHere: 'Move here',
    add: 'Add',
    rename: 'Rename',
    remove: 'Remove',
    cancel: 'Cancel',
    file: {
        tooBig: 'File too big.',
        uploadFile: 'Upload file',
        uploading: 'Uploading...',
        createDocument: 'Create a new document',
        newDocumentName: "Document's name",
    },
    name: 'Name',
    creationDate: 'Creation time',
    updatedDate: 'Update time',
    type: 'Type',
    size: 'Size'
};

export default {
    fr: FR,
    us: EN,
};
