import React, { useState, useCallback } from 'react';
import { useCallbackOpen } from '../hooks/useCallbackOpen';

const useFileUpload = () => {
	const [isUploading, setIsUploading, setIsNotUploading] = useCallbackOpen(false);
	const [newDocumentPopperAnchorElement, setNewDocumentPopperAnchorElement] = useState();
	const openNewDocumentPopper = Boolean(newDocumentPopperAnchorElement);
	const handleNewDocumentButtonClick = useCallback(
		(event) => setNewDocumentPopperAnchorElement(event.currentTarget),
		[]
	);
	const closeNewDocumentPopper = useCallback(() => setNewDocumentPopperAnchorElement(null), []);
	return {
		isUploading,
		setIsUploading,
		setIsNotUploading,
		openNewDocumentPopper,
		handleNewDocumentButtonClick,
		closeNewDocumentPopper,
		newDocumentPopperAnchorElement
	}

};

export default useFileUpload;