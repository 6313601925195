import React from 'react';
import FileButton from '../containers/locker/Locker/Files/FileButton';
import useFileActions from '../containers/locker/Locker/useFileActions';

const FormattedMessage = ({ message, fileId, type, file }) => {
	const  {
        handleClick
	} = useFileActions()
	
	return <div >
		{fileId && <FileButton handleClick={handleClick} file={file} type={type} noName />}
		<div className="ta-left">{(message || '').match(/[@#+&]\[[a-zA-Z]*]\([1-9]*\)/gm)
			? message.replaceAll(/[@#]\[/g, '').replaceAll(/]\([1-9]*\)/g, '')
			: message}</div>
	</div>

};

export default FormattedMessage;