import React from 'react'
import Notification from 'react-web-notification'
import { getUser } from '../containers/helpers'
const NotificationBrowser = ({
  title = 'React-Web-Notification' + Date.now(),
  body = 'Hello' + new Date(),
  tag = Date.now(),
  onClick,
  onClose= () =>{},
  setIgnore,
  ignore,
  inProgress = false
}) => {
  const user = getUser()
  const parseLanguage = (lang) => {
    switch (lang) {
      case 'fr':
        return 'fr'
      case 'us':
      case 'en':
        return 'en'
      default:
        return 'fr'
    }
  }
  const lang = parseLanguage(user.language || 'fr')

  //faire une popup pour ça et redemmander ...
  // const [askAgain, setAskAgain] = useState(false)

  // const now = Date.now();

  // const title = 'React-Web-Notification' + now;
  // const body = 'Hello' + new Date();
  // const tag = now;
  // const icon = 'http://mobilusoss.github.io/react-web-notification/example/Notifications_button_24.png';
  // const icon = 'http://localhost:3000/Notifications_button_24.png';

  // Available options
  // See https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
  const options = {
    tag: tag,
    body: body,
    icon: 'https://www.essec.edu/static/desktop/img/icons/icon-bell.png',
    lang,
    dir: 'ltr',
    // sound: './sound.mp3'  // no browsers supported https://developer.mozilla.org/en/docs/Web/API/notification/sound#Browser_compatibility
  }
  // this.setState({
  //   title: title,
  //   options: options
  // });

  const timeout = 5000

  // useEffect(() => {
  //   setIgnore(false)
  //   setAskAgain(false)
  // }, [
  //   title,
  //   body,
  //   tag,
  //   icon,
  // ])
  const handleNotSupported = () => {
    setIgnore(true)
  }
  const handlePermissionGranted = () => {
    // console.log('handlePermissionGranted')
    // setIgnore(false)
  }
  const handlePermissionDenied = () => {
    // setAskAgain(true)
    setIgnore(true)
  }
  const handleNotificationOnShow = () => {
    // console.log('handleNotificationOnShow')
  }
  const handleNotificationOnClick = () => {
    // console.log('handleNotificationOnClick')
    onClick()
  }
  const handleNotificationOnClose = () => {
    onClose()
    // console.log('handleNotificationOnClose')
  }
  const handleNotificationOnError = () => {
    setIgnore(true)
  }
  return inProgress ? null : <div>
    <Notification
      ignore={ignore}
      notSupported={handleNotSupported}
      onPermissionGranted={handlePermissionGranted}
      onPermissionDenied={handlePermissionDenied}
      onShow={handleNotificationOnShow}
      onClick={handleNotificationOnClick}
      onClose={handleNotificationOnClose}
      onError={handleNotificationOnError}
      timeout={timeout}
      title={title}
      options={options}
      // askAgain={askAgain}
    // swRegistration={this.props.swRegistration}
    />
    {/* <audio id='sound' preload='auto'>
          <source src='./sound.mp3' type='audio/mpeg' />
          <source src='./sound.ogg' type='audio/ogg' />
          <embed hidden={true} autostart='false' loop={false} src='./sound.mp3' />
        </audio> */}
  </div>

}

export default NotificationBrowser