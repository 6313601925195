import React, { useContext, useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Grid, Typography, Modal } from "@material-ui/core";
import { DataGrid } from '@material-ui/data-grid'
import useStyles from './style'
import { Store } from "../../../store/store";
import SearchBar from '../../../components/SearchBar';
import ModalRights from '../../../components/ModalRights';

export const CustomizedDataGrid = ({ title, rows: rowsActual = [], setRows: setRowsActual = () => { }, columns = [],
  type, userId, titleLang }) => {
  const classes = useStyles()
  const [rows, setRows] = useState(rowsActual)
  const { state: { language: { access: accessLanguage } } } = useContext(Store)
  const rightsEnum = {
    [accessLanguage[0]]: 0,
    [accessLanguage[1]]: 1,
    [accessLanguage[2]]: 2,
  }

  useEffect(() => {
    setRows(rowsActual)
  }, [rowsActual])

  const [modal, setModal] = useState(false)
  const [element, setElement] = useState({})
  const updateRows = (rights) => {
    setRowsActual(rows.map(row => row.id === element.id ? ({ ...row, rights }) : ({ ...row, rights: rightsEnum[row.rights] })))
  }
  const onRowClick = ({ row: {
    folder: folderId,
    id,
    parent,
    leenk_id,
    rights,
    parent_type: parentType
  } }) => {
    setElement({
      id,
      type,
      title,
      userId,
      folderId,
      parent,
      leenk_id,
      parentType,
      rights: !!!rights ? -1 : rightsEnum[rights]
    })
    setModal(true)
  }
  return (
    <Grid container item xs direction="column">
      {titleLang ? (
        <Typography variant="h4" classes={{ root: classes.title, h4: classes.h4 }}>
          {titleLang}
        </Typography>
      ) : null}
      <SearchBar list={rowsActual} setList={setRows} />
      <DataGrid
        onRowClick={onRowClick}
        rows={rows}
        columns={columns}
        pageSize={10}
        autoHeight
        density="compact"
        classes={{ root: classes.dataGrid }}
      />
      <ModalRights modal={modal} setModal={setModal} onUpdate={updateRows} {...element} />
    </Grid>
  )
}

export const Section = ({ children, title }) => {
  const classes = useStyles()

  return (
    <Accordion elevation={0} square classes={{ root: classes.accordion }} expanded>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography variant="h3" classes={{ root: classes.title, h3: classes.h3 }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          {children}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
