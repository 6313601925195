import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                padding: '8px 12px',
                fontSize: 14,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: '#efefef',
            },
        },
        MuiButton:{
            outlined:{
              backgroundColor: 'rgb(241, 242, 247)',
            }
          }
    },

});

export default theme;
