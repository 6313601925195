export const FR = {
	contacts: 'Contacts',
	folders: 'Dossiers'
}

export const EN = {
	contacts: 'Contacts',
	folders: 'Folders'
}

export default {
	fr: FR,
	us: EN,
}
