import React, { useState, useRef, useContext, useCallback } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { Button, DialogActions, ListItemIcon, TextField } from '@material-ui/core';
import { Store } from '../store/store';
import PopperPaper from './PopperPaper';
import ItemListUpdateButton from '../containers/leenks/Leenks/ItemListUpdateButton';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 200,
        color: 'rgba(0, 0, 0, .87)',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    popperPaperActions: {
        padding: 0,
        marginTop: theme.spacing(2),
    },
    listItemIcon: {
        minWidth: 34,
    },
}));

const NestedList = ({ title, list, action, current, moreButton, moreButtonLabel = '+', settingButton = false }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(!!current);
    const [moreListItemAnchorElement, setMoreListItemAnchorElement] = useState();

    useEffect(() => {
        setOpen(!!current);
    }, [current]);
    const inputRef = useRef();

    const closePopperPaper = useCallback(() => setMoreListItemAnchorElement(null), []);

    const addNew = useCallback(() => {
        if (Boolean(inputRef.current.value)) {
            closePopperPaper();
            return moreButton(inputRef.current.value);
        }
    }, [inputRef.current, moreButton]);

    const openPopperPaper = Boolean(moreListItemAnchorElement);

    const handleMoreListItemClick = useCallback(
        (event) => {
            if (openPopperPaper) {
                setMoreListItemAnchorElement(null);
            }
            setMoreListItemAnchorElement(event.currentTarget);
        },
        [openPopperPaper]
    );

    const {
        state: {
            language: {
                form: { name: nameLang },
            },
        },
    } = useContext(Store);

    return (
        <List className={classes.root}>
            <ListItem
                button
                onClick={() => {
                    setOpen((value) => !value);
                    action(list[0])
                }}
                classes={{ root: classes.root, selected: classes.selected }}
            >
                <ListItemText primary={title} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {list.map((obj, key) => (
                    <div className="flex" {...{ key }}>
                    <ListItem
                        button
                        selected={obj.id === current}
                        onClick={() => action(obj)}
                        classes={{ root: classes.nested, selected: classes.selectedItem }}
                    >
                        <ListItemText primary={obj.name} />
                    </ListItem>
                    {!!settingButton && <ItemListUpdateButton name={obj.name} onClick={(arg) => settingButton(obj.id, arg, obj)} />}
                    </div>
                ))}
                {Boolean(moreButton) && (
                    <>
                        <PopperPaper
                            anchorEl={moreListItemAnchorElement}
                            open={openPopperPaper}
                            onClose={closePopperPaper}
                            placement="right"
                        >
                            <TextField variant={'outlined'} inputRef={inputRef} placeholder={nameLang} />
                            <DialogActions classes={{ root: classes.popperPaperActions }}>
                                <Button onClick={addNew}>{moreButtonLabel}</Button>
                            </DialogActions>
                        </PopperPaper>
                        <ListItem button onClick={handleMoreListItemClick} classes={{ root: classes.nested }}>
                            <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                <AddCircleIcon />
                            </ListItemIcon>
                            <ListItemText primary={moreButtonLabel} />
                        </ListItem>
                    </>
                )}
            </Collapse>
        </List>
    );
};

export default NestedList;
