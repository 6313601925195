import React, { useContext } from 'react';
import { Modal, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { Store } from '../../../store/store';
import { useHistory } from 'react-router-dom';
import { createTask } from '../../task/helpers';
const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	section: {
		height: 'calc(100% / 3)',
		width: '100%',
		padding: theme.spacing(1),
	},
	userTitle: {
		color: '#45535C',
		textAlign: 'left',
		padding: theme.spacing(2),
	},
	title: {
		color: '#45535C',
		textAlign: 'left',
	},
	h3: {
		fontSize: theme.typography.pxToRem(18),
	},
	h4: {
		fontSize: theme.typography.pxToRem(16),
		marginBottom: theme.spacing(1),
	},
	sectionContainer: {
		height: '100%',
	},
	dataGrid: {
		color: '#45535C',
		height: 300
	},
	accordion: {
		borderBottom: '1px solid #F4F5F5',
		'&.Mui-expanded': {
			margin: 0
		}
	},
	listItem: {
		fontSize: theme.typography.pxToRem(16),
		color: '#45535C'
	},
	fab: {
		color: '#fff',
		backgroundColor: '#1773C8',
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2)
	},
	selected: {
		backgroundColor: '#E2F0F7'
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		position: 'absolute',
		// width: 400,
		backgroundColor: theme.palette.background.paper,
		borderRadius: '10px',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
}))

const ModalCalendarCreateEvent = ({
	dateCreation: {
		beginDate,
		endDate
} = {},
setDateCreation
}) => {
	const { state: { language: { calendar: {
		chooseAction: chooseActionLang,
		createMeeting: createMeetingLang,
		createTask: createTaskLang,
	} } } } = useContext(Store)
	const classes = useStyles()
	const history = useHistory()
	const dateToMls = date =>  (new Date(date)).getTime()
	const navigateToNewMeeting = () => history.push(
		`/spots/meeting?beginDate=${dateToMls(beginDate)}&endDate=${dateToMls(endDate)}`
		)
	const navigateToNewTask = () => createTask(null, beginDate, endDate)
	.then(taskId => history.push(
		'/task/'+taskId
		))
	
	return (
		<Modal
			open={!!beginDate}
			onClick={e => e.stopPropagation()}
			onClose={() => setDateCreation({})}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			className={classes.modal}
		>
			<div className={classes.paper}>
					<h2 id="simple-modal-title">{chooseActionLang}</h2>
					<div className="flex">
					<Button onClick={navigateToNewMeeting}>{createMeetingLang}</Button>
					<Button onClick={navigateToNewTask}>{createTaskLang}</Button>
					</div>
			</div>
		</Modal>
	);
};

export default ModalCalendarCreateEvent;