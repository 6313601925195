/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useContext } from 'react'
import Profile from './Profile'
import { Store } from '../../../store/store'
import { getUser,
} from '../../helpers'
import { updateUserInfos, loginAfterUpdate } from './helpers'
import ModalComponent from '../../../components/Modal'

export default ({
  match,
  history,
}) => {
  const { state } = useContext(Store)
  const {token} = match.params
  const {
    language: {
      profile:{
        firstname: firstnameLang,
        lastname: lastnameLang,
        password:passwordLang,
        gender:genderLang,
        male:maleLang,
        female:femaleLang,
        titleModalError: titleModalErrorLang,
        buttonModalError: buttonModalErrorLang,
        errorModal: errorModalLang,
        cancelTitle: cancelTitleLang,
        updateProfileButton: updateProfileButtonLang,
      },
      login: {
        leftSide:{
          userAgreementPlaceholder:userAgreementPlaceholderLang,
          termsAndConditionsAgreement:termsAndConditionsAgreementLang,
          termsAndConditions:termsAndConditionsLang,
        }
      }
    },
  } = state || {}

  const getUserAgreement = () => <label>
    {termsAndConditionsAgreementLang}
    {/* eslint-disable-next-line react/jsx-no-target-blank */}
    <a href='https://www.klack.fr/confidentialite/' target='_blank'>
      {termsAndConditionsLang}
    </a>
  </label>
  const user = getUser()
  const inputs = [
    {
      type: 'select',
      name: 'gender',
      required: true,
      silentRequired: true,
      options: [
        {key: 'male', value: 'male', text: maleLang },
       {key: 'female', value: 'female', text: femaleLang }
      ],
      placeholder: genderLang,
    },
    {
      type:           'text',
      name:           'firstname',
      placeholder:    firstnameLang,
      required:       true,
      silentRequired: true,
    },
    {
      type:           'text',
      name:           'lastname',
      placeholder:    lastnameLang,
      required:       true,
      silentRequired: true,
    },
    {
      type:           'password',
      name:           'password',
      placeholder:    passwordLang,
      required:       true,
      silentRequired: true,
      legend:         'minimum 5 chars',
      signIn:         true,
    },
    {
      type:        'checkbox',
      name:        'userAgreement',
      label:       getUserAgreement(),
      placeholder: userAgreementPlaceholderLang,
      labelRight:  true,
      required:    true,
    },

  ]

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [modal, setModal] = useState(false)

  const sendForm = async ({
    gender,
    password,
    firstname,
    lastname,
  }) => {
    setLoading(true)
    try {
      if (firstname === user.firstname && lastname === user.lastname) {
        throw new Error(errorModalLang)
      }
      await updateUserInfos({firstname, lastname, gender, password}, token)
      await loginAfterUpdate(token, password)
      const path = JSON.parse(localStorage.getItem('path'))
      if (path) localStorage.removeItem('path')
      path ? history.push(path) :
        history.push('/home')
    } catch (error) {
      console.log('error :>> ', error);
      setError(error.message)
      setModal(true)
      setLoading(false)
    }
  }

  const closeModal = () => setModal(false)
  const push = url => history.push(url)
  return (
    <React.Fragment>
      <Profile
        loading={loading}
        updateProfileButtonLang={updateProfileButtonLang}
        cancelTitle={cancelTitleLang}
        language={state.language.profile}
        inputs={inputs}
        user={user}
        push={push}
        sendForm={sendForm}
      />
      <ModalComponent
        onClose={closeModal}
        open={modal}
        title={titleModalErrorLang}
        description={error}
        onClick={closeModal}
        button={buttonModalErrorLang}
      />
    </React.Fragment>
  )
}
