import { fetchApi } from '../../helpers'

export const createAdmin = ({
   password, firstname, lastname, gender
}, token) =>
  fetchApi(`/user/init/${token}`, {
    method: "PATCH",
    body: {
      password,
      firstname,
      lastname,
      gender,
    },
  })

export default {
  createAdmin,
}
