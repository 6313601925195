import React from 'react';
import SpotRooms from "./SpotRooms";
import { Grid } from '@material-ui/core';

const Spots = () => (
  <Grid container className="page-container border-radius-5 shadow bg-white">
    <SpotRooms />
  </Grid>
);

export default Spots;
