/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState } from 'react'
import { createAdmin } from './helpers'
import UnLogGrid from '../../../components/UnLogGrid'
import { Store } from '../../../store/store'
import ModalComponent from '../../../components/Modal'

export default ({ history, match }) => {
  const { state } = useContext(Store)
  const [modal, setModal] = useState(false)
  const [error, setError] = useState('')
  const [googleSignUp, setGoogleSignUp] = useState(false)
  const {token} = match.params
  const {
    leftSide: {
      firstname: firstNameLang,
      lastname: lastNameLang,
      password: passwordLang,
      gender:genderLang,
      male:maleLang,
      female:femaleLang,
      validUp:validUpLang,
      signupModalTitle: signupModalTitleLang,
      signupModalTextSuccess: signupModalTextSuccessLang,
      signupModalButtonSuccess: signupModalButtonSuccessLang,
      signupModalButtonError: signupModalButtonErrorLang,
      googleAccountText:googleAccountTextLang,
      errorTitle: errorTitleLang,
      userAgreementPlaceholder:userAgreementPlaceholderLang,
      termsAndConditionsAgreement:termsAndConditionsAgreementLang,
      termsAndConditions:termsAndConditionsLang,
    },
  } = state.language.login

  const getUserAgreement = () => <label>
    {termsAndConditionsAgreementLang}
    <a href='https://www.klack.fr/confidentialite/' target='_blank'>
      {termsAndConditionsLang}
    </a>
  </label>
  const inputs = [
    {
      type: 'select',
      name: 'gender',
      required: true,
      fluid: true,
      silentRequired: true,
      options: [
        {key: 'male', value: 'male', text: maleLang },
       {key: 'female', value: 'female', text: femaleLang }
      ],
      placeholder: genderLang,
    },
    {
      type:           'text',
      name:           'firstname',
      placeholder:    firstNameLang,
      required:       true,
      fluid:          true,
      silentRequired: true,
    },
    {
      type:           'text',
      name:           'lastname',
      placeholder:    lastNameLang,
      required:       true,
      fluid:          true,
      silentRequired: true,
    },
    {
      type:           'password',
      name:           'password',
      placeholder:    passwordLang,
      required:       true,
      silentRequired: true,
      fluid:          true,
      legend:         'minimum 5 chars',
      signIn:         true,
    },
    {
      type:        'checkbox',
      name:        'userAgreement',
      label:       getUserAgreement(),
      placeholder: userAgreementPlaceholderLang,
      labelRight:  true,
      required:    true,
    },
  ] 

  const sendForm = async values => {
    try {
      const { signInType } = await createAdmin(values, token)
      if (signInType && signInType === 'google') setGoogleSignUp(true)
      setModal(true)
    } catch (e) {
      setError(e.message)
      setModal(true)
    }
  }

  const goToLogin = () => history.push('/login')
  const closeErrorModal = () => {
    setError('')
    setModal(false)
  }
  return (
    <React.Fragment>
      <UnLogGrid
        inputs={inputs}
        sendForm={sendForm}
        navigateHandle={goToLogin}
        page="signup"
        submitMessage={validUpLang}
      />
      <ModalComponent
        onClose={error !== '' ? closeErrorModal : goToLogin}
        open={modal}
        title={error !== '' ? errorTitleLang :  signupModalTitleLang}
        description={error !== '' ? error : googleSignUp ? googleAccountTextLang : signupModalTextSuccessLang }
        onClick={error !== '' ? closeErrorModal : goToLogin}
        button={error !== '' ? signupModalButtonErrorLang : signupModalButtonSuccessLang}

      />
    </React.Fragment>
  )
}
