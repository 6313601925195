import React from 'react';
import { useSpotHooks } from '../../spots/Spots/useSpotsHooks';
import { IconButton, Tooltip } from '@material-ui/core';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import ChatIcon from '@mui/icons-material/Chat';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';

const UserActionButton = ({
	onClick,
	text,
	children,
	handleClickAway
}) => {
	const onClickWithEffect = () => {
		handleClickAway()
		onClick()
	}

	return <Tooltip title={text}>
	<IconButton onClick={onClickWithEffect} >
		{children}
	</IconButton>
	</Tooltip>
}

const UserSearchActions = ({ user, handleClickAway }) => {
	const { language: {
		directMessage: directMessageLang,
		startMeeting: startMeetingLang,
		startDocx: startDocxLang,
	},
		handleDirectMessage,
		handleStartMeeting,
		handleStartDocx,

	} = useSpotHooks({ user })

	return (
		<div className="flex">
			<UserActionButton onClick={handleDirectMessage} text={directMessageLang} handleClickAway={handleClickAway} >
				<ChatIcon />
			</UserActionButton>
			<UserActionButton onClick={handleStartMeeting} text={startMeetingLang} handleClickAway={handleClickAway} >
				<VoiceChatIcon />
			</UserActionButton>
			<UserActionButton onClick={handleStartDocx} text={startDocxLang} handleClickAway={handleClickAway} >
				<FileOpenIcon />
			</UserActionButton>
		</div>
	);
};

export default UserSearchActions;