export const FR = {
  transferMail: 'Transférer mail en leenk',
  transferContact: 'Transférer correspondant en leenk',
  newMail: 'Nouveau mail',
  inbox: 'Boite de reception',
  from: 'De',
  to: 'À',
  disconnect: 'Se déconnecter',
  disconnected: 'Déconnecté',
  transformToPrivateLeenk: 'Transformer en leenk privé'
}

export const EN = {
  transferMail: 'Forward mail as leenk',
  transferContact: 'Forward contact as leenk',
  newMail: 'New mail',
  inbox: 'Inbox',
  from: 'From',
  to: 'To',
  disconnect: 'Disconnect',
  disconnected: 'Disconnected',
  transformToPrivateLeenk: 'Transform to private leenk'

}

export default {
  fr: FR,
  us: EN,
}
