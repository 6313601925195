import React, { useState, useEffect } from 'react';
import { getSharedTeams, getTeams } from '../../../leenks/Leenks/helpers';
import { getUsersFromAllCompanies } from '../../../setting/Setting/helpers';
import { upperFirst } from 'lodash';
import SearchBar from '../../../../components/SearchBar'
import ShareFileButton from './ShareFileButton';
const types = {
	USER: 0,
	TEAM: 1,
	SHARED_TEAM: 2
}

const ShareFolderSearchBar = ({ file, folderId }) => {
	const [elementsFromAPI, setElementsFromAPI] = useState([])
	const [elements, setElements] = useState([])

	const getLeenksFromTeams = (teams, shared = false) =>
		teams
			.map(({ leenks, companyName, name: teamName = null }) => (leenks || [])
				.map(({ name, ...leenk }) => ({
					name,
					shared,
					title: `${upperFirst(name)}${teamName ? ` [${upperFirst(teamName)}]` : ''} (${upperFirst(companyName)})`,
					valueToCompare: `${upperFirst(name)}${teamName ? ` [${upperFirst(teamName)}]` : ''} (${upperFirst(companyName)})`,
					...leenk,
					type: types[shared ? 'SHARED_TEAM' : 'TEAM']
				}))).flat()

	const formatUsers = users => users.map(({
		firstname, lastname, companyName, ...user
	}) => ({
		title: `${upperFirst(firstname)} ${upperFirst(lastname)} ${companyName ? `(${upperFirst(companyName)})` : ''}`,
		valueToCompare: `${upperFirst(firstname)} ${upperFirst(lastname)} ${companyName ? `(${upperFirst(companyName)})` : ''}`,
		...user,
		type: types.USER
	}))

	const formatElementsFromApi = ([sharedTeams, teams, users]) => [
		getLeenksFromTeams(sharedTeams, true),
		getLeenksFromTeams(teams),
		formatUsers(users),
	].flat()


	const chargeElements = () => Promise.all(
		[getSharedTeams(), getTeams(), getUsersFromAllCompanies()]
	).then(formatElementsFromApi)
		.then(res => {
			setElements(res)
			setElementsFromAPI(res)
		})


	useEffect(() => {
		chargeElements()
	}, [])



	return (
		<div style={{ maxHeight: 280, padding: 8 }}>
			<SearchBar list={elementsFromAPI} setList={setElements} />
			<div>
				{elements.map((element, key) =>
					<ShareFileButton
						key={key}
						file={file}
						element={element}
					/>
				)}
			</div>
		</div>
	);
};

export default ShareFolderSearchBar;