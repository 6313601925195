import React, { useEffect, useState, useContext } from 'react';
// import DataGrid from 'react-data-grid';
import { Store } from '../store/store';
import { Header } from 'semantic-ui-react';
const XlsxViewer = ({ file }) => {
	const {state:{language: {general:{
		impossibleToDisplay:impossibleToDisplayLang
	}}}} = useContext(Store)
	// const [arr, setArr] = useState(null)
	// const [xlsx, setXlsx] = useState()
	// const getXlsxFromFile = async () => {
	// 	return XLSX.read(await file.blob.arrayBuffer(), { type: "array" });
	// }
	// useEffect(() => {
	// 	getXlsxFromFile()
	// 		.then(setXlsx)
	// }, [file])

	// const getArrFromXlsx = () => {

	// }

	// useEffect(() => {
	// 	setArr(getArrFromXlsx())
	// }, [xlsx])

	// console.log('xlsx :>> ', xlsx);

	return (
		/*Boolean(arr) &&*/
		<Header as={'h5'}>{impossibleToDisplayLang}</Header>
		// <Spreadsheet data={columns} />
	);
};

export default XlsxViewer;