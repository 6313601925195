import { fetchApi } from "../../helpers";

//+socket back
export const updateDocx = (docxId, docx) => fetchApi(`/docx/update`, {
	method: 'PUT',
	body: {
		docx,
		docxId
	}
})

//+socket back
export const saveFile = (docxId, docx) => fetchApi(`/docx/save`, {
	method: 'PUT',
	body: {
		docx,
		docxId
	}
})

export const getDocx = docxId => fetchApi(`/docx/${docxId}`).then(data => ({ ...data, actual: data.actual ? data.actual : data.actual }));
