import React, { useEffect } from 'react'
import { Modal, Button } from 'semantic-ui-react'

const ModalComponent = ({
  onClose = () => { },
  onClick = () => { },
  open = false,
  title = null,
  description = null,
  button = null,
  button2 = null,
  onClick2 = () => {},
}) => {
  const downHandler = ({ key }) => {
    if (open) {
      switch (key) {
      case 'Enter':
        return onClick()
      default:
      }
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [])

  return (
    <Modal
      onClose={onClose}
      open={open}
      closeIcon
      className={`modal-confirmation-container ${button2 ? '' : 'single-button'}`}
      size="tiny"
      centered
    >
      {title ? <Modal.Header>{title}</Modal.Header>:null}
      <Modal.Content>
        {description ? <Modal.Description>
          {description}
        </Modal.Description>:null}
      </Modal.Content>
      {button2 ? <div className='flex'>
        {button ? <Button onClick={onClick}>{button}</Button>:null}
        {button2 ? <Button onClick={onClick2}>{button2}</Button>:null}
      </div> :
      button ? <Button onClick={onClick}>{button}</Button>:null
      }
    </Modal>
  )
}

export default ModalComponent
