import React, { useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, CircularProgress, TextField, DialogActions } from '@material-ui/core';
import { Store } from '../store/store';
import { createDocx, sendFile } from '../containers/locker/Locker/helpers';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr';
import { CloudUpload as CloudUploadIcon, NoteAdd as NoteAddIcon } from '@material-ui/icons';
import { dispatchAlert } from '../containers/menu/helpers';

import PopperPaper from './PopperPaper';

import cn from 'classnames';
import useFileUpload from './useFileUpload';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 'auto',
        padding: theme.spacing(1.5),
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderTop: '1px solid #efefef',
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    icon: {
        color: 'rgba(0, 0, 0, .6)',
        marginRight: theme.spacing(1),
    },
    progress: {
        marginRight: theme.spacing(2),
    },
    popperPaper: {
        width: 300,
    },
    popperPaperActions: {
        margin: theme.spacing(2, 0, 0),
    },
}));

const LockerFolderActions = ({ list, setFiles, folderId }) => {
    const classes = useStyles();
    const {
		isUploading,
setIsUploading,
setIsNotUploading,
openNewDocumentPopper,
handleNewDocumentButtonClick,
closeNewDocumentPopper,
newDocumentPopperAnchorElement
	} = useFileUpload()

    const docxRef = useRef(null);

    const history = useHistory();
    const {
        dispatch,
        state: {
            alerts,
            languageValue,
            language: {
                folder: {
                    file: {
                        tooBig: tooBigLang,
                        uploadFile: uploadFileLang,
                        uploading: uploadingLang,
                        createDocument: createDocumentLang,
                        newDocumentName: newDocumentNameLang,
                    },
                },
            },
        },
    } = useContext(Store);

    moment.locale(languageValue === 'fr' ? 'fr' : 'en');

    const handleFileChange = async (event) => {
        try {
            if (event.target.files[0].size > 31457280) return alert(tooBigLang);
            const fd = new FormData();
            fd.append('vocal', event.target.files[0]);
            setIsUploading();
            const file = await sendFile(fd, folderId);
            setFiles([...list, {folderId, ...file}]);
            setIsNotUploading();
            dispatchAlert(dispatch, alerts, { type: 'success' });
        } catch (e) {
            dispatchAlert(dispatch, alerts, { type: 'error', message: e.message });
        }
    };

    const createDocument = () => {
        createDocx(folderId, docxRef.current.value).then((docxId) => history.push(`/docx/${docxId}`));
    };

    return (
        <>
            <PopperPaper
                classes={{ paper: classes.popperPaper }}
                anchorEl={newDocumentPopperAnchorElement}
                open={openNewDocumentPopper}
                onClose={closeNewDocumentPopper}
            >
                <TextField
                    fullWidth
                    placeholder={newDocumentNameLang}
                    inputRef={docxRef}
                    variant="outlined"
                    classes={{ root: classes.input }}
                />
                <DialogActions classes={{ root: classes.popperPaperActions }}>
                    <Button onClick={createDocument}>{createDocumentLang}</Button>
                </DialogActions>
            </PopperPaper>
            <input
                type="file"
                id="upload-file"
                style={{ display: 'none' }}
                accept="text/csv,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/png,image/jpeg,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleFileChange}
            />
            <Box classes={{ root: cn(classes.root, 'bg-grey-light') }}>
                <Button classes={{ root: classes.button }} variant="contained" onClick={handleNewDocumentButtonClick}>
                    <NoteAddIcon classes={{ root: classes.icon }} />
                    {createDocumentLang}
                </Button>
                <label htmlFor="upload-file">
                    <Button component="span" classes={{ root: classes.button }} variant="contained">
                        {isUploading ? (
                            <CircularProgress classes={{ root: classes.progress }} size={16} />
                        ) : (
                            <CloudUploadIcon classes={{ root: classes.icon }} />
                        )}
                        {isUploading ? uploadingLang : uploadFileLang}
                    </Button>
                </label>
                {/*<TableContainer>*/}
                {/*    <Table classes={{ root: classes.table }} aria-label="customized table">*/}
                {/*        <TableHead>*/}
                {/*            <TableRow>*/}
                {/*                <StyledTableCell>*/}
                {/*                    <div className={classes.file}>*/}
                {/*                        <InputLabel*/}
                {/*                            disabled={fileRef.current && fileRef.current.files[0]}*/}
                {/*                            classes={{ root: classes.buttonAction }}*/}
                {/*                            style={{ cursor: 'pointer' }}*/}
                {/*                            onClick={() =>*/}

                {/*                            }*/}
                {/*                        >*/}
                {/*                            <NoteAddIcon fontSize="large" />*/}
                {/*                        </InputLabel>*/}
                {/*                        <InputLabel*/}
                {/*                            htmlFor="upload-file"*/}
                {/*                            classes={{ root: classes.buttonAction }}*/}
                {/*                            style={{ cursor: 'pointer' }}*/}
                {/*                        >*/}
                {/*                            <BackupOutlinedIcon fontSize="large" />*/}
                {/*                        </InputLabel>*/}
                {/*                        {fileRef.current && fileRef.current.files[0] ? (*/}
                {/*                            <p>{fileRef.current.files[0].name}</p>*/}
                {/*                        ) : (*/}
                {/*                            <Input*/}
                {/*                                placeholder={newFileNameLang}*/}
                {/*                                inputRef={docxRef}*/}
                {/*                                disableUnderline*/}
                {/*                                classes={{ root: classes.input }}*/}
                {/*                            />*/}
                {/*                        )}*/}
                {/*                    </div>*/}
                {/*                    <input*/}
                {/*                        type="file"*/}
                {/*                        id="upload-file"*/}
                {/*                        style={{ display: 'none' }}*/}
                {/*                        accept="text/csv,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/png,image/jpeg,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"*/}
                {/*                        ref={fileRef}*/}
                {/*                        onChange={() => setDisplaySend(true)}*/}
                {/*                    />*/}
                {/*                </StyledTableCell>*/}
                {/*                <StyledTableCell align="right" style={{ width: 'auto' }}>*/}
                {/*                    {displaySend ? (*/}
                {/*                        <div className={classes.actions}>*/}
                {/*                            <ButtonBase classes={{ root: classes.buttonAction }} onClick={addFile}>*/}
                {/*                                {sendLang}*/}
                {/*                            </ButtonBase>*/}
                {/*                            <IconButton classes={{ root: classes.buttonAction }} onClick={clear}>*/}
                {/*                                <DeleteForeverIcon />*/}
                {/*                            </IconButton>*/}
                {/*                        </div>*/}
                {/*                    ) : null}*/}
                {/*                </StyledTableCell>*/}
                {/*            </TableRow>*/}
                {/*        </TableHead>*/}
                {/*        <TableBody className={classes.body}>*/}
                {/*            {list.map(({ name, created_at, updated_at, id, docxid }) => (*/}
                {/*                <StyledTableRow key={id}>*/}
                {/*                    <StyledTableCell>*/}
                {/*                        <Button onClick={() => (docxid ? history.push(`/docx/${docxid}`) : null)}>*/}
                {/*                            {name}*/}
                {/*                            <GetAppIcon*/}
                {/*                                onClick={() => {*/}
                {/*                                    downloadFromServer(id, name);*/}
                {/*                                }}*/}
                {/*                            />*/}
                {/*                        </Button>*/}
                {/*                    </StyledTableCell>*/}
                {/*                    <StyledTableCell align="right">*/}
                {/*                        {moment(updated_at || created_at).fromNow()}*/}
                {/*                    </StyledTableCell>*/}
                {/*                </StyledTableRow>*/}
                {/*            ))}*/}
                {/*        </TableBody>*/}
                {/*    </Table>*/}
                {/*</TableContainer>*/}
            </Box>
        </>
    );
};

export default LockerFolderActions;
