export const FR = {
    join: 'Rejoindre',
    start: 'Démarrer',
    users: 'Participants',
    removeUser: 'Enlever cet utilisateur',
};

export const EN = {
    join: 'Join',
    start: 'Start',
    users: 'Participants',
    removeUser: 'Remove user',
};

export default {
    fr: FR,
    us: EN,
};
