import io from "socket.io-client";
const apiUrl = process.env.REACT_APP_WEBSOCKET_URL // 'https://localhost:9000'

export const socketRooms = [
    'update user connected list',
    'connect',
    'notification',
    'delete notification',
    'new mail',
    'update_message',
    'update_docx',
]
export let socket = io(apiUrl, {
    transports: [process.env.NODE_ENV === 'production' ? 'polling' : 'websocket'],
    query: { token: localStorage.getItem('token') },
    secure: true,
});

export const removeEvent = (name) => {
    socket.removeListener(name)
}
export const reloadSocket = () => {
    socketRooms.map(room => socket.removeListener(room, timestamp => {
        console.log(timestamp)
    }))
    socket = io(apiUrl, {
        transports: [process.env.NODE_ENV === 'production' ? 'polling' : 'websocket'],
        query: { token: localStorage.getItem('token') },
        secure: true,
    });
}

export default {
    socket,
    socketRooms,
    reloadSocket,
    removeEvent
}
