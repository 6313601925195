import { fetchApi } from "../../helpers";

export const getUsers = () => fetchApi('/users')

export const getUsersFromAllCompanies = () => fetchApi('/companies/users')

export const getTeams = () => fetchApi('/teams')

export const getCompanyTeams = () => fetchApi('/company/teams')

export const getCustomers = () => fetchApi('/customers')

export const getSuppliers = () => fetchApi('/suppliers')

export const getFolders = () => fetchApi('/folders/private/folder')

export const getLeenk = (id, type) => fetchApi(`/leenks/${type}/${id}`)

export const getMeetings = () => fetchApi('/meetings?past=true')

export const getMeetingsForAdmin = () => fetchApi('/meetings/admin?past=true')

export const getAccess = userId => fetchApi(`/access/${userId}`)

export const createAccessPrivateLeenk = (leenkId, userIds) =>
  fetchApi(`/access/leenk/private/` + leenkId, {
    method: 'POST',
    body: {
      userIds
    }
  })
export const createAccessSubject = (leenkId, accessType, parentType, userId, folderId, parentId, rights = 2) =>
  fetchApi(`/access/leenk/subject`, {
    method: 'POST',
    body: {
      leenkId,
      folderId,
      userId,
      parentType,
      parentId,
      rights
    }
  })
export const updateAccessSubject = (leenkId, accessType, parentType, userId, folderId, parentId, rights = 2) =>
  fetchApi(`/access/leenk/subject`, {
    method: 'PUT',
    body: {
      leenkId,
      folderId,
      userId,
      parentType,
      parentId,
      rights
    }
  })

export const createAccessLeenk = (elementId, accessType, type, userId, folderId) =>
  fetchApi(`/access/leenk`, {
    method: 'POST',
    body: {
      type,
      userId,
      folderId,
      elementId,
    }
  })

export const removeAccessSubject = (leenkId, accessType, parentType, userId, folderId) =>
  fetchApi(`/access/leenk/subject`, {
    method: 'DELETE',
    body: {
      parentType,
      userId,
      folderId,
      leenkId,
    }
  })
export const removeAccessLeenkPrivate = (leenkId, userIds) =>
  fetchApi(`/access/leenk/private/` + leenkId, {
    method: 'DELETE',
    body: {
      userIds,
    }
  })
export const removeAccessLeenk = (leenkId, accessType, parentType, userId, folderId) =>
  fetchApi(`/access/${accessType}`, {
    method: 'DELETE',
    body: {
      parentType,
      userId,
      folderId,
      leenkId,
    }
  })

export const createAccessFolder = (userId, folderId, rights = 2) => fetchApi('/access/folder', {
  method: 'POST',
  body: {
    userId,
    folderId,
    rights
  }
})
export const updateAccessFolder = (userId, folderId, rights = 2) => fetchApi('/access/folder', {
  method: 'PUT',
  body: {
    userId,
    folderId,
    rights
  }
})

export const createAccessMeeting = (userId, meetingId, leenkId, rights) => fetchApi('/access/meeting', {
  method: 'POST',
  body: {
    userId,
    meetingId,
    leenkId,
    rights
  }
})
export const updateAccessMeeting = (userId, meetingId, leenkId, rights) => fetchApi('/access/meeting', {
  method: 'PUT',
  body: {
    userId,
    meetingId,
    leenkId,
    rights
  }
})

export const removeAccessFolder = (userId, folderId) => fetchApi('/access/folder', {
  method: 'DELETE',
  body: {
    userId,
    folderId
  }
})

export const removeAccessMeeting = (userId, meetingId, leenkId) => fetchApi('/access/meeting', {
  method: 'DELETE',
  body: {
    userId,
    meetingId,
    leenkId
  }
})

export default {
  createAccessSubject,
  removeAccessSubject,
  createAccessPrivateLeenk,
  removeAccessLeenkPrivate,
  updateAccessSubject,
  updateAccessFolder,
  createAccessMeeting,
  removeAccessMeeting,
  updateAccessMeeting,
  getUsers,
  getUsersFromAllCompanies,
  getCompanyTeams,
  getMeetingsForAdmin
}