import React, { useContext, useEffect } from 'react';
import { Modal, Select, FormControl, MenuItem } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles'
import { Store } from '../store/store';
import {
	createAccessFolder,
	createAccessSubject,
	createAccessMeeting,
	removeAccessFolder,
	removeAccessSubject,
	removeAccessMeeting,
	updateAccessSubject,
	updateAccessFolder,
	updateAccessMeeting
} from "../containers/setting/Setting/helpers";
import { dispatchAlert } from '../containers/menu/helpers';

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	section: {
		height: 'calc(100% / 3)',
		width: '100%',
		padding: theme.spacing(1),
	},
	userTitle: {
		color: '#45535C',
		textAlign: 'left',
		padding: theme.spacing(2),
	},
	title: {
		color: '#45535C',
		textAlign: 'left',
	},
	h3: {
		fontSize: theme.typography.pxToRem(18),
	},
	h4: {
		fontSize: theme.typography.pxToRem(16),
		marginBottom: theme.spacing(1),
	},
	sectionContainer: {
		height: '100%',
	},
	dataGrid: {
		color: '#45535C',
		height: 300
	},
	accordion: {
		borderBottom: '1px solid #F4F5F5',
		'&.Mui-expanded': {
			margin: 0
		}
	},
	listItem: {
		fontSize: theme.typography.pxToRem(16),
		color: '#45535C'
	},
	fab: {
		color: '#fff',
		backgroundColor: '#1773C8',
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2)
	},
	selected: {
		backgroundColor: '#E2F0F7'
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		position: 'absolute',
		width: 400,
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
}))



function rand() {
	return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
	const top = 50 + rand();
	const left = 50 + rand();

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	};
}


const ModalRights = ({
	modal = false,
	setModal = () => { },
	onUpdate = () => { },
	rights: rightStart = 0,
	type,
	id,
	title,
	userId,
	folderId,
	parent,
	leenk_id,
	parentType
}) => {
	const [modalStyle] = React.useState(getModalStyle);
	const classes = useStyles()
	const [right, setRight] = React.useState(rightStart);

	const { state: {
		enums: {
			leenk: { parentTypes } },
		alerts,
		language: {
			access: {
				update: updateLang,
				...rightsLang
			} } }, dispatch } = useContext(Store)
	const rights = [
		rightsLang[0], rightsLang[1], rightsLang[2]
	]

	const add = async rights => {
		try {
			if (type === 'leenk') {
				const parentTypeToSend = parentTypes.SHARED_TEAM === parentType ? 'shared_team' : title
				await (right !== -1 ? updateAccessSubject : createAccessSubject)(id, type, parentTypeToSend, userId, folderId, parent, rights)
			}
			if (type === 'folder') {
				await (right !== -1 ? updateAccessFolder : createAccessFolder)(userId, id, rights)
			}
			if (type === 'meeting') {
				await (right !== -1 ? updateAccessMeeting : createAccessMeeting)(userId, id, leenk_id, rights)
			}
			return rights
		} catch (e) {
			console.log('error when try to add :>> ', e);
			throw e
		}
	}

	const remove = async () => {
		try {
			if (type === 'leenk')
				await removeAccessSubject(id, type, title, userId, folderId)
			if (type === 'folder')
				await removeAccessFolder(userId, id)
			if (type === 'meeting')
				await removeAccessMeeting(userId, id, leenk_id)
			return rights
		} catch (e) {
			console.log('error when try to remove :>> ', e);
			throw e
		}
	}

	const handleChange = async (event) => {
		const { value } = event.target
		try {
			if (value === -1) {
				await remove()
			} else {
				await add(value)
			}
			setRight(value)
			onUpdate(value)
			dispatchAlert(dispatch, alerts, { type: 'success', message: 'success' })
		} catch (e) {
			console.log('error on handleChange :>> ', e);
		}

	};
	useEffect(() => {
		setRight(rightStart)
	}, [rightStart])

	return (
		<Modal
			open={modal}
			onClick={e => e.stopPropagation()}
			onClose={() => setModal(false)}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			className={classes.modal}
		>
			<div className={classes.paper}>
				<div style={modalStyle} className={classes.paper}>
					<h2 id="simple-modal-title">{updateLang}</h2>
					<FormControl className={classes.formControl} id="simple-modal-description">
						<Select
							labelId="demo-simple-select-placeholder-label-label"
							id="demo-simple-select-placeholder-label"
							value={right}
							onChange={handleChange}
							displayEmpty
							className={classes.selectEmpty}
						>
							<MenuItem value={-1}>
								<em>{rightsLang.noRights}</em>
							</MenuItem>
							{rights.map((value, index) =>
								<MenuItem key={index} value={index}>{value}</MenuItem>
							)}
						</Select>
					</FormControl>
				</div>
			</div>
		</Modal>
	);
};

export default ModalRights;