export const FR = {
  campaign: 'Démarrer une nouvelle étude',
  dashboard: 'Leenks',
  brands: 'Marques',
  items: 'Études',
  private: 'Espace personnel',
  guides: 'Guides',
  profil: 'Profil',
  payment: 'Paiement',
  billing: 'Facturation',
  plan: 'Forfait',
  support: 'Support',
  mail: 'Mail',
  locker: 'Locker',
  spots: 'Spots',
  leenks: 'Leenks',
  management: 'Direction',
  meetingRooms: 'Salles de réunion',
}

export const EN = {
  campaign: 'Start new survey',
  dashboard: 'Leenks',
  brands: 'Brands',
  items: 'Surveys',
  private: 'Private space',
  guides: 'Guides',
  profil: 'Profil',
  payment: 'Payment method',
  billing: 'Billing & Invoices',
  plan: 'Plan',
  support: 'Support',
  mail: 'Mail',
  locker: 'Locker',
  spots: 'Spots',
  leenks: 'Leenks',
  management: 'Management',
  meetingRooms: 'Meeting Rooms',
}

export default {
  fr: FR,
  us: EN,
}
