import React from 'react';

import { makeStyles, Box } from '@material-ui/core';

import Speaker from './Speaker';

import styles from './Speakers.styles';
import AddSpeaker from './AddSpeaker';
import { getUser, userRoles } from '../../../helpers';

const useStyles = makeStyles(styles);

const Speakers = ({ speakers, leenks, setSpeakers, removeUserFromLeenk, setCurrent, updateTypes, changeLeenk }) => {
    const classes = useStyles();
    const { role } = getUser()
    const isGuest = role === userRoles.GUEST
    return (
        <Box classes={{ root: classes.root }}>
            {speakers.map(({ firstname, lastname, icon, id }, index) => (
                <Speaker
                    key={`speaker_${id}_${index}`}
                    classes={{ root: classes.speaker }}
                    userId={id}
                    {...{
                        removeUserFromLeenk,
                        firstname,
                        lastname,
                        icon,
                        leenks,
                        setCurrent,
                        updateTypes,
                        changeLeenk,
                    }}
                />
            ))}
            {speakers.length > 0 && !isGuest && (
                <AddSpeaker
                    classes={{ addIconButton: classes.addIconButton }}
                    {...{
                        speakers,
                        setSpeakers,
                    }}
                />
            )}
        </Box>
    );
};

export default Speakers;
