import { fetchApi } from '../helpers'

export const isUniqueNotification = (notif, index, arr) => {
  for (let i = index - 1; i >= 0; i--)
    if (JSON.stringify(notif) === JSON.stringify(arr[i]))
      return false
  return true
}

export const getAllNotifications = () => fetchApi('/notifications')
export const getAllNotificationsWithReaded = () => fetchApi('/notifications/readedtoo')

export const readNotification = (notificationId, dispatch) =>
  fetchApi('/notification/read/' + notificationId, {
    method: 'PUT',
  }).then(() => dispatch && dispatch({
    type: 'REMOVE_NOTIFICATION',
    payload: notificationId,
  }))
export const readNotificationAPI = (notificationId) =>
  fetchApi('/notification/read/' + notificationId, {
    method: 'PUT',
  })

export const updateNotification = (id) => fetchApi(`/notification/${id}`, {
  method: 'PUT'
})
export default {
  readNotification,
  getAllNotifications,
  readNotificationAPI,
  getAllNotificationsWithReaded
}
