import React, { useEffect, useState, useContext } from 'react';
import { FormControl, MenuItem, Select, Typography } from "@material-ui/core";
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined';
import { useStyles } from "./style"
import { Store } from '../../store/store';

const Status = ({ pstatus, id, updateTask }) => {
  const classes = useStyles();
  const [status, setStatus] = useState(-1);
  const { state: { language: {
    tasks: { status: {
      title: titleLang,
      undefined: undefinedLang,
      toDo: toDoLang,
      doing: doingLang,
      done: doneLang,
    } }
  } } } = useContext(Store)
  useEffect(() => {
    if (pstatus)
      setStatus(pstatus)
  }, [pstatus])

  const update = async e => {
    const { value } = e.target
    try {
      await updateTask({ status: value }, id);
      setStatus(value)
    } catch (e) {
      console.log('error', e.message)
    }
  }

  return (
    <div className={classes.row}>
      <Typography classes={{ root: classes.title }}>
        <PublicOutlinedIcon />
        {titleLang}:
      </Typography>
      <FormControl variant="outlined">
        <Select value={status} onChange={update}>
          <MenuItem value={-1}>{undefinedLang}</MenuItem>
          <MenuItem value={0}>{toDoLang}</MenuItem>
          <MenuItem value={1}>{doingLang}</MenuItem>
          <MenuItem value={2}>{doneLang}</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default Status;
