import React, { useContext } from 'react';
import { Store } from '../../store/store';
import { NotificationComponent } from '../notification/Notification/Notification';

const PersistantNotificationContainer = () => {
	const { state: { notifications } } = useContext(Store)
	const persistants = notifications.filter(n => !!n.persistant).reverse()
	return (
		<div style={{
			position: 'absolute',
			bottom: '-76px',
			left: '-8px',
			maxHeight: 200
		}}
		className="scroll-y scrollbar-disable">
			{persistants.map((notification, key) =>
				<NotificationComponent {...notification} persistant={true} key={key} tryBrowser />
			)}
		</div>
	);
};

export default PersistantNotificationContainer;