import React, { useRef, useState, useContext } from 'react';
import { InputBase, Button } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { isNull } from '../../helpers';
import { Store } from '../../../store/store';

// cherche dans les derners éléments, soit les subjects, soit les messages privés.
// affichage d'une liste de résultat ci-dessous
const CompanyLockerSearchBar = ({ companies = [], parentStyle = {}, getExpended = () => {} }) => {
	const {dispatch } = useContext(Store)
	const history = useHistory()
	const [res, setRes] = useState([])
	const inputRef = useRef(null);
	const prepareString = (str) => (str || '').toLowerCase().replace(/[\n\r\t\s]+/g, '')
	const prepareStringSpecial = (str) => (str || '').toLowerCase().replace(/[\n\r\t\s\(\)\[\]]+/g, '')
	const matchWithValue =
		(valueToCompare, value) =>
			prepareString(valueToCompare).includes(prepareString(value)) ||
			prepareStringSpecial(valueToCompare).includes(prepareString(value));
	const searchInEachDepth = value => {
		if (isNull(value) || value === '')
			return []
		const arrRes = []
		for (const company of companies) {
			for (const team of company.teams) {
				if (!team.leenks) {
					if (matchWithValue(team.name, value)) {
						arrRes.push({
							title: team.name,
							navigateTo: team.folderId,
							type: 'company'
						})
					}
				} else {
					for (const leenk of team.leenks) {
						if (matchWithValue(leenk.name, value)) {
							arrRes.push({
								title: `${leenk.name} [${team.name}] (${company.name})`,
								navigateTo: leenk.folder,
							})
						}
					}
				}

			}
		}
		return arrRes
	}
	const onChange = () => {
		const { value } = inputRef.current;
		setRes(searchInEachDepth(value))
	};

	const navigate = (folderId, type = null) => {
		setRes([])
		inputRef.current.value = ''
		getExpended(folderId, [], type)
		if (!type){
			dispatch({ type: "UPDATE_FOLDER", payload: { folderId, folderType: 'public', parentType: 'folder' } })
		}
		history.push('/locker/folder/' + folderId)
	}

	return (
		<>
			<div style={parentStyle}>
				<InputBase
					startAdornment={<SearchIcon fontSize="small" />}
					className="bg-white shadow border-radius-5 header-search-bar"
					inputRef={inputRef}
					onChange={onChange}
				/>
			</div>
			<div>
				{res.map(({ title, navigateTo, type = null }, index) =>
					<Button
						key={index}
						variant="contained"
						color="primary"
						style={{ textTransform: 'none', margin: 5 }}
						onClick={() => navigate(navigateTo, type)}>{title}</Button>
				)}
			</div>
		</>
	);
};

export default CompanyLockerSearchBar;