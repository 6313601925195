export const FR = {
  brand:               'Marque',
  item:                'Étude',
  title:               'Démarrez une nouvelle étude',
  subtitle:            'Optimisez vos stratégies avec Klack !',
  profil:              'Profil complété',
  profilButton:        'Completer',
  payment:             'Moyen de paiement',
  paymentButton:       'Ajouter',
  followedGuide:       'Guides suivis',
  followedGuideButton: 'Suivre',
  invite:              'Inviter',
}

export const EN = {
  brand:               'Brand',
  item:                'Survey',
  title:               'Start a new survey',
  subtitle:            'Optimize your stategies with Klack',
  profil:              'Profil completion',
  profilButton:        'Complete',
  payment:             'Payment method',
  paymentButton:       'Add',
  followedGuide:       'Followed Guides',
  followedGuideButton: 'Show me',
  invite:              'Invite',
}

export default {
  fr: FR,
  us: EN,
}
