import React, {useContext, useEffect, useRef, useState} from 'react'
import {
  Avatar, Typography, ListItem, TextField, InputAdornment,
  ListItemText, ListItemAvatar, List, IconButton
} from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send';
import moment from 'moment'
import { Store } from "../../store/store"
import 'moment/locale/fr'
import { useStyles } from './style'
import {createComment, getComments} from "./helpers";
import {sendMessageApi} from "../leenks/Leenks/helpers";

const Comment = ({ firstname, lastname, message, created_at }) => {
  const classes = useStyles()
  const { state:{ languageValue } } = useContext(Store)

  moment.locale(languageValue === "fr" ? "fr" : 'en')

  return (
    <ListItem alignItems="flex-start" classes={{root: classes.comment}}>
      <ListItemAvatar>
        <Avatar alt={`${firstname} ${lastname}`}>
          {firstname.charAt(0)}{lastname.charAt(0)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        classes={{primary: classes.itemTextPrimary, secondary: classes.itemTextSecondary}}
        primary={
          <>
            <Typography
              component="span"
              variant="body1"
            >
              {firstname} {lastname}
            </Typography>
            <Typography
              component="span"
              variant="body2"
            >
              ({moment(created_at).fromNow()})
            </Typography>
          </>
        }
        secondary={message}
      />
    </ListItem>
  );
};

const CommentTab = ({ taskId }) => {
  const [comments, setComments] = useState([]);
  const classes = useStyles()
  const messageRef = useRef()
  const { state: {
    language: {
      tasks: {
        commentInputPlaceholder: commentInputPlaceholderLang,
      }
    }
  } } = useContext(Store)

  useEffect(() => {
    if(taskId)
      getComments(taskId).then(data => setComments(data))
  }, [taskId])

  const post = () => {
    createComment(taskId, messageRef.current.value).then(() => {
      messageRef.current.value = ''
      getComments(taskId).then(data => setComments(data))
    })
  }

  return (
    <>
      <List style={{height: '100%'}} className="scroll-y scrollbar-disable">
        {comments.map(({ firstname, lastname, message, created_at }, key) => (
          <Comment key={key} firstname={firstname} lastname={lastname} message={message} created_at={created_at} />
        ))}
      </List>
      <TextField
        className={classes.inputComment}
        inputRef={messageRef}
        placeholder={`${commentInputPlaceholderLang}...`}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={post}>
                <SendIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </>
  )

}

export default CommentTab
