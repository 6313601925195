export const FR = {
	chooseAction: 'Choisissez une action',
	createMeeting: 'Créer une réunion',
	createTask: 'Créer une tâche'
  }
  
  export const EN = {
	chooseAction: 'Choose an action',
	createMeeting: 'Create a meeting',
	createTask: 'Create a task'
  }
  
  export default {
	fr: FR,
	us: EN,
  }
  