import React, {
  useState, useContext, useEffect,
} from 'react'
import UnLogGrid from '../../../components/UnLogGrid'
import { Store } from '../../../store/store'
import { confirmAccount, sendConfirmationMail } from './helper'
import ModalComponent from '../../../components/Modal'

const ConfirmAccount = ({ match, history }) => {
  const { token } = match.params
  const [modal, setModal] = useState(false)
  const [confirmationText, setConfirmationText] = useState('')
  const {
    state: {
      language: {
        confirmAccount: {
          mail: mailLang,
          submit: submitLang,
          accountValidated: accountValidatedLang,
          buttonToLogin: buttonToLoginLang,
          closeModal: closeModalLang,
          modalTitle: modalTitleLang,
          mailConfirmationText: mailConfirmationTextLang,
          errorConfirm: errorConfirmLang,
        },
      },
    },
  } = useContext(Store)
  const inputs = [
    {
      type:           'text',
      name:           'email',
      placeholder:    mailLang,
      required:       true,
      silentRequired: true,
      fluid:          true,
    },

  ]

  useEffect(() => {
    const handleAccountConfirmation = async () => {
      try {
        await confirmAccount(token)
        setConfirmationText(accountValidatedLang)
        setModal(true)
        return true
      } catch (e) {
        setConfirmationText(errorConfirmLang)
        setModal(true)
        return false
      }
    }
    if (token) {
      handleAccountConfirmation()
        .then(res => {
          if (res) {
            setTimeout(() => {
              history.push('/login')
            }, 5000)
          }
        })
    }
  }, [])

  const sendLinkConfirm = async values => {
    try {
      await sendConfirmationMail(values)
      setConfirmationText(mailConfirmationTextLang)
      setModal(true)
      setTimeout(() => {
        history.push('/login')
      }, 3000)
    } catch (e) {
      setConfirmationText(errorConfirmLang)
      setModal(true)
    }
  }

  const closeModal = () => {
    if (confirmationText === accountValidatedLang) { return history.push('/login') }
    setModal(false)
  }
  return (<div>
    <UnLogGrid
      page="confirmAccount"
      inputs={inputs}
      submitMessage={submitLang}
      sendForm={sendLinkConfirm}
    />
    <ModalComponent
      onClose={closeModal}
      open={modal}
      title={modalTitleLang}
      description={confirmationText}
      onClick={closeModal}
      button={confirmationText === accountValidatedLang ? buttonToLoginLang : closeModalLang}
    />
  </div>
  )
}

export default ConfirmAccount
