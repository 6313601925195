export const FR = {
  newNote:              'Nouvelle note',
  noteTitlePLacholder:  'Titre',
}

export const EN = {
  newNote:              'New note',
  noteTitlePLacholder:  'Title',
}

export default {
  fr: FR,
  us: EN,
}
