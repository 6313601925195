export const FR = {
	navigateToFolder: 'Accéder au dossier'
  }
  
  export const EN = {
	navigateToFolder: 'Navigate to folder'
  }
  
  export default {
	fr: FR,
	us: EN,
  }
  