import React, { useContext } from 'react'
import { Store } from '../../store/store'
import MenuComponent from './Menu'
import { getItemsMenu } from './helpers'
import { useHistory } from "react-router-dom";

const Menu = () => {
  const { state } = useContext(Store)
  const { menuItems } = getItemsMenu(state)
  const history = useHistory();
  const { pathname } = history.location

  return (
    <MenuComponent
      menuItems={menuItems}
      pathname={pathname}
    />
  )
}

export default Menu
