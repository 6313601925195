import { fetchApi } from "../helpers";

export const createTask = (title = null, from = null, to =null) =>
  fetchApi('/task', {
    method: 'POST',
    body: {
      title, 
      from, 
      to
    }
  })
export const createTaskAccess = (taskId, userId) =>
  fetchApi('/access/task', {
    method: 'POST',
    body: {
      taskId,
      userId,
    }
  })

export const updateTask = (obj, id) =>
  fetchApi(`/task/${id}`, {
    method: 'PUT',
    body: obj
  })

export const removeTask = id => fetchApi(`/task/${id}`, {
  method: 'DELETE'
})
export const removeTaskAccess = (userId, taskId) => fetchApi(`/access/task`, {
  method: 'DELETE',
  body:{userId, taskId}
})

export const getTasks = () => fetchApi('/tasks')

export const getTask = id => fetchApi(`/task/${id}`)

export const getCheckLists = id => fetchApi(`/checklists/${id}`)

export const getCheckBoxs = id => fetchApi(`/checkboxs/${id}`)

export const updateCheckBox = (obj, id) => fetchApi(`/checkbox/${id}`, {
  method: 'PUT',
  body: obj
})

export const addCheckbox = (obj, id) => fetchApi(`/checkbox`, {
  method: 'POST',
  body: {
    ...obj,
    checklistId: id
  }
})

export const deleteCheckBox = (id) => fetchApi(`/checkbox/${id}`, { method: 'DELETE' })


export const createCheckList = (taskId, title) => fetchApi('/checklist', {
  method: 'POST',
  body: {
    taskId,
    title
  }
})

export const removeCheckList = id => fetchApi(`/checklist/${id}`, { method: 'DELETE' })


export const updateCheckList = (obj, id) => fetchApi(`/checklist/${id}`, {
  method: 'PUT',
  body: obj
})

export const getLabels = () => fetchApi(`/labels`)

export const createLabel = (name, color) => fetchApi('/label', {
  method: 'POST',
  body: {
    label: name,
    color
  }
})

export const getComments = taskId => fetchApi(`/comment/${taskId}`)

export const createComment = (taskId, message) => fetchApi('/comment', {
  method: 'POST',
  body: {
    taskId,
    message
  }
})

export const getTaskUsers = (taskId) => fetchApi('/access/task/'+taskId)
export default {
  createTaskAccess,
  getTaskUsers,
  removeTaskAccess
}