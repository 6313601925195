import React, { useMemo } from 'react';
import UserIcons from '../UserIcons';
import { Avatar, makeStyles } from '@material-ui/core';
import styles from './UserAvatar.styles';
const useStyles = makeStyles(styles);

const UserAvatar = ({ icon, firstName, lastName, classes: receivedClasses = {}, style = {} }) => {
    const classes = useStyles({ classes: receivedClasses });
    const initials = useMemo(() => {
        if (!firstName || !lastName) {
            return '?';
        }
        return `${firstName.charAt(0)} ${lastName.charAt(0)}`.toUpperCase();
    }, [firstName, lastName]);

    if (icon) {
        return (
            <div className={classes.root} style={style}>
                <UserIcons {...{ icon }} index={Number(icon)} />
            </div>
        );
    }

    return <Avatar className={receivedClasses.root}>{initials}</Avatar>;
};

export default UserAvatar;
