export const FR = {
    createSpace: 'Créer un espace',
    spaceName: "Nom de l'espace",
    spaceNumber: 'Nombre de postes',
    management: 'Direction',
    meetingRooms: 'Salles de réunion',
    breakRooms: 'Salles de repos',
    addFloor: 'Ajouter un étage',
    room: 'Salle',
    meetingRoomTitle: 'Reunion',
    meetingRoomHost: 'Hote',
    meetingRoomSchedule: 'Horaire',
    meetingRoomScheduleFrom: 'À partir de',
    meetingRoomScheduleTo: "Jusqu'à",
    meetingRoomUser: 'Moi',
    meetingRoomCreate: 'Créer',
    meetingRoomLink: 'Lien',
    meetingRoomAccess: 'Accéder au meeting',
    meetingRoomAddUser: 'Ajouter un participant',
    spotAvailable: 'Disponible',
    spotNotAvailable: 'Non disponible',
    spotCollaborationText: 'Texte',
    spotCollaborationSpreadsheet: 'Tableur',
    spotSettle: "M'installer ici",
    position: 'position',
    addUser: 'Ajouter un utilisateur',
    removeUser: 'Retirer cet utilisateur',
    userRemoved: "L'utilisateur a été retiré de l'espace",
    userAdded: "L'utilisateur a été ajouté à l'étage",
    collaboration: 'Collaboration',
    directMessage: 'Lancer une conversation',
    startMeeting: 'Lancer un meeting',
    startDocx: 'Collaborer sur un fichier texte',
    changeOrientation: "Changer d'orentation",
    toctocSent: 'Une notification a été envoyée à votre destinataire afin de l\'encourager à se connecter ✨.'
};

export const EN = {
    createSpace: 'Create space',
    spaceName: 'Space name',
    spaceNumber: 'Space number',
    management: 'Management',
    meetingRooms: 'Meeting Rooms',
    breakRooms: 'Break Rooms',
    addFloor: 'Add floor',
    room: 'Room',
    meetingRoomTitle: 'Meeting',
    meetingRoomHost: 'Host',
    meetingRoomSchedule: 'Schedule',
    meetingRoomScheduleFrom: 'From',
    meetingRoomScheduleTo: 'To',
    meetingRoomUser: 'Me',
    meetingRoomCreate: 'Create',
    meetingRoomLink: 'Link',
    meetingRoomAccess: 'Access to meeting',
    meetingRoomAddUser: 'Add one participant',
    spotAvailable: 'Available',
    spotNotAvailable: 'Not available',
    spotCollaborationText: 'Text',
    spotCollaborationSpreadsheet: 'Spreadsheet',
    spotSettle: 'Settle here',
    position: 'position',
    addUser: 'Add one user',
    removeUser: 'Remove user',
    userAdded: 'User added to floor',
    userRemoved: 'User removed from floor',
    collaboration: 'Collaborate',
    directMessage: 'Launch leenk',
    startMeeting: 'Launch meeting',
    startDocx: 'Collaborate on text file',
    changeOrientation: 'Change orientation',
    toctocSent: 'A notification is sent to your collaborator to invite him to connect ✨.'
};

export default {
    fr: FR,
    us: EN,
};
