import React, { useState, useEffect } from 'react';
import NestedList from '../../../components/NestedList';
import { Divider } from '@material-ui/core';
import SearchBar from '../../../components/SearchBar';

const CompanyList = ({
	companies: companiesFromParent,
	changeSpace,
	saveTeam,
	current,
	createTeamLang,
	isGuest,
	updateTeamName
}) => {
	const [companies, setCompanies] = useState(companiesFromParent)
	const isShared = () => !!current.shared
	useEffect(() => {
		setCompanies(companiesFromParent)
	}, [companiesFromParent])
	return (
		<>
			<SearchBar
				list={companiesFromParent.map(company => ({
					valueToCompare: company.name,
					...company
				}))}
				setList={setCompanies}
				parentStyle={{
					marginBottom: 2
				}}
			/>
			{companies.map(({ company, name, teams }, index) => (
				<React.Fragment key={index}>
					<NestedList
						map={name}
						title={name}
						list={teams}
						action={(team) => changeSpace(team, 'TEAM')}
						current={current.company === company && current.parentType === 'TEAM' ? current.parentId : false}
						moreButton={isShared(teams) || isGuest ? false : (name) => saveTeam(company, name)}
						moreButtonLabel={createTeamLang}
						settingButton={updateTeamName}
					/>
					<Divider />
				</React.Fragment>
			))}
		</>
	);
};

export default CompanyList;