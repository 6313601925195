const EN = {
    spaces: 'Spaces',
    customers: 'Customers',
    createTeam: 'Create team',
    createSubject: 'Create subject',
    suppliers: 'Suppliers',
    privates: 'See private messages',
    teams: 'Teams',
    subjects: 'Subjects',
    previousMessages: 'Show me previous messages',
    sendMessage: 'Send',
    folder: 'Folder',
    file: 'File',
    leenkNoExists: "Leenk doesn't exist",
    transformOnTask: 'Transform on task',
    affectTask: 'Affect the task',
    startConv: 'Start conversation about',
    enableMicro: 'Please enable micro.',
    removeUserFromLeenk: 'Remove user from this leenk',
    interactCompany: 'Contact a company',
    interact: 'Interact',
    subject: 'Subject',
    team: 'Team',
    addUser: 'Add user',
    invalidEmail: 'Invalid email',
    emailSent:'Email sent',
    discussWith: 'Discuss with ',
    updateName: 'Update name',
    fileButton: {
        join: 'Join a file',
        extern: 'Extern',
        collabore: 'Collaborate on',
        text: 'Text',
        csv: 'CSV',
    },
    sharedTeamTitle: 'Invitation to join shared team',
    accept: 'Accept',
    refuse: 'Refuse',
    newMessage : 'New Message',
    taskHaveToHaveTitle: 'You can only create task with not empty message text.',
    newLinkHaveToHaveTitle: 'You can only create message with not empty message text.',
};

export const FR = {
    spaces: 'Espaces',
    customers: 'Clients',
    suppliers: 'Fournisseurs',
    createTeam: "Créer une équipe",
    createSubject: 'Créer un sujet',
    privates: 'Voir les messages privés',
    teams: 'Équipes',
    subjects: 'Sujets',
    previousMessages: 'Afficher messages précédents',
    sendMessage: 'Envoyer',
    folder: 'Dossier',
    file: 'Fichier',
    invalidEmail: 'Mail invalide',
    emailSent:'Mail envoyé',
    leenkNoExists: "Le leenk n'existe pas",
    transformOnTask: 'Transformer en tâche',
    affectTask: 'Affecter la tâche',
    startConv: 'En discuter',
    enableMicro: "Activer le micro s'il vous plaît.",
    removeUserFromLeenk: "Supprimer l'utilisateur de ce leenk",
    interactCompany: 'Contacter une entreprise',
    addUser: 'Ajouter un utilisateur',
    interact: 'Intéragir',
    subject: 'Sujet',
    team: 'Équipe',
    updateName: 'Modifier le nom',
    discussWith: 'Discuter avec ',
    fileButton: {
        join: 'Joindre un fichier',
        extern: 'Externe',
        collabore: 'Collaborer sur',
        text: 'Texte',
        csv: 'CSV',
    },
    sharedTeamTitle: 'Invitation pour rejoindre une équipe partagée',
    accept: 'Accepter',
    refuse: 'Refuser',
    newMessage : 'Nouveau message',
    taskHaveToHaveTitle: 'Vous ne pouvez créer une tâche qu\'avec un message contenant du texte.',
    newLinkHaveToHaveTitle: 'Vous ne pouvez créer un message qu\'avec un message contenant du texte.'
};

export default {
    fr: FR,
    us: EN,
};
