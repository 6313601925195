import React from 'react'

const HorizontalCenteredGridFlexed = ({
  children,
}) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>{children}</div>
  )
}

export default HorizontalCenteredGridFlexed
