import { fetchApi } from '../../helpers'

export const inviteUser = ({ email }) => fetchApi('/user/invite/' + email)
export const inviteCollaborator = ({ email, rights = 0 }) => fetchApi('/user/add/' + email, {
    method: 'POST',
    body: {
        rights
    }
})

export default {
    inviteUser,
    inviteCollaborator
}
