import { useCallback, useState } from 'react';

export const useCallbackOpen = (defaultValue = false) => {
    const [open, setOpen] = useState(defaultValue);
    const setOpened = useCallback(() => setOpen(true), []);
    const setClosed = useCallback(() => setOpen(false), []);
    const setStatus = useCallback((status) => setOpen(status), []);
    const toggle = useCallback(() => (open ? setClosed() : setOpened()), [open, setClosed, setOpened]);
    return [open, setOpened, setClosed, setStatus, toggle];
};
