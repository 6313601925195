export const FR = {
    messages: {
        error: 'Donnée pas enregistrée.',
        warning: 'Donnée pas enregistrée.',
        info: 'Donnée pas enregistrée.',
        success: 'Donnée enregistrée.',
        dismiss: 'Compris',
    },
};

export const EN = {
    messages: {
        error: 'Data not saved.',
        warning: 'Data not saved.',
        info: 'Data not saved.',
        success: 'Data saved.',
        dismiss: 'Dismiss',
    },
};

export default {
    fr: FR,
    us: EN,
};
