import {fetchApi} from "../../helpers";

export const getNotes = () => fetchApi('/notes')

export const createNote = (title, text) => fetchApi('/note', {
  method: 'POST',
  body: {
    title,
    text,
  }
})

export const deleteNote = id => fetchApi(`/note/${id}`, {
  method: 'DELETE'
})

export const updateNote = (id, title, text) =>
  fetchApi(`/note/${id}`, {
    method: 'PUT',
    body: {
      title,
      text,
    }
  })
