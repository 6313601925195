export const EN = {
	noRights: 'No rights',
	0: 'READ',
	1: 'WRITE',
	2: 'ADMIN',
	update: 'Choose right\'s type',
  }
  
  export const FR = {
	noRights: 'Aucun droits',
	0: 'LECTURE',
	1: 'ÉCRITURE',
	2: 'ADMIN',
	update: 'Choisissez le type de droit',

  }
  
  
  export default {
	fr: FR,
	us: EN,
  }
  