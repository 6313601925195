import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Accordion from '../../../components/Accordion';
import CompanyLockerSearchBar from './CompanyLockerSearchBar';

const CompanyList = ({
	companies: companiesFromParent,
	folderId,
	accordion,
	path,
	navigateToFolder,
	getExpended
}) => {
	const [companies, setCompanies] = useState(companiesFromParent)
	useEffect(() => {
		setCompanies(companiesFromParent)
	}, [companiesFromParent])
	return (
		<>
			<CompanyLockerSearchBar
				companies={companies}
				getExpended={getExpended}
			/>
			 {companies.map((data, key) =>
                <Grid item key={key}>
                    <Accordion
                        {...data}
                        list={data.teams}
                        title={data.name}
                        folderId={folderId}
                        selected={data.company === accordion.company}
                        paths={(path || {}).paths}
                        accordion={accordion}
                        navigateToFolder={navigateToFolder}
                        getExpended={getExpended}
                    />
                </Grid>
            )}
		</>
	);
};

export default CompanyList;