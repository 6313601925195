export const EN = {
  users: 'Users',
  customers: 'Customers',
  suppliers: 'Suppliers',
  teams: 'Teams',
  subjects: 'Subjects',
  folders: 'Folders',
  meetings: 'Meetings',
  userProfile: 'Access to user profile',
  emailUserAlreadyExists: 'A user with this email already exists.'
}

export const FR = {
  users: 'Utilisateurs',
  customers: 'Clients',
  suppliers: "Fournisseurs",
  teams: "Équipes",
  subjects: 'Sujets',
  folders: 'Dossiers',
  meetings: 'Reunions',
  userProfile: 'Accéder au profil de l\'utilisateur',
  emailUserAlreadyExists: 'Un utilisateur avec cet email existe déjà.'
}

export default {
  fr: FR,
  us: EN,
}
