export const FR = {
  meet: '$1 vous a convié à la réunion $2',
  meeting: '$1 vous a convié à une réunion privée',
  leenk: '$1 vous a ajouté au leenk $2',
  message: '$1 vous a envoyé un message dans $2',
  mention: '$1 vous a mentionné dans $2',
  folder: '$1 vous a ajouté a un dossier $2',
  task: '$1 vous a assigné la tâche $2',
  collaborator: '$1 vous a assigné la tâche $2',
  floor: '$1 vous a donné un droit d\'accès à l\'étage $2',
  docx: '$1 souhaite collaborer avec vous sur le document $2',
  leenk_collaborate: '$1 souhaite discuter avec vous dans un leenk privé',
  shared_team: '$1 vous invite à collaborer sur le sujet $2 dans la nouvelle équipe $3',
  privateConv: 'votre conversation privée',
  readed: 'Afficher les notifications lues',
}

export const EN = {
  meet: '$1 invited you to the meeting $2',
  meeting: '$1 invite you to start private meeting ',
  leenk: '$1 added you to leenk $2',
  message: '$1 sent you a message in $2',
  mention: '$1 mention you in $2',
  folder: '$1 added you to the folder $2',
  task: '$1 assigned you to task $2',
  collaborator: '$1 assigned you to task $2',
  floor: '$1 give you rights to access to floor $2',
  docx: '$1 want to collabore with you on document $2',
  leenk_collaborate: '$1 want to discuss with you in private leenk',
  shared_team: '$1 invite you to collaborate about subject $2 in new team $3',
  privateConv: 'your private discussion',
  readed: 'To display readed notifications',
}

export default {
  fr: FR,
  us: EN,
}
