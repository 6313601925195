import React, { useEffect, useRef, useState } from 'react';

import { Box, makeStyles } from '@material-ui/core';
import styles from './HorizontalCollapse.styles';
import { animated, useSpring } from 'react-spring';

import cn from 'classnames';

const useStyles = makeStyles(styles);

const HorizontalCollapse = ({ open = true, minWidth = 0, children }) => {
    const classes = useStyles();

    const { width } = useSpring({
        width: open ? 'fit-content' : minWidth,
        // Remove if you want to animate width (might bring some poor performances on some setups.
        immediate: true,
    });

    // You can uncomment translateX if you want the content to slide in.
    const { /* translateX, */ opacity } = useSpring({
        // translateX: open ? '0%' : '0%',
        opacity: open ? 1 : 0,
    });

    return (
        <Box component={animated.div} className={cn(classes.root, 'scrollbar-disable')} style={{ width }}>
            <Box
                component={animated.div}
                className={classes.content}
                style={{
                    // transform: translateX.interpolate((value) => `translate3d(${value}, 0, 0)`),
                    opacity,
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default HorizontalCollapse;
