import React, { useState, useEffect, useContext } from 'react';
import cn from 'classnames';
import RenderFolders from './RenderFolders';
import ListFolders from './ListFolders';
import { getFolders, getSpecificFolders } from './helpers';
import { Store } from '../../../store/store';
import { Box, makeStyles } from '@material-ui/core';

import styles from './Locker.styles';
import HorizontallyCollapsableColumn from '../../../components/HorizontallyCollapsableColumn';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(styles);

const Locker = ({ history, match }) => {
    const classes = useStyles();
    const params = useParams()
    const folderId = params.folderId ? Number(params.folderId) : null;

    const navigateToFolder = (id) => {
        history.push('/locker/folder/' + id);
    };
    const [folders, setFolders] = useState([]);
    const [files, setFiles] = useState([]);
    const [path, setPath] = useState();
    const { state, dispatch } = useContext(Store);

    const {
        language: {
            locker: {
                spaces: spacesLang
            },
        },
    } = state;

    useEffect(() => {
        dispatch({ type: 'LOADING_ON' });
    }, []);
    const [folder, setFolder] = useState({})
    useEffect(() => {
        if (folderId) {
            getSpecificFolders(folderId)
                .then((data) => {
                    //renvoyer type
                    setPath(data.path);
                    setFiles(data.files.map(f => ({ folderId, ...f })));
                    setFolders(data.folders);
                    setFolder(data)
                })
                .catch(e => history.push('/locker'))
        } else
            getFolders('private', 'folder').then((data) => {
                setFiles([]);
                setFolders(data);
            });
    }, [folderId]);

    useEffect(() => {
        dispatch({ type: 'LOADING_OFF' });
    }, [path]);

    return (
        <Box className={cn(classes.root, 'page-container border-radius-5 shadow bg-white')}>
            <HorizontallyCollapsableColumn
                classes={{ root: classes.horizontallyCollapsableColumn }}
                maxWidth={350}
                title={spacesLang}
            >
                <ListFolders path={path} folderId={folderId} navigateToFolder={navigateToFolder} />
            </HorizontallyCollapsableColumn>
            <RenderFolders
                folders={folders}
                files={files}
                folderId={folderId}
                navigateToFolder={navigateToFolder}
                setFiles={setFiles}
                setFolders={setFolders}
                meeting={false}
                parent={path ? path.paths : null}
                folderName={folder.name}
                folder={folder}
            />
        </Box>
    );
};

export default Locker;
