import { fetchApi, isNull } from "../../helpers";

export const readAllNotifications = () => fetchApi('/notification/read', {
  method: 'PUT'
})

export const getUserById = userId => fetchApi(`/users/${userId}`)

export const getLeenk = leenkId => fetchApi(`/leenk/${leenkId}`)

export const getFolder = id => fetchApi(`/folder/${id}`)

export const getMeeting = id => fetchApi(`/meeting/${id}`)
export const notificationTypes = {
  USER: 0,
  SPACE: 1,
  LEENK: 2,
  FOLDER: 3,
  TASK: 4,
  MEETING: 5,
  MESSAGE: 6,
  MENTION: 7,
  COLLABORATOR: 8,
  DOCX: 9,
  LEENK_COLLABORATE: 10,
  MAIL: 11,
  SHARED_TEAM: 12,
  FLOOR: 13
}

const keyType = {
  USER: { type: 'user', operator: '@' },
  LEENK: { type: 'leenk', operator: '#' },
  TASK: { type: 'task', operator: '$' },
  MEETING: { type: 'meeting', operator: '' },
  FOLDER: { type: 'folder', operator: '#' },
  SPACE: { type: 'space', operator: '@' },
  COLLABORATOR: { type: 'collaborator', operator: '/' },
  DOCX: { type: 'docx', operator: '~' },
  TEAM: { type: 'team', operator: '&' },
  FLOOR: { type: 'floor', operator: '§' },
}

const keysMessageType = {
  [notificationTypes.USER]: [keyType.USER, keyType.USER],
  [notificationTypes.SPACE]: [keyType.USER, keyType.SPACE],
  [notificationTypes.LEENK]: [keyType.USER, keyType.LEENK],
  [notificationTypes.FOLDER]: [keyType.USER, keyType.FOLDER],
  [notificationTypes.TASK]: [keyType.USER, keyType.TASK],
  [notificationTypes.MEETING]: [keyType.USER, keyType.MEETING],
  [notificationTypes.MESSAGE]: [keyType.USER, keyType.LEENK],
  [notificationTypes.MENTION]: [keyType.USER, keyType.LEENK],
  [notificationTypes.COLLABORATOR]: [keyType.USER, keyType.COLLABORATOR],
  [notificationTypes.FLOOR]: [keyType.USER, keyType.FLOOR],
  [notificationTypes.DOCX]: [keyType.USER, keyType.DOCX],
  [notificationTypes.LEENK_COLLABORATE]: [keyType.USER, keyType.LEENK],
  [notificationTypes.SHARED_TEAM]: [keyType.USER, keyType.LEENK, keyType.TEAM],
}
const getKeyTypeByMessageType = (index = 0, notifType = 0) => { return keysMessageType[notifType][index] || keyType.USER }

export const getNotificationTypeReadable = (type) =>
  Object.keys(notificationTypes).filter(notifType => notificationTypes[notifType] === type)[0] || 'MENTION'

const isLastKeyLeenk = (notifType) => {
  const keysTypes = keysMessageType[notifType]
  if (!keysTypes) {
    return false
  }
  return keysTypes[keysTypes.length - 1] === keyType.LEENK
}

export const parseKeys = (message, keysReceived, notifType, privateConvLang) => {
  const keys = [...keysReceived]
  if (isLastKeyLeenk(notifType) && keys.length > 0 && isNull(keys[keys.length - 1])) {
    keys[keys.length - 1] = privateConvLang
  }
  const arr = []
  let actualStr = ''
  const pushActual = () => {
    if (actualStr.length > 0) {
      arr.push({ message: actualStr, type: 'message' })
      actualStr = ''
    }
  }
  const pushKey = () => {
    if (keys.length > 0) {
      arr.push({ message: keys[0], type: 'key', keyType: getKeyTypeByMessageType((keysReceived.length - keys.length) - 1, notifType) })
      keys.shift()
    }
  }
  for (let index = 0; index < message.length; index++) {
    const char = message[index];
    if (char === '$') {
      pushActual()
      while (message[index] && message[index] !== ' ') {
        index++
      }
      //index === ' ' || string over
      pushKey()
      index--
    } else {
      actualStr += char
    }

  }

  if (actualStr.length > 0)
    pushActual()
  return arr
}

export default {
  readAllNotifications,
  getUserById,
  getLeenk,
  getFolder,
  getMeeting,
  notificationTypes,
  getNotificationTypeReadable,
  parseKeys
}