import React, { useContext, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Link, useHistory } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemText, Zoom } from '@material-ui/core'
import { Store } from "../../store/store";
import { getFloors } from "./helpers";
import { getUser } from "../helpers";
import Logo from '../../images/logo.svg'
import AlertContainer from './AlertContainer'

const ListItemLink = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    '&:hover': {
      color: '#fff',
      backgroundColor: '#fff9'
    },
    '&.Mui-selected': {
      color: '#fff',
      backgroundColor: '#fff9',
    },
    // '&:nth-child(n+4) .MuiListItemText-primary': {
    //   borderRadius: '50%',
    //   backgroundColor: 'white'
    // },
    // '&:nth-child(n+4).Mui-selected .MuiListItemText-primary': {
    //   backgroundColor: '#0E75BD',
    //   color: '#fff',
    // },
    // '&:nth-child(n+4):hover .MuiListItemText-primary': {
    //   backgroundColor: '#0E75BD',
    //   color: '#fff',
    // },
  },
}))(props => <ListItem button {...props} />);

const ListItemLinkText = withStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    margin: 0,
  },
  primary: {
    width: 'max-content',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  }
}))(ListItemText);

const FloorList = () => {
  const history = useHistory();
  const [floors, setFloors] = useState([]);
  const { state } = useContext(Store);
  const {
    language: {
      menu: {
        management: managementLang,
        meetingRooms: meetingRoomsLang
      },
    },
  } = state;

  const user = getUser();

  const fetchFloors = () => getFloors().then(data => setFloors(data))
  useEffect(() => {
    fetchFloors()
  }, [])
  return (
    <Zoom in={history.location.pathname.split('/')[1] === 'spots'}>
      <List>
        <ListItemLink selected={history.location.pathname === '/spots/diretion'}
          onClick={() => history.push('/spots/direction')}>
          <ListItemLinkText primary={managementLang} />
        </ListItemLink>
        <ListItemLink selected={history.location.pathname === '/spots/meeting'}
          onClick={() => history.push('/spots/meeting')}>
          <ListItemLinkText primary={meetingRoomsLang} />
        </ListItemLink>
        {floors.map(({ id, position, name }) => (
          <ListItemLink selected={history.location.pathname === `/spots/${id}`}
            onClick={() => history.push(`/spots/${id}`)} key={id}>
            <ListItemLinkText primary={name} />
          </ListItemLink>
        ))}
      </List>
    </Zoom>
  )
}

const getItem = ({ label, href, separator, iconLeft, iconRight, className = '' }, index) => (
  < Link to={href} key={index} >
    <div className={`pointer white item-menu ${className}${href.split('/')[1] === window.location.pathname.split('/')[1] ? ' active' : ''}`}>
      {iconLeft}
      <span>{label}</span>
      {iconRight}
    </div>
    {separator ? <div className="mb-item separator-items white" />: null}
  </Link >
)

const MenuComponent = ({ menuItems = [] }) => (
  <Grid className="left-container-home max-height scroll-y scrollbar-disable" >
      <div className="logo-container bg-blue-light">
        <img style={{height: 116}} src={Logo} alt="" />
      </div>
    <Grid item className="padding-0 menu-container scroll-y scrollbar-disable">
      <div className="menu-items scroll-y scrollbar-disable">
        {menuItems.map(getItem)}
      </div>
      <FloorList />
      <AlertContainer />
    </Grid>
  </Grid>
);

export default MenuComponent
