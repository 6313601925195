import React, { useContext } from 'react';
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Store } from '../../../store/store';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    width: '100%',
  },
  title: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    padding: theme.spacing(2),
  },
}))

const LeadersFloor = () => {
  const classes = useStyles()
  const { state: { language: { spots: {
    management: managementLang
  } } } } = useContext(Store)
  return (
    <div className={classes.root}>
      <Typography classes={{ root: classes.title }} variant={'h2'}>{managementLang}</Typography>
    </div>
  );
};

export default LeadersFloor;
