import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Store } from '../../../store/store';
import { getPrivateLeenk, createPrivateLeenk, createPrivateDocx } from '../../leenks/Leenks/helpers';
import { createLeenkCollaborateNotification, getPrivateMeeting, createPrivateMeeting, createMeetingNotification } from './helpers';
import { dispatchAlert } from '../../menu/helpers';
const ORIENTATIONS = ['top', 'right', 'bottom', 'left'];

export const useSpotHooks = ({user, orientation, setOrientation}) => {
	const history = useHistory();
	const {
        dispatch,
        state: {
            alerts,
            language: {
                spots:language
            },
        },
    } = useContext(Store);
    const handleDirectMessage = () => {
        const { id } = JSON.parse(localStorage.getItem('profile'));
        const ids = [user.id, id];
        getPrivateLeenk(user.id)
            .then((response) => {
                if (Object.keys(response).length === 0)
                    createPrivateLeenk(ids).then((data) => {
                        createLeenkCollaborateNotification(user.id, data.id)
                            .then(() => history.push(`/leenk/${data.id}`))
                            .catch((e) => dispatchAlert(dispatch, alerts, { type: 'error', message: e.message }));
                    });
                else {
                    createLeenkCollaborateNotification(user.id, response.id)
                        .then(() => history.push(`/leenk/${response.id}`))
                        .catch((e) => dispatchAlert(dispatch, alerts, { type: 'error', message: e.message }));
                }
            })
            .catch((e) => dispatchAlert(dispatch, alerts, { type: 'error', message: e.message }));
    };
    
    const handleStartMeeting = async () => {
        //TES CENSÉ faire tout ça en back et juste renvoyer une url..
        const { id } = JSON.parse(localStorage.getItem('profile'));
        const ids = [user.id, id];

        try {
            const leenk = await createPrivateLeenk(ids, true)
            const meeting = await createPrivateMeeting(user.id)
            window.open(
                `/meet/${meeting.id}/folder?private=true&folderId=${leenk.parent}&leenkId=${leenk.id}`
            )
        } catch (error) {
            console.log('error :>> ', error);
            dispatchAlert(dispatch, alerts, { type: 'error', message: error.message })
        }
    };

    const handleStartDocx = () => {
        const { id } = JSON.parse(localStorage.getItem('profile'));
        const ids = [user.id, id];
        getPrivateLeenk(user.id).then((leenkResponse) => {
            if (Object.keys(leenkResponse).length === 0) {
                createPrivateLeenk(ids).then((leenkData) => {
                    createPrivateDocx(leenkData.parent, `${user.id}-${id}-${leenkData.parent}`, user.id).then(
                        (response) => {
                            history.push(`/docx/${response}`);
                        }
                    );
                });
            } else {
                createPrivateDocx(leenkResponse.parent, `${user.id}-${id}-${leenkResponse.parent}`, user.id).then(
                    (response) => {
                        history.push(`/docx/${response}`);
                    }
                );
            }
        });
    };

    const handleChangeOrientation = useCallback(() => {
        const currentOrientationIndex = ORIENTATIONS.findIndex((key) => key === orientation);
        setOrientation(
            currentOrientationIndex === ORIENTATIONS.length - 1
                ? ORIENTATIONS[0]
                : ORIENTATIONS[currentOrientationIndex + 1]
        );
    }, [orientation]);

	return {handleChangeOrientation,
		handleDirectMessage,
		handleStartMeeting,
		handleStartDocx,
	language};
};
