import React, {useEffect, useLayoutEffect, useRef, useState, useContext} from 'react';
import ListIcon from '@material-ui/icons/List'
import {
  InputAdornment,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  TextField,
  Typography
} from "@material-ui/core";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {addCheckbox, deleteCheckBox, getCheckBoxs, removeCheckList, updateCheckBox, updateCheckList} from "./helpers";
import DeleteIcon from '@material-ui/icons/Delete'
import {fetchApi} from "../helpers";
import { StyledButton, useStyles } from './style'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {Store} from '../../store/store'

const CheckBox = ({ message, id, checked, setCheckBoxs }) => {
  const inputRef = useRef(null);
  const [check, setCheck] = useState(checked)

  useLayoutEffect(() => {
    if (message)
      inputRef.current.value = message
  }, [message])

  const removeCheckBox = () =>
    deleteCheckBox(id).then(() =>
      setCheckBoxs(checkBoxs => checkBoxs.filter(e => e.id !== id))
    )

  return <ListItem>
    <ListItemIcon>
      <Checkbox
        edge="start"
        onChange={e => {
          updateCheckBox({checked: e.target.checked}, id)
          setCheck(e.target.checked)
        }}
        tabIndex={-1}
        disableRipple
        checked={check}
      />
    </ListItemIcon>
    <TextField inputRef={inputRef} onBlur={() => updateCheckBox({message: inputRef.current.value}, id)} />
    <ListItemSecondaryAction>
      <IconButton edge={'end'} onClick={removeCheckBox}>
        <DeleteIcon color={"error"}/>
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
}

const AddCheckBox = ({ checkListId, setCheckBoxs }) => {
  const [displayFields, setDisplayFields] = useState(false);
  const [checked, setChecked] = useState(false)
  const inputRef = useRef(null);
  const { state: {
    language: {
      tasks: {
        addCheckListElement: addCheckListElementLang,
      }
    }
  } } = useContext(Store)

  const save = () => {
    if (!inputRef.current.value)
      return ;
    addCheckbox({checked, message: inputRef.current.value}, checkListId)
      .then(data => {
        setCheckBoxs(checkBoxs => [...checkBoxs, {message: inputRef.current.value, checked, id: data.checkboxId}])
        inputRef.current.value = '';
        setChecked(false);
        setDisplayFields(false)
      })
  }

  return <ListItem>
    {displayFields
      ? (
      <>
        <ListItemIcon>
          <Checkbox
            edge="start"
            tabIndex={-1}
            disableRipple
            checked={checked}
            onChange={e => setChecked(e.target.checked)}
          />
        </ListItemIcon>
        <TextField inputRef={inputRef} />
        <ListItemSecondaryAction>
          <IconButton onClick={save}>
            <AddCircleOutlineOutlinedIcon />
          </IconButton>
          <IconButton variant={'outlined'} onClick={() => setDisplayFields(false)}>
            <CancelOutlinedIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </>
    ) : (
      <StyledButton onClick={() => setDisplayFields(true)}>
        {addCheckListElementLang}
      </StyledButton>
    )}
  </ListItem>
}


const CheckList = ({ checkList, setCheckList }) => {
  const classes = useStyles()
  const [checkBoxs, setCheckBoxs] = useState([]);
  const subjectRef = useRef(null);
  const { state: {
    language: {
      tasks: {
        taskChecklist: taskChecklistLang,
      }
    }
  } } = useContext(Store)

  useEffect(() => {
    if(checkList)
      getCheckBoxs(checkList.id).then(data => setCheckBoxs(data))
  }, [checkList])

  useLayoutEffect(() => {
    subjectRef.current.value = checkList.title
  }, [])

  const remove = () => removeCheckList(checkList.id).then(() => setCheckList(list => list.filter(e => e.id !== checkList.id) ))

  return (
    <div className={classes.column}>
      <div className={classes.row}>
        <Typography classes={{root: classes.title}}>
          <ListIcon />
          {taskChecklistLang}
        </Typography>
        <div>
          <TextField fullWidth 
            inputRef={subjectRef} 
            variant="outlined"
            onBlur={() => updateCheckList({title: subjectRef.current.value}, checkList.id)}
            style={{ marginBottom: 4 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={remove}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <List classes={{root: classes.list}}>
            {checkBoxs.map(({id, message, checked}) => (
              <CheckBox
                key={id}
                id={id}
                message={message}
                checked={checked}
                setCheckBoxs={setCheckBoxs}
              />
            ))}
            <AddCheckBox checkListId={checkList.id} setCheckBoxs={setCheckBoxs} />
          </List>
        </div>
      </div>
    </div>
  );
};

export default CheckList;
