import React, { useContext } from 'react';
import { Badge, makeStyles, Tooltip } from '@material-ui/core';
import { RemoveCircle as RemoveCircleIcon } from '@material-ui/icons';
import { Store } from '../../../../../store/store';

import styles from './Speaker.styles';
import UserAvatar from '../../../../../components/UserAvatar';
import { createPrivateLeenk } from '../../helpers';
import { dispatchAlert } from '../../../../menu/helpers';
import { getUser, userRoles } from '../../../../helpers';

const useStyles = makeStyles(styles);

const Speaker = ({
    firstname,
    lastname,
    icon,
    userId,
    removeUserFromLeenk,
    leenks,
    classes: receivedClasses,
    setCurrent,
    updateTypes,
    changeLeenk,
}) => {
    const classes = useStyles({ classes: receivedClasses });
    const user = getUser()
    const isMe = user.id === userId
    const isGuest = user.role === userRoles.GUEST
    const {
        state: { alerts },
        dispatch,
    } = useContext(Store);

    const startPrivateLeenk = async () => {
        try {
            const leenk = await createPrivateLeenk([Number(userId)])
            return changeLeenk(leenk);
        } catch (e) {
            console.log(e.message);
            dispatchAlert(dispatch, alerts, { type: 'error' });
        }
    };

    const {
        state: {
            language: {
                leenks: { removeUserFromLeenk: removeLang, discussWith: discussWithLang },
            },
        },
    } = useContext(Store);

    return (
        <div className={classes.root}>
            {isMe ? <UserAvatar firstName={firstname} lastName={lastname} {...{ icon }} /> :
                <Badge
                    overlap="circle"
                    {...(!isGuest ? {
                        badgeContent:
                            <Tooltip placement="top" title={removeLang}>
                                <button type="button" onClick={() => removeUserFromLeenk(userId)}>
                                    <RemoveCircleIcon style={{ height: '17px' }} classes={{ root: classes.removeIcon }} color="error" />
                                </button>
                            </Tooltip>
                    } : {})}
                >
                    <Tooltip placement="top" title={`${discussWithLang} ${firstname}`}>
                        <button type="button" onClick={startPrivateLeenk}>
                            <UserAvatar firstName={firstname} lastName={lastname} {...{ icon }} />
                        </button>
                    </Tooltip>
                </Badge>
            }
        </div>
    );
};

export default Speaker;
