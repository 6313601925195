import { fetchApi } from '../../helpers'

export const sendNewPassword = (token, email, password) => fetchApi(`/user/resetPassword/${token}/${email}`, {
  method: 'PUT',
  body: { password }
})

export default {
  sendNewPassword,
}
