import React, { useState } from 'react';
import { Fade, Paper, Popper, makeStyles, ClickAwayListener } from '@material-ui/core';

import styles from './PopperPaper.styles';

const useStyles = makeStyles(styles);

const PopperPaper = ({
    open,
    anchorEl,
    arrow = true,
    placement = 'bottom',
    onClose,
    classes: receivedClasses,
    children,
}) => {
    const classes = useStyles({ classes: receivedClasses });
    const [arrowElement, setArrowElement] = useState();
    return (
        <Popper
            {...{ open, anchorEl, placement }}
            disablePortal={false}
            className={classes.popper}
            modifiers={{
                flip: {
                    enabled: true,
                },
                arrow: {
                    enabled: arrow,
                    element: arrowElement,
                },
            }}
            transition
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <div>
                        <ClickAwayListener onClickAway={onClose}>
                            <Paper classes={{ root: classes.paper }} elevation={6}>
                                {arrow && <span className={classes.arrow} ref={setArrowElement} />}
                                {children}
                            </Paper>
                        </ClickAwayListener>
                    </div>
                </Fade>
            )}
        </Popper>
    );
};

export default PopperPaper;
