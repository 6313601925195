import React, { useCallback, useContext, useState } from 'react';
import { IconButton, Tooltip, makeStyles } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { Store } from '../../../../../store/store';

import styles from './AddOpenSpaceUser.styles';
import PopperPaper from '../../../../../components/PopperPaper';
import SearchUserField from '../../../../../components/SearchUserField';
const useStyles = makeStyles(styles);

const AddOpenSpaceUser = ({ withoutUsers, onAdd, classes: receivedClasses }) => {
    const classes = useStyles({ classes: receivedClasses });
    const [anchorElement, setAnchorElement] = useState();

    const open = Boolean(anchorElement);

    const handleIconButtonClick = useCallback(
        (event) => {
            if (open) {
                setAnchorElement(null);
                return;
            }
            setAnchorElement(event.currentTarget);
        },
        [open]
    );

    const closePopper = useCallback(() => setAnchorElement(null), []);

    const {
        state: {
            language: {
                leenks: { addUser: addUserLang },
            },
        },
    } = useContext(Store);

    return (
        <>
            <PopperPaper
                classes={{ paper: classes.popperPaper }}
                {...{ open }}
                anchorEl={anchorElement}
                onClose={closePopper}
            >
                <SearchUserField
                    {...{ withoutUsers }}
                    onChange={(event, addedUser) => {
                        onAdd(addedUser);
                        closePopper();
                    }}
                />
            </PopperPaper>
            <Tooltip placement="top" title={addUserLang}>
                <IconButton classes={{ root: classes.addIconButton }} onClick={handleIconButtonClick}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
        </>
    );
};

export default AddOpenSpaceUser;
