import React from 'react'
import { IconButton, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ChatIcon from '@mui/icons-material/Chat';
import { getPrivateLeenks } from '../leenks/Leenks/helpers';

const PrivateMessageLink = ({title = ''}) => {
	const history = useHistory()

	const navigate = async () => getPrivateLeenks()
		.then(leenks => history.push('/leenk' + (leenks.length > 0 ? `/${leenks[0].id}` : '')))
		.catch(e => console.log('Error during loading private leenks', e))
	return (
		<Tooltip placement="top" title={title}>
		<IconButton className="shadow notification privateMessageLinkButton"
			onClick={navigate}>
			<ChatIcon />
		</IconButton>
		</Tooltip>
	)
}

export default PrivateMessageLink
