import React, { memo } from 'react';
import { Box} from '@material-ui/core';

import { makeStyles } from '@material-ui/core';

import styles from './Files.styles';
import UpdateFile from './UpdateFile';
import FileButton from './FileButton';

const useStyles = makeStyles(styles);

const Files = memo(({ files, setFiles, handleClick }) => {
    const classes = useStyles();
    //mettre le document dans app.js et dispatch le blob ou le path
    return (
        <Box className={classes.root}>
            {files
                .sort(
                    (a, b) =>
                        new Date(b.updated_at || b.created_at).getTime() -
                        new Date(a.updated_at || a.created_at).getTime()
                )
                .map((file, index) => {
                    return (
                        <Box key={index} className={classes.item}>
                            <div style={{ display: 'flex' }}>
                                <FileButton file={file} handleClick={handleClick} styleContainer={{maxWidth: 67}} />
                                <UpdateFile file={file} setFiles={setFiles} />
                            </div>
                        </Box>
                    )
                })}
        </Box>
    );
});

export default Files;
