import React, { useRef, useContext } from 'react';
import { Input } from "@material-ui/core";
import { createSmtp } from "./helpers";
import Button from "@material-ui/core/Button";
import { Store } from '../../../store/store';
import { dispatchAlert } from '../../menu/helpers';

const Index = ({
  setIsConnect,
}) => {
  const smtpRef = useRef(null)
  const portRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const {
    state: {
      alerts,
      language: {
        form: {
          emptyFields: emptyFieldsLang,
          submit: submitLang
        }
      }
    }, dispatch } = useContext(Store)

  const connect = async () => {
    if (
      !!!smtpRef.current.value ||
      !!!portRef.current.value ||
      !!!emailRef.current.value ||
      !!!passwordRef.current.value
    )
      return dispatchAlert(dispatch, alerts, { type: 'error', message: emptyFieldsLang })
    try {
      await createSmtp(
        smtpRef.current.value,
        portRef.current.value,
        emailRef.current.value,
        passwordRef.current.value
      )
      setIsConnect()
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Input placeholder={"imap.gmail.com"} inputRef={smtpRef} />
      <Input placeholder={"port"} inputRef={portRef} defaultValue="993" type="number" />
      <Input placeholder={"email"} inputRef={emailRef} />
      <Input placeholder={'password'} type={'password'} inputRef={passwordRef} />
      <Button onClick={connect}>{submitLang}</Button>
    </div>
  );
};

export default Index;
