import React, { useContext, useState, useEffect } from 'react';
import { Store } from "../../../store/store";
import { Grid, List } from "@material-ui/core";
import {
  NotificationComponent
} from "./Notification";
import { useStyles } from './style'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@material-ui/core';
import useNotificationHooks from '../../header/useNotificationHooks';

const useStylesFormControl = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    position: 'absolute',
    right: 0,
    zIndex: 1000,
    color: 'black'
  }
}))
const Notification = () => {
  const classes = useStyles();
  const {
    state: { notifications: notificationsFromContext = [],
      language: { notifications: { readed: readedLang } } }
  } = useContext(Store);
  // useEffect(() => {
    //   //a virer et mettre que lorsque tu te log car les sockets sont censées alimenter 
    //   getNotifications(notifications, dispatch)
    //     .then(() => {
      //       // readAllNotifications().then(() => dispatch({ type: "READ_ALL_NOTIFICATIONS" }))
      //     })
      // }, [])
      const [notifications, setNotifications] = useState([])
      const classesFormControl = useStylesFormControl();
      const [all, setAll] = useState(false)
      const {
        initAllNotification,
        initNotification
      } = useNotificationHooks()
      
      const handleChange = async event => {
        event.target.checked ? setAll(true) : setAll(false)
        const ns = await (event.target.checked ? initAllNotification : initNotification)(true)
        setNotifications(ns)
      }
      
      useEffect(() => {
        if (!all){
          setNotifications(notificationsFromContext)
        } else{
          setNotifications(notifications => [...new Set([...notifications, ...notificationsFromContext].map(JSON.stringify))].map(JSON.parse))
        }
      }, [notificationsFromContext])
      
  return (
    <Grid container className="page-container border-radius-5 shadow bg-white">
      <FormControlLabel className={classesFormControl.root} control={<Switch
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />} label={readedLang} />
      <List className="scroll-y scrollbar-disable padding-top-40px" classes={{ root: classes.list }}>
        {notifications.sort((a, b)=> b.id-a.id).map((notification, i) => (
          <NotificationComponent
            tryBrowser={false}
            {...notification}
            key={i}
          />
        ))}
      </List>
    </Grid>
  );
};

export default Notification;
