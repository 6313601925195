import React, { useEffect } from 'react';

import { Box, Button, makeStyles } from '@material-ui/core';
import { KeyboardArrowRight as KeyboardArrowRightIcon } from '@material-ui/icons';
import { animated, config, useSpring } from 'react-spring';

import { useCallbackOpen } from '../../hooks/useCallbackOpen';
import HorizontalCollapse from '../HorizontalCollapse';

import styles from './HorizontallyCollapsableColumn.styles';

const useStyles = makeStyles(styles);

const HorizontallyCollapsableColumn = ({
    title = '?',
    open: receivedOpen = true,
    onChange,
    classes: receivedClasses,
    maxWidth = 250,
    children,
}) => {
    const classes = useStyles({ classes: receivedClasses, maxWidth });

    const [open, , , setOpen, toggleOpen] = useCallbackOpen(receivedOpen);

    useEffect(() => {
        if (typeof onChange !== 'function') {
            return;
        }
        onChange(open);
    }, [open]);

    useEffect(() => {
        setOpen(receivedOpen);
    }, [receivedOpen]);

    const { rotate } = useSpring({
        rotate: open ? -180 : -0,
        config: config.stiff,
    });

    return (
        <Box className={classes.root}>
            <Button classes={{ root: classes.titleButton, label: classes.titleLabel }} onClick={toggleOpen}>
                {title}
                <Box
                    component={animated.div}
                    className={classes.arrowIconContainer}
                    style={{
                        transform: rotate.interpolate((value) => `rotate(${value}deg)`),
                    }}
                >
                    <KeyboardArrowRightIcon />
                </Box>
            </Button>
            <HorizontalCollapse open={open}>
                <Box className={classes.content}>{children}</Box>
            </HorizontalCollapse>
        </Box>
    );
};

export default HorizontallyCollapsableColumn;
