import React from 'react'

const HomeComponent = ({
  // language: {
  //   invite: inviteLang,
  // },
}) => {
  return (
    <div className='page-container border-radius-5 shadow bg-white'>
    </div>
  )
}

export default HomeComponent
