import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isLoggedIn, isAccessible, parentsPaths } from '../containers/helpers'

export const AuthRoute = ({
  component: Component, props, exceptAccessibility, ...rest
}) => {
  if (!isLoggedIn()) {
    localStorage.setItem('path', JSON.stringify(rest.location))
  }
  return (
    <Route
      {...rest}
      render={routeProps =>
        isLoggedIn() && isAccessible(exceptAccessibility) ? (
          <Component {...props} {...routeProps} />
        ) : (
            <Redirect to={{ pathname: '/login' }} />
          )
      }
    />
  )
}

export const UnAuthRoute = ({
  component: Component, props, ...rest
}) => {
  return (
    <Route
      {...rest}
      render={routeProps =>
        !isLoggedIn() ? (
          <Component {...props} {...routeProps} />
        ) : (
            <Redirect to={{ pathname: parentsPaths.leenks }} />
          )
      }
    />
  )
}
