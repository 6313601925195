/* eslint-disable import/no-anonymous-default-export */
import formLanguage from './form.language'
import logInLanguage from './logIn.language'
import homeLanguage from './home.language'
import menuLanguage from './menu.language'
import profileLanguage from './profile.language'
import passwordLanguage from './password.language'
import confirmAccountLanguage from './confirmAcount.language'
import headerLanguage from './header.language'
import forgotPasswordLanguage from './forgotPassword.language'
import rightSideLanguage from './rightSide.language'
import titleLanguage from './title.language'
import supportLanguage from './support.language'
import leenkLanguage from './leenks.language'
import lockerLanguage from './locker.language'
import spotsLanguage from './spots.language'
import settingsLanguage from './settings.language'
import tasksLanguage from './tasks.language'
import mailsLanguage from './mails.language'
import notesLanguage from './notes.language'
import notificationsLanguage from './notifications.language'
import accessLanguage from './access.language'
import alertLanguage from './alert.language'
import generalLanguage from './general.language'
import meetingLanguage from './meeting.language'
import folderLanguage from './folder.language'
import docxLanguage from './docx.language'
import searchBarLanguage from './searchBar.language'
import calendarLanguage from './calendar.language'

export const getLanguage = (value = 'fr') => ({
  form: formLanguage[value],
  login: logInLanguage[value],
  home: homeLanguage[value],
  menu: menuLanguage[value],
  profile: profileLanguage[value],
  password: passwordLanguage[value],
  confirmAccount: confirmAccountLanguage[value],
  header: headerLanguage[value],
  forgotPassword: forgotPasswordLanguage[value],
  rightSide: rightSideLanguage[value],
  title: titleLanguage[value],
  support: supportLanguage[value],
  leenks: leenkLanguage[value],
  locker: lockerLanguage[value],
  spots: spotsLanguage[value],
  settings: settingsLanguage[value],
  tasks: tasksLanguage[value],
  mails: mailsLanguage[value],
  notes: notesLanguage[value],
  notifications: notificationsLanguage[value],
  access: accessLanguage[value],
  alert: alertLanguage[value],
  general: generalLanguage[value],
  meeting: meetingLanguage[value],
  folder: folderLanguage[value],
  docx: docxLanguage[value],
  searchBar: searchBarLanguage[value],
  calendar: calendarLanguage[value]
})

export default {
  fr: getLanguage(),
  us: getLanguage('us'),
}
