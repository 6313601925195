import React, { useEffect, useState, useRef, useContext } from 'react';
import { getSpaces, createSpace, updateFloor } from '../helpers';
import {
    Avatar,
    Box,
    Button,
    IconButton,
    Input,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { getUser, userRoles } from '../../../helpers';
import { Store } from '../../../../store/store';

import styles from './Settings.styles';

const useStyles = makeStyles(styles);

const SettingSpace = () => {
    const classes = useStyles();
    const [spaces, setSpaces] = useState([]);
    const spaceNameRef = useRef(null);
    // const spaceNumberRef = useRef(null);
    const user = getUser();
    const {
        state: {
            language: {
                spots: {
                    createSpace: createSpaceLang,
                    spaceName: spaceNameLang,
                    spaceNumber: spaceNumberLang,
                    position: positionLang,
                },
                form: { submit: submitLang },
            },
        },
    } = useContext(Store);
    useEffect(() => {
        getSpaces()
        .then(setSpaces);
    }, []);

    const addSpace = () => {
        createSpace(spaceNameRef.current.value/*, spaceNumberRef.current.value*/).then(() => {
            getSpaces().then((data) => {
                setSpaces(data);
                spaceNameRef.current.value = '';
                // spaceNumberRef.current.value = '';
            });
        });
    };

    const onSpaceUp = (id, index) => {
        updateFloor(id, index - 1).then(() => getSpaces().then((data) => setSpaces(data)));
    };

    const onSpaceDown = (id, index) => {
        updateFloor(id, index + 1).then(() => getSpaces().then((data) => setSpaces(data)));
    };

    const canAccess = user.role === userRoles.ADMIN || user.role === userRoles.SUPERADMIN;

    if (!canAccess) {
        return null;
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.createSpaceColumn}>
                <Typography variant="h5" classes={{ root: classes.createSpaceTypography }}>
                    {createSpaceLang}
                </Typography>
                <TextField
                    fullWidth
                    classes={{ root: classes.textField }}
                    variant="outlined"
                    label={spaceNameLang}
                    inputRef={spaceNameRef}
                />
                {/* <TextField
                    fullWidth
                    classes={{ root: classes.textField }}
                    variant="outlined"
                    label={spaceNumberLang}
                    inputRef={spaceNumberRef}
                /> */}
                <Button classes={{ root: classes.submitButton }} variant="outlined" onClick={addSpace}>
                    {submitLang}
                </Button>
            </Box>
            <Box className={classes.reorderSpacesColumn}>
                <List classes={{ root: classes.list }}>
                    {spaces.map((space, index) => (
                        <ListItem key={`space_${index}`}>
                            <ListItemAvatar>
                                <Avatar>{index}</Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                classes={{ root: classes.text }}
                                primary={
                                    <>
                                        <Typography component="div">{`${spaceNameLang} : ${space.title}`}</Typography>
                                        {/* <Typography component="div">
                                            {`${spaceNumberLang} : ${space.square}`}
                                        </Typography> */}
                                        <Typography component="div">{`${positionLang} : ${space.position}`}</Typography>
                                    </>
                                }
                            />
                            {index > 0 && (
                                <IconButton
                                    onClick={() => {
                                        onSpaceUp(space.folder_id, index);
                                    }}
                                >
                                    <ArrowUpwardIcon />
                                </IconButton>
                            )}
                            {index < spaces.length - 1 && (
                                <IconButton
                                    onClick={() => {
                                        onSpaceDown(space.folder_id, index);
                                    }}
                                >
                                    <ArrowDownwardIcon />
                                </IconButton>
                            )}
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Box>
    );
};

export default SettingSpace;
