import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Accordion as BaseAccordion,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ButtonBase,
} from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import RemoveIcon from '@material-ui/icons/Remove';
import { Store } from '../store/store';
import cn from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: '#f1f2f7',
        color: '#1773C8',
        boxShadow: 'none',
    },
    expandIcon: {
        color: 'inherit',
    },
    details: {
        flexDirection: 'column',
        padding: 0,
    },
    expanded: {
        backgroundColor: '#1773C8',
        color: '#fff',
    },
    second: {
        backgroundColor: 'inherit',
        color: 'inherit',
        boxShadow: 'none',
        borderBottom: '1px solid #fff3',
        '&.Mui-expanded': {
            margin: 0,
        },
    },
    secondExpand: {
        backgroundColor: '#459ce8',
    },
    secondDetails: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
    },
    heading: {
        borderBottom: '1px solid #fff3',
    },
    headingContent: {
        alignItems: 'center',
    },
    headingTypo: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
        marginLeft: theme.typography.pxToRem(8),
    },
    nested: {
        paddingLeft: theme.spacing(4),
        '&.Mui-selected': {
            backgroundColor: '#6db4f2',
        },
    },
    button:{
        padding: 5,
        '&:hover':{
            backgroundColor: '#6db4f2',
        }
    },
    selected : {
        backgroundColor: '#6db4f2',
    }
}));

const ListFolderLeenks = ({ id, navigateToFolder, selected, paths, folderId, leenks, accordion, getExpended }) => {
    const classes = useStyles();
    const { dispatch } = useContext(Store);

    const handleListItemClick = (e, index, folderId) => {
        e.stopPropagation();
        dispatch({ type: "UPDATE_FOLDER", payload: { folderId, folderType: 'public', parentType: 'folder' } })
        getExpended(folderId, [])
        navigateToFolder(folderId);
    };

    const getSelected = (leenkId) => {
        return leenkId === accordion.leenk
    };

    return leenks.map(({ name, id, folder }, key) => (
        <ListItem
            key={key}
            button
            selected={getSelected(id)}
            onClick={(e) => handleListItemClick(e, key, folder)}
            className={classes.nested}
        >
            <ListItemIcon style={{ color: 'inherit' }}>
                <RemoveIcon />
            </ListItemIcon>
            <ListItemText primary={name} />
        </ListItem>
    ));
};

const Accordion = ({ list, title, icon, company: id, navigateToFolder, accordion, selected = false, paths, folderId: folderIdSelected, getExpended }) => {
    const classes = useStyles();

    const handleChildClick = (e, folderId) => {
        e.stopPropagation();
        return getExpended(folderId, [], 'team')
    };

    const handleOnClick = (select) => {
        return getExpended(id, [], 'company')
    };

    return (
        <BaseAccordion
            classes={{ root: classes.root, expanded: classes.expanded }}
            expanded={selected}
            onClick={() => handleOnClick(selected)}
        >
            <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{
                    root: classes.heading,
                    content: classes.headingContent,
                    expandIcon: classes.expandIcon,
                }}
            >
                {icon}
                <Typography className={classes.headingTypo}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.details }}>
                {list.map(({ name, id, folderId, leenks = null }, key) => (
                    leenks && leenks.length > 0 ? <BaseAccordion
                        expanded={accordion.team === folderId}
                        onClick={(e) => {
                            handleChildClick(e, folderId);
                        }}
                        key={key}
                        square
                        classes={{ root: classes.second, expanded: classes.secondExpand }}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1a-content"
                            id={`panel1a-header`+id}
                            classes={{ expandIcon: classes.expandIcon }}
                        >
                            <Typography className={classes.headingTypo}>{name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.secondDetails}>
                            <List component="nav" aria-label="main leenks folders">
                                <ListFolderLeenks
                                    folderId={folderId}
                                    id={id}
                                    navigateToFolder={navigateToFolder}
                                    selected={selected}
                                    paths={paths}
                                    leenks={leenks}
                                    accordion={accordion}
                                    getExpended={getExpended}
                                />
                            </List>
                        </AccordionDetails>
                    </BaseAccordion> : <ButtonBase
                    className={cn(
                        classes.heading,
                        classes.headingContent,
                        classes.expandIcon,
                        classes.button, 
                        folderId === folderIdSelected && classes.selected)}
                        key={'folder' + id}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigateToFolder(folderId);
                        }}
                    >
                            {name}
                        </ButtonBase>
                ))}
            </AccordionDetails>
        </BaseAccordion>
    );
};

export default Accordion;
