import React from 'react';
import { Editor } from "react-draft-wysiwyg";
import { IconButton, Grid, makeStyles } from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
// import { EditorState } from 'draft-js';

const useStyles = makeStyles(theme => ({
	save: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
		backgroundColor: '#1773C8',
		zIndex: 10,
		color: 'white',
		'&:hover': {
			color: 'black'
		}
	},
	update: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(10),
		backgroundColor: '#105000',
		zIndex: 10,
		color: 'white',
		'&:hover': {
			color: 'black'
		}
	}
}))

const editorStyles = {
	wrapper: {
		height: '100%',
	},
	toolbar: {
		border: 'none',
		borderBottom: '1px solid #F4F5F5',
		borderRadius: 0,
		margin: 0,
	},
	editor: {
		padding: 8,
		color: 'black',
		boxSizing: 'border-box',
		overflowY: 'scroll',
		height: '100%'
	}
}

const Docx = ({
	onChange,
	saveFile,
	content,
	onBlur,
}) => {
	const classes = useStyles()
	return (
		<Grid container item xs={11} direction="column"
			style={{ position: 'relative', border: 'none', height: '100%' }}
			className="leenks-section">
			<IconButton classes={{ root: classes.save }} onClick={saveFile}>
				<SaveIcon fontSize="large" />
			</IconButton>
			{/* <IconButton classes={{ root: classes.update }} onClick={onBlur}>
				<SaveIcon fontSize="large" />
			</IconButton> */}
			<Editor
				editorState={content}
				onEditorStateChange={onChange}
				toolbarStyle={editorStyles.toolbar}
				editorStyle={editorStyles.editor}
				wrapperStyle={editorStyles.wrapper}
				onBlur={onBlur}
			/>
		</Grid>
	);
};

export default Docx;
