import {fetchApi} from "../../helpers";

export const createSmtp = (smtp, port, email, password) => fetchApi('/smtp', {
  method: 'POST',
  body: {
    smtp,
    port,
    email,
    password
  }
})

export const checkConnect = () => fetchApi('/smtp/user')

export default {
  createSmtp,
  checkConnect
}