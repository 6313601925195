import React, { useRef } from 'react';
import { InputBase } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

const SearchBar = ({ list = [{ valueToCompare: 'Hello dear' }], setList, parentStyle = {} }) => {
    const inputRef = useRef(null);
    const onChange = () => {
        const { value } = inputRef.current;
        const prepareString = (str) => (str || '').toLowerCase().replace(/[\n\r\t\s]+/g, '')
        const matchWithValue = ({ valueToCompare }) => prepareString(valueToCompare).includes(prepareString(value));
        setList(list.filter(matchWithValue).map(({ valueToCompare, ...element }) => ({ ...element })));
    };
    return (
        <div style={parentStyle}>
            <InputBase
                startAdornment={<SearchIcon fontSize="small" />}
                className="bg-white shadow border-radius-5 header-search-bar"
                inputRef={inputRef}
                onChange={onChange}
            />
        </div>
    );
};

export default SearchBar;
