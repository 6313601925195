export default ({ spacing }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    content: {
        maxWidth: ({ maxWidth }) => maxWidth,
        width: 'max-content',
        height: '100%',
    },
    titleButton: {
        margin: spacing(1),
        padding: spacing(2),
        justifyContent: 'space-between',
    },
    titleLabel: {
        textTransform: 'none',
        fontSize: 16,
    },
    arrowIconContainer: {
        display: 'flex',
        transformOrigin: 'center',
        marginLeft: 8 * 2,
    },
});
