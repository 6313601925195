import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const useFileActions = () => {
	const history = useHistory()
	const downloadFromServer = async (id) =>
	history.push({
		pathname: window.location.pathname,
		search: '?fileId=' + id
	})
const handleClick = useCallback(
	({ name, id, docxid }) => () => {
		if (docxid) {
			return history.push(`/docx/${docxid}`);
		}
		return downloadFromServer(id, name);
	},
	[]
);
	
	return {
		downloadFromServer,
		handleClick
	}
};

export default useFileActions;