import { fetchApi } from '../../helpers'

export const confirmAccount = token => fetchApi(`/user/verify/${token}`,
  {
    method: 'PUT',
  })

export const sendConfirmationMail = ({email}) => fetchApi(`/user/sendConfirmationEmail/${email}`)

export default {
  confirmAccount,
  sendConfirmationMail,
}
