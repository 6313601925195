import React, { useContext, useState } from 'react';
import { IconButton, Popover, makeStyles, ListItem, ListItemText } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { getUser } from '../containers/helpers';
import moment from 'moment';
import { Store } from '../store/store';
import { createTask, createTaskAccess } from '../containers/task/helpers';
import 'moment/locale/fr';
import { upperFirst } from 'lodash';
import { dispatchAlert } from '../containers/menu/helpers';
import UserAvatar from './UserAvatar';
import FormattedMessage from './FormattedMessage';

const useStyles = makeStyles((theme) => ({
    buttonRight: {
        position: 'absolute',
        top: 0,
        right: -32,
    },
    buttonLeft: {
        position: 'absolute',
        top: 0,
        left: -32,
    },
    typography: {
        padding: theme.spacing(2),
        '&:not(:last-of-type)': {
            borderBottom: '1px solid lightgrey',
        },
        cursor: 'pointer',
    },
}));

const MessageComponent = ({ speaker, from, message, created_at, fileId, navigateToTask, createLeenk, parentType, shared, type , file}) => {
    const classes = useStyles();
    const user = getUser();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const {
        dispatch,
        state: {
            alerts,
            languageValue,
            language: {
                leenks: { transformOnTask: transformOnTaskLang, affectTask: affectTaskLang, startConv: startConvLang, taskHaveToHaveTitle:taskHaveToHaveTitleLang, newLinkHaveToHaveTitle:newLinkHaveToHaveTitleLang },
            },
        },
    } = useContext(Store);

    moment.locale(languageValue === 'fr' ? 'fr' : 'en');

    const createNewTask = async () => {
        try {
            if (!message){
                throw new Error(taskHaveToHaveTitleLang)
            }
            const taskId = await createTask(message);
            setAnchorEl(null);
            dispatchAlert(dispatch, alerts, { type: 'success' });
            return taskId;
        } catch (e) {
            dispatchAlert(dispatch, alerts, { type: 'error', message: e.message });
            return false
        }
    };

    const affectNewTask = async (userId) => {
        try {
            const taskId = await createNewTask();
            if (!taskId){
                return
            }
            if (Number(user.id) !== Number(userId)) await createTaskAccess(taskId, userId);
            dispatchAlert(dispatch, alerts, { type: 'success' });
            !!navigateToTask && navigateToTask(taskId);
        } catch (e) {
            dispatchAlert(dispatch, alerts, { type: 'error', message: e.message });
        }
    };

    const createNewLeenk = async () => {
        try{
            if (!message){
                throw new Error(newLinkHaveToHaveTitleLang)
            }
            await createLeenk(speaker.id, message)
            setAnchorEl(false)
        }catch (e) {
            dispatchAlert(dispatch, alerts, { type: 'error', message: e.message });
        }
    };

    const getUserReadable = (initiales = false) =>
        speaker
            ? initiales
                ? `${speaker.firstname.charAt(0)} ${speaker.lastname.charAt(0)}`
                : `${upperFirst(speaker.firstname)} ${upperFirst(speaker.lastname)}`
            : '';

    const isMe = (speaker || {}).id === (user || {}).id
    return (
        <div className={`message-bubble message-bubble-${user.id === from ? 'right' : 'left'}`}>
            <div className="message-bubble-avatar">
                <UserAvatar {...speaker} />
                <div className="color-orange bold">{getUserReadable()}</div>
            </div>
            <div className="message-bubble-date">{moment(created_at).format('Do MMMM YYYY, H:mm:ss')}</div>
            <IconButton
                aria-describedby={id}
                classes={{ root: user.id === from ? classes.buttonLeft : classes.buttonRight }}
                onClick={(e) => setAnchorEl(e.currentTarget)}
            >
                <MoreVertIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: user.id === from ? 'left' : 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: user.id === from ? 'right' : 'left',
                }}
            >
                <ListItem button onClick={createNewTask}>
                    <ListItemText primary={transformOnTaskLang} />
                </ListItem>
                <ListItem button onClick={() => affectNewTask(from)}>
                    <ListItemText primary={affectTaskLang} />
                </ListItem>
                {parentType !== 'FOLDER' && parentType !== 'MEETING' /*&& !shared */&& !isMe && (
                    <ListItem button onClick={createNewLeenk}>
                        <ListItemText primary={startConvLang} />
                    </ListItem>
                )}
            </Popover>
            <FormattedMessage message={message} fileId={fileId} type={type} file={file} />
        </div>
    );
};

export default MessageComponent;
