import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography, Tooltip } from '@material-ui/core';
import {
    getCollaborators,
    setSeat,

} from '../containers/spots/Spots/helpers';
import { useSpotHooks } from '../containers/spots/Spots/useSpotsHooks';
import { Store } from '../store/store';
import cn from 'classnames';
import UserAvatar from './UserAvatar';
import { getUser } from '../containers/helpers';

import { ReactComponent as TocIcon } from '../images/svg/toc.svg';
import { upperFirst } from 'lodash';


const useStyles = makeStyles((theme) => ({
    seat: {
        width: '100%',
        height: '100%',
        display: 'flex',
    },
    top: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& $table': {
            marginTop: '-20%',
            height: '50%',
            width: '90%',
        },
    },
    right: {
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'center',
        '& $table': {
            height: '90%',
            width: '35%',
        },
        '& $avatar': {
            transform: 'rotate(90deg)',
            marginLeft: '-20%',
        },
    },
    bottom: {
        flexDirection: 'column-reverse',
        alignItems: 'center',
        justifyContent: 'center',
        '& $table': {
            height: '50%',
            width: '90%',
        },
        '& $avatar': {
            transform: 'rotate(180deg)',
            marginTop: '-20%',
        },
    },
    left: {
        alignItems: 'center',
        justifyContent: 'center',
        '& $table': {
            height: '90%',
            width: '35%',
            marginLeft: '-20%',
        },
        '& $avatar': {
            transform: 'rotate(270deg)',
        },
    },
    table: {
        borderRadius: 50,
        backgroundColor: '#CED3D7',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatar: {
        zIndex: 1,
        height: 80,
        width: 80,
    },
    spot: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        gridColumnEnd: 'span 1',
        border: '1px solid transparent',
        borderRadius: 5,
        '&:hover': {
            borderColor: '#CED3D7',
        },
        '&:hover>div': {
            display: 'flex',
        },
    },
    spotPopover: {
        zIndex: 2,
        position: 'absolute',
        display: 'none',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#00f9',
        padding: 0,
        margin: 0,
        width: '100%',
        height: '100%',
        borderRadius: 5,
        '&>*:not(:last-child)': {
            borderBottom: '1px solid #fff',
        },
    },
    spotPopoverText: {
        flex: '1',
        width: '100%',
        height: '100%',
        fontWeight: theme.typography.fontWeightBold,
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    spotPopoverAction: {
        flex: '1',
        width: '100%',
        height: '100%',
        borderRadius: 0,
    },
    spotPopoverActionLabel: {
        textTransform: 'initial',
        color: '#fff',
    },
    tocIcon: {
        height: 40,
        width: 'auto',
    },
}));

const SpotPopoverText = ({ children }) => {
    const classes = useStyles();

    return <Typography className={classes.spotPopoverText}>{children}</Typography>;
};

const SpotPopoverAction = ({ children, onClick }) => {
    const classes = useStyles();

    return (
        <Button classes={{ root: classes.spotPopoverAction, label: classes.spotPopoverActionLabel }} onClick={onClick}>
            {children}
        </Button>
    );
};

const SpotPopover = ({ children, color }) => {
    const classes = useStyles();

    return (
        <div className={classes.spotPopover} style={{ backgroundColor: color, opacity: 0.5 }}>
            {children}
        </div>
    );
};

const Seat = ({ user, orientation }) => {
    const classes = useStyles();

    return (
        <Box className={cn(classes.seat, classes[orientation])}>
            <UserAvatar
                classes={{ root: classes.avatar }}
                firstName={user.firstname}
                lastName={user.lastname}
                icon={user.icon}
            />
            <Box className={classes.table} style={user.connected ? { background: '#cbff8e' } : {}} />
        </Box>
    );
};

const EmptySeat = ({ updateUserSeat }) => {
    const {
        state: {
            language: {
                spots: { spotSettle: spotSettleLang },
            },
        },
    } = useContext(Store);

    return (
        <SpotPopover color="blue">
            <SpotPopoverAction onClick={updateUserSeat}>{spotSettleLang}</SpotPopoverAction>
        </SpotPopover>
    );
};

const AvailabilitySeat = ({ user, orientation, setOrientation }) => {
    const classes = useStyles();
    const { language: {
        changeOrientation: changeOrientationLang,
        directMessage: directMessageLang,
        startMeeting: startMeetingLang,
        startDocx: startDocxLang,
        //false for the moment
        toctocSent:toctocSentLang
    },
        handleChangeOrientation,
        handleDirectMessage,
        handleStartMeeting,
        handleStartDocx,

    } = useSpotHooks({
        user,
        orientation,
        setOrientation
    })
    const currentUser = getUser();

    const isCurrentUser = currentUser.id === user.id;

    if (isCurrentUser) {
        return (
            <SpotPopover color="green">
                <SpotPopoverAction onClick={handleChangeOrientation}>{changeOrientationLang}</SpotPopoverAction>
            </SpotPopover>
        );
    }

    const { connected } = user;

    if (!connected) {
        return (
            <SpotPopover color="red">
                <SpotPopoverAction onClick={() => alert(toctocSentLang)}>
                    <TocIcon className={classes.tocIcon} />
                </SpotPopoverAction>
            </SpotPopover>
        );
    }

    return (
        <SpotPopover color="green">
            <SpotPopoverAction onClick={handleDirectMessage}>{directMessageLang}</SpotPopoverAction>
            <SpotPopoverAction onClick={handleStartMeeting}>{startMeetingLang}</SpotPopoverAction>
            <SpotPopoverAction onClick={handleStartDocx}>{startDocxLang}</SpotPopoverAction>
        </SpotPopover>
    );
};

const Spot = ({ users, index, spaceId, setUsers }) => {
    const classes = useStyles();
    // TODO Store orientation for each user (it's here to show how it works).
    const [orientation, setOrientation] = useState('top');

    const user = users.find((u) => u.position === index);

    const updateUserSeat = () =>
        setSeat(spaceId, index).then(() => getCollaborators(spaceId).then((data) => setUsers(data)));
    const renderElement = () => <Box className={classes.spot}>
        {user && <Seat {...{ user, orientation }} />}
        {user ? (
            <AvailabilitySeat user={user} {...{ orientation, setOrientation }} />
        ) : (
                <EmptySeat updateUserSeat={updateUserSeat} />
            )}
    </Box>
    return (
        user ? <Tooltip title={`${upperFirst(user.firstname)} ${upperFirst(user.lastname)}`}>
            {renderElement()}
        </Tooltip> : renderElement()
    );
};

export default Spot;
