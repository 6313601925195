import React, { useContext } from 'react';
import { Store } from '../../store/store';
import { getAllNotifications, isUniqueNotification, readNotificationAPI, getAllNotificationsWithReaded } from './helpers';

const useNotificationHooks = () => {
	const { dispatch, state: { notifications, language } } = useContext(Store)

	const removePersistantFromNotification = id => 
		dispatch({
		type: 'REMOVE_PERSISTANT_FROM_NOTIFICATION',
		payload: id
	})

	const receiveNotification = notification => {
		dispatch({
			type: 'ADD_NOTIFICATION',
			payload: {...notification, persistant: true, read: false},
		})
	}

	const initAllNotification = async (noDispatching = false) => {
		const notifications = await getAllNotificationsWithReaded()
		if (noDispatching){
			return notifications
		}
			return	dispatch({
					type: 'INIT_NOTIFICATIONS',
					payload: notifications
					.map(n => ({...n, persistant: false}))
					.filter(isUniqueNotification),
				})
	}

	const initNotification = async (noDispatching = false) => {
		const notifications = await getAllNotifications()
		if (noDispatching){
			return notifications
		}
			return dispatch({
					type: 'INIT_NOTIFICATIONS',
					payload: notifications
					.map(n => ({...n, persistant: false}))
					.filter(isUniqueNotification),
				})
	}

	const readNotification = id =>{
	readNotificationAPI(id)
	.then(() => dispatch({
		type: 'REMOVE_NOTIFICATION',
		payload: id
	}))
	 }
	return {
		initNotification,
		initAllNotification,
		receiveNotification,
		readNotification,
		removePersistantFromNotification,
		notifications,
		language
	};
};

export default useNotificationHooks;