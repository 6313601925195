export default {
    jutsu: {
        flex: 1,
    },
    messengerLocker: {
        display: 'flex',
        flexDirection: 'column',
        width: 500,
        height: '100%',
    },
    horizontallyCollapsableColumn: {
        borderLeft: '1px solid #efefef',
    },
};
