import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import RenderFolders from './RenderFolders';
import { getSpecificFolders } from './helpers';
import { useParams } from 'react-router-dom';
import { getParamsFromUrl } from '../../helpers';
const RenderMeeting = ({ history, match, location, parentFolderId }) => {
    const meetingId = Number(useParams().meetId);
    const navigateToFolder = (id) => {
        history.push(`/meet/${meetingId}/folder/${id}`);
    };
    const params = getParamsFromUrl(location.search)
    const [folders, setFolders] = useState([]);
    const getFolderId = (search ={}, params={}) => 
    params.folderId || search.folderId
    const folderId = getFolderId(params, useParams())// params.folderId;
    const [currentFolderId, setCurrentFolderId] = useState(folderId);
    const [files, setFiles] = useState([]);
    const [path, setPath] = useState();

    useEffect(() => {
        setCurrentFolderId(parentFolderId);
    }, [meetingId]);

    useEffect(() => {
        if (currentFolderId){
            getSpecificFolders(currentFolderId)
                .then((data) => {
                    setPath(data.path);
                    setFiles(data.files);
                    setFolders(data.folders);
                })
                .catch((err) => {
                    console.log('first use effect err :>> ', err);
                    history.push(`/spots/0`);
                });
        }
    }, [currentFolderId]);

    useEffect(() => {
        if (folderId)
            getSpecificFolders(folderId)
                .then((data) => {
                    setPath(data.path);
                    setFiles(data.files);
                    setFolders(data.folders);
                    setCurrentFolderId(folderId);
                })
                .catch((err) => {
                    console.log('second use effect err :>> ', err);
                    history.push(`/spots/0`);
                });
        else {
            setCurrentFolderId(parentFolderId);
        }
    }, [folderId]);

    return (
        <Grid container className="page-container border-radius-5 bg-white">
            <RenderFolders
                folders={folders}
                files={files}
                folderId={currentFolderId}
                navigateToFolder={navigateToFolder}
                setFiles={setFiles}
                setFolders={setFolders}
                parent={path ? path.paths : null}
                meeting={true}
            />
        </Grid>
    );
};

export default RenderMeeting;
