export default (theme) => ({
    root: {
        display: 'flex',
        height: '100%',
        width: '100%',
    },
    content: {
        flex: 1,
    },
    horizontallyCollapsableColumn: {
        borderRight: '1px solid #efefef',
    },
    list: {},
    listItem: {
        position: 'relative',
        color: 'rgba(0, 0, 0, .87)',
    },
    unseenListItem: {
        '& $listItemTextPrimary, & $listItemTextSecondary': {
            fontWeight: 500,
        },
    },
    unseenDot: {
        height: 10,
        width: 10,
        position: 'absolute',
        top: theme.spacing(1),
        left: theme.spacing(1),
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
    },
    listItemTextPrimary: {
        marginBottom: theme.spacing(1),
    },
    listItemTextSecondary: {},
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: theme.spacing(3),
    },
    mailInfos: {
        display: 'flex',
        flexDirection: 'column',
        '&>*': {
            textAlign: 'left',
        },
    },
    mailBody: {
        height: '100%',
        color: '#45535C',
        padding: theme.spacing(3),
        boxSizing: 'border-box',
        '& div': {
            textAlign: 'left',
        },
    },
    headerInfos: {
        display: 'flex',
        alignItems: 'center',
    },
});
