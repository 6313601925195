import React, { useContext } from 'react';
import { Store } from '../../store/store';
import Alert from '../../components/Alert';
const AlertContainer = () => {
    const {
        state: { alerts = [] },
    } = useContext(Store);
    return (
        <div style={{ position: 'absolute', bottom: 10, left: 0 }}>
            {alerts.length > 0 &&
                alerts.map((alert, key) =>
                 <Alert {...alert} key={key} />
                 )}
        </div>
    );
};

export default AlertContainer;
