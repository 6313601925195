import React, { useContext } from "react";
import { IconButton, makeStyles, Popover, Typography, Button, CircularProgress } from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import { Store } from "../../../store/store";
import useFileUpload from "../../../components/useFileUpload";
import { CloudUpload as CloudUploadIcon } from '@material-ui/icons';
import FileButton from "../../locker/Locker/Files/FileButton";
import { getFileExtension } from "../../locker/Locker/helpers";

import { getFileTypeFromExtension } from '../../helpers'

const useStyles = makeStyles((theme) => ({
  inputUpload: {
    display: 'none',
  },
  iconButton: {
    padding: 0
  },
  popover: {
    padding: `${theme.spacing()}px ${theme.spacing() * 2}px`
  },
  icon: {
    color: 'rgba(0, 0, 0, .6)',
    marginRight: theme.spacing(1),
  },
  progress: {
    marginRight: theme.spacing(2),
  },
}))

const File = ({
  navigateToFolder,
  setFile,
  setFd,
  file
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { state: { language: { leenks: {
    fileButton: {
      join: joinLang,
      extern: externLang,
      collabore: collaboreLang,
      text: textLang,
      csv: csvLang,
    },
  }, folder: {
    file: {
      tooBig: tooBigLang,
      uploadFile: uploadFileLang,
      uploading: uploadingLang,
    },
  } } } } = useContext(Store)
  const {
    isUploading,
  } = useFileUpload()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleFileChange = ({ target: { files } }) => {
    if (files[0].size > 31457280) return alert(tooBigLang);
    const fd = new FormData();
    fd.append('vocal', files[0]);
    setFd(fd)
    setFile({
      src: window.URL.createObjectURL(new Blob(files)),
      type: getFileTypeFromExtension(getFileExtension(files[0].name)),
      name: files[0].name,
    })
    handleClose()
  }

  return <div>
    {file && <FileButton file={file} type={file.type} noName src={file.src}  size="imgLittle" />}
    <input
      type="file"
      id="upload-file-messenger"
      style={{ display: 'none' }}
      accept="text/csv,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/png,image/jpeg,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      onChange={handleFileChange}
    />
    {/*<input accept="image/*" className={classes.inputUpload} id="icon-button-file" type="file" onChange={handleChange}/>*/}
    <IconButton aria-describedby={id} onClick={handleClick} classes={{ root: classes.iconButton }}>
      {/*<IconButton classes={{ root: classes.iconButton }} aria-label="upload file" component="span">*/}
      <AddCircleOutlineOutlinedIcon />
      {/*</IconButton>*/}
    </IconButton>
    <Popover
      open={open}
      id={id}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <div
        className={classes.popover}
      >
        <Typography>
          {joinLang} :
      </Typography>
        <label htmlFor="upload-file-messenger">
          <Button component="span">
            {isUploading ? (
              <CircularProgress classes={{ root: classes.progress }} size={16} />
            ) : (
                <CloudUploadIcon classes={{ root: classes.icon }} />
              )}
            {isUploading ? uploadingLang : uploadFileLang}{' ' + externLang}
          </Button>
        </label>
        <br />
        <Button onClick={navigateToFolder}>
          Locker
      </Button>
        <hr />
        <Typography>
          {collaboreLang} :
      </Typography>
        <Button onClick={navigateToFolder}>
          {textLang}
        </Button>
        <br />
        <Button onClick={navigateToFolder}>
          {csvLang}
        </Button>
      </div>
    </Popover>
  </div>
}

export default File
