import React, { useState } from 'react'
import {
  Form, Input, Label,
} from 'semantic-ui-react'
import ImageInput from './ImageInput'
import InfoInput from './InfoInput'

const InputEmail = ({
  onKeyPress = () => { },
  type,
  name,
  onChange,
  value,
  initialValue,
  error,
  labelObject,
  styles = { container: '' },
  placeholder,
  required,
  silentRequired = false,
  toCheck = false,
  disabled,
  options = [],
  maxLength = null,
  fluid = false,
  legend = null,
  inline = true,
  responsive = true,
  style = {},
  labelStyle = {},
  centered = false,
  info = null,
  label,
  handleEffect,
  requiredLang,
  fileLang,
  fileErrorLang,
  onBlur = null,
  forwardRef = null,
  children,
  onFocus = () => {},
}) => {
  const [email, setEmail] = useState('')

  const handleInputEmail = event => {
    setEmail(event.target.value)
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)) {
      handleEffect(event, { value: event.target.value })
    } else {
      handleEffect({ target: { value: '' } }, { value: '' })
    }
  }
  return (
    <Form.Field
      required={required}
      className={styles.container}
      style={responsive ? centered ? { display: 'flex', justifyContent: 'center' } : { display: 'flex' } : {}}
      inline={inline} >
      {label ?
        <label style={labelStyle} htmlFor={name + (initialValue ? '-' + initialValue : '')}>
          {label}
        </label> : null
      }
      {type === 'image' ? <ImageInput
        id={name + (initialValue ? '-' + initialValue : '')}
        name={name}
        onChange={handleEffect}
        // onBlur={handleEffect}
        fileLang={fileLang}
        fileErrorLang={fileErrorLang}
      /> :
      <Input
        {...(forwardRef ? { ref: forwardRef } : {})}
        onKeyPress={onKeyPress}
        id={name + (initialValue ? '-' + initialValue : '')}
        name={name}
        type={type}
        value={ email}
        placeholder={placeholder}
        onChange={handleInputEmail }
        onBlur={ handleInputEmail }
        fluid={fluid}
        style={style}
        onFocus={handleInputEmail}

        {...(maxLength ? { maxLength } : {})}
      />
      }
      {legend ? <div><p className="legend-input">{legend}</p></div> : null}
      {info ? <InfoInput info={info} /> : null}
      {children}
      {required && error && !silentRequired ?
				<Label style={{
				  display:      'inline-table',
				  marginTop:    'auto',
				  marginBottom: 'auto',
				}} pointing={'left'} color={'red'} basic >
				  {requiredLang}
				</Label> : null
      }
    </Form.Field>
  )
}

export default InputEmail
