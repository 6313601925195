import { makeStyles, withStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  section: {
    height: 'calc(100% / 3)',
    width: '100%',
    padding: theme.spacing(1),
  },
  userTitle: {
    color: '#45535C',
    textAlign: 'left',
    padding: theme.spacing(2),
  },
  title: {
    color: '#45535C',
    textAlign: 'left',
  },
  h3: {
    fontSize: theme.typography.pxToRem(18),
  },
  h4: {
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(1),
  },
  sectionContainer: {
    height: '100%',
  },
  dataGrid: {
    color: '#45535C',
    height: 300
  },
  accordion: {
    borderBottom: '1px solid #F4F5F5',
    '&.Mui-expanded': {
      margin: 0
    }
  },
  listItem: {
    fontSize: theme.typography.pxToRem(16),
    color: '#45535C'
  },
  fab: {
    color: '#fff',
    backgroundColor: '#1773C8',
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  selected: {
    backgroundColor: '#E2F0F7'
  },
  modal: {
    backgroundColor: 'white'
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

export default useStyles