export const FR = {
  title: 'Nous sommes là pour vous',
  subtitles: "",//La solution Klack apporte des réponses sur des thématiques produit spécifiques. Il se peut que vous souhaitiez plus d'informations et nous serions ravis de vous aider à avancer sur vos problématiques avec l'engagement de vos audiences",
  leftTitle: "Besoin d'un expert tout de suite ?",
  leftBox: [
    'Aide téléphonique',
    'Lundi à vendredi',
    'De 9h à 18h',
    ' - ',
    '+33 1 00 00 00 00',
  ],
  rigthTitle: 'Une question spécifique ?',
  rightBox: [
    'Assistance par mail',
    '24/7',
    'vous méritez de la réactivité',
    ' - ',
    'contact@leenkeet.io',
  ],
  guide: ' Retrouvez la section des guides juste ici !',
  guideButton: 'Accèdez',
}
export const EN = {
  title: 'We are here for you',
  subtitles: "",//'The Klack solution provides you with answers regarding your product. We would be glad to provide you with further assistance! Our team would be thrilled to support you in the resolution of the problems you might encounter.',
  leftTitle: 'Need an expert right now ?',
  leftBox: [
    'Phone support',
    'Monday to Friday',
    '9am to 6pm',
    ' - ',
    '+33 1 00 00 00 00',
  ],
  rigthTitle: 'Any specific question ?',
  rightBox: [
    'Mail support',
    '24/7',
    'you deserve reactivity',
    ' - ',
    'contact@leenkeet.io',
  ],
  guide: 'Guide section just here !',
  guideButton: 'Access',
}

export default {
  fr: FR,
  us: EN,
}
