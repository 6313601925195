import React, { useCallback, useContext, useEffect, useState } from 'react';
import { createFloorAccess, getFloorAccessUsers, getUsers, removeFloorAccess } from '../helpers';

import { Badge, Box, makeStyles, Tooltip } from '@material-ui/core';

import styles from './OpenSpaceUsers.styles';
import { RemoveCircle as RemoveCircleIcon } from '@material-ui/icons';
import UserAvatar from '../../../../components/UserAvatar';
import { Store } from '../../../../store/store';
import { dispatchAlert } from '../../../menu/helpers';
import AddOpenSpaceUser from './AddOpenSpaceUser';

const useStyles = makeStyles(styles);

const OpenSpaceUsers = ({ floorId }) => {
    const classes = useStyles();

    const [floorUsers, setFloorUsers] = useState();

    useEffect(() => {
        (async () => {
            setFloorUsers(await getFloorAccessUsers(floorId));
        })();
    }, [floorId]);

    const {
        dispatch,
        state: {
            alerts,
            language: {
                spots: { removeUser: removeUserLang, userRemoved: userRemovedLang, userAdded: userAddedLang },
            },
        },
    } = useContext(Store);

    const removeFromUsers = (userId) => floorUsers.filter((user) => user.id !== userId);

    const removeUserFromFloor = useCallback(
        async (id) => {
            await removeFloorAccess(id, floorId);
            setFloorUsers(removeFromUsers(id));
            dispatchAlert(dispatch, alerts, { type: 'success', message: userRemovedLang });
        },
        [dispatch, floorId, alerts, floorUsers]
    );

    const addUserToFloor = useCallback(
        async (user) => {
            await createFloorAccess(user.id, floorId);
            setFloorUsers((users) => [...users, user]);
            dispatchAlert(dispatch, alerts, { type: 'success', message: userAddedLang });
        },
        [dispatch, floorId, alerts]
    );

    return (
        <Box className={classes.root}>
            {floorUsers?.map(({ id, lastname, firstname, icon }, index) => (
                <div className={classes.userItem} key={index}>
                    <Badge
                        overlap="circle"
                        badgeContent={
                            <Tooltip placement="top" title={removeUserLang}>
                                <button type="button" onClick={() => removeUserFromFloor(id)}>
                                    <RemoveCircleIcon classes={{ root: classes.removeIcon }} color="error" />
                                </button>
                            </Tooltip>
                        }
                    >
                        <Tooltip placement="top" title={`${firstname} ${lastname}`}>
                            <button type="button">
                                <UserAvatar firstName={firstname} lastName={lastname} {...{ icon }} />
                            </button>
                        </Tooltip>
                    </Badge>
                </div>
            ))}
            <AddOpenSpaceUser
                classes={{ addIconButton: classes.addOpenSpaceUser }}
                withoutUsers={floorUsers}
                onAdd={addUserToFloor}
            />
        </Box>
    );
};

export default OpenSpaceUsers;
