import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Store } from '../../../store/store';
import { getLeenk } from '../../notification/Notification/helpers';
import { getSpeakers, deleteSubject, validateSharedTeam } from './helpers';
import { getUser } from '../../helpers';
import { dispatchAlert } from '../../menu/helpers';
import { useHistory } from 'react-router-dom';

export default function SharedTeamInvitation({
	open,
	setOpen,
	leenkId
}) {
	const history = useHistory()
	const { state: { alerts, language: {
		leenks: {
			sharedTeamTitle: sharedTeamTitleLang,
			accept: acceptLang,
			refuse: refuseLang
		},
		notifications: {
			shared_team: sharedTeamLang
		}
	} }, dispatch } = useContext(Store)
	const [leenk, setLeenk] = useState()

	const handleClose = () => {
		setOpen(false);
		history.replace('/leenk/' + leenkId)
	};
	const [description, setDescription] = useState(sharedTeamLang)
	const { id: myId } = getUser()
	useEffect(() => {
		if (leenk) {
			if (!leenk.team) { handleClose() }
			if ((leenk.team || {}).status === 1) {
				dispatchAlert(dispatch, alerts, { type: 'success' })
				handleClose()
			}
			getSpeakers(leenkId)
				.then(speakers => {
					const he = speakers.find(speaker => speaker.id !== myId)
					if (he) {
						setDescription(sharedTeamLang
							.replace('$1', `${he.firstname} ${he.lastname}`)
							.replace('$2', leenk.name)
							.replace('$3', leenk.team.name) + '.')
					}
				})
		}
	}, [leenk])

	useEffect(() => {
		if (leenkId) {
			getLeenk(leenkId)
				.then(setLeenk)
				.catch(handleClose)
		}
	}, [leenkId])


	const accept = async () => {
		try {
			await validateSharedTeam(leenkId)
			handleClose()
			dispatchAlert(dispatch, alerts, { type: 'success' })
		} catch (e) {
			dispatchAlert(dispatch, alerts, { type: 'error', message: e.message })
		}
	}

	const refuse = async () => {
		//remove all
		try {
			await deleteSubject(leenkId)
			dispatchAlert(dispatch, alerts, { type: 'success' })
			handleClose()
		} catch (e) {
			dispatchAlert(dispatch, alerts, { type: 'error', message: e.message })
		}
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{sharedTeamTitleLang}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{description}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={accept} color="primary">
					{acceptLang}
				</Button>
				<Button onClick={refuse} color="primary" autoFocus>
					{refuseLang}
				</Button>
			</DialogActions>
		</Dialog>
	);
}