import React, { useState, useEffect, useContext } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { getUsers, removeAccessFolder, createAccessFolder } from '../../setting/Setting/helpers';
import { upperFirst } from 'lodash';

import { getFolderUsers } from './helpers';
import { Store } from '../../../store/store';
import { dispatchAlert } from '../../menu/helpers';

const AddUserFolderSearchBar = ({
	folderId,
}) => {
	const { state: { alerts, language: { form: {
		add: addLang
	} } }, dispatch } = useContext(Store)

	const [users, setUsers] = useState([])
	const [options, setOptions] = useState([])
	const [usersFromApi, setUsersFromApi] = useState([])
	const removeAlreadyAddedUsers = usersFromApi => usersFromApi.filter(user => users.findIndex(({ id }) => id === user.id) === -1)
	const getRemovedUser = newUsers => users.find(user => newUsers.findIndex(({ id }) => id === user.id) === -1)
	const getAddedUser = newUsers => newUsers.find(user => users.findIndex(({ id }) => id === user.id) === -1)
	const removeFromUsers = userId => users.filter(user => user.id !== userId)
	const onChange = async (evt, newValues) => {
		if (newValues.length === users.length)
			return
		try {
			if (newValues.length < users.length) {
				const { id: userId } = getRemovedUser(newValues)
				await removeAccessFolder(userId, folderId)
				setUsers(removeFromUsers(userId))
			} else {
				const newUser = getAddedUser(newValues)
				await createAccessFolder(newUser.id, folderId, 1)
				setUsers(users => [...users, newUser])
			}
			dispatchAlert(dispatch, alerts, { type: 'success' })
		} catch (e) {
			dispatchAlert(dispatch, alerts, { type: 'error' })
			console.log(e.message)
		}
	}
	useEffect(() => {
		setOptions(removeAlreadyAddedUsers(usersFromApi))
	}, [users, usersFromApi])

	useEffect(() => {
		getFolderUsers(folderId)
			.then(setUsers)
		getUsers()
			.then(setUsersFromApi)
	}, [])
	return (<div>
		<Autocomplete
			onChange={onChange}
			multiple
			id="tags-outlined"
			options={options}
			value={users}
			getOptionLabel={(option) => upperFirst(option.firstname) + ' ' + upperFirst(option.lastname)}
			defaultValue={[]}
			filterSelectedOptions
			renderInput={(params) => (
				<TextField
					{...params}
					variant="outlined"
				/>
			)}
		/>
	</div>
	);
};

export default AddUserFolderSearchBar;