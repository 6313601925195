import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Grid, Typography } from '@material-ui/core';
import { Store } from '../../../store/store';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    padding: theme.spacing(2),
  },
  mainHub: {
    borderBottom: '1px solid grey',
    padding: theme.spacing(2),
    height: '50%',
  },
  roomTitle: {
    fontSize: theme.typography.pxToRem(20),
    textAlign: 'left',
    padding: theme.spacing(1),
  },
  rooms: {
    height: '50%',
    '&>*:not(:last-child)': {
      borderRight: '1px solid grey',
    }
  },
  room: {
    padding: theme.spacing(2),
  }
}))

const BreakFloor = () => {
  const classes = useStyles();
  const { state } = useContext(Store);
  const {
    language: {
      spots: {
        breakRooms: breakRoomsLang,
        room: roomLang,
      },
    },
  } = state;

  return <div className={classes.root}>
    <Typography classes={{root: classes.title}} variant={'h2'}>{breakRoomsLang}</Typography>
    <Grid container direction="column" style={{height: '100%'}}>
      <Grid container item xs spacing={1} classes={{root: classes.mainHub}}>
        <Grid item>
          <Avatar>TT</Avatar>
        </Grid>
        <Grid item>
          <Avatar>TT</Avatar>
        </Grid>
      </Grid>
      <Grid container item xs classes={{root: classes.rooms}}>
        <Grid container item xs direction="column" classes={{root: classes.room}}>
          <Typography classes={{root: classes.roomTitle}} variant={'h3'}>{roomLang} 1</Typography>
          <Grid container item spacing={1}>
            <Grid item>
              <Avatar>TT</Avatar>
            </Grid>
            <Grid item>
              <Avatar>TT</Avatar>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs direction="column" classes={{root: classes.room}}>
          <Typography classes={{root: classes.roomTitle}} variant={'h3'}>{roomLang} 2</Typography>
          <Grid container item spacing={1}>
            <Grid item>
              <Avatar>TT</Avatar>
            </Grid>
            <Grid item>
              <Avatar>TT</Avatar>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs direction="column" classes={{root: classes.room}}>
          <Typography classes={{root: classes.roomTitle}} variant={'h3'}>{roomLang} 3</Typography>
          <Grid container item spacing={1}>
            <Grid item>
              <Avatar>TT</Avatar>
            </Grid>
            <Grid item>
              <Avatar>TT</Avatar>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </div>
}

export default BreakFloor