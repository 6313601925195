import React, { useContext, useEffect, useMemo } from 'react';
import { Store } from '../store/store';
import { toast } from 'react-hot-toast';

const Alert = ({ type, title, message: messageFromAlert }) => {
    const {
        state: {
            language: {
                alert: {
                    messages: { error: errorLang, warning: warningLang, info: infoLang, success: successLang },
                },
            },
        },
    } = useContext(Store);

    const getMessage = () => {
        switch (type) {
            case 'error':
                return messageFromAlert || errorLang;
            case 'warning':
                return messageFromAlert || warningLang;
            case 'info':
                return messageFromAlert || infoLang;
            case 'success':
                return messageFromAlert || successLang;
            default:
                return messageFromAlert || errorLang;
        }
    };
    const message = getMessage();
    const formattedMessage = useMemo(() => (({ id }) => (<button 
    type="button" 
    onClick={() => toast.dismiss(id)}>
        {message}
        </button>))
        , [message])

    useEffect(() => {
        switch (type) {
            case 'error': {
                toast.error(formattedMessage, { duration: 5000 });
                break;
            }
            case 'success': {
                toast.success(formattedMessage, { duration: 3000 });
                break;
            }
            case 'warning': {
                toast(formattedMessage, { icon: '⚠️', duration: 5000 });
                break;
            }
            default: {
                toast(formattedMessage, { duration: 2000 });
            }
        }
    }, [type, message]);

    return null;
};

export default Alert;
