import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom';
import { useStyles, StyledListItem, StyledListItemText, StyledButton } from './style'
import { getFolder, getLeenk, getMeeting, notificationTypes, parseKeys, getNotificationTypeReadable } from "./helpers";
import { Store } from '../../../store/store';
import { IconButton } from '@material-ui/core';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import useNotificationHooks from '../../header/useNotificationHooks';
import NotificationBrowser from '../../../components/NotificationBrowser';
import { isNull } from '../../helpers';

export const NotificationLeenk = ({ link, user, id, lang }) => {
  const classes = useStyles();
  const history = useHistory();
  const [leenk, setLeenk] = useState({})

  useEffect(() => {
    getLeenk(id).then(({ name }) => setLeenk(name))
  }, [])

  return (
    <StyledListItem button onClick={() => history.push(link)}>
      <StyledListItemText classes={{ primary: classes.user }} primary={`@${user.firstname} ${user.lastname}`} />
      <StyledListItemText primary={lang} />
      <StyledListItemText classes={{ primary: classes.leenk }} primary={`#${leenk}`} />
    </StyledListItem>
  )
}

export const NotificationFolder = ({ id, link, user, lang }) => {
  const classes = useStyles();
  const history = useHistory();
  const [folder, setFolder] = useState("")

  useEffect(() => {
    if (id)
      getFolder(id).then(({ name }) => setFolder(name))
  }, [id])

  return (
    <StyledListItem button onClick={() => history.push(link)}>
      <StyledListItemText classes={{ primary: classes.user }} primary={`@${user.firstname} ${user.lastname}`} />
      <StyledListItemText primary={lang} />
      <StyledListItemText classes={{ primary: classes.leenk }} primary={`#${folder}`} />
    </StyledListItem>
  )
}

export const NotificationMeet = ({ id, link, user, lang }) => {
  const classes = useStyles();
  const [meeting, setMeeting] = useState({})

  useEffect(() => {
    if (id) {
      getMeeting(id).then((data) => setMeeting(data))
    }
  }, [id])

  return (
    <StyledListItem>
      <StyledListItemText classes={{ primary: classes.user }} primary={`@${user.firstname} ${user.lastname}`} />
      <StyledListItemText primary={`${lang} ${meeting.title} ( ${meeting.from} - ${meeting.to} )`} />
    </StyledListItem>
  )
}

export const NotificationTask = ({ id, link, user, lang }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <StyledListItem button onClick={() => history.push(link)}>
      <StyledListItemText classes={{ primary: classes.user }} primary="@username" />
      <StyledListItemText primary={lang} />
      <StyledListItemText classes={{ primary: classes.task }} primary="$taskname" />
    </StyledListItem>
  )
}


export const NotificationMessage = ({ id, link, user, lang }) => {
  const classes = useStyles();
  const history = useHistory();
  const [leenk, setLeenk] = useState("")

  useEffect(() => {
    getLeenk(id).then(({ name }) => setLeenk(name))
  })

  return (
    <StyledListItem button onClick={() => history.push(link)}>
      <StyledListItemText classes={{ primary: classes.user }} primary={`@${user.firstname} ${user.lastname}`} />
      <StyledListItemText primary={lang} />
      <StyledListItemText classes={{ primary: classes.leenk }} primary={`#${leenk}`} />
    </StyledListItem>
  )
}

export const NotificationComponent = ({
  keys: keysToParse = [],
  link,
  type,
  onClick = () => { },
  persistant = false,
  id,
  read,
  tryBrowser = false,
  inProgress = false,
  ...props
}) => {

  const [ignore, setIgnore] = useState(false)
  const {
    readNotification,
    removePersistantFromNotification
  } = useNotificationHooks()
  const classes = useStyles();
  const history = useHistory();
  const { state: {
    language: {
      notifications: {
        privateConv: privateConvLang,
        ...notificationLang
      }
    }
  }, dispatch } = useContext(Store)

  const removePersistantWithTimer = (id, timer = 4000) =>
    setTimeout(() => removePersistantFromNotification(id), timer)
    const setInProgress = ()=>{
      dispatch({type: 'NOTIFICATION_ON_PROGRESS', payload: id})
    }
  useEffect(() => {
  setInProgress()
  if (persistant) {
      const timer = removePersistantWithTimer(id)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [id])

  if (!id && !link)
    return null

  const keys = String(isNull(keysToParse) ? '' : keysToParse).split(',')
  const typeReadable = getNotificationTypeReadable(type).toLowerCase()
  const message = notificationLang[typeReadable]
  const messageToParse = parseKeys(message, keys, type, privateConvLang)
  const handleClick = () => {
    readNotification(id)

    if (typeReadable === 'meeting') {
      window.open(link)
    } else {
      history.push(link)
    }
  }

  return !!(tryBrowser && !ignore) ? <NotificationBrowser
    ignore={ignore}
    setIgnore={setIgnore}
    title={'Nouvelle notification de Leenkeet'}
    body={
      messageToParse.map(({
        message,
      }) => message
      ).join(' ')
    }
    onClick={handleClick}
    inProgress={inProgress}
  /> : <div
    className={'relative' + (persistant ? ' mb-5' : '')}
  ><StyledListItem button onClick={handleClick}
    className={(persistant ? 'notification-persistant ' : '') + (read ? 'backgrounNotifReaded' : '')}
  >
        {messageToParse.map(({
          type,
          message,
          keyType: { type: keyType, operator = '' } = {} } = {}, key) =>
          type === 'message' ? <StyledListItemText key={key} primary={message} />
            : <StyledListItemText
              key={key}
              classes={{ primary: classes[keyType] }}
              primary={operator + message}
            />
        )}
      </StyledListItem>
      <IconButton className={classes.root} onClick={() => readNotification(id)}>
        <HighlightOffIcon />
      </IconButton>
    </div>
}