import React, { useState, useEffect, useMemo } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { getUsers, getUsersFromAllCompanies } from '../../containers/setting/Setting/helpers'
import { upperFirst, isString } from 'lodash'
import { getUser } from '../../containers/helpers'

const SearchUserField = ({
    withoutUsers = [],
    onChange,
    hidden = false,
    external = false,
    notMyCompany = false,
    freeSolo=true
}) => {
    const [allUsers, setAllUsers] = useState()
    const displayedUsers = useMemo(
        () => allUsers?.filter(({ id }) => !withoutUsers.find((withoutUser) => withoutUser.id === id)),
        [allUsers]
    )

    useEffect(() => {
        (external ? getUsersFromAllCompanies : getUsers)()
            .then(users => {
                const { company } = getUser()
                setAllUsers(
                    external && notMyCompany ? users.filter(u => u.company !== company) : users
                )
            })
    }, [])

    if (!allUsers) {
        return null
    }

    return (
        <Autocomplete
            freeSolo={freeSolo}
            hidden={hidden}
            onChange={onChange}
            options={displayedUsers}
            getOptionLabel={option => {
                if (isString(option))
                    return option
                const { firstname, lastname, companyName, email } = option
                return `${upperFirst(firstname)} ${upperFirst(lastname)}${external ? ` (${upperFirst(companyName)}) #${email}` : ''}`
            }
            }
            renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
    )
}

export default SearchUserField
