export default ({ spacing }) => ({
    root: {
        width: '100%',
        display: 'flex',
    },
    createSpaceColumn: {
        minWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRight: '1px solid #efefef',
        padding: spacing(5, 3, 3),
    },
    createSpaceTypography: {
        color: 'rgba(0, 0, 0, .87)',
        marginBottom: spacing(3),
        fontWeight: 500,
    },
    textField: {
        margin: spacing(1.5),
    },
    submitButton: {
        marginTop: spacing(2),
    },
    reorderSpacesColumn: {
        flex: 1,
        padding: spacing(3),
    },
    list: {
        maxWidth: 300,
        color: 'rgba(0, 0, 0, .87)',
    },
    text: {
        minWidth: 180,
        width: 180,
    },
});
