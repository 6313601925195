import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import SearchBar from '../../../components/SearchBar';
import ItemListUpdateButton from './ItemListUpdateButton';

const SubjectList = ({
	classes,
	leenks: leenksFromParent = [],
	leenk = {},
	changeLeenk,
	updateLeenkName
}) => {
	const [leenks, setLeenks] = useState(leenksFromParent)

	useEffect(() => { setLeenks(leenksFromParent) }, [leenksFromParent])

	return (
		<>
			<SearchBar
				list={leenksFromParent.map(leenk => ({
					valueToCompare: leenk.name,
					...leenk
				}))}
				setList={setLeenks}
				parentStyle={{
					marginBottom: 2
				}}
			/>
			<List classes={{ root: classes.list }}>
				{leenks.map(({ id, name, isPrivate = false }, index) => (
					<div
						key={`leenk_subject_${index}`}
						className="flex">
						<ListItem
							selected={leenk.id === id}
							button
							onClick={() => changeLeenk({ id })}
						>
							<ListItemText style={{
								    maxWidth: 'fit-content'
							}} primary={name} />
						</ListItem>
						{!isPrivate && <ItemListUpdateButton name={name} onClick={(name) => {
							updateLeenkName(id, name)
						}} />}
					</div>
				))}
			</List>
		</>
	);
};

export default SubjectList;