/* eslint-disable import/no-anonymous-default-export */
import jwtDecode from 'jwt-decode'
import React from 'react'
import { createBrowserHistory } from 'history/cjs/history.min'
import { pathToRegexp } from 'path-to-regexp'
import { socket, socketRooms } from '../socket'

const headers = {
  'Access-Control-Request-Headers': '*',
  'Access-Control-Request-Method': '*',
  'Access-Control-Allow-Origin': '*',
}

const jsonHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
}

export const getToken = () => localStorage.getItem('token')
export const getHeaders = (multiform = false) => {
  return ({
    ...headers,
    ...(multiform ? {} : jsonHeaders),
    'x-refresh-token': localStorage.getItem('refreshToken'), // await SecureStore.getItemAsync('refreshToken', {}),
    'x-access-token': localStorage.getItem('token'), // await SecureStore.getItemAsync('userToken', {}),
    'x-language': localStorage.getItem('language'),
  })
}

export const catchGesture = ({ status }, history) =>
  status === 403 && history.push('/home')

export const isObject = elem => typeof elem === 'object' && elem !== null

export const isTokenRegenerate = async (res, apiRoute, params) => {
  const body = await res.json() || {}
  res.message = body.message
  if (isObject(res) && res.status) {
    if (body.message === 'regenerateTokens') {
      saveTokens(body.tokens, () => { })
      return fetchApi(apiRoute, params)
    } else if (body.message === 'disconnect') {
      logOut()
    } else if (body.message === 'forbidden') {
      return history.push('/')
    }
  }
  return Promise.reject(res)
}

export const responseToJson = (res, apiRoute, params) => {
  if (res.ok) {
    return res.json()
  } else {
    return isTokenRegenerate(res, apiRoute, params)
  }
}

export const apiUrl = process.env.REACT_APP_API_URL // 'https://localhost:9000'
export const websiteUrl = process.env.REACT_APP_WEBSITE_URL // 'https://localhost:8000'
export const fetchApi = async (route, init = null) => {
  const apiRoute = apiUrl + route
  const params = !init ? { headers: getHeaders() } : {
    method: init && init.body ? 'POST' : 'get',
    ...init,
    mode: 'cors',
    headers: { ...getHeaders(init.multiform), ...init.headers },
    body: init.multiform ? init.body : JSON.stringify(init.body),
  }
  return fetch(apiRoute, params)
    .then(res =>
      res.status !== 204
        ? responseToJson(res, route, !init ? null : params)
        : null
    )
}
const differenceDatesInSeconds = (d1, d2) =>
  Math.abs(Math.round((d2.getTime() - d1.getTime()) / 1000));

export const setLoggedInCookie = (value, expiresIn = Date.now()) =>
  (document.cookie = `loggedIn=${value}; expires=${expiresIn}; path=/ ; max-age=${differenceDatesInSeconds(new Date(), new Date(expiresIn))}`)

export const isLoggedIn = () =>
  document.cookie && document.cookie.split(';').reduce((cookies, str) => {
    const [key, value] = str.split('=').map(s => s.trim())
    cookies[key] = value
    return cookies
  }, {}).loggedIn === '1'
export const saveTokens = ({ token, refreshToken }, dispatch) => {
  const user = jwtDecode(token.token)
  if (user) {
    setLoggedInCookie(1, token.expiresIn)
    localStorage.setItem('token', token.token)
    localStorage.setItem('tokenExpiration', refreshToken.expiresIn)
    localStorage.setItem('refreshToken', refreshToken.token)
    localStorage.setItem('refreshTokenExpiration', token.expiresIn)
    localStorage.setItem('profile', JSON.stringify(user))
    return token
  } else {
    throw new Error('Token is no-readeable.')
  }
}

export const isNull = value => value === null || value === undefined || value === 'NULL' || value === 'undefined' || value === 'null'

export const logOut = async () => {
  setLoggedInCookie(0)
  socketRooms.map(room => socket.removeListener(room, timestamp => {
    console.log(timestamp)
  }))
  localStorage.removeItem('token')
  localStorage.removeItem('tokenExpiration')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('refreshTokenExpiration')
  localStorage.removeItem('profile')
  return history.push('/')
}

export const getImage = imageId => {
  return fetchApi('/image/' + imageId)
}

export const updateLanguage = language => {
  return fetchApi('/user/language', {
    method: 'PATCH',
    body: { language: language },
  })
}

export const capitalizeFirstLetter = (string) => string ? string.charAt(0).toUpperCase() + string.slice(1) : ''

export const unCapitalizeFirstLetter = (string) => string ? string.charAt(0).toLowerCase() + string.slice(1) : ''

export function round(value, precision) {
  const multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

export const roundUpToNextTen = value => value % 10 === 0 ? value : ((Math.floor(value / 10)) + 1) * 10
export const roundUpToLastTen = value => value % 10 === 0 ? value : ((Math.floor(value / 10))) * 10
export const strToLineBreak = str => <div>
  {str
    .split('.')
    .filter(e => e)
    .map(sentence => sentence + '.')
    .map((sentence, index) => (
      <div key={index}>{sentence}</div>
    ))}
</div>

export const regenerateTokens = dispatch => fetchApi('/user/regenerateTokens')
  .then(tokens => saveTokens(tokens, dispatch))
  .catch(e => {
    logOut()
    process.env.NODE_ENV !== 'production' && console.error(e.message)
  })
export const history = createBrowserHistory()

export const getUser = () => {
  const data = JSON.parse(localStorage.getItem('profile')) || {}
  data.icon = Number(data.icon)
  return data || {}
}

export const getEnums = () => fetchApi('/enums')

export const userRoles = {
  USER: 0,
  ADMIN: 1,
  SUPERADMIN: 2,
  GUEST: 3,
}

export const collaboratorRoles = {
  DG: 0,
  DIRECTOR: 1,
  MANAGER: 2,
  EXECUTANT: 3,
  COLLABORATOR: 4,
  PRESTA: 5,
  INTERN: 6,
}

export const exceptAccessibilityPath = {
  home: null,
  profile: null,
  user: [userRoles.GUEST, userRoles.USER],
  support: null,
  mail: [userRoles.GUEST],
  locker: null,
  spot: [userRoles.GUEST],
  leenks: null,
  docx: null,
  task: null,
  meet: [userRoles.GUEST],
  calendar: null,
  notes: [userRoles.GUEST],
  notification: null,
  setting: [userRoles.GUEST, userRoles.USER],
}

export const parentsPaths = {
  home: '/',
  profile: '/profile',
  support: '/support',
  mail: '/mail',
  locker: '/locker',
  spot: '/spots/setting',
  leenks: '/leenk',
  docx: '/docx',
  task: '/task',
  meet: '/meet',
  calendar: '/calendar',
  notes: '/notes',
  notification: '/notification',
  setting: '/setting'
}

export const getLoginPathsIndexes = () => {
  return ({
    home: {
      href: '/',
      parent: parentsPaths.home,
      exceptAccessibility: exceptAccessibilityPath.home,
      get regex() {
        return pathToRegexp(this.href)
      },
    },
    profile: {
      href: '/profile',
      parent: parentsPaths.profile,
      exceptAccessibility: exceptAccessibilityPath.profile,
      get regex() { return pathToRegexp(this.href) },
    },
    userProfile: {
      href: '/user/:userId',
      parent: parentsPaths.setting,
      exceptAccessibility: exceptAccessibilityPath.userProfile,
      get regex() { return pathToRegexp(this.href) },
    },
    profileUpdate: {
      href: '/profile/update',
      parent: parentsPaths.profile,
      exceptAccessibility: exceptAccessibilityPath.profile,
      get regex() { return pathToRegexp(this.href) },
    },
    support: {
      href: '/support',
      parent: parentsPaths.support,
      exceptAccessibility: exceptAccessibilityPath.support,
      get regex() { return pathToRegexp(this.href) },
    },
    homePrincipal: {
      href: '/home',
      parent: parentsPaths.home,
      exceptAccessibility: exceptAccessibilityPath.home,
      get regex() { return pathToRegexp(this.href) },
    },
    subscription: {
      href: '/subscription/payment/:plan',
      parent: parentsPaths.plan,
      exceptAccessibility: exceptAccessibilityPath.plan,
      get regex() { return pathToRegexp(this.href) },
    },
    mail: {
      href: '/mail',
      parentsPaths: parentsPaths.mail,
      exceptAccessibility: exceptAccessibilityPath.mail,
      get regex() { return pathToRegexp(this.href) },
    },
    locker: {
      href: '/locker',
      parentsPaths: parentsPaths.locker,
      exceptAccessibility: exceptAccessibilityPath.locker,
      get regex() { return pathToRegexp(this.href) },
    },
    folder: {
      href: '/locker/folder/:folderId',
      parentsPaths: parentsPaths.locker,
      exceptAccessibility: exceptAccessibilityPath.locker,
      get regex() { return pathToRegexp(this.href) },
    },
    spots: {
      href: '/spots/:floorId',
      parentsPaths: parentsPaths.spot,
      exceptAccessibility: exceptAccessibilityPath.spot,
      get regex() { return pathToRegexp(this.href) },
    },
    leenks: {
      href: '/leenk',
      parentsPaths: parentsPaths.leenks,
      exceptAccessibility: exceptAccessibilityPath.leenks,
      get regex() { return pathToRegexp(this.href) },
    },
    leenkInvite: {
      href: '/leenk/invite/:leenkId',
      parentsPaths: parentsPaths.leenks,
      exceptAccessibility: exceptAccessibilityPath.leenks,
      get regex() { return pathToRegexp(this.href) },
    },
    leenk: {
      href: '/leenk/:leenkId',
      parentsPaths: parentsPaths.leenks,
      exceptAccessibility: exceptAccessibilityPath.leenks,
      get regex() { return pathToRegexp(this.href) },
    },
    docx: {
      href: '/docx/:id',
      parentsPaths: parentsPaths.docx,
      exceptAccessibility: exceptAccessibilityPath.docx,
      get regex() { return pathToRegexp(this.href) },
    },
    tasks: {
      href: '/task',
      parentsPaths: parentsPaths.task,
      exceptAccessibility: exceptAccessibilityPath.task,
      get regex() {
        return pathToRegexp(this.href)
      },
    },
    task: {
      href: '/task/:taskId',
      parentsPaths: parentsPaths.task,
      exceptAccessibility: exceptAccessibilityPath.task,
      get regex() {
        return pathToRegexp(this.href)
      },
    },
    meet: {
      href: '/meet/:meetId',
      parentsPaths: parentsPaths.meet,
      exceptAccessibility: exceptAccessibilityPath.meet,
      get regex() { return pathToRegexp(this.href) },
    },
    meetFolders: {
      href: '/meet/:meetId/folder',
      parentsPaths: parentsPaths.meetFolders,
      exceptAccessibility: exceptAccessibilityPath.meetFolders,
      get regex() { return pathToRegexp(this.href) },
    },
    meetFolder: {
      href: '/meet/:meetId/folder/:folderId',
      parentsPaths: parentsPaths.meetFolder,
      exceptAccessibility: exceptAccessibilityPath.meetFolder,
      get regex() { return pathToRegexp(this.href) },
    },
    calendar: {
      href: '/calendar',
      parentsPaths: parentsPaths.calendar,
      exceptAccessibility: exceptAccessibilityPath.calendar,
      get regex() { return pathToRegexp(this.href) },
    },
    notes: {
      href: '/notes',
      parentsPaths: parentsPaths.notes,
      exceptAccessibility: exceptAccessibilityPath.notes,
      get regex() { return pathToRegexp(this.href) },
    },
    note: {
      href: '/note/:noteId',
      parentsPaths: parentsPaths.notes,
      exceptAccessibility: exceptAccessibilityPath.notes,
      get regex() { return pathToRegexp(this.href) },
    },
    notification: {
      href: '/notification',
      parentsPaths: parentsPaths.notification,
      exceptAccessibility: exceptAccessibilityPath.notification,
      get regex() {
        return pathToRegexp(this.href)
      },
    },
    settings: {
      href: '/setting',
      parentsPaths: parentsPaths.setting,
      exceptAccessibility: exceptAccessibilityPath.setting,
      get regex() { return pathToRegexp(this.href) },
    },
    settingUser: {
      href: '/setting/user/:userId',
      parentsPaths: parentsPaths.setting,
      exceptAccessibility: exceptAccessibilityPath.setting,
      get regex() { return pathToRegexp(this.href) },
    }
  })
}

export const getloginPaths = () => {
  const paths = getLoginPathsIndexes()
  const obj = {}
  Object.keys(paths).map(key => (
    obj[key] = paths[key].href
  ))
  return obj
}

export const getPathsAccessibility = () => {
  const paths = getLoginPathsIndexes()
  const obj = {}
  Object.keys(paths).map(key => (
    obj[key] = paths[key].exceptAccessibility
  ))
  return obj
}

export const isAccessible = exceptAccessibility =>
  exceptAccessibility ?
    !!!exceptAccessibility.find(excepted => excepted === getUser().role)
    : true


export const createDatabase = ({ email, name }) => fetchApi('/database', {
  method: 'POST',
  body: {
    email,
    name,
  }
})

export const deleteDatabase = ({ code }) => fetchApi('/database/' + code, {
  method: 'DELETE',
})

export const getUserFromApi = userId => fetchApi('/users/' + userId)

export const getUsersConnected = () => fetchApi('/users/connected')
export const messageFileTypes = {
  IMAGE: 0,
  AUDIO: 1,
  FILE: 2,
  DOCX: 3,
  PDF: 4,
}

export const getFileTypeFromExtension = (extension) => {
  switch (extension) {
    case 'png':
    case 'PNG':
    case 'jpeg':
    case 'jpg':
    case 'JPG':
      return messageFileTypes.IMAGE
    case 'docx':
      return messageFileTypes.DOCX
    case 'pdf':
      return messageFileTypes.PDF
    case 'ogg':
      return messageFileTypes.AUDIO
    default:
      return messageFileTypes.FILE
  }
}

export const getParamsFromUrl = (search) => search.substring(1) ? JSON.parse('{"' + decodeURI(search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : {}

export const updateSocketId = socketId => fetchApi('/user/socketid', {
  method: 'PATCH',
  body: {
    socketId
  }
})

export default {
  isLoggedIn,
  fetchApi,
  responseToJson,
  setLoggedInCookie,
  logOut,
  saveTokens,
  catchGesture,
  getImage,
  apiUrl,
  capitalizeFirstLetter,
  unCapitalizeFirstLetter,
  round,
  roundUpToNextTen,
  roundUpToLastTen,
  strToLineBreak,
  regenerateTokens,
  getUser,
  isObject,
  parentsPaths,
  getLoginPathsIndexes,
  getloginPaths,
  isNull,
  userRoles,
  getPathsAccessibility,
  isAccessible,
  collaboratorRoles,
  getUserFromApi,
  getEnums,
  getToken,
  getUsersConnected,
  messageFileTypes,
  getFileTypeFromExtension,
  getParamsFromUrl,
  updateSocketId
}
