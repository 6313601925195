import { fetchApi, saveTokens } from "../../helpers";

export const updateUserInfos = ({ firstname, lastname, password, gender }, token) => fetchApi('/userProfile/' + token, {
    body: {
        firstname,
        lastname,
        password,
        gender,
    },
    method: 'PATCH',
})

export const loginAfterUpdate = (token, password) => fetchApi('/user/tokenLogin/' + token,
    {
        method: 'POST',
        body: { password },
    })
    .then(tokens => saveTokens(tokens))

export default {
    updateUserInfos,
}
