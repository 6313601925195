import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import SearchBar from '../../../components/SearchBar';
import {
    Button,
    TextField,
    makeStyles,
    IconButton,
    Popover,
    Tooltip,
    DialogActions,
    List,
    ListItem,
    ListItemText,
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    Divider,
    Typography,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { createFolder, getFolders, getSpecificFolders, getParent, mooveFolderTo } from './helpers';
import ButtonFolder from '../../../components/ButtonFolder';
import { Store } from '../../../store/store';
import LockerFolderActions from '../../../components/LockerFolderActions';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { dispatchAlert } from '../../menu/helpers';
import AddUserFolderSearchBar from './AddUserFolderSearchBar';
import PopperPaper from '../../../components/PopperPaper';
import Files from './Files';
import ToggleViewChoices from '../../../components/ToggleViewChoices';
import RenderFolderList from './RenderFolderList';
import { useHistory } from 'react-router-dom';
import UpdateFile from './Files/UpdateFile'
import useFileActions from './useFileActions';
const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        border: 'none',
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        color: '#45535C',
        textAlign: 'left',
    },
    h3: {
        fontSize: theme.typography.pxToRem(18),
    },
    h4: {
        fontSize: theme.typography.pxToRem(16),
        marginBottom: theme.spacing(1),
    },
    folders: {
        padding: theme.spacing(4),
        display: 'flex',
        flexWrap: 'wrap',
    },
    createFolderContainer: {
        display: 'flex',
        padding: 8,
        marginLeft: 4,
        marginTop: 6,
    },
    popperPaper: {},
    popperPaperActions: {
        margin: theme.spacing(1, 0, 0),
    },
    inputContainer: {
        position: 'absolute',
        borderRadius: 5,
        padding: theme.spacing(1),
        backgroundColor: '#F4F5F5',
        top: '100%',
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    input: {
        borderRadius: 5,
        padding: theme.spacing(1),
        backgroundColor: '#fff',
    },
    list: {
        color: 'rgba(0, 0, 0, .87)',
    },
    dialogContent: {
        display: 'flex',
    },
    moveFolderContainer: {
        margin: theme.spacing(1),
    },
    file: {
        padding: theme.spacing(2),
        backgroundColor: '#EFEFEF',
        borderRadius: 10,
        color: '#45535C',
        cursor: 'pointer',
    },
    buttonRight: {
        position: 'realtive',
        zIndex: 2,
    },
    modal: {
        backgroundColor: 'white',
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    dividerFiles: {
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(6),
    },
    folderNameTypography: {
        color: 'rgba(0, 0, 0, .87)',
        fontWeight: 500,
        marginTop: "10px!important",
        marginLeft: '15px'
    }
}));

const getModalStyle = () => {
    const top = 50 + Math.round(Math.random() * 20) - 10;
    const left = 50 + Math.round(Math.random() * 20) - 10;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
};

const NewFolder = ({ folderId, setFolders, type = 'private' }) => {
    const classes = useStyles();
    const inputRef = useRef(null);
    const [anchorElement, setAnchorElement] = useState();

    const openPopperPaper = Boolean(anchorElement);

    const handleButtonClick = useCallback((event) => {
        setAnchorElement(event.currentTarget);
    }, []);

    const closePopperPaper = useCallback(() => {
        setAnchorElement(null);
    }, []);

    const {
        dispatch,
        state: {
            alerts,
            language: {
                locker: { createFolder: createFolderLang, folderName: folderNameLang },
            },
        },
    } = useContext(Store);

    const handleSave = () => {
        if (!inputRef.current.value) {
            return;
        }
        closePopperPaper();
        createFolder(type, inputRef.current.value, folderId)
            .then(() => {
                if (folderId) getSpecificFolders(folderId).then(({ folders }) => setFolders(folders));
                else getFolders(type, 'folder').then((folders) => setFolders(folders));
            })
            .catch((e) => dispatchAlert(dispatch, alerts, { type: 'error' }));
        inputRef.current.value = '';
    };

    return (
        <>
            <PopperPaper
                classes={{ paper: classes.popperPaper }}
                anchorEl={anchorElement}
                open={openPopperPaper}
                onClose={closePopperPaper}
            >
                <TextField
                    variant="outlined"
                    className={classes.input}
                    inputRef={inputRef}
                    placeholder={folderNameLang}
                />
                <DialogActions classes={{ root: classes.popperPaperActions }}>
                    <Button onClick={handleSave}>{createFolderLang}</Button>
                </DialogActions>
            </PopperPaper>
            <Tooltip placement="top" title={createFolderLang}>
                <div className={classes.createFolderContainer}>
                    <ButtonFolder create onClick={handleButtonClick}>
                        <AddCircleIcon />
                    </ButtonFolder>
                </div>
            </Tooltip>
        </>
    );
};

const UpdateFolder = ({ folder, folders, folderId, parent, setFolders }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [mooveFolder, setMooveFolder] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalStyle] = useState(getModalStyle);
    const [mooveParent, setMooveParent] = useState([]);
    const [modalShare, setModalShare] = useState(false);
    const open = Boolean(anchorEl);
    const classes = useStyles();
    const [mooveTo, setMooveTo] = useState(0);
    const [previous, setPrevious] = useState(0);
    const userToShare = useRef(null);
    const {
        dispatch,
        state: {
            alerts,
            language: {
                folder: { move: moveLang, share: shareLang, goBack: goBackLang, moveHere: moveHereLang },
            },
        },
    } = useContext(Store);
    useEffect(() => {
        setMooveFolder(folders);
    }, [folders]);
    useEffect(() => {
        getParent(folderId).then((parentResponse) => {
            if (parentResponse && parentResponse.folder_id != folderId) {
                setPrevious(parentResponse.folder_id);
            }
        });
    }, [modal]);

    const handleMooveClick = (e) => {
        e.stopPropagation();
        setModal(true);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setMooveFolder(folders);
        setMooveTo(0);
        setModal(false);
        setAnchorEl(null);
    };

    const handleOnClick = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleOnFolderClik = (id) => {
        getSpecificFolders(id).then((response) => {
            setMooveFolder(response.folders);
            setMooveTo(id);
            setPrevious(folderId);
        });
    };

    const handleOnMoove = () => {
        getParent(folderId).then((parentResponse) => {
            mooveFolderTo(folderId, mooveTo, parentResponse.folder_id)
                .then(() => {
                    setModal(false);
                    setAnchorEl(null);
                    setFolders(folders.filter((f) => f.id != folderId));
                    dispatchAlert(dispatch, alerts, { type: 'success' })
                })
                .catch((e) => dispatchAlert(dispatch, alerts, { type: 'error' }));
        });
    };

    const handleGoBack = () => {
        getParent(previous).then((parentResponse) => {
            getSpecificFolders(parentResponse.folder_id).then((response) => {
                if (previous === parentResponse.folder_id) {
                    getFolders('private', 'folder').then((data) => {
                        setMooveFolder(data);
                        setMooveTo(folderId);
                        setPrevious(0);
                    });
                } else if (previous === folderId) {
                    setMooveFolder(response.folders);
                    setMooveTo(0);
                    setPrevious(parentResponse.folder_id);
                } else {
                    setMooveFolder(response.folders);
                    setMooveTo(parentResponse.folder_id);
                    setPrevious(parentResponse.folder_id);
                }
            });
        });
    };

    const handleModalShareClose = (e) => {
        e.stopPropagation();
        setMooveFolder(folders);
        setMooveTo(0);
        setModalShare(false);
        setAnchorEl(null);
    };
    const handleShareClick = (e) => {
        e.stopPropagation();
        setModalShare(true);
    };

    return (
        <>
            <Dialog open={modal} onClose={handleClose}>
                <DialogTitle>{moveLang}</DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    {previous > 0 ? <Button onClick={handleGoBack}>{goBackLang}</Button> : null}
                    {mooveFolder.map((f, index) => {
                        return f.id !== folder.id ? (
                            <div className={classes.moveFolderContainer} key={index}>
                                <ButtonFolder onClick={() => handleOnFolderClik(f.id)} folder={folder}>
                                    {f.name}
                                </ButtonFolder>
                            </div>
                        ) : null;
                    })}
                    {mooveTo > 0 ? <Button onClick={handleOnMoove}>{moveHereLang}</Button> : null}
                </DialogContent>
            </Dialog>
            <Dialog open={modalShare} onClose={handleModalShareClose}>
                <DialogTitle>{shareLang}</DialogTitle>
                <DialogContent>
                    <AddUserFolderSearchBar folderId={folderId} />
                </DialogContent>
            </Dialog>
            <div>
                <IconButton
                    aria-describedby={folder.id}
                    classes={{ root: classes.buttonRight }}
                    onClick={handleOnClick}
                >
                    <MoreVertIcon />
                </IconButton>
            </div>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={(e) => {
                    e.stopPropagation();
                    setAnchorEl(null);
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List className={classes.list}>
                    {/* <ListItem button onClick={handleMooveClick}>
                        <ListItemText primary={moveLang} />
                    </ListItem> */}
                    <ListItem button onClick={handleShareClick}>
                        <ListItemText primary={shareLang} />
                    </ListItem>
                </List>
            </Popover>
        </>
    );
};
const RenderFolders = ({
    folderId = null,
    navigateToFolder,
    folders: foldersActual = [],
    files: filesActual = [],
    parent,
    meeting,
    folderName,
    folder
}) => {
    const history = useHistory()
    const classes = useStyles();
    const [view, setView] = useState('elements')

    const {
        downloadFromServer,
        handleClick
    } = useFileActions()
    const [folders, setFolders] = useState(foldersActual)
    const [files, setFiles] = useState(filesActual)
    useEffect(() => {
        setFolders(foldersActual)
        setFiles(filesActual)
    }, [filesActual, foldersActual])

    const addToolsToElement = e => ({
        ...e,
        onClick: e.extension ? ({ name, id, docxid }) => {
            if (docxid) {
                return history.push(`/docx/${docxid}`);
            }
            return downloadFromServer(id, name);
        } : (elem) =>
                navigateToFolder(
                    elem.id
                ),
        buttonClick: e.extension ?
            <UpdateFile key={'file' + e.id} file={e} setFiles={setFiles} /> :
            <UpdateFolder key={'folder' + e.id} folder={e} folders={folders} folderId={folderId} parent={parent} setFolders={setFolders} />
    })
    const getElements = (files, folder) => {
        return [...files, ...folder].map(addToolsToElement)
            .sort((a, b) => Date.parse(b.updated_at) - Date.parse(a.updated_at))
    }
    const [elements, setElements] = useState(getElements(files, folders))

    useEffect(() => {
        setElements(getElements(files, folders))
    }, [files, folders])

    const updateElementsFromSearch = (elements) => {
        const newFiles = elements.filter(e => e.type === 'file')
        const newFolders = elements.filter(e => e.type === 'folder')
        setFiles(newFiles)
        setFolders(newFolders)
    }

    return (
        <Box classes={{ root: classes.root }} className="leenks-section scroll-y scrollbar-disable">
            <div className="flex width-100-p space-between">
                {folderName &&

                    <Typography variant="h5" classes={{ root: classes.folderNameTypography }}>
                        {folderName}
                    </Typography>
                }
                <SearchBar list={
                    [
                        ...filesActual.map(e => ({ ...e, type: 'file' })),
                        ...foldersActual.map(e => ({ ...e, type: 'folder' })),
                    ].map(e => ({ ...e, valueToCompare: e.name }))
                } setList={updateElementsFromSearch}
                    parentStyle={{
                        margin: 'auto 0'
                    }}
                />
                <ToggleViewChoices view={view} setView={setView} />
            </div>
            {view === 'elements' ? <>
                <Box className={classes.folders}>
                    {folders.map((folder, index) => (
                        <Grid key={index + folder.id} item>
                            <div style={{ display: 'flex' }}>
                                <ButtonFolder
                                    onClick={
                                        () =>
                                            navigateToFolder(
                                                folder.id
                                            ) /*() => dispatch({ type: "UPDATE_FOLDER", payload: { folderId: id, folderType: 'private', parentType: 'folder' } })*/
                                    }
                                    folder={folder}
                                >
                                    {folder.name}
                                </ButtonFolder>
                                {!meeting ? (
                                    <UpdateFolder
                                        folder={folder}
                                        folders={folders}
                                        folderId={folder.id}
                                        parent={parent}
                                        setFolders={setFolders}
                                    />
                                ) : null}
                            </div>
                        </Grid>
                    ))}
                    <NewFolder type={'private'} folderId={folderId} setFolders={setFolders} parent={parent} />
                </Box>
                {folderId && files.length > 0 &&
                    <Box className={classes.dividerFiles}>
                        <Divider />
                        <Files {...{ files, setFiles, handleClick }} />
                    </Box>
                }
            </> : <RenderFolderList
                    elements={elements}
                    folderName={folderName}
                />}
            {Boolean(folderId) && <LockerFolderActions list={files} folderId={folderId} {...{ setFiles }} />}
        </Box>
    );
};

export default RenderFolders;
