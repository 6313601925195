import {fetchApi} from "../../helpers";
const encode = str => encodeURIComponent(str)
export const getCustomers = search => fetchApi(`/search/customers/${encode(search)}`);

export const getSuppliers = search => fetchApi(`/search/suppliers/${encode(search)}`);

export const getTeams = search => fetchApi(`/search/teams/${encode(search)}`);

export const getFolders = search => fetchApi(`/search/folders/${encode(search)}`)

export const searchUsers = search => fetchApi(`/search/users/${encode(search)}`)

export const getMessages = search => fetchApi(`/search/messages/${encode(search)}`)
