import { fetchApi } from "../../helpers";

export const getUsers = () => fetchApi('/users')

export const createPrivateMeeting = (user, notNotif = false) => fetchApi('/private/meeting', {
  method: 'POST',
  body: {
    user,
    notNotif
  }
})
export const createMeetingNotification = (to, meetingId, leenkId, folderId) => fetchApi('/notification/meeting', {
  method: 'POST',
  body: {
    to, meetingId, leenkId, folderId
  }
})

export const createMeetingAccess = (meetingId, userId, leenkId) => fetchApi('/access/meeting', {
  method: 'POST',
  body: {
    meetingId,
    leenkId,
    userId,
    rights: 1
  }
})
export const createLeenkCollaborateNotification = (to, leenkId) => fetchApi('/notification/leenk/private', {
  method: 'POST',
  body: {
    to, leenkId
  }
})

export const getPrivateMeeting = (user) => fetchApi(`/private/meeting/${user}`)

export const createMeeting = (name, users, from, to, isPublic) =>
  fetchApi('/meeting', {
    method: 'POST',
    body: {
      name,
      users,
      from,
      to,
      public: isPublic
    }
  })

export const createSpace = (name, workplaceNumber) => fetchApi('/space', {
  method: 'POST',
  body: {
    name,
    // workplaceNumber
  }
})

export const updateFloor = (floorId, position) => fetchApi('/floor', {
  method: 'PUT',
  body: {
    floorId,
    position
  }
})

export const getSpaces = () => fetchApi('/spaces')
export const getSpace = (floorId) => fetchApi(`/space/${floorId}`)


export const getMeetings = () => fetchApi('/meetings')

export const getUsersInMeeting = meetingId => fetchApi(`/meeting/users/${meetingId}`)

export const getCollaborators = spaceId => fetchApi(`/collaborators/${spaceId}`)
export const getSpaceAccessUsers = spaceId => fetchApi(`/access/space/` + spaceId)
export const getFloorAccessUsers = floorId => fetchApi(`/access/floor/` + floorId)

export const getVisio = id => fetchApi(`/visio/${id}`)
export const getPrivateVisio = (meetingId, folderId) => fetchApi(`/private/visio/${meetingId}/${folderId}`)

export const setSeat = (spaceId, position) =>
  fetchApi('/collaborator')
    .then(data =>
      (data)
        ? fetchApi('/collaborator', {
          method: 'PUT',
          body: {
            position,
            direction: 'down',
            spaceId
          }
        })
        : fetchApi('/collaborator', {
          method: 'POST',
          body: {
            position,
            direction: 'down',
            spaceId
          }
        })
    )

export const createFloorAccess = (userId, floorId) => fetchApi('/access/floor', {
  method: 'POST',
  body: {
    userId,
    floorId
  }
})
export const removeFloorAccess = (userId, floorId) => fetchApi('/access/floor', {
  method: 'DELETE',
  body: {
    userId,
    floorId
  }
})
export const addSpaceAcces = (userId, spaceId) => fetchApi('/access/space', {
  method: 'POST',
  body: {
    userId,
    spaceId
  }
})
export const createSpaceAccess = (userId, spaceId) => fetchApi('/access/space', {
  method: 'POST',
  body: {
    userId,
    spaceId
  }
})

export const deleteMeetingAccess = (userId, meetingId, leenkId) => fetchApi('/access/meeting', {
  method: 'DELETE',
  body: {
    userId,
    meetingId,
    leenkId
  }
})

export const getMeetingsAccess = (meetingId) => fetchApi(`access/meeting/${meetingId}`)
export default {
  createMeetingNotification,
  createLeenkCollaborateNotification,
  getSpaceAccessUsers,
  createSpaceAccess,
  createFloorAccess,
  getFloorAccessUsers,
  removeFloorAccess
}