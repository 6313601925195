import { fetchApi } from "../../helpers";

export const getFolders = () => fetchApi('/mail/folders')
export const getMails = (offset = 0) => fetchApi(`/mails?offset=${offset}`)
export const mailTestAPI = () => fetchApi(`/mail/test`)
export const markAsSeen = mailId => fetchApi(`/mail/` + mailId, {
	method: 'PUT'
})

export const disconnectSmtp = () => fetchApi('/smtp', {
	method: 'DELETE'
})

export default {
	getFolders,
	getMails,
	mailTestAPI,
	markAsSeen,
	disconnectSmtp
}
