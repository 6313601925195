import React, { useContext, useState, useEffect, useRef } from 'react';
import { ListItem, Grid, List, ListItemText, Typography, TextField, Button } from "@material-ui/core";
import useStyles from './style';
import { Store } from "../../../store/store";
import SearchBar from '../../../components/SearchBar';

const Users = ({ users: usersActual = [], userId, setUserId, addUser }) => {
  const classes = useStyles();
  const { state } = useContext(Store);
  const {
    language: {
      settings: {
        users: usersLang,
      },
    },
  } = state;
  const [users, setUsers] = useState(usersActual)
  const [isNew, setIsNew] = useState(false)
  const newUserRef = useRef(null)
  const onChange = () => {
    if (!isNew)
      return setIsNew(true)
    addUser(newUserRef.current.value)
    .then(() => newUserRef.current.value = '')
    
  }
  useEffect(() => {
    setUsers(usersActual)
  }, [usersActual])
  return (
    <Grid container item xs={2} direction="column" className="leenks-section scroll-y scrollbar-disable" style={{    flexWrap: 'nowrap'}}>
      <Typography variant="h3" classes={{ root: classes.userTitle, h3: classes.h3 }}>
        {usersLang}
      </Typography>
      <div>{isNew
            ? <TextField variant={'outlined'} inputRef={newUserRef} placeholder="coco@leenkeet.fr" />
            : null}
      <Button onClick={onChange}>+</Button>
      </div>
    <List>
        {(usersActual || []).length > 0 ? <SearchBar
          list={usersActual.map(user => ({
            valueToCompare: user.firstname + ' ' + user.lastname,
            ...user
          }))}
          setList={setUsers}
        />: null}
        {
          users.map(({ id, firstname, lastname }) =>
            <ListItem classes={{ selected: classes.selected }} key={id} role={undefined} selected={id === userId} dense button onClick={() => setUserId(id)}>
              <ListItemText classes={{ primary: classes.listItem }}
                id={`checkbox-list-label-${id}`}
                primary={`${firstname} ${lastname}`}
              />
            </ListItem>
          )
        }
      </List>
    </Grid>
  )
}

export default Users
