import React, { useCallback, useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/fr';
import { Store } from '../../../store/store';
import {
    Typography,
    Avatar,
    IconButton,
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Button,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TransferMail from './TransferMail';
import { createLeenk, sendMessageApi, createPrivateLeenk, sendGuestMessageApi } from '../../leenks/Leenks/helpers';
import { inviteUser } from '../../home/Home/helpers';
import { createAccessSubject } from '../../setting/Setting/helpers';
import { dispatchAlert } from '../../menu/helpers';
import styles from './Mail.styles';
import HorizontallyCollapsableColumn from '../../../components/HorizontallyCollapsableColumn';
import cn from 'classnames';
import PopperPaper from '../../../components/PopperPaper';
import { markAsSeen } from './helpers';
import SearchBar from '../../../components/SearchBar';
import NavigateEmailsButtons from './NavigateEmailsButtons';

const parseAddress = (from={value:[]}) => (from.value[0] || {}).address || 'Undefined';

const useStyles = makeStyles(styles);

const Attachment = ({ attachment }) => {
    const arrayBufferToBase64 = (buffer) => {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };
    const url = `data:${attachment.contentType};base64,${arrayBufferToBase64(attachment.content.data)}`;
    return (
        <a download={attachment.filename} href={url}>
            <img src={url} style={{ maxHeight: 100 }} />
            <span>{attachment.filename}</span>
        </a>
    );
};

const Attachments = ({ attachments }) => {
    return <>{attachments.length ? attachments.map((e, key) => <Attachment attachment={e} key={key} />) : null}</>;
};

const Content = ({ mailSelected }) => {
    const classes = useStyles();
    const [transferMailAnchorElement, setTransformMailAnchorElement] = useState();
    const openTransferMailPopperPaper = Boolean(transferMailAnchorElement);

    const closeTransferMailPopperPaper = useCallback(() => setTransformMailAnchorElement(null), []);

    const handleTransferMailIconButtonClick = useCallback(
        (event) => setTransformMailAnchorElement(event.currentTarget),
        []
    );

    const {
        state: {
            alerts,
            languageValue,
            language: {
                mails: { from: fromLang, to: toLang },
            },
        },
        dispatch,
    } = useContext(Store);
    moment.locale(languageValue === 'fr' ? 'fr' : 'en');
    if (!mailSelected) return null;
    const { from, to, date, subject, html, text } = mailSelected;
    const addLeenk = async (parentType, parentId, folderId, guest) => {
        try {
            let leenk;
            if (parentType === 'FOLDER') {
                leenk = await createPrivateLeenk([guest.id]);
                leenk.name = leenk.id;
                leenk.parentId = leenk.parent;
            } else {
                leenk = await createLeenk(subject, parentId, parentType, folderId);
            }
            dispatchAlert(dispatch, alerts, { type: 'success' });
            return leenk;
        } catch (e) {
            console.log(e.message);
            dispatchAlert(dispatch, alerts, { type: 'error' });
        }
    };

    const transferLeenk = async (parentType, parentId, folderId) => {
        //create Leenk
        try {
            const leenk = await addLeenk(parentType, parentId, folderId);
            await sendMessageApi(leenk.id, text, []);
            dispatchAlert(dispatch, alerts, { type: 'success' });
        } catch (e) {
            dispatchAlert(dispatch, alerts, { type: 'error' });
            console.log(e.message);
        }
    };

    const transferCorrespondantToLeenk = async (parentType, parentId = null, folderId = null) => {
        const email = (from.value[0] || {}).address;
        try {
            if (email) {
                const guest = await inviteUser({ email });
                const leenk = await addLeenk(parentType, parentId, folderId, guest);
                if (parentType !== 'FOLDER') {
                    try {
                        await createAccessSubject(leenk.id, 1, parentType, guest.id, folderId, parentId);
                    } catch (e) {
                        console.log(createAccessSubject, e);
                    }
                }
                await sendGuestMessageApi(leenk.id, text, [], guest.id);
                dispatchAlert(dispatch, alerts, { type: 'success' });
            }
        } catch (e) {
            dispatchAlert(dispatch, alerts, { type: 'error' });
            console.log('error', e);
        }
    };

    return (
        <>
            <PopperPaper
                placement="left"
                open={openTransferMailPopperPaper}
                anchorEl={transferMailAnchorElement}
                onClose={closeTransferMailPopperPaper}
            >
                <TransferMail {...{ createLeenk: transferLeenk, transferCorrespondantToLeenk }} />
            </PopperPaper>
            <div className={classes.contentHeader}>
                <ListItem
                    classes={{
                        root: classes.listItem,
                    }}
                >
                    <ListItemAvatar>
                        <Avatar>{parseAddress(from)[0].toUpperCase()}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        classes={{
                            primary: classes.listItemTextPrimary,
                            secondary: classes.listItemTextSecondary,
                        }}
                        primary={
                            <>
                                <Typography component="div">
                                    {fromLang}:
                                    <Typography component="span" style={{ marginLeft: 8 }}>
                                        {(from||{value:[]}).value.map(({ address }) => address).join(', ')}
                                    </Typography>
                                </Typography>
                                <Typography component="div">
                                    {toLang}:
                                    <Typography component="span" style={{ marginLeft: 8 }}>
                                        {(to||{value:[]}).value.map(({ address }) => address).join(', ')}
                                    </Typography>
                                </Typography>
                            </>
                        }
                        secondary={`${moment(date).fromNow()} - ${subject}`}
                    />
                </ListItem>
                <IconButton onClick={handleTransferMailIconButtonClick}>
                    <MoreVertIcon fontSize="large" />
                </IconButton>
            </div>
            <div className={classes.mailBody} dangerouslySetInnerHTML={{ __html: html }} />
            {mailSelected.attachments ? <Attachments attachments={mailSelected.attachments} /> : null}
        </>
    );
};

const Inbox = ({ mails: mailsFromAPI = [], disconnect }) => {
    const classes = useStyles();
    const [selected, setSelected] = useState(0);
    const setValueToCompareToMails = (mails = []) => (mails|| []).map(m => ({
        ...m, valueToCompare: `${m && m.from ? m.from.text : ''}${m ? m.date : ''}${m ? m.text : ''}${m && m.to ? m.to.text : ''}`
        }))
    const [mails, setMails] = useState([])
    useEffect(()=>{
        setMails(setValueToCompareToMails(mailsFromAPI))
    }, [mailsFromAPI])
    const {
        dispatch, 
        state: {
            alerts, 
            languageValue,
            language: {
                mails: { inbox: inboxLang, disconnect:disconnectLang },
            },
        },
    } = useContext(Store);
    moment.locale(languageValue === 'fr' ? 'fr' : 'en');
    const selectMail = async (mailId) => {
        setSelected(mailId)
        try{
            await markAsSeen(mailId)
            dispatch({type: 'SEE_MAIL', payload: mailId})
        }catch(e){
            dispatchAlert(dispatch, alerts, {type: 'error', message: e.message})
        }
    }
    const [loading, setLoading] = useState(false)
    const loadData = () => setLoading(true)
    const dataLoaded = () => setLoading(false)
    return (
        <Box className={classes.root}>
            <div>
            <Button onClick={disconnect}>{disconnectLang}</Button>
            <SearchBar setList={setMails} list={setValueToCompareToMails(mailsFromAPI)} />
            <NavigateEmailsButtons 
            loadData={loadData}
            dataLoaded={dataLoaded}
             />
            {loading ? <div>loading...</div> :  <HorizontallyCollapsableColumn
                open
                classes={{ root: classes.horizontallyCollapsableColumn }}
                title={`${inboxLang} (${mails.length || 0})`}
                maxWidth={315}
            >
                <List classes={{ root: classes.list }}>
                    {mails
                    .map((mail, index) => ({ ...mail, id: index }))
                        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                        .map(({ from, date, subject, id, seen = false }, index) => {
                            return (
                                <ListItem
                                    button
                                    key={`mail_item_${id}_${index}`}
                                    classes={{
                                        root: cn(classes.listItem, !seen && classes.unseenListItem),
                                    }}
                                    onClick={() => selectMail(id)}
                                    selected={selected === id}
                                >
                                    {!seen && <div className={classes.unseenDot} />}
                                    <ListItemAvatar>
                                        <Avatar>{parseAddress(from)[0].toUpperCase()}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        classes={{
                                            primary: classes.listItemTextPrimary,
                                            secondary: classes.listItemTextSecondary,
                                        }}
                                        primary={parseAddress(from)}
                                        secondary={`${moment(date).fromNow()} - ${subject}`}
                                    />
                                </ListItem>
                            );
                        })}
                </List>
            </HorizontallyCollapsableColumn>}
            </div> 
            <Box className={cn(classes.content, 'scroll-y', 'scrollbar-disable')}>
                <Content mailSelected={mails[selected]} />
            </Box>
        </Box>
    );
};

export default Inbox;
