import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { getMeetings, getTasks } from "./helpers";
import { useHistory } from 'react-router-dom'
import ModalCalendarCreateEvent from './ModalCalendarCreateEvent'
const localizer = momentLocalizer(moment)

const styles = {
  width: '100%',
  height: '100%',
}

const Calendar = () => {
  const [events, setEvents] = useState([])
  const history = useHistory()
  useEffect(() => {
    Promise.all([
      getTasks(),
      getMeetings()
    ]).then(([tasks, meetings]) => {
      const refactoTasks = tasks.map(task => ({
        start: task.from ? new Date(task.from) : null,
        end: task.to ? new Date(task.to) : null,
        title: task.title,
        id: task.id,
        type: 0
      })).filter(e => e.start && e.end)
      const refactoMeetings = meetings.map(meeting => ({
        id: meeting.id,
        start: new Date(meeting.from),
        end: new Date(meeting.to),
        title: meeting.title,
        type: 1
      }))
      setEvents([...refactoTasks, ...refactoMeetings])
    })
  }, [])

  const handleOnClik = (id, type) => {
    switch (type) {
      case 0:
        history.push(`/task/${id}`)
        break
      case 1:
        window.open(`/meet/${id}/folder`)
        break
      default:
        break
    }
  }
  const [dateCreation, setDateCreation] = useState({})
  const handleSelectEmptySlot = ({ start:beginDate, end:endDate }) => {
    setDateCreation({
      beginDate,
      endDate
    })
  }
  return <Grid container className="page-container border-radius-5 shadow bg-white" style={{ padding: 8 }}>
    <BigCalendar
      selectable
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={styles}
      onSelectEvent={event => handleOnClik(event.id, event.type)}
      onSelectSlot={handleSelectEmptySlot}
    />
    <ModalCalendarCreateEvent dateCreation={dateCreation} setDateCreation={setDateCreation} />
  </Grid>
}

export default Calendar
