const EN = {
    privateSpace: 'Private space',
    publicSpace: 'Public space',
    accessMyFiles: 'Access my files',
    spaces: 'Spaces',
    createFolder: 'Create folder',
    folderName: 'Folder name',
};

export const FR = {
    privateSpace: 'Espace privé',
    publicSpace: 'Espace public',
    spaces: 'Espaces',
    accessMyFiles: 'Accéder à mes dossiers',
    createFolder: 'Créer un dossier',
    folderName: 'Nom du dossier',
};

export default {
    fr: FR,
    us: EN,
};
