import languages from '../languages'
import { isEqual } from 'lodash'
const initialUser = {}

const initialSocket = {
  status: '',
  message: '',
}

const defaultEmails = []

export const initialState = {
  users: [],
  mails: defaultEmails,
  mailsLength: 0,
  notifications: [],
  alerts: [],
  user: initialUser,
  languageValue: 'fr',
  language: languages.fr,
  languageHidden: false,
  rightSideLoading: false,
  menu: true,
  socket: initialSocket,
  folderId: 0,
  folderType: 'private',
  parentType: 'folder',
  smtp: false,
  enums: {
    leenk: { parentTypes: {} },
    user: { roles: {} },
    notification: { types: {} },
    access: {
      types: {},
      rights: {},
    },
  }
}

const namesReducer = (reducer, name) => {
  const namedReducer = {}
  if (!name) { return reducer }
  Object
    .keys(reducer)
    .map(key => {
      const objectReducer = (state, { payload }) => ({
        ...state,
        [name.toLowerCase()]: reducer[key](state[name.toLowerCase()], { payload }),
      })
      return (
        namedReducer[`${name}/${key}`] = objectReducer
      )
    })
  return namedReducer
}

const addMail = (mails, newMail) => mails.find(mail => isEqual(mail, newMail)) ? mails : [...mails, newMail]

const APIreducer = {
  RESET: () => initialState,
  INIT_NOTIFICATIONS: (state, { payload }) => ({ ...state, notifications: [...new Set([...payload].map(JSON.stringify))].map(JSON.parse) }),
  ADD_NOTIFICATION: (state, { payload }) => ({ ...state, notifications: [...new Set([...state.notifications, payload].map(JSON.stringify))].map(JSON.parse) }),
  REMOVE_NOTIFICATION: (state, { payload }) => ({
    ...state,
    notifications: state
      .notifications
      .filter(notification => notification.id !== payload),
  }),
  REMOVE_PERSISTANT_FROM_NOTIFICATION: (state, { payload }) => ({
    ...state,
    notifications: state
      .notifications
      .map(notification => notification.id === payload ? {...notification, persistant: false, inProgress: false}: notification),
  }),
  READ_NOTIFICATION: (state, { payload }) => ({
    ...state,
    notifications: state
      .notifications
      .map(notification =>
        notification.id === payload ?
          ({ ...notification, read: true })
          : ({ ...notification }))
  }),
  READ_ALL_NOTIFICATIONS: (state) => ({ ...state, notifications: state.notifications.map(notification => ({ ...notification, read: true })) }),
  NOTIFICATION_ON_PROGRESS: (state, {payload}) => ({ 
    ...state, 
    notifications: state
    .notifications
    .map(notification => notification.id === payload ? ({ ...notification, inProgress: true }): notification) }),
  REMOVE_ALL_NOTIFICATIONS: (state) => ({ ...state, notifications: [] }),
  CHANGE_LANGUAGE: (state, { payload = 'fr' }) => {
    localStorage.setItem('language', payload)
    return ({
      ...state, language: languages[payload], languageValue: payload,
    })
  },
  SHOW_MENU: state => ({ ...state, menu: true }),
  HIDE_MENU: state => ({ ...state, menu: false }),
  HIDE_LANGUAGE: state => ({ ...state, languageHidden: true }),
  LOADING_ON: state => ({ ...state, rightSideLoading: true }),
  LOADING_OFF: state => ({ ...state, rightSideLoading: false }),
  SMTP_CONNECT: state => ({ ...state, smtp: true }),
  SMTP_DISCONNECT: state => ({ ...state, smtp: false, mails: [] }),
  ADD_MAIL: (state, { payload }) => ({ ...state, mails: addMail(state.mails, payload) }),
  SEE_MAIL: (state, { payload }) => ({
    ...state, mails: state
      .mails
      .map((mail, index) => index === payload ?
        { ...mail, seen: true } : mail)
  }),
  CHARGE_MAILS: (state, { payload }) => ({ ...state, mails: [...payload] }),
  MAILS_DISTANT_LENGHT: (state, { payload }) => ({ ...state, mailsLength: payload }),
  UPDATE_SOCKET: (state, { payload }) => ({ ...state, socket: payload }),
  UPDATE_FOLDER: (state, { payload }) => ({ ...state, ...payload }),
  ADD_ALERT: (state, { payload }) => ({ ...state, alerts: [...state.alerts, { ...payload }] }),
  REMOVE_ALERT: (state, { payload }) => ({
    ...state,
    alerts: state
      .alerts
      .filter((alert, id) => id !== payload),
  }),
  CHARGE_ENUMS: (state, { payload }) => ({ ...state, enums: { ...state.enums, ...payload } }),
  INIT_USERS: (state, { payload }) => ({ ...state, users: [...payload] }),
}


const userReducer = {
  INIT: (state, { payload }) => ({ ...payload }),
  UPDATE: (state, { payload }) => ({ ...state, ...payload }),
  RESET: state => ({ ...initialUser }),
}

const subscriptionReducer = {
  INIT: (state, { payload }) => ({ ...payload }),
  PAYMENT: (state, { payload }) => ({ ...state, ...payload }),
  RESET: () => ({ ...initialSocket }),
}

const reducers = {
  ...APIreducer,
  ...(namesReducer(userReducer, 'USER')),
  ...(namesReducer(subscriptionReducer, 'SOCKET')),
}

const reducer = (state = initialState, action) =>
  action.type in reducers ? reducers[action.type](state, action) : state

export default reducer
