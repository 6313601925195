import React, {
  useState, useContext, useEffect,
} from 'react'
import { resetPassword } from './helpers'
import UnLogGrid from '../../../components/UnLogGrid'
import { Store } from '../../../store/store'
import { Modal, Button } from 'semantic-ui-react'

const ForgotPassword = ({ match, history }) => {
  const {
    state:{
      language:{
        login:{
          leftSide: {
            mail: mailLang,
            forgotPasswordTitle:forgotPasswordTitleLang,
            validForgot:validForgotLang,
          },
        },
        forgotPassword: {
          titleModal:          titleModalLang,
          contentModal:contentModalLang,
          goTo:goToLang,
        },
      },
    },
  } = useContext(Store)
  const [email, setEmail] = useState(match.params.email && match.params.email !== 'null' ? match.params.email : '')
  const inputs = [
  {
    type:           'text',
    name:           'email',
    placeholder:    mailLang,
    required:       true,
    silentRequired: true,
    fluid:          true,
    value:          email,
  }
]
  const [loader, setLoader] = useState(false)
  const [modal, setModal] = useState(false)
  const goToReset = () => history.push('/')
  const sendMail = async values => {
    setLoader(true)
    try {
      await resetPassword(values)
      setEmail(values.email)
      setModal(true)
      setLoader(false)
    } catch (e) {
      setLoader(false)
      alert(e.message)
    }
  }
  useEffect(() => {
    if (modal === true) {
      const timer = setTimeout(goToReset, 5000)
      return () => clearTimeout(timer)
    }
  }, [modal, email])

  return (<div>
    <UnLogGrid
      inputs={inputs}
      sendForm={sendMail}
      page="forgot"
      message={forgotPasswordTitleLang}
      submitMessage={validForgotLang}
      loader={loader}
    />

    <Modal open={modal}
      onClose={goToReset}
      dimmer="inverted"
      closeIcon
      className="modal-login-container"
    >
      <Modal.Header>{titleModalLang}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {contentModalLang}
        </Modal.Description>
      </Modal.Content>

      <Button
        className="modal-login-button"
        onClick={goToReset}>
        {goToLang}
      </Button>
    </Modal>
  </div>
  )
}

export default ForgotPassword
