import React from 'react'
import {
  Form, Input, Label,
} from 'semantic-ui-react'
import ImageInput from './ImageInput'
import InfoInput from './InfoInput'
import { apiUrl } from '../containers/helpers'

const TextInput = ({
  onKeyPress = () => { },
  type,
  name,
  onChange,
  value,
  initialValue,
  error,
  labelObject,
  styles = { container: '' },
  placeholder,
  required,
  silentRequired = false,
  toCheck = false,
  disabled = false,
  options = [],
  maxLength = null,
  fluid = false,
  legend = null,
  inline = true,
  responsive = true,
  style = {},
  labelStyle = {},
  centered = false,
  info = null,
  label,
  handleEffect,
  requiredLang,
  fileLang,
  fileErrorLang,
  onBlur = null,
  forwardRef = null,
  children,
  onFocus = () => {},
  errorLogic,
  preview,
}) => {
  return (
    <Form.Field
      required={required}
      className={styles.container}
      style={responsive ? centered ? { display: 'flex', justifyContent: 'center' } : { display: 'flex' } : {}}
      inline={inline} >
      {label ?
				<label style={labelStyle} htmlFor={name + (initialValue ? '-' + initialValue : '')}>
				  {label}
				</label>:null
      }
      {type === 'image' ?       <>
      <ImageInput
        id={name + (initialValue ? '-' + initialValue : '')}

        name={name}
        onChange={handleEffect}
        // onBlur={handleEffect}
        fileLang={value ? typeof value === 'string' ? value : value.name : fileLang}
        fileErrorLang={fileErrorLang}
      />
      {(value && preview) ? <img className="preview-image" src={typeof value === 'string' ? `${apiUrl}/images/${value}` : URL.createObjectURL(value)} alt="object"/>:null}
      </>       :
      <Input
        {...(forwardRef ? { ref: forwardRef } : {})}
        onKeyPress={onKeyPress}
        id={name + (initialValue ? '-' + initialValue : '')}
        name={name}
        type={type}
        value={value || ''}
        placeholder={placeholder}
        onChange={handleEffect}
        onBlur={onBlur || handleEffect}
        fluid={fluid}
        style={style}
        className="h-fit"
        disabled={disabled}
        onFocus={onFocus}
        {...(maxLength ? { maxLength } : {})}
      />
      }
      {legend ? <div><p className="legend-input">{legend}</p></div>:null}
      {info ? <InfoInput info={info} />: null}
      {children}
      { (errorLogic || (required && error && !silentRequired)) ?
				<Label style={{
				  display:      'inline-table',
				  marginTop:    'auto',
				  marginBottom: 'auto',
				}} pointing={'left'} color={'red'} basic >
				  {errorLogic || requiredLang}
				</Label>: null
      }
    </Form.Field>
  )
}

export default TextInput
