import React from 'react';
import { fetchApi, parentsPaths, userRoles, getUser } from '../helpers';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
export const getItemsMenu = ({
    language: {
        menu: { mail: mailLang, support: supportLang, leenks: leenkLang, locker: lockerLang, spots: spotsLang },
    },
}) => {
    const { role } = getUser();

    const menuItems = [
        {
            label: leenkLang,
            iconLeft: <ForumRoundedIcon style={{ height: 20, width: 20 }} />,
            href: parentsPaths.leenks,
        },
        role !== userRoles.GUEST
            ? {
                  label: mailLang,
                  iconLeft: <MailOutlineIcon style={{ height: 20, width: 20 }} />,
                  href: parentsPaths.mail,
              }
            : undefined,
        {
            label: lockerLang,
            iconLeft: <FilterDramaIcon style={{ height: 20, width: 20 }} />,
            href: parentsPaths.locker,
        },
        role !== userRoles.GUEST
            ? {
                  label: spotsLang,
                  iconLeft: <WorkspacesIcon style={{ height: 20, width: 20 }} />,
                  href: parentsPaths.spot,
              }
            : undefined,
    ].filter((e) => e);

    const lastItem = {
        label: supportLang,
        iconLeft: 'life ring',
        href: '/support',
        className: 'last-item',
        index: menuItems.length,
    };

    return { menuItems, lastItem };
};

export const createFloor = (floors) =>
    fetchApi('/floor', {
        method: 'POST',
        body: {
            name: floors.length ? floors[floors.length - 1].name + 1 : 1,
            position: floors.length ? floors[floors.length - 1].name + 1 : 1,
            type: 'SPACE',
        },
    });

export const getFloors = () => fetchApi('/floors');

export const dispatchAlert = (dispatch, alerts, alert = { type: 'error', message: 'rergegreg' }) => {
    dispatch({ type: 'ADD_ALERT', payload: alert });
};

export default {
    getItemsMenu,
    dispatchAlert,
};
