export default ({ spacing, palette }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: 8 * 2,
        borderRadius: '0',
        borderBottom: '1px solid #efefef',
        backgroundColor: palette.background.paper,
    },
    speaker: {
        margin: '0 8px',
    },
    addIconButton: {
        marginLeft: spacing(1),
    },
});
