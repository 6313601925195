import React, { useEffect, useState, useContext } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Jutsu } from 'react-jutsu';
import { getUser } from '../../helpers';
import Messenger from '../../leenks/Leenks/Messenger';
import { useParams } from 'react-router';
import { getVisio, getPrivateVisio } from '../../spots/Spots/helpers';
// params in URL => room password subject leenkId meetingId
import { Switch } from 'react-router-dom';
import RenderMeeting from '../../locker/Locker/RenderMeeting';
import { getloginPaths } from '../../helpers';
import { AuthRoute } from '../../../components/GuardRoutes';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { Store } from '../../../store/store';
import queryString from 'query-string';
import HorizontallyCollapsableColumn from '../../../components/HorizontallyCollapsableColumn';

import styles from './Meet.styles';
import { getLeenk } from '../../notification/Notification/helpers';

const useStyles = makeStyles(styles);

const Meet = ({ location }) => {
    const classes = useStyles();
    const loginPaths = getloginPaths();
    const [room, setRoom] = useState('');
    const [call, setCall] = useState(false);
    const meetId = Number(useParams().meetId);
    const getValues = queryString.parse(location.search) || {};
    const user = getUser();
    const [password, setPassword] = useState(null);
    const [subject, setSubject] = useState(null);
    const [leenk, setLeenk] = useState(null)
    const [folderId, setFolderId] = useState(null);
    const history = useHistory();
    const {
        state: {
            language: {
                general: { loading: loadingLang },
                meeting: { join: joinLang },
            },
        },
    } = useContext(Store);
    useEffect(() => {
        if (room) {
            setCall(true);
        }
    }, [room]);

    useEffect(() => {
        if (getValues.private) {
            getLeenk(getValues.leenkId)
            .then(l => setLeenk(l.folder ? l : {
                ...l,
                folder: l.parent
            }))
            .catch(e => console.log('error during loading leenk', e))
            setFolderId(getValues.folderId);
            getPrivateVisio(meetId, getValues.folderId)
                .then(({ visio }) => {
                    setPassword(visio.password);
                    setSubject(visio.title);
                    setRoom(visio.title);
                })
                .catch((err) => {
                    history.push(`/spots/0`);
                });
        } else
            getVisio(meetId)
                .then(({ visio, leenkId, folderId, files, folders, folder }) => {
                    getLeenk(leenkId)
                    .then(l => setLeenk(l.folder ? l : {
                        ...l,
                        folder: l.parent
                    }))
                    .catch(e => console.log('error during loading leenk', e))
                    setPassword(visio.password);
                    setSubject(visio.meeting_title);
                    setRoom(visio.title);
                    setFolderId(folderId);
                })
                .catch((err) => {
                    history.push(`/spots/0`);
                });
    }, [meetId]);
    const handleClick = (event) => {
        event.preventDefault();
        if (room) setCall(true);
    };
    
    return (
        <Grid container className="page-container border-radius-5 shadow bg-white full-screen-window-container">
            {call ? (
                <>
                    <Box className={classes.jutsu}>
                        <Jutsu
                            roomName={room}
                            subject={subject}
                            password={password}
                            displayName={`${user.firstname} ${user.lastname}`}
                            containerStyles={{ width: '100%', height: '100%' }}
                            loadingComponent={<p>{loadingLang} ...</p>}
                        />
                    </Box>
                    <HorizontallyCollapsableColumn
                        classes={{ root: classes.horizontallyCollapsableColumn }}
                        title="Leenks & Locker"
                        maxWidth={600}
                    >
                        <Box className={classes.messengerLocker}>
                            {leenk && <Messenger
                                seeSpeakers={false}
                                parentType={'MEETING'}
                                leenk={leenk}
                                parentId={getValues.private ? folderId : meetId}
                                folderId={getValues.private ? folderId : meetId}
                                leenks={[]}
                                propStyle={{ maxHeight: 300 }}
                            />}
                            <Switch>
                                <AuthRoute
                                    exact
                                    path={loginPaths.meetFolders}
                                    component={RenderMeeting}
                                    props={{ parentFolderId: folderId }}
                                />
                                <AuthRoute
                                    exact
                                    path={loginPaths.meetFolder}
                                    component={RenderMeeting}
                                    props={{ parentFolderId: folderId }}
                                />
                            </Switch>
                        </Box>
                    </HorizontallyCollapsableColumn>
                </>
            ) : (
                <form onSubmit={handleClick}>
                    <input
                        id="room"
                        type="text"
                        placeholder="Room"
                        value={room}
                        onChange={(e) => setRoom(e.target.value)}
                    />
                    <button onClick={handleClick} type="submit">
                        {joinLang}
                    </button>
                </form>
            )}
        </Grid>
    );
};
export default Meet;
