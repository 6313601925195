import React from 'react'

const iconsTypes = {
	calendar: (props)=> <svg
	xmlns="http://www.w3.org/2000/svg"
	viewBox="0 0 80.35 80.35"
	{...props}
  >
	<defs>
	  <style>{".prefix__cls-2{fill:#007ed3}"}</style>
	</defs>
	<g opacity={0.86} id="prefix__Calque_2" data-name="Calque 2">
	  <path
		className="prefix__cls-2"
		d="M80.35 6.28H67.16V0h-5.27v6.28H18.46V0h-5.28v6.28H0v74.07h80.35zm-75.83 6h8.26v6.6h5.61v-6.6H62v6.6h5.6v-6.6h8.26V23.5H4.52zm71.3 62.94H4.52V28.35h71.3z"
	  />
	  <path
		className="prefix__cls-2"
		d="M11.93 36.09h6.28v6.28h-6.28zM24.48 36.09h6.28v6.28h-6.28zM37.03 36.09h6.28v6.28h-6.28zM49.59 36.09h6.28v6.28h-6.28zM62.14 36.09h6.28v6.28h-6.28zM11.93 48.65h6.28v6.28h-6.28zM24.48 48.65h6.28v6.28h-6.28zM37.03 48.65h6.28v6.28h-6.28zM49.59 48.65h6.28v6.28h-6.28zM11.93 61.2h6.28v6.28h-6.28zM24.48 61.2h6.28v6.28h-6.28zM37.03 61.2h6.28v6.28h-6.28zM49.59 61.2h6.28v6.28h-6.28zM62.14 48.65h6.28v6.28h-6.28z"
	  />
	</g>
  </svg>,
	note: (props)=> <svg
	xmlns="http://www.w3.org/2000/svg"
	viewBox="0 0 88.14 88.14"
	{...props}
  >
	<defs>
	  <style>{".prefix__cls-2{fill:#007ed3}"}</style>
	</defs>
	<g opacity={0.86} id="prefix__Calque_2" data-name="Calque 2">
	  <path
		className="prefix__cls-2"
		d="M42.57 0H14.45v5.19H0v83h57v-83H42.57zM19.62 5.16h17.79v5.21H19.62zm32.24 5.19V83H5.16V10.35h9.29v5.19h28.12v-5.19z"
	  />
	  <path
		className="prefix__cls-2"
		d="M12.95 31.12h5.19v5.16h-5.19zM23.33 31.12h20.74v5.16H23.33zM12.95 41.49h5.19v5.16h-5.19zM23.33 41.49h20.74v5.16H23.33zM12.95 51.86h5.19v5.16h-5.19zM23.33 51.86h20.74v5.16H23.33zM12.95 62.23h5.19v5.16h-5.19zM23.33 62.23h20.74v5.16H23.33zM77.77 10.37V5.19h-5.19V0h-5.16v5.19h-5.19v70.88a7.78 7.78 0 005.19 7.32v4.75h5.16v-4.75a7.78 7.78 0 005.19-7.32V15.54H83V33.7h5.17V10.37zm-5.17 0v10.37h-5.21V10.35zm0 15.56v5.21h-5.21v-5.23zM70 78.67a2.61 2.61 0 01-2.61-2.6V36.28h5.21v39.79a2.61 2.61 0 01-2.6 2.6z"
	  />
	</g>
  </svg>,
	notification: (props)=> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.19 47" {...props}>
	<path
	  d="M36.42 37.4a7.29 7.29 0 01-2.22-3c-1.91-4.05-2.31-9.75-2.31-13.82A13.32 13.32 0 0024 8.42V5.24A5.24 5.24 0 0018.81 0h-.43a5.24 5.24 0 00-5.23 5.24v3.18A13.32 13.32 0 005.3 20.56c0 4.07-.4 9.77-2.31 13.82a7.29 7.29 0 01-2.22 3A1.35 1.35 0 000 38.92 1.4 1.4 0 001.41 40h10.1a7.09 7.09 0 0014.17 0h10.09a1.4 1.4 0 001.38-1.08 1.33 1.33 0 00-.73-1.52zM15.9 5.24a2.48 2.48 0 012.48-2.48h.43a2.48 2.48 0 012.47 2.48v2.28a13.54 13.54 0 00-5.38 0zm2.69 39A4.34 4.34 0 0114.26 40h8.67a4.35 4.35 0 01-4.34 4.24zm5.55-7H4.55A13.07 13.07 0 005.27 36c1.85-3.64 2.78-8.82 2.78-15.41a10.54 10.54 0 1121.08 0v.05c0 6.56.94 11.73 2.78 15.35.25.48.49.9.73 1.27z"
	  fill="#4e5962"
	  data-name="Calque 2"
	/>
  </svg>,
  settings: (props) =>   <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 28.18 28.18"
  {...props}
>
  <path
	d="M16.78 28.18a1.24 1.24 0 01-.9-.4l-1.41-1.57a5.08 5.08 0 01-.78 0l-1.41 1.56a1.22 1.22 0 01-1.15.38 14.32 14.32 0 01-4.91-2A1.2 1.2 0 015.68 25l.11-2.1c-.19-.18-.38-.36-.56-.55l-2.1.11a1.22 1.22 0 01-1.07-.55A14.09 14.09 0 010 17a1.21 1.21 0 01.4-1.12L2 14.47v-.38-.4L.4 12.28a1.19 1.19 0 01-.4-1.14 14.27 14.27 0 012-4.92 1.15 1.15 0 011.07-.54l2.1.11c.18-.19.37-.38.56-.56l-.11-2.1a1.22 1.22 0 01.55-1.07 14.2 14.2 0 014.91-2 1.19 1.19 0 011.21.34L13.7 2h.79L15.9.4A1.2 1.2 0 0117 0a14.22 14.22 0 014.91 2 1.22 1.22 0 01.55 1.07l-.11 2.1c.19.18.37.37.55.56l2.1-.04a1.21 1.21 0 011.07.54 14.16 14.16 0 012 4.92 1.21 1.21 0 01-.37 1.14l-1.49 1.41v.79l1.56 1.41a1.22 1.22 0 01.38 1.1 14.16 14.16 0 01-2 4.91 1.18 1.18 0 01-1.15.59l-2.1-.11c-.18.19-.36.37-.55.55l.11 2.1a1.2 1.2 0 01-.55 1.07 14.08 14.08 0 01-4.92 2 1 1 0 01-.21.07zM15 23.76a1.25 1.25 0 01.9.4l1.32 1.47A11.93 11.93 0 0020 24.45l-.1-2a1.24 1.24 0 01.42-1 9.36 9.36 0 001.14-1.13 1.19 1.19 0 011-.42l2 .1a11.37 11.37 0 001.18-2.84l-1.46-1.32a1.21 1.21 0 01-.39-1v-.8-.8a1.2 1.2 0 01.39-1L25.63 11a11.76 11.76 0 00-1.18-2.84l-2 .1a1.26 1.26 0 01-1-.43 10 10 0 00-1.13-1.13 1.22 1.22 0 01-.42-1l.1-2a12.36 12.36 0 00-2.84-1.18L15.89 4a1.27 1.27 0 01-1 .4 10.82 10.82 0 00-1.61 0 1.28 1.28 0 01-1-.4L11 2.55a11.37 11.37 0 00-2.86 1.18l.1 2a1.2 1.2 0 01-.43 1 9.37 9.37 0 00-1.13 1.08 1.22 1.22 0 01-1 .42l-2-.1A11.6 11.6 0 002.55 11L4 12.29a1.21 1.21 0 01.4 1v1.6a1.23 1.23 0 01-.4 1L2.55 17.2A11.53 11.53 0 003.73 20l2-.1a1.2 1.2 0 011 .42 8.62 8.62 0 001.07 1.17 1.22 1.22 0 01.43 1l-.1 2A11.22 11.22 0 0011 25.62l1.32-1.46a1.2 1.2 0 011-.39 10.68 10.68 0 001.6 0zm-.89-4.47a5.2 5.2 0 115.2-5.2 5.21 5.21 0 01-5.22 5.2zm0-8a2.78 2.78 0 102.78 2.78 2.78 2.78 0 00-2.8-2.76z"
	fill="#383cd5"
	data-name="Calque 2"
  />
</svg>,
task: (props) =>  <svg
xmlns="http://www.w3.org/2000/svg"
viewBox="0 0 78.96 85.66"
{...props}
>
<defs>
  <style>{".prefix__cls-2{fill:#007ed3}"}</style>
</defs>
<g opacity={0.86} id="prefix__Calque_2" data-name="Calque 2">
  <path
	className="prefix__cls-2"
	d="M4.41 0v11.33H0v5h4.41v9.49H0v5h4.41v9.48H0v5h4.41v9.49H0v5h4.41v9.49H0v5h4.41v11.38H79V0zm69.53 80.65H9.43v-6.32h4.42v-5H9.43v-9.5h4.42v-5H9.43v-9.5h4.42v-5H9.43v-9.49h4.42v-5H9.43v-9.5h4.42v-5H9.43V5h64.51z"
  />
  <path
	className="prefix__cls-2"
	d="M48.38 19.54h16.28v5.01H48.38zM48.38 40.34h16.28v5.01H48.38zM48.38 62.77h16.28v5.01H48.38zM40.69 17.37l-3.55-3.54-8.14 8.1-3.75-3.75-3.55 3.54L29 29zM40.69 38.47l-3.55-3.54L29 43l-3.75-3.75-3.55 3.54 7.3 7.32zM40.69 59.58L37.14 56 29 64.13l-3.75-3.74-3.55 3.54 7.3 7.29z"
  />
</g>
</svg>
}
	

const AppIcons = ({
	name = '0',
	color = 'black',
	size = 22,
	style = {},
	viewBox: initViewBox = '0 0 512 512',
	className,
}) => {
	const props = {
		width: size,
		height: size,
		style
	}
	return iconsTypes[name](props)
	
}

export default AppIcons
