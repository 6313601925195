import React, { useContext } from 'react';
import TitleComponent from './TitleComponent';
import { Button, Grid } from '@material-ui/core';
import { Store } from '../store/store';
import Skeleton from 'react-loading-skeleton';

const RightSide = ({
    title,
    subtitle = '',
    underSubtitle = '',
    children,
    previous = null,
    nextButton = null,
    loading = false,
    skeletonLoading = false,
}) => {
    const {
        state: {
            language: {
                rightSide: { nextButton: nextButtonLang },
            },
        },
    } = useContext(Store);
    const nextButtonFinal = {
        message: nextButtonLang,
        position: 'right',
        size: 'large',
        color: 'default',
        onClick: () => {},
        available: !loading,
        ...nextButton,
    };
    return (
        <>
            {/* {skeletonLoading ? <div className="title-container"><Skeleton height={37} width={500} /></div> : <TitleComponent {...(typeof title === 'object' ?  title : { title })} subtitle={subtitle} underSubtitle={underSubtitle} previous={previous}/>} */}
            <Grid container className="page-container border-radius-5 shadow bg-white">
                {children}
            </Grid>
            {nextButton ? (
                <Button
                    onClick={nextButtonFinal.onClick}
                    size={nextButtonFinal.size}
                    className="next-step-button purple-background-button"
                    color={nextButtonFinal.color}
                    disabled={!nextButtonFinal.available}
                    loading={loading}
                >
                    {nextButtonFinal.message || nextButtonLang}
                </Button>
            ) : null}
        </>
    );
};

export default RightSide;
