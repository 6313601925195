import styles from './Leenks.styles';
import React, { useCallback, useRef, useContext, useState } from 'react';
import PopperPaper from '../../../components/PopperPaper';
import SearchUserField from '../../../components/SearchUserField';
import { TextField, makeStyles, Button } from '@material-ui/core';
import { Store } from '../../../store/store';
import { isString } from 'lodash';
import { sendEmailToCreateSpace, createSharedTeam } from './helpers';
import { dispatchAlert } from '../../menu/helpers';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(styles);
const ExternalInteractionButton = () => {
	const classes = useStyles();
	const history = useHistory()
	const [anchorElement, setAnchorElement] = useState();
	const subjectRef = useRef(null);
	const teamRef = useRef(null);
	const { state: { alerts, language: { leenks: {
		subject: subjectLang,
		team: teamLang,
		interact: interactLang,
		interactCompany: interactCompanyLang,
		invalidEmail: invalidEmailLang,
		emailSent: emailSentLang
	} } }, dispatch } = useContext(Store)
	const openPopper = Boolean(anchorElement);

	const closePopper = useCallback(() => setAnchorElement(null), []);

	const handleButtonClick = useCallback((event) => setAnchorElement(event.currentTarget), []);
	const [hidden, setHidden] = useState(true)
	const onUpdate = () => setHidden(!!!subjectRef.current.value || !!!teamRef.current.value)
	//Fonction création de shared pole
	const interactWithExtern = async (event, user) => {
		if (!!!subjectRef.current.value || !!!teamRef.current.value) {
			return
		}
		const subject = subjectRef.current.value
		const team = teamRef.current.value
		if (!!!user) {
			return
		}
		try {
			if (isString(user)) {
				if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(user))
					throw new Error(invalidEmailLang)
				sendEmailToCreateSpace(user)
				dispatchAlert(dispatch, alerts, { type: 'success', message: emailSentLang })
				return closePopper()
			}
			const { id, company } = user
			// create shared_team (name to update when collabo receive notification) + subject (name can be updated too) 
			const { id: leenkId } = await createSharedTeam({ userId: id, subject, team, company })
			dispatchAlert(dispatch, alerts, { type: 'success' })
			closePopper()
			return history.push('/leenk/' + leenkId)
		} catch (e) {
			dispatchAlert(dispatch, alerts, { type: 'error', message: e.message })
		}
	}

	return (
		<>
			<PopperPaper
				classes={{ paper: classes.popperPaper }}
				anchorEl={anchorElement}
				open={openPopper}
				onClose={closePopper}
			>
				<TextField
					variant={'outlined'}
					inputRef={teamRef}
					placeholder={teamLang}
					onChange={onUpdate}
				/>
				<TextField
					variant={'outlined'}
					inputRef={subjectRef}
					placeholder={subjectLang}
					onChange={onUpdate}
				/>
				<SearchUserField
					onChange={interactWithExtern}
					hidden={hidden}
					external={true}
					notMyCompany
				/>
			</PopperPaper>
			<Button classes={{ root: classes.root }} variant="outlined" onClick={handleButtonClick}>
				{interactCompanyLang}
			</Button>
		</>
	);
};

export default ExternalInteractionButton;