import React, { useCallback, useContext, useState } from 'react';
import { IconButton, Tooltip, makeStyles } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { Store } from '../../../../store/store';

import styles from './AddParticipant.styles';
import PopperPaper from '../../../../components/PopperPaper';
import SearchUserField from '../../../../components/SearchUserField';
import { createMeetingAccess } from '../helpers';
import { dispatchAlert } from '../../../menu/helpers';
const useStyles = makeStyles(styles);

const AddParticipant = ({ users, setUsers, meetingId, leenkId, classes: receivedClasses }) => {
    const classes = useStyles({ classes: receivedClasses });
    const [anchorElement, setAnchorElement] = useState();

    const open = Boolean(anchorElement);

    const {
        state: {
            alerts,
            language: {
                spots: { userAdded: userAddedLang },
            },
        },
        dispatch,
    } = useContext(Store);

    const handleIconButtonClick = useCallback(
        (event) => {
            if (open) {
                setAnchorElement(null);
                return;
            }
            setAnchorElement(event.currentTarget);
        },
        [open]
    );

    const closePopper = useCallback(() => setAnchorElement(null), []);

    const handleSearchUserFieldChange = useCallback(
        async (event, newParticipant) => {
            // Not yet created meeting.
            if (!meetingId) {
                try {
                    setUsers((users) => [...users, newParticipant]);
                    dispatchAlert(dispatch, alerts, { type: 'success', message: userAddedLang });
                } catch (e) {
                    dispatchAlert(dispatch, alerts, { type: 'error' });
                }
                closePopper();
                return;
            }
            try {
                await createMeetingAccess(meetingId, newParticipant.id, leenkId);
                setUsers((users) => [...users, newParticipant]);
                closePopper();
                dispatchAlert(dispatch, alerts, { type: 'success', message: userAddedLang });
            } catch (e) {
                console.log('e -= > ', e);
                dispatchAlert(dispatch, alerts, { type: 'error' });
            }
            closePopper();
        },
        [users, dispatch, alerts, userAddedLang, meetingId]
    );

    const {
        state: {
            language: {
                leenks: { addUser: addUserLang },
            },
        },
    } = useContext(Store);

    return (
        <>
            <PopperPaper
                classes={{ paper: classes.popperPaper }}
                {...{ open }}
                anchorEl={anchorElement}
                onClose={closePopper}
            >
                <SearchUserField freeSolo={false} external withoutUsers={users} onChange={handleSearchUserFieldChange} />
            </PopperPaper>
            <Tooltip placement="top" title={addUserLang}>
                <IconButton classes={{ root: classes.addIconButton }} onClick={handleIconButtonClick}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
        </>
    );
};

export default AddParticipant;
