import { fetchApi, getUser } from "../../helpers";

export const getCustomers = () => fetchApi('/customers')

export const getSuppliers = () => fetchApi('/suppliers')

export const getTeams = () => fetchApi('/teams')

export const getSharedTeams = () => fetchApi('/sharedTeams')

export const getLeenkFromURL = leenkId => fetchApi(`/leenk/${leenkId}`)

export const getLeenk = (id, type) => fetchApi(`/leenks/${type}/${id}`)

export const getSpeakers = leenkId => fetchApi(`/leenk/speakers/${leenkId}`)

export const getMessages = (id, offset = 0) => fetchApi(`/message/leenk/${id}?offset=${offset}`).then(data => data.reverse())

export const createTeam = (name) => fetchApi('/team', {
	method: 'POST',
	body: { name }
})

export const createSupplier = (name) => fetchApi('/supplier', {
	method: 'POST',
	body: { name }
})

export const createCustomer = (name) => fetchApi('/customer', {
	method: 'POST',
	body: { name }
})

export const createLeenk = (subject, parentId, parentType, folderId) => fetchApi('/leenk', {
	method: 'POST',
	body: {
		parent: parentId,
		name: subject,
		parentType,
		folderId
	}
})
export const createPrivateLeenk = (userIds = [], notNotif) => fetchApi('/leenk/private', {
	method: 'POST',
	body: {
		userIds,
		notNotif
	}
})

export const sendMessageApi = (to = 0, message = '', mentions = [], file = null) =>
	fetchApi('/message',
		{
			method: 'POST',
			body: {
				to,
				message,
				mentions,
				...(file ? file: {})
			}
		})
export const sendGuestMessageApi = (to, message, mentions, userId) =>
	fetchApi('/message/' + userId,
		{
			method: 'POST',
			body: {
				to,
				message,
				mentions,
			}
		})

export const sendVocalToApi = (fd, to) =>
	fetchApi(`/message/voice/${to}`, {
		method: 'POST',
		body: fd,
		multiform: true
	})

export const getUsers = leenkId => fetchApi(`/message/users/${leenkId}`)

export const getTasks = () => fetchApi('/tasks')
export const getPrivateLeenk = (user) => fetchApi(`/private/leenk/${user}`)
export const getPrivateLeenks = () => fetchApi(`/leenks/private`)

export const createPrivateDocx = (folderId, fileName, user) => fetchApi('/private/docx',
	{
		method: 'POST',
		body: {
			folderId, fileName, user
		}
	})

export const sendEmailToCreateSpace = email => fetchApi('/company/mail',
	{
		method: 'POST',
		body: {
			email
		}
	})

export const createSharedTeam = ({ userId, subject, team, company }) => fetchApi('/sharedTeam',
	{
		method: 'POST',
		body: {
			userId, subject, team, company
		}
	})

export const deleteSubject = leenkId => fetchApi('/sharedTeam/subject/' + leenkId,
	{
		method: 'DELETE',
	})

export const validateSharedTeam = leenkId => fetchApi('/sharedTeam/validate/' + leenkId,
	{
		method: 'PUT',
	})

export const createCompaniesFromTeams = teams => {
	const companies = [{ company: getUser().company, name: getUser().name, teams: [] }]
	for (let index = 0; index < teams.length; index++) {
		const team = teams[index];
		const indexOfCompany = companies.findIndex(({ company }) => company && team.company === company)
		if (indexOfCompany === -1) {
			companies.push({ company: team.company, name: team.companyName, teams: [team] })
		} else {
			if (!companies[indexOfCompany].name) {
				companies[indexOfCompany].name = team.companyName
			}
			companies[indexOfCompany].teams.push(team)
		}
	}
	return companies
}

export const updateLeenk = (leenkId, body) => fetchApi('/leenk/' + leenkId,
	{
		method: 'PATCH',
		body
	})
export const updateTeam = (teamId, body) => fetchApi('/team/' + teamId,
	{
		method: 'PATCH',
		body
	})
export const updateSharedTeam = (teamId, body) => fetchApi('/sharedTeam/' + teamId,
	{
		method: 'PATCH',
		body
	})
export default {
	getCustomers,
	getLeenk,
	createLeenk,
	getSpeakers,
	createPrivateLeenk,
	getPrivateLeenks,
	sendGuestMessageApi,
	sendEmailToCreateSpace,
	createSharedTeam,
	getTeams,
	getSharedTeams,
	deleteSubject,
	validateSharedTeam,
	createCompaniesFromTeams,
	updateLeenk,
	updateTeam,
	updateSharedTeam
}
