export default {
    root: {
        overflowX: 'hidden',
        height: '100%',
    },
    content: {
        width: 'fit-content',
        height: '100%',
    },
};
