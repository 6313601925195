import React, { useEffect, useState, useContext } from 'react';
import { Grid, Button } from "@material-ui/core";
import { getUsers, getAccess, getCompanyTeams } from "./helpers";
import Users from "./Users";
import { Section, CustomizedDataGrid } from "./Section";
import { getLeenk, getFolders, getMeetingsForAdmin } from "./helpers";
import { Store } from "../../../store/store";
import { useParams, useHistory } from 'react-router-dom';
import { inviteCollaborator } from '../../home/Home/helpers';
import { dispatchAlert } from '../../menu/helpers';
import { getUser } from '../../helpers';

const Setting = () => {
  const params = useParams()
  const history = useHistory()
  const userId = params.userId ? Number(params.userId) : null
  const [users, setUsers] = useState([])
  const [teams, setTeams] = useState(null)
  const [folders, setFolders] = useState(null)
  const [meetings, setMeetings] = useState(null);
  const [access, setAccess] = useState([]);
  const { state, dispatch } = useContext(Store);
  const {
    alerts,
    language: {
      settings: {
        teams: teamsLang,
        customers: customersLang,
        suppliers: suppliersLang,
        meetings: meetingsLang,
        folders: foldersLang,
        userProfile: userProfileLang,
        emailUserAlreadyExists:emailUserAlreadyExistsLang
      },
      access: accessLanguage
    },
    // rightSideLoading
  } = state;
  const setUserId = id => history.push('/setting/user/' + id)
  const columnsLeenk = [
    { field: 'rights', headerName: 'Droits', width: 90 },
    { field: 'name', headerName: 'Name', width: 90 },
    { field: 'parentName', headerName: 'Space', width: 90 },
    { field: 'companyName', headerName: 'Company', width: 120 },
  ];

  const foldersColumn = [
    { field: 'rights', headerName: 'Droits', width: 90 },
    { field: 'name', headerName: 'Name', width: 90 },
    { field: 'id', width: 15 }
  ]

  const meetingsColumn = [
    { field: 'rights', headerName: 'Droits', width: 90 },
    { field: 'title', headerName: 'Title', width: 90 },
    { field: 'description', headerName: 'Description', width: 120 }
  ]


  const getAccessFromType = (element, access, type = 'LEENK') => (
    access
      .find(e => e.type === type && e.element_id === element.id)
    || {}
  ).rights

  const addUser = async (email, rights) => {
    try {
      const newUser = await inviteCollaborator({ email, rights })
      if (newUser.alreadyExists){
        return dispatchAlert(dispatch, alerts, { type: 'error', message: emailUserAlreadyExistsLang })
      }
      setUsers(users => !!users.find(user => user.id === newUser.id) ? [...users] : [newUser, ...users])
      dispatchAlert(dispatch, alerts, { type: 'success' })
    } catch (e) {
      dispatchAlert(dispatch, alerts, { type: 'error' })
    }

  }
  const { name: companyName } = getUser()
  const getLeenkFromParent = (data, parent, access) => Promise.all(
    data.map(e =>
      getLeenk(e.id, e.shared ? 'shared_team' : parent)
        .then(data =>
          data.map(leenk =>
            ({
              ...leenk,
              parentName: e.name,
              folderId: e.folderId,
              rights: getAccessFromType(leenk, access, 'LEENK'),
              companyName: e.companyName || companyName
            })
          ))))
  useEffect(() => {
    if (access) {
      //get all sharedTeam how includes myCompany
      getCompanyTeams()
        .then(data => {
          getLeenkFromParent(data, 'team', access)
            .then((data) => setTeams(data.flat(1)))
        })
      getFolders().then(data => setFolders(
        data.map(element =>
          ({
            ...element,
            rights: getAccessFromType(element, access, 'FOLDER')
          }))
      ))
      getMeetingsForAdmin().then(data => setMeetings(
        data.map(element =>
          ({
            ...element,
            rights: getAccessFromType(element, access, 'MEETING')
          }))
      ))
    }
  }, [access])

  useEffect(() => {
    if (userId) {
      getAccess(userId).then(data => setAccess(data))
    }
  }, [userId])

  useEffect(() => {
    getUsers().then(u => setUsers([getUser(), ...u]))
  }, [])

  const getReadableRights = rights => accessLanguage[rights]
  const isRenderer = () => [
    users,
    teams
    , folders
    , meetings
  ].filter(arr => !Array.isArray(arr)).length === 0

  //       useEffect(()=>{
  //         if([teams
  //           ,folders
  //           ,meetings].filter(arr => !!!arr).length === 0 && rightSideLoading){
  // dispatch({type: 'LOADING_OFF'})
  //           }
  //       }, [teams
  //         ,folders
  //         ,meetings])
  const navigateToUser = () => history.push('/user/' + userId)
  return (
    <Grid container className="page-container border-radius-5 shadow bg-white">
      <Users users={users} userId={userId} setUserId={setUserId} addUser={addUser} />
      {!!userId && !!isRenderer()
        ? <Grid container item xs={10} direction="column" wrap="nowrap" className="leenks-section scroll-y scrollbar-disable">
          <Button onClick={navigateToUser}>{userProfileLang}</Button>
          <Section title="Leenks">
            <CustomizedDataGrid
              titleLang={teamsLang}
              title="team"
              type="leenk"
              userId={userId}
              rows={teams.map(element => ({
                ...element,
                valueToCompare: element.name,
                rights: getReadableRights(element.rights),
              }))}
              setRows={setTeams}
              columns={columnsLeenk}
            />
          </Section>
          {/* <Section title={foldersLang}>
            <CustomizedDataGrid
              userId={userId}
              type={'folder'}
              rows={folders.map(element => ({
                ...element,
                valueToCompare: element.name,
                rights: getReadableRights(element.rights),
              }))}
              setRows={setFolders}
              columns={foldersColumn}
            />
          </Section> */}
          <Section title={meetingsLang}>
            <CustomizedDataGrid
              type={'meeting'}
              userId={userId}
              rows={meetings.map(element => ({
                ...element,
                valueToCompare: element.title + element.description,
                rights: getReadableRights(element.rights),
              }))}
              setRows={setMeetings}
              columns={meetingsColumn}
            />
          </Section>
        </Grid>
        : null
      }
    </Grid>
  );
};

export default Setting;
