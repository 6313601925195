import React, { useState, useContext, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Popover, List, ListItem, ListItemText, makeStyles, IconButton, TextField } from '@material-ui/core';
import { Store } from '../../../../store/store';
import { updateFile, removeFile } from '../helpers';
import { dispatchAlert } from '../../../menu/helpers';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ShareFolderSearchBar from './ShareFolderSearchBar';
import { upperFirst } from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        border: 'none',
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    folders: {
        padding: theme.spacing(4),
        display: 'flex',
        flexWrap: 'wrap',
    },
    createFolderContainer: {
        display: 'flex',
        padding: 8,
    },
    popperPaper: {},
    popperPaperActions: {
        margin: theme.spacing(1, 0, 0),
    },
    inputContainer: {
        position: 'absolute',
        borderRadius: 5,
        padding: theme.spacing(1),
        backgroundColor: '#F4F5F5',
        top: '100%',
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    input: {
        borderRadius: 5,
        padding: theme.spacing(1),
        backgroundColor: '#fff',
    },
    list: {
        color: 'rgba(0, 0, 0, .87)',
    },
    dialogContent: {
        display: 'flex',
    },
    moveFolderContainer: {
        margin: theme.spacing(1),
    },
    file: {
        padding: theme.spacing(2),
        backgroundColor: '#EFEFEF',
        borderRadius: 10,
        color: '#45535C',
        cursor: 'pointer',
    },
    buttonRight: {
        position: 'realtive',
        zIndex: 2,
    },
    modal: {
        backgroundColor: 'white',
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    dividerFiles: {
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(6),
    },
}));
const UpdateFile = ({ file, setFiles }) => {
    const dialogTypes = {
        RENAME: 0,
        REMOVE: 1,
        SHARE: 2
    }
    const inputRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [modal, setModal] = useState(false);
    const [dialog, setDialog] = useState({})
    const open = Boolean(anchorEl);
    const classes = useStyles();
    const {
        dispatch,
        state: {
            alerts,
            language: {
                folder: { remove: removeLang, rename: renameLang, share: shareLang,
                    cancel: cancelLang },
            },
        },
    } = useContext(Store);




    const handleOnClick = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };


    const handleClose = (e) => {
        e.stopPropagation();
        setModal(false);
    };

    const closePopover = (e) => {
        if (e) {
            e.stopPropagation();
        }
        setAnchorEl(null);
    }

    const openDialog = (e, type) => {
        setModal(true)
        const dialog = {}
        switch (type) {
            case dialogTypes.REMOVE:
                dialog.title = removeLang
                break;
            case dialogTypes.RENAME:
                dialog.title = renameLang
                break;
            case dialogTypes.SHARE:
                dialog.title = shareLang
                break;
            default:
                dialog.title = renameLang
                break;
        }
        setDialog({
            ...dialog,
            type
        })
    }
    const rename = async () => {
        try {
            const { name } = await updateFile(file.id, { name: inputRef.current.value })
            setFiles(files => files.map(f => f.id === file.id ? { ...f, name } : f))
            dispatchAlert(dispatch, alerts, { type: 'success' })
            setModal(false)
            closePopover()
        } catch (e) {
            dispatchAlert(dispatch, alerts, { type: 'error' })
        }
    }

    const remove = async () => {
        try {
            await removeFile(file.id)
            setFiles(files => files.filter(f => f.id !== file.id))
            dispatchAlert(dispatch, alerts, { type: 'success' })
            setModal(false)
            closePopover()
        } catch (e) {
            dispatchAlert(dispatch, alerts, { type: 'error' })
        }
    }
    return (
        <>
            <Dialog open={modal} onClose={handleClose}>
                <DialogTitle>
                    {dialog.title}{dialog.type === dialogTypes.SHARE ? `: ${upperFirst(file.name)}` : ''}
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    {dialog.type === dialogTypes.RENAME && <>
                        <TextField variant={'outlined'} inputRef={inputRef} defaultValue={file.name} />
                        <Button onClick={rename}>{renameLang}</Button>
                    </>}
                    {dialog.type === dialogTypes.REMOVE && <>
                        <Button onClick={handleClose}>{cancelLang}</Button>
                        <Button onClick={remove}>{removeLang}</Button>
                    </>}
                    {dialog.type === dialogTypes.SHARE && <>
                        <ShareFolderSearchBar file={file} />
                    </>}
                </DialogContent>
            </Dialog>
            <div>
                <IconButton
                    aria-describedby={file.id}
                    classes={{ root: classes.buttonRight }}
                    onClick={handleOnClick}
                >
                    <MoreVertIcon />
                </IconButton>
            </div>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={closePopover}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List className={classes.list}>
                    {/* <ListItem button onClick={handleMooveClick}>
                        <ListItemText primary={moveLang} />
                    </ListItem>
                    <ListItem button onClick={handleShareClick}>
                        <ListItemText primary={shareLang} />
                    </ListItem> */}
                    <ListItem button onClick={(e) => openDialog(e, dialogTypes.RENAME)}>
                        <ListItemText primary={renameLang} />
                    </ListItem>
                    <ListItem button onClick={(e) => openDialog(e, dialogTypes.SHARE)}>
                        <ListItemText primary={shareLang} />
                    </ListItem>
                    <ListItem button onClick={(e) => openDialog(e, dialogTypes.REMOVE)}>
                        <ListItemText primary={removeLang} />
                    </ListItem>
                </List>
            </Popover>
        </>
    );
};

export default UpdateFile;