export const FR = {
	loading: 'Chargement',
	return: 'Retour',
	remove: 'Supprimer',
	add: 'Ajouter',
	impossibleToDisplay: "Impossible d'afficher le document, veuillez le télécharger pour y accéder."
}

export const EN = {
	loading: 'Loading',
	return: 'Go back',
	remove: 'Remove',
	add: 'Add',
	impossibleToDisplay: "Impossible to display the document, please download it to acces to him."
}

export default {
	fr: FR,
	us: EN,
}
