import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Input, Grid, Link, Typography, Avatar, TextField, Badge, Tooltip, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/moment';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMeeting, getMeetings, getUsers, getUsersInMeeting, getVisio, deleteMeetingAccess } from './helpers';
import { getUser } from '../../helpers';
import MeetingRoom from '../../../components/MeetingRoom';
import moment from 'moment';
import 'moment/locale/fr';
import { Store } from '../../../store/store';
import MeetingAutocomplete from './AddUserSearchBarToMeeting';
import AddUserSearchBarCurrentMeeting from './AddUserSearchBarCurrentMeeting';
import { RemoveCircle as RemoveCircleIcon } from '@material-ui/icons';
import UserAvatar from '../../../components/UserAvatar';
import AddParticipant from './AddParticipant';
import { dispatchAlert } from '../../menu/helpers';
import { useLocation } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        width: '100%',
        overflowY: 'scroll',
    },
    title: {
        fontSize: theme.typography.pxToRem(24),
        fontWeight: theme.typography.fontWeightBold,
        textAlign: 'left',
        padding: theme.spacing(2),
    },
    meetingContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(3),
        width: '100%',
        height: 300,
        borderBottom: '1px solid #efefef',
    },
    room: {
        height: '100%',
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2, 4, 2),
    },
    roomInfos: {
        flex: 1,
        paddingLeft: theme.spacing(4),
        color: 'rgba(0, 0, 0, .87)',
        borderLeft: '1px solid #efefef',
    },
    roomInfosRow: {
        display: 'flex',
        alignItems: 'center',
        height: 'min-content',
        marginBottom: theme.spacing(1),
        '&>*': {
            marginRight: theme.spacing(1),
        },
        '&>*:first-child': {
            color: '#45535C',
        },
    },
    users: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    userItem: {
        margin: theme.spacing(1),
    },
    buttonBorder: {
        width: 'min-content',
        textTransform: 'capitalize',
        border: '1px solid #1773C8',
        borderRadius: theme.spacing(3),
    },
    meetingLinkButton: {
        margin: theme.spacing(2, 0, 0),
    },
    createButton: {
        width: 'fit-content',
    },
}));

const ONE_HOUR = 60 * 60 * 1000;

const CreateMeeting = ({ setRefresh }) => {
    const classes = useStyles();
    const [users, setUsers] = useState([]);
    const [addUsers, setAddUsers] = useState([]);
    const subjectRef = useRef(null);
    const [selectedBeginDate, handleDateBeginChange] = useState(new Date());
    const [selectedEndDate, handleDateEndChange] = useState(new Date(Date.now() + ONE_HOUR));
    const location = useLocation()
    const search = location.search.substring(1);
    const getParamsFromUrl = () => search ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : {}
    useEffect(() => {
        const params = (getParamsFromUrl() || {})
        const { beginDate, endDate } = params
        if (beginDate) {
            handleDateBeginChange(new Date(Number(beginDate)))
        }
        if (endDate) {
            handleDateEndChange(new Date(Number(endDate)))
        }
    }, [location])

    const {
        state: {
            language: {
                spots: {
                    meetingRoomTitle: meetingRoomTitleLang,
                    meetingRoomHost: meetingRoomHostLang,
                    meetingRoomSchedule: meetingRoomScheduleLang,
                    meetingRoomScheduleFrom: meetingRoomScheduleFromLang,
                    meetingRoomScheduleTo: meetingRoomScheduleToLang,
                    meetingRoomCreate: meetingRoomCreateLang,
                    meetingRoomUser: meetingRoomUserLang,
                },
                meeting: { users: participantsLang, removeUser: removeUserLang },
                general: { remove: removeLang },
            },
        },
    } = useContext(Store);

    const me = getUser();

    useEffect(() => {
        getUsers().then((users) => setUsers(users));
    }, []);


    const removeFromUsers = (userId) => addUsers.filter((user) => user.id !== userId);

    return (
        <Grid container item direction="column" xs={6} className={classes.roomInfos}>
            <div className={classes.roomInfosRow}>
                <TextField label={meetingRoomTitleLang} variant="outlined" inputRef={subjectRef} />
            </div>
            <div className={classes.roomInfosRow}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                        ampm={false}
                        inputVariant="outlined"
                        // inputLabel={meetingRoomScheduleToLang}
                        minDate={Date.now()}
                        value={selectedBeginDate}
                        onChange={(e) => {
                            handleDateBeginChange(e);
                            handleDateEndChange(new Date(Date.parse(e) + ONE_HOUR));
                        }}
                    />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                        ampm={false}

                        inputVariant="outlined"
                        // inputLabel={meetingRoomScheduleToLang}
                        minDate={selectedBeginDate}
                        value={selectedEndDate}
                        onChange={handleDateEndChange}
                    />
                </MuiPickersUtilsProvider>
            </div>
            <div className={classes.roomInfosRow}>
                <Box className={classes.roomInfosRow}>
                    <Typography>{participantsLang} :</Typography>
                    <Box className={classes.users}>
                        {addUsers.map(({ id, lastname, firstname, icon }, key) => (
                            <div key={key} className={classes.userItem}>
                                <Badge
                                    overlap="circle"
                                    badgeContent={
                                        <Tooltip placement="top" title={removeUserLang}>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setAddUsers(removeFromUsers(id));
                                                }}
                                            >
                                                <RemoveCircleIcon
                                                    classes={{ root: classes.removeIcon }}
                                                    color="error"
                                                />
                                            </button>
                                        </Tooltip>
                                    }
                                >
                                    <Tooltip placement="top" title={`${firstname} ${lastname}`}>
                                        <button type="button">
                                            <UserAvatar firstName={firstname} lastName={lastname} {...{ icon }} />
                                        </button>
                                    </Tooltip>
                                </Badge>
                            </div>
                        ))}
                    </Box>
                    <Box style={{ display: 'flex' }}>
                        <AddParticipant users={addUsers} setUsers={setAddUsers} />
                    </Box>
                </Box>
            </div>
            <Button
                classes={{ root: classes.createButton }}
                variant="outlined"
                onClick={() =>
                    subjectRef.current.value
                        ? createMeeting(
                            subjectRef.current.value,
                            addUsers,
                            selectedBeginDate,
                            selectedEndDate,
                            1
                        ).then(() => setRefresh((e) => !e))
                        : null
                }
            >
                {meetingRoomCreateLang}
            </Button>
        </Grid>
    );
};

const LinkToMeeting = ({ meeting, meetingRoomAccessLang }) => {
    const classes = useStyles();
    const handleOnClick = () => {
        window.open(`/meet/${meeting.id}/folder`)
    };
    return (
        <Button classes={{ root: classes.meetingLinkButton }} variant="outlined" onClick={handleOnClick}>
            {meetingRoomAccessLang}
        </Button>
    );
};

const DisplayMeeting = ({ meeting, index }) => {
    const classes = useStyles();
    const [users, setUsers] = useState([]);
    const {
        state: {
            languageValue,
            language: {
                spots: {
                    meetingRoomTitle: meetingRoomTitleLang,
                    meetingRoomHost: meetingRoomHostLang,
                    meetingRoomSchedule: meetingRoomScheduleLang,
                    meetingRoomLink: meetingRoomLinkLang,
                    meetingRoomAccess: meetingRoomAccessLang,
                    meetingRoomAddUser: meetingRoomAddUserLang,
                },
                meeting: { users: participantsLang, removeUser: removeUserLang },
                general: { add: addLang },
            },
        },
    } = useContext(Store);

    moment.locale(languageValue === 'fr' ? 'fr' : 'en');

    const me = getUser();

    useEffect(() => {
        getUsersInMeeting(meeting.id).then((data) => setUsers(data));
    }, [meeting]);

    const removeUser = (id) => {
        deleteMeetingAccess(id, meeting.id, meeting.leenk_id).then((r) => {
            setUsers(users.filter((u) => u.id != id));
        });
    };

    return (
        <Box classes={{ root: classes.meetingContainer }}>
            <Box classes={{ root: classes.room }}>
                <MeetingRoom users={users} name={`Table ${index + 1}`} {...{ meeting }} />
            </Box>
            <Box className={classes.roomInfos}>
                <Box className={classes.roomInfosRow}>
                    <Typography>{meetingRoomTitleLang} :</Typography>
                    <Typography>{meeting.title}</Typography>
                </Box>
                <Box className={classes.roomInfosRow}>
                    <Typography>{meetingRoomScheduleLang} :</Typography>
                    <Typography>
                        {moment(meeting.from).format('DD/MM/YYYY HH:SS')} -{' '}
                        {moment(meeting.to).format('DD/MM/YYYY HH:SS')}
                    </Typography>
                </Box>
                <Box className={classes.roomInfosRow}>
                    <Typography>{meetingRoomHostLang} :</Typography>
                    <Typography>
                        {me.id === meeting.owner ? me.firstname : (users.find((e) => e.id === meeting.owner) || {}).firstname}
                    </Typography>
                </Box>
                <Box className={classes.roomInfosRow}>
                    <Typography>{participantsLang} :</Typography>
                    <Box className={classes.users}>
                        {users.map(({ id, lastname, firstname, icon }, key) => (
                            <div key={key} className={classes.userItem}>
                                <Badge
                                    overlap="circle"
                                    badgeContent={
                                        <Tooltip placement="top" title={removeUserLang}>
                                            <button type="button" onClick={() => removeUser(id)}>
                                                <RemoveCircleIcon
                                                    classes={{ root: classes.removeIcon }}
                                                    color="error"
                                                />
                                            </button>
                                        </Tooltip>
                                    }
                                >
                                    <Tooltip placement="top" title={`${firstname} ${lastname}`}>
                                        <button type="button">
                                            <UserAvatar firstName={firstname} lastName={lastname} {...{ icon }} />
                                        </button>
                                    </Tooltip>
                                </Badge>
                            </div>
                        ))}
                    </Box>
                    <Box style={{ display: 'flex' }}>
                        <AddParticipant
                            users={users}
                            setUsers={setUsers}
                            meetingId={meeting.id}
                            leenkId={meeting.leenk_id}
                        />
                    </Box>
                </Box>
                <Box className={classes.roomInfosRow}>
                    <LinkToMeeting meetingRoomAccessLang={meetingRoomAccessLang} meeting={meeting} />
                </Box>
            </Box>
        </Box>
    );
};

const MeetingFloor = () => {
    const classes = useStyles();
    const [meetings, setMeetings] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const { state } = useContext(Store);
    const {
        language: {
            spots: { meetingRooms: meetingRoomsLang },
        },
    } = state;

    useEffect(() => {
        getMeetings().then((meetings) => setMeetings(meetings));
    }, [refresh]);
    return (
        <Box className={classes.root}>
            <Typography classes={{ root: classes.title }} variant={'h2'}>
                {meetingRoomsLang}
            </Typography>
            <Box className={'scroll-y'} style={{ height: '80vh' }}>
                {meetings.map((meeting, index) => (
                    <DisplayMeeting key={index} {...{ meeting, index }} />
                ))}
                <Box classes={{ root: classes.meetingContainer }}>
                    <Box classes={{ root: classes.room }}>
                        <MeetingRoom users={[]} />
                    </Box>
                    <CreateMeeting setRefresh={setRefresh} />
                </Box>
            </Box>
        </Box>
    );
};

export default MeetingFloor;
