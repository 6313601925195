import React from 'react'
import avatar0 from '../images/PNG/AvatarUsers2_1.png'
import avatar1 from '../images/PNG/AvatarUsers2_10.png'
import avatar2 from '../images/PNG/AvatarUsers2_11.png'
import avatar3 from '../images/PNG/AvatarUsers2_12.png'
import avatar4 from '../images/PNG/AvatarUsers2_13.png'
import avatar5 from '../images/PNG/AvatarUsers2_14.png'
import avatar6 from '../images/PNG/AvatarUsers2_15.png'
import avatar7 from '../images/PNG/AvatarUsers2_16.png'
import avatar8 from '../images/PNG/AvatarUsers2_17.png'
import avatar9 from '../images/PNG/AvatarUsers2_18.png'
import avatar10 from '../images/PNG/AvatarUsers2_19.png'
import avatar11 from '../images/PNG/AvatarUsers2_2.png'
import avatar12 from '../images/PNG/AvatarUsers2_20.png'
import avatar13 from '../images/PNG/AvatarUsers2_21.png'
import avatar14 from '../images/PNG/AvatarUsers2_22.png'
import avatar15 from '../images/PNG/AvatarUsers2_23.png'
import avatar16 from '../images/PNG/AvatarUsers2_24.png'
import avatar17 from '../images/PNG/AvatarUsers2_25.png'
import avatar18 from '../images/PNG/AvatarUsers2_26.png'
import avatar19 from '../images/PNG/AvatarUsers2_27.png'
import avatar20 from '../images/PNG/AvatarUsers2_28.png'
import avatar21 from '../images/PNG/AvatarUsers2_29.png'
import avatar22 from '../images/PNG/AvatarUsers2_3.png'
import avatar23 from '../images/PNG/AvatarUsers2_30.png'
import avatar24 from '../images/PNG/AvatarUsers2_31.png'
import avatar25 from '../images/PNG/AvatarUsers2_32.png'
import avatar26 from '../images/PNG/AvatarUsers2_33.png'
import avatar27 from '../images/PNG/AvatarUsers2_34.png'
import avatar28 from '../images/PNG/AvatarUsers2_35.png'
import avatar29 from '../images/PNG/AvatarUsers2_36.png'
import avatar30 from '../images/PNG/AvatarUsers2_37.png'
import avatar31 from '../images/PNG/AvatarUsers2_38.png'
import avatar32 from '../images/PNG/AvatarUsers2_39.png'
import avatar33 from '../images/PNG/AvatarUsers2_4.png'
import avatar34 from '../images/PNG/AvatarUsers2_40.png'
import avatar35 from '../images/PNG/AvatarUsers2_41.png'
import avatar36 from '../images/PNG/AvatarUsers2_42.png'
import avatar37 from '../images/PNG/AvatarUsers2_43.png'
import avatar38 from '../images/PNG/AvatarUsers2_44.png'
import avatar39 from '../images/PNG/AvatarUsers2_45.png'
import avatar40 from '../images/PNG/AvatarUsers2_46.png'
import avatar41 from '../images/PNG/AvatarUsers2_47.png'
import avatar42 from '../images/PNG/AvatarUsers2_48.png'
import avatar43 from '../images/PNG/AvatarUsers2_49.png'
import avatar44 from '../images/PNG/AvatarUsers2_5.png'
import avatar45 from '../images/PNG/AvatarUsers2_50.png'
import avatar46 from '../images/PNG/AvatarUsers2_6.png'
import avatar47 from '../images/PNG/AvatarUsers2_7.png'
import avatar48 from '../images/PNG/AvatarUsers2_8.png'
import avatar49 from '../images/PNG/AvatarUsers2_9.png'
const paths = [
	(props) => <img {...props} alt="avatar" src={avatar0} />,
	(props) => <img {...props} alt="avatar" src={avatar1} />,
	(props) => <img {...props} alt="avatar" src={avatar2} />,
	(props) => <img {...props} alt="avatar" src={avatar3} />,
	(props) => <img {...props} alt="avatar" src={avatar4} />,
	(props) => <img {...props} alt="avatar" src={avatar5} />,
	(props) => <img {...props} alt="avatar" src={avatar6} />,
	(props) => <img {...props} alt="avatar" src={avatar7} />,
	(props) => <img {...props} alt="avatar" src={avatar8} />,
	(props) => <img {...props} alt="avatar" src={avatar9} />,
	(props) => <img {...props} alt="avatar" src={avatar10} />,
	(props) => <img {...props} alt="avatar" src={avatar11} />,
	(props) => <img {...props} alt="avatar" src={avatar12} />,
	(props) => <img {...props} alt="avatar" src={avatar13} />,
	(props) => <img {...props} alt="avatar" src={avatar14} />,
	(props) => <img {...props} alt="avatar" src={avatar15} />,
	(props) => <img {...props} alt="avatar" src={avatar16} />,
	(props) => <img {...props} alt="avatar" src={avatar17} />,
	(props) => <img {...props} alt="avatar" src={avatar18} />,
	(props) => <img {...props} alt="avatar" src={avatar19} />,
	(props) => <img {...props} alt="avatar" src={avatar20} />,
	(props) => <img {...props} alt="avatar" src={avatar21} />,
	(props) => <img {...props} alt="avatar" src={avatar22} />,
	(props) => <img {...props} alt="avatar" src={avatar23} />,
	(props) => <img {...props} alt="avatar" src={avatar24} />,
	(props) => <img {...props} alt="avatar" src={avatar25} />,
	(props) => <img {...props} alt="avatar" src={avatar26} />,
	(props) => <img {...props} alt="avatar" src={avatar27} />,
	(props) => <img {...props} alt="avatar" src={avatar28} />,
	(props) => <img {...props} alt="avatar" src={avatar29} />,
	(props) => <img {...props} alt="avatar" src={avatar30} />,
	(props) => <img {...props} alt="avatar" src={avatar31} />,
	(props) => <img {...props} alt="avatar" src={avatar32} />,
	(props) => <img {...props} alt="avatar" src={avatar33} />,
	(props) => <img {...props} alt="avatar" src={avatar34} />,
	(props) => <img {...props} alt="avatar" src={avatar35} />,
	(props) => <img {...props} alt="avatar" src={avatar36} />,
	(props) => <img {...props} alt="avatar" src={avatar37} />,
	(props) => <img {...props} alt="avatar" src={avatar38} />,
	(props) => <img {...props} alt="avatar" src={avatar39} />,
	(props) => <img {...props} alt="avatar" src={avatar40} />,
	(props) => <img {...props} alt="avatar" src={avatar41} />,
	(props) => <img {...props} alt="avatar" src={avatar42} />,
	(props) => <img {...props} alt="avatar" src={avatar43} />,
	(props) => <img {...props} alt="avatar" src={avatar44} />,
	(props) => <img {...props} alt="avatar" src={avatar45} />,
	(props) => <img {...props} alt="avatar" src={avatar46} />,
	(props) => <img {...props} alt="avatar" src={avatar47} />,
	(props) => <img {...props} alt="avatar" src={avatar48} />,
	(props) => <img {...props} alt="avatar" src={avatar49} />,
]

export const userIconsLenght = paths.length


const UserIcons = ({
	index = 0,
	color = 'black',
	size = 44,
	style,
	viewBox: initViewBox = '0 0 512 512',
	className,
	name,
}) => {
	const props = {
		width: size,
		height: size,
	}
	return paths[index](props)

}

export default UserIcons
