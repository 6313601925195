import React, { useContext } from 'react';
import { createPrivateLeenk, sendMessageApi } from '../../../leenks/Leenks/helpers';
import { Store } from '../../../../store/store';
import { dispatchAlert } from '../../../menu/helpers';
const types = {
	USER: 0,
	TEAM: 1,
	SHARED_TEAM: 2
}
const useShareFileActions = ({
	element: { id, type },
	file: {
		id: fileId,
		extension,
	}
}) => {
	const { state: { alerts }, dispatch } = useContext(Store)
	const addMessageFileToUser = async () => {
		const leenk = await createPrivateLeenk([Number(id)]);
		await sendMessageApi(leenk.id, '', [], { fileId, extension })
	}

	const handleClick = async () => {
		try {
			switch (type) {
				case types.USER:
					//crée le private meeting + crée un message dans le private leenk avec le fichier + donne les droits d'accès au dossier à l'user
					await addMessageFileToUser()
					break;
				case types.TEAM:
				case types.SHARED_TEAM:
					//create message with document : fileId, et donner les droits au dossier à tous les users de la Team
					await sendMessageApi(id, '', [], { fileId, extension })
					break;
				default:
					break;
			}
			dispatchAlert(dispatch, alerts, { type: 'success' })

		} catch (error) {
			dispatchAlert(dispatch, alerts, { type: 'error' })
		}
	}
	return {
		handleClick
	}
};

export default useShareFileActions;