import { fetchApi, apiUrl, getHeaders } from "../../helpers";

export const getMyLockers = () => fetchApi('/lockers')

export const createFolder = (type, name, parentId = null) => fetchApi('/folder', {
  method: 'POST',
  body: {
    type,
    name,
    parentId
  }
})

export const getParent = (folderId) => fetchApi(`/parent/${folderId}`)

export const getFolders = (folderType, parentType) => fetchApi(`/folders/${folderType}/${parentType}`)
export const getFolderUsers = (id) => fetchApi(`/folder/users/` + id)

export const getSpecificFolders = (folderId) => fetchApi(`/folder/${folderId}`)

export const sendFile = (file, folderId) => fetchApi(`/file/${folderId}`, {
  method: 'POST',
  body: file,
  multiform: true
})

export const getFile = fileId => fetchApi('/file/' + fileId)

export const getFileDownload = fileId => fetch(apiUrl + '/download/' + fileId, { headers: getHeaders() })


export const getCustomers = () => fetchApi('/folders/public/customer')

export const getSuppliers = () => fetchApi('/folders/public/supplier')

export const getTeams = () => fetchApi('/folders/public/team')

export const createDocx = (folderId, fileName) => fetchApi('/docx', {
  method: 'POST',
  body: {
    folderId,
    fileName
  }
})

export const mooveFolderTo = (folderId, to, parentId) => fetchApi('/moove/folder', {
  method: 'PUT',
  body: {
    folderId,
    to,
    parentId
  }
})
export const reverseString = str => [...str].reverse().join('')

export const getFileExtension = filename => {
  const reversed = reverseString(filename)
  return reverseString(reversed.substr(0, reversed.indexOf('.'))).replaceAll(' ', '')
}

export const updateFile = (filedId, body) => fetchApi('/file/'+filedId, {
  method: 'PATCH',
  body
})
export const removeFile = (filedId, body) => fetchApi('/file/'+filedId, {
  method: 'DELETE',
})

export default {
  getFolderUsers,
  getFile,
  getFileDownload,
  reverseString,
  getFileExtension,
  updateFile,
  removeFile
}