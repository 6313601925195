import React, { useEffect, useRef, useState, useContext } from 'react';
import { createLabel, getLabels } from "./helpers";
import {
  InputAdornment, IconButton, TextField, Typography,
  FormControl, Select, MenuItem, Chip, Divider
} from "@material-ui/core";
import { ColorPicker } from "material-ui-color";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import LabelIcon from '@material-ui/icons/Label';
import { useStyles } from './style';
import { Store } from '../../store/store'

const Label = ({ taskId, tlabel, updateTask, setTasks, labels, setLabels }) => {
  const classes = useStyles()
  const [color, setColor] = useState('black')
  const [label, setLabel] = useState('');
  const labelRef = useRef(null);
  const {
    state: {
      language: {
        tasks: {
          taskLabel: taskLabelLang,
          taskLabelChoose: taskLabelChooseLang,
        }
      }
    } } = useContext(Store)

  useEffect(() => {
    getLabels()
    .then(data => {
      setLabels(data)
    })
  }, [])

  useEffect(() => {
    if (tlabel && labels.length) {
      const actualLabel = labels.find(({ id }) => tlabel === id) || {}
      setLabel(actualLabel.label)
      setColor(actualLabel.color)
    }
  }, [tlabel, labels])

  const add = async () => {
    try {
      const { value } = labelRef.current
      const { labelId } = await createLabel(value, color)
      setLabels(labels => [...labels, { id: labelId, color, label: value }]);
      await updateTask({ label: labelId })
      setTasks(tasks => tasks
        .map(task => task.id === taskId ? {
          ...task,
          label: labelId
        } : task))
      setLabel(value)
      setColor(color)
    } catch (e) {
    }
  }

  const deleteLabelFromTask = async () => {
    try {
      await updateTask({ label: 0 })
      setTasks(tasks => tasks
        .map(task => task.id === taskId ? {
          ...task,
          label: null
        } : task))
      setLabel('')
      setColor('black')
    } catch (e) {
    }
  }

  const selectLabel = async e => {
    const { id: labelId, label, color } = labels.find(({ id }) => e.target.value === id) || { label: 0 }
    try {
      await updateTask({ label: e.target.value })
      setTasks(tasks => tasks
        .map(task => task.id === taskId ? {
          ...task,
          label: labelId
        } : task))
      setLabel(label)
      setColor(color)
    } catch (e) { }
  }
  const [disabled, setDisabled] = useState(true)
  const isDisabled = (e) => setDisabled(!!!(e.target || {}).value)
  return (<div className={classes.row}>
    <Typography classes={{ root: classes.title }}>
      <LabelIcon />
      {taskLabelLang}:
    </Typography>
    {label
      ? (
        <div className={classes.borderedContainer}>
          <Chip label={label} onDelete={deleteLabelFromTask}
            color="primary"
            style={{ backgroundColor: color || 'black' }}
          />
        </div>
      ) : (
        <div className={classes.lineSpaceBetween}>
          <TextField
            variant="outlined"
            inputRef={labelRef}
            onChange={isDisabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ColorPicker
                    onChange={e => {
                      setColor(e.css.backgroundColor)
                    }}
                    value={color}
                    defaultValue={'black'}
                    hideTextfield />
                  <IconButton disabled={disabled} edge="end" onClick={add}>
                    <AddCircleOutlineOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Divider orientation="vertical" flexItem />
          <FormControl variant="outlined">
            <Select value={label} onChange={selectLabel}>
              <MenuItem disabled value="">
                {taskLabelChooseLang}
              </MenuItem>
              {labels.map(({ color, label, id }, index) => <MenuItem style={{ backgroundColor: color }} key={index} value={id}>{label}</MenuItem>)}
            </Select>
          </FormControl>
        </div>
      )}
  </div>
  );
};

export default Label;
